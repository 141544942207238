export function AddToCalendar(props: { style?: any }) {
  return (
    <div className={`${props.style}  w-fit`}>
      <p
        style={{
          margin: "0px,0px,10px,0px",
          textAlign: "center",
          fontSize: "17px",
          lineHeight: "150%",
          color: "#000000",
          fontWeight: "bold",
        }}
      >
        Add all events to calendar
      </p>
      <p
        className="grid grid-flow-col"
        style={{
          margin: "0px,0px,10px,0px",
          textAlign: "center",
          fontSize: "0px",
        }}
      >
        <a
          href="https://www.addevent.com/calendar/XK624050+apple"
          title="Apple"
          target="_blank"
          style={{ display: "inline" }}
        >
          <img
            src="https://cdn.addevent.com/libs/imgs/icon-emd-share-apple-t1.png"
            alt="Apple"
            width="45"
            // border="0"
            style={{ width: "45px", display: "inline" }}
          />
        </a>
        <a
          href="https://www.addevent.com/calendar/XK624050+google"
          title="Google"
          target="_blank"
          style={{ display: "inline" }}
        >
          <img
            src="https://cdn.addevent.com/libs/imgs/icon-emd-share-google-t1.png"
            alt="Google"
            width="45"
            // border="0"
            style={{ width: "45px", display: "inline" }}
          />
        </a>
        <a
          href="https://www.addevent.com/calendar/XK624050+office365"
          title="Office 365"
          target="_blank"
          style={{ display: "inline" }}
          //   style="display:inline;"
        >
          <img
            src="https://cdn.addevent.com/libs/imgs/icon-emd-share-office365-t1.png"
            alt="Office 365"
            width="45"
            // border="0"
            style={{ width: "45px", display: "inline" }}
          />
        </a>
        <a
          href="https://www.addevent.com/calendar/XK624050+outlook"
          title="Outlook"
          target="_blank"
          style={{ display: "inline" }}
        >
          <img
            src="https://cdn.addevent.com/libs/imgs/icon-emd-share-outlook-t1.png"
            alt="Outlook"
            width="45"
            // border="0"
            style={{ width: "45px", display: "inline" }}
          />
        </a>
        <a
          href="https://www.addevent.com/calendar/XK624050+outlookcom"
          title="Outlook.com"
          target="_blank"
          style={{ display: "inline" }}
        >
          <img
            src="https://cdn.addevent.com/libs/imgs/icon-emd-share-outlookcom-t1.png"
            alt="Outlook.com"
            width="45"
            // border="0"
            style={{ width: "45px", display: "inline" }}
          />
        </a>
        <a
          href="https://www.addevent.com/calendar/XK624050+yahoo"
          title="Yahoo"
          target="_blank"
        >
          <img
            src="https://cdn.addevent.com/libs/imgs/icon-emd-share-yahoo-t1.png"
            alt="Yahoo"
            width="45"
            // border="0"
            style={{ width: "45px", display: "inline" }}
          />
        </a>
      </p>
      <p
        style={{ margin: 0, padding: "25px 0px 0px 0px", textAlign: "center" }}
      >
        <a
          href="https://www.addevent.com/?utm_source=calendar&utm_medium=atclinks&utm_id=plg"
          target="_blank"
          style={{
            fontWeight: "normal",
            color: "#125ef8",
            textDecoration: "underline",
          }}
        >
          <img
            src="https://cdn.addevent.com/web/images/logo-email-plain-fff-t1.png"
            alt=""
            width="129"
            height="15"
            style={{ width: "129px", height: "15px", display: "inline" }}
          />
        </a>
      </p>
    </div>
  );
}
