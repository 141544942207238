import {
  RealHourlyWageCalculator,
  TopiaTextInput,
  formatCurrency,
} from "@topia-app/topia-react-web";
import { useState } from "react";

export function TBCalculator({ blok }) {
  const [state, setState] = useState(blok.formula.inputs);
  const stateValue = {};
  blok.formula.inputs.forEach((input) => {
    stateValue[input.id] = input.defaultValue;
  });
  const keys = Object.keys(stateValue);

  let completedFormula = "";
  for (let input of keys) {
    completedFormula += `let ${input} = ${stateValue[input] || `0`};\n`;
  }

  completedFormula += blok.formula.formula;
  const formulaResult = eval(completedFormula);
  return (
    <div className="flex w-full flex-row justify-center bg-rgb-blue/20">
      <div className="w-fit p-2">
        <h2 className="my-2 text-xl">
          {blok.title ? blok.title : "Cashflow Calculator"}
        </h2>
        {/* <pre>{JSON.stringify(blok, null, 2)}</pre> */}
        <div className="rounded-md bg-slate-600 p-2">
          <div className="flex  flex-col justify-center ">
            <p className="text-sm text-topia-gray">Profit</p>
            <p className="text-2xl font-bold text-future-blue">
              {formatCurrency(formulaResult)}/mo
            </p>
          </div>
          <div className="my-4 grid grid-cols-2 gap-4 md:flex md:flex-row  md:items-end">
            {state.map((inputs, ix) => {
              if (inputs.defaultValue === "0") {
                setState((s) => {
                  s[ix].defaultValue = "";
                  return [...s];
                });
              }
              return (
                <div
                  key={inputs.id}
                  className="rounded-md  md:flex md:flex-col md:items-center"
                >
                  <TopiaTextInput
                    label={inputs.label}
                    key={inputs.id}
                    inputProps={{
                      value: inputs.defaultValue,
                      onChange: (ev) => {
                        setState((s) => {
                          s[ix].defaultValue = ev.target.value;

                          return [...s];
                        });
                      },
                      type: "number",
                    }}
                  />
                </div>
              );
            })}
          </div>
          {blok.include_real_hourly_wage && (
            <RealHourlyWageCalculator monthlyProfit={formulaResult} />
          )}
        </div>
      </div>
    </div>
  );
}
