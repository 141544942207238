import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth";
import { db } from "../firebase";
import { CONSTANTS } from "../constants";
import { collection, doc, query, setDoc } from "firebase/firestore";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";

import {
  TopiaButton,
  TopiaEmailInput,
  TopiaPasswordInput,
  TopiaTextInput,
} from "@topia-app/topia-react-web";
import { mixpanelTrack } from "../helpers";
import { useQuery } from "../hooks/useQuery";

export function SignUpPage(props: {}) {
  const authCtx = useAuthContext();
  const queryParams = useQuery();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    mixpanelTrack(`page_view_sign_up`);
  }, []);

  if (authCtx.user) {
    navigate("/");
  }

  async function onSubmit(vals) {
    try {
      setLoading(true);

      const user = await authCtx.signUpWithEmail(
        vals.email.trim(),
        vals.password,
        vals.name
      );

      await setDoc(doc(collection(db, "users"), user.user.uid), {
        email: vals.email.trim(),
        name: vals.name,
        phone: vals.phone,
        isInTheInnerCircle:
          queryParams.get("ic") === "147F2591-0A58-407F-B779-E5EC573BD9E6",
      });

      navigate("/");
    } catch (ex) {
      if (ex.code === "auth/email-already-in-use") {
        setErr(
          <div className="p-4">
            <Link to="/login">
              <p className="text-red-500">Email already in use</p>
              <p className="text-red-500">Try logging in</p>
            </Link>
          </div>
        );
      }
      console.error(ex);
    } finally {
      setLoading(false);
    }
  }

  // console.log(errors);
  return (
    <>
      <div className="flex items-center justify-center p-8">
        <div className="w-full max-w-[500px]">
          <div className="w-full">
            <div className="flex items-center justify-center">
              <img
                src="/logo.svg"
                className="max-w-[300px] h-auto"
                style={{
                  filter: "invert(1)",
                }}
              />
            </div>
            <h2 className="my-8 text-2xl text-center md:text-5xl  tracking-tight text-white font-serif font-light">
              Welcome to the FIpreneurs Member's Portal!
            </h2>
            <p className="text-white text-md mb-3 md:mb-6">
              Now, let's create your member's portal account where you'll access
              all of your new side business playbooks, resources, community, and
              tools.
            </p>
            <p className="text-future-blue font-bold text-md mb-5">
              The password you just created was to manage your billing. Please
              create your separate FIpreneurs portal account below.
            </p>
            <a
              href="/"
              target="_blank"
              className="underline text-slate-300 my-4 hover:text-future-blue hover:cursor-pointer"
            >
              Already have an account?
            </a>
          </div>

          <div className="w-full mt-2">
            <div className="">
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <TopiaTextInput
                    label="Name"
                    inputProps={register("name", {
                      required: true,
                    })}
                    id="name"
                    className=""
                  />
                  {errors.name && (
                    <p className="text-red-500 mb-3">Please enter your name</p>
                  )}

                  <TopiaEmailInput
                    label="Email address"
                    inputProps={register("email", {
                      required: true,
                      onChange: (ev) => {
                        // console.log(ev);
                      },
                    })}
                    id="email"
                    className=""
                  />
                  {errors.email && (
                    <p className="text-red-500 mb-3">
                      Please enter your email address
                    </p>
                  )}

                  <p className="text-white">Phone Number</p>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <PhoneInput
                        label="Optional: Phone Number"
                        defaultCountry="US"
                        value={value}
                        onChange={onChange}
                        inputProps={register("phone", { required: true })}
                        id="phone"
                        className="w-full p-4 rounded-xl"
                      />
                    )}
                  />
                  {errors.phone && (
                    <p className="text-red-500 mb-3">
                      Please enter your phone number
                    </p>
                  )}

                  <TopiaPasswordInput
                    label="Password"
                    inputProps={register("password", {
                      required: true,
                    })}
                    id="login-password"
                    className="mt-4"
                  />
                </div>

                <div>
                  <TopiaButton
                    analyticsEvent="sign_up_page_submit_clicked"
                    type="submit"
                    className="w-full"
                    btnStyle="primary"
                    loading={loading}
                  >
                    Create Account
                  </TopiaButton>
                </div>
              </form>

              <p className="text-xs text-white mt-2">
                By creating your account you agree to the FIPreneur Community{" "}
                <a
                  href="/fipreneurs_terms_and_conditions.pdf"
                  className="text-future-blue underline"
                  target="_blank"
                >
                  Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  href="/fipreneurs_community_privacy_policy.pdf"
                  className="text-future-blue underline"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </p>

              {err}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
