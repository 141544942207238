import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { adminGetUser, adminSaveUser } from "../api";

export function UserPage() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({});
  const [userPrivateInfo, setUserPrivateInfo] = useState<any>({});
  const [adminUserInfo, setAdminUserInfo] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    async function getUser() {
      try {
        setLoading(true);
        const { data } = await adminGetUser({ id: slug });

        setUserInfo(data.userInfo);
        setUserPrivateInfo(data.userPrivate);
        setAdminUserInfo(data.userAdmin);

        setLoading(false);
      } catch (err) {
        console.log("Error:", err);
      }
    }
    getUser();
  }, []);

  return (
    <div className="p-8">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="flex flex-col gap-4">
          <button
            onClick={async () => {
              try {
                setButtonLoading(true);
                adminSaveUser({
                  privateUser: userPrivateInfo,
                  adminInfo: adminUserInfo,
                  id: userInfo.uid,
                });
                console.log("working");
                setButtonLoading(false);
                navigate("/admin");
              } catch (err) {
                alert(
                  "Hi Logan, there was an error. Please contact Chad or Matt at your earliest convenience."
                );
                console.log("Error", err);
              }
            }}
            className="text-white border rounded-md p-3  hover:bg-blue-600"
          >
            {buttonLoading ? "Loading..." : "Save"}
          </button>
          <p className="text-xl text-white">User: {userInfo.displayName}</p>
          <p className="text-xl text-white">Email: {userInfo.email}</p>
          <div className="flex flex-row items-center gap-4">
            <label className="text-white text-md" id="onboarding">
              Has completed onboarding?
            </label>
            <input
              type="checkbox"
              id="onboarding"
              checked={userPrivateInfo?.hasCompletedOrientation}
              onChange={() => {
                setUserPrivateInfo((prev) => ({
                  ...prev,
                  hasCompletedOrientation: !prev?.hasCompletedOrientation,
                }));
              }}
            />
          </div>
          <div className="flex flex-col ">
            <label className="text-white text-md" id="grant-check-in">
              Notes
            </label>
            <textarea
              value={adminUserInfo?.notes}
              onChange={(e) => {
                setAdminUserInfo((prev) => ({
                  ...prev,
                  notes: e.target.value,
                }));
              }}
              rows={15}
              className="min-h-fit"
            />
          </div>
        </div>
      )}
    </div>
  );
}
// onboarding completed and grant meeting completed
