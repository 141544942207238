import { TopiaButton } from "@topia-app/topia-react-web";
import { useDataContext } from "../context/DataContext";
import { useState } from "react";

export function CancelSubscriptionModal(props: { onDismiss: () => void }) {
  const dataCtx = useDataContext();
  const [isDealTaken, setIsDealTaken] = useState(false);
  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-topia-black text-xl text-center">
        <strong>You won't regret it - I promise</strong>
      </h1>

      <p className="text-sm px-[20%] text-center text-neutral-500">
        You're next bill will only be for $40. Please reach out to
        support@fipreneurs.com if you have any additional questions
      </p>

      {!isDealTaken && (
        <>
          <h1 className="text-topia-black text-xl  ">
            <strong>
              I don't want you to walk away from unlocking a life you love.
            </strong>
          </h1>
          <p className="text-topia-black">
            I promised I would do everything I can to help you unlock more
            freedom in your life.
          </p>
          <p className="text-topia-black">
            Before you click that final button, I want to honor that promise
            with the FIpreneur Lifeline, designed specifically to to reignite
            your journey:
          </p>
          <ol className="flex flex-col gap-3 mx-6 mb-8">
            <li className="list-decimal">
              <strong>Discounted Membership:</strong> Enjoy a reduced rate of
              just $40 for the next 30 days.
            </li>
            <li className="list-decimal">
              <strong>Work with me directly:</strong> I’m not about generic
              advice. In the eext few days, you'll get an email from me,
              personally. Let’s dive deep into what’s holding you back and
              together, map out a clear, actionable plan to reignite your
              journey towards freedom.
            </li>
          </ol>
          <div className="flex flex-col gap-4 md:flex-row md:gap-6">
            <button
              className="p-2 border hover:scale-105 hover:shadow-xl hover:shadow-black transition ease-in-out   font-bold bg-pale-yellow rounded-full border-black  text-sm"
              onClick={() => {
                setIsDealTaken(true);
                dataCtx.updateUser({
                  deal_lifeline40_taken_at: new Date().toISOString(),
                });
              }}
            >
              I'll take the Lifeline and keep moving towards a life on my own
              terms
            </button>
            <a
              className="border text-sm mx-1 p-1 text-center flex flex-row items-center rounded-full hover:bg-black/10 border-black"
              onClick={() => {
                props.onDismiss();
                dataCtx.updateUser({ triedToCancel: new Date() });
              }}
              href="https://course.grantsabatier.com/account/billing"
            >
              No thanks, I want to cancel my subscription and get back to my
              usual routine.
            </a>
          </div>
        </>
      )}
    </div>
  );
}
