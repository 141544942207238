import { TopiaButton } from "@topia-app/topia-react-web/lib/components/TopiaButton";
import { AddToCalendar } from "../components/AddToCalendar";
import { useEffect, useState } from "react";
import { mixpanelTrack } from "../helpers";
import { VIDEO_VAULT } from "../video-vault-data";
import { DummyLoader } from "../components/DummyLoader";

export function WeeklyCallsPage() {
  useEffect(() => {
    mixpanelTrack(`page_view_weekly_calls`);
  }, []);

  const [weeklyFilter, setWeeklyFilter] = useState<any>("all");

  const visibleVideos = VIDEO_VAULT.filter((x) => {
    if (weeklyFilter === "all") {
      return x.type === "weekly-build" || x.type === "weekly-idea";
    }
    return x.type === weeklyFilter;
  });

  return (
    <div className="p-4 gap-8 grid grid-cols-2">
      <div className="p-4">
        <div className="flex flex-col items-center gap-2 md:flex-row pb-4 border-b border-neutral-500">
          <h1 className="text-2xl text-white flex-1">
            Watch our previous calls
          </h1>
          <select
            className="p-4 px-8 border-neutral-500 text-topia-black bg-white rounded-full"
            value={weeklyFilter}
            onChange={(ev) => setWeeklyFilter(ev.target.value)}
          >
            <option value="all">All</option>
            <option value="weekly-idea">Idea Generation</option>
            <option value="weekly-build">Business Building</option>
          </select>
        </div>
        <DummyLoader key={weeklyFilter} rippleClassNames="white">
          <div className="flex flex-col gap-4 mt-4 md:grid md:grid-cols-2">
            {visibleVideos.map((v) => (
              <div
                key={v.filename}
                className="flex-1 topia-fade-in p-2 rounded-md bg-black"
              >
                <div className="flex flex-row gap-4 justify-between mb-6">
                  <p className="text-white text-md font-bold">{v.title}</p>
                  <p className="text-white text-md ">{v.date || ""}</p>
                </div>

                <div className="flex w-full items-center justify-center">
                  <iframe
                    src={`https://share.descript.com/embed/${v.filename}`}
                    width="100%"
                    height="220"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            ))}
          </div>
        </DummyLoader>
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="text-xl text-white text-center">
          Unlocking More Freedom: Weekly Group Coaching with Grant
        </h1>
        <p className="text-white">
          <strong>Grant as Your Coach:</strong> You're not just getting
          guidance; you're accessing a wealth of knowledge, experience, and
          inspiration tailored to help you achieve your goals.
        </p>
        <p className="text-white">
          <strong>Stay on Track to a Life You Love:</strong> Grant will ensure
          every part of your journey is aligned with your ultimate goal:
          crafting a life filled with joy and purpose. Together, you'll overcome
          challenges, refine your strategies, and grow your business—all while
          staying true to your vision.
        </p>
        <p className="text-white">
          <strong>Your Key to Success:</strong> Treat these calls as essential.
          Schedule them, set reminders, and carve out this crucial time with
          Grant. It's an investment in your future, ensuring you're consistently
          advancing toward your dreams.
        </p>
        <div className="flex flex-row bg-future-blue p-4 rounded-md justify-center">
          <AddToCalendar style=" " />
        </div>

        <section className="flex flex-col relative gap-4 ">
          <p className="text-white absolute top-[20%] right-[50%]">
            Loading...
          </p>
          {/* <p className="text-white  text-lg mt-6">Weekly Meetings</p> */}
          <iframe
            src="https://www.addevent.com/calendar/XK624050/embed/?id=XK624050&calendars=XK624050&calendars_sel=XK624050&config=true&title=&titleshow=true&today=true&datenav=true&date=true&monthweektoggle=true&subscribebtn=true&swimonth=true&swiweek=true&swischedule=true&print=true&timezone=true&defaultview=month&firstday=1&datetimeformat=1#XK624050"
            className="h-[70vh]"
          ></iframe>
        </section>
      </div>
    </div>
  );
}
