import { TopiaButton, TopiaTextInput } from "@topia-app/topia-react-web";
import classNames from "classnames";
import { useDataContext } from "./context/DataContext";
import { useEffect, useRef, useState } from "react";
import { useModalContext } from "./context/ModalContext";
import {
  ArrowLeftCircleIcon,
  ArrowPathIcon,
  CheckIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import _ from "lodash";
import { DASHBOARD_MODULES } from "./module-data";
import { DummyLoader } from "./components/DummyLoader";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "./firebase";
import { useAuthContext } from "./auth";
import { uuidv4 } from "./helpers";
import { PortalModule } from "./module-types";
import { InnerCirclePitch } from "./components/InnerCirclePitch";
import { Tooltip } from "react-tooltip";
import { HiInformationCircle } from "react-icons/hi";
import { DbUser } from "./types";
import { StepsConfig } from "./components/StepsConfig";
import { WelcomeVideo } from "./components/WelcomeVideo";
import { BigGetStartedButton } from "./components/BigGetStartedButton";
import { PreInnerCircle } from "./components/PreInnerCircle";
import { IdeaGenPrimer } from "./components/IdeaGenPrimer";
import { SingleChoice } from "./components/SingleChoice";
import { PlaybookSelectMain } from "./components/PlaybookSelectMain";
import { PlaybookSelectSecondary } from "./components/PlaybookSelectSecondary";
import { TextArea } from "./components/TextArea";
import { MultiText } from "./components/MultiText";
import { Table } from "./components/Table";
import { YoutubeEmbed } from "./components/YoutubeEmbed";
import { ChatGPTIntegration } from "./components/ChatGPTIntegration";
import { TopiaStepper } from "./TopiaStepper";
import { FreeAccountWelcome } from "./components/FreeAccountWelcome";
import { FreePaywall } from "./components/FreePaywall";

function DashboardModuleBody({
  config,
  configId,
  isLockedOut,
  noBack,
  onNext,
  onQuestionOpen,
}: {
  config: PortalModule;
  configId: string;
  isLockedOut: boolean;
  noBack?: boolean;
  onNext?: (moduleId?: string) => void;
  onQuestionOpen?: (questionOpen?: boolean) => void;
}) {
  const dataCtx = useDataContext();
  const authCtx = useAuthContext();
  const modalCtx = useModalContext();
  const [textInputValue, setTextInputValue] = useState("");
  const [multiTextValue, setMultiTextValue] = useState<string[]>([]);
  const [tableValue, setTableValue] = useState<any[]>([[]]);
  const [shrinking, setShrinking] = useState(false);
  const [chatSessionId, setChatSessionId] = useState<string | null>();
  const noBackButton = noBack || config.noBackButton;
  const [questionOpen, setQuestionOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // This function and the subsequent useEffect need to be rewritten, it's gotten too hacky, and it's 100% my fault :)
  // - Matt
  function gotoNextModule(
    _nextId: string,
    moduleResult?: any,
    href?: any,
    user?: Partial<DbUser>
  ) {
    let nextId = _nextId;

    const nextConfig = DASHBOARD_MODULES[nextId];
    if (
      (nextConfig?.type === "pre-inner-circle" ||
        nextConfig?.type === "inner-circle") &&
      dataCtx.user?.isInTheInnerCircle
    ) {
      nextId = "diyValidateOptionsInnerCircle";
    }
    modalCtx.closeModal();
    setQuestionOpen(false);

    const payload = {
      finishedAt: new Date().toISOString(),
      text: textInputValue,
      table: JSON.stringify(tableValue),
      multi: JSON.stringify(multiTextValue),
      result: moduleResult || null,
    };

    const newUser = { ..._.cloneDeep(dataCtx.user), ...user };
    newUser.currentModuleId = nextId;

    if (
      (nextId === "ideabotResult" || nextId === "onboardingCompleted") &&
      moduleResult?.value
    ) {
      newUser.activePlaybook = moduleResult.value;
    }

    newUser.moduleResults = newUser.moduleResults || {};
    newUser.moduleHistory = newUser.moduleHistory || ([] as any);
    newUser.moduleHistory = [...newUser.moduleHistory, configId];

    const historyId = configId; //getModuleHistoryId(flowId, configId);
    newUser.moduleResults[historyId] = {
      ...payload,
      history: [...(newUser.moduleResults[historyId]?.history || []), payload],
    };

    /**
     * When a user goes back and edits old questions, clear out the chatSessionId
     * for any modules that reference this module
     */
    if (dataCtx.chatRequests) {
      const chatRequests = Object.entries(dataCtx.chatRequests);
      const chatSessionsReferenced = chatRequests.filter(
        ([id, session]) =>
          session.includedModuleResponses.indexOf(configId) > -1
      );

      console.log("references", chatSessionsReferenced);
      for (let [id, session] of chatSessionsReferenced) {
        // Find all module results referencing this chat session
        const moduleResultsReferencingSession = Object.entries(
          newUser.moduleResults
        ).filter(([id, result]) => result.chatSessionId === session.sessionId);

        // Remove the chat session from the chat requests
        console.log("!!!!deleting", moduleResultsReferencingSession);
        for (let ref of moduleResultsReferencingSession) {
          newUser.moduleResults[ref[0]].chatSessionId = "";
        }
      }
    }

    if (newUser.activePlaybook) {
      newUser.playbookStates[newUser.activePlaybook] = nextId;
    }

    onNext && onNext(nextId);

    setShrinking(true);
    dataCtx.updateUser(newUser);

    setTimeout(() => {
      if (href) {
        window.open(href, "_blank");
      }
    }, 250);
  }

  const session = chatSessionId ? dataCtx.chatRequests[chatSessionId] : null;

  const newSessionLock = useRef(false);
  const activePlaybook =
    DASHBOARD_MODULES[dataCtx.user.currentModuleId]?.playbook;

  useEffect(() => {
    if (dataCtx.doingInitialLoad) return;
    setShrinking(false);

    setTextInputValue("");

    setTableValue([[]]);
    setMultiTextValue([]);

    const previousModuleResults = ((dataCtx.user.moduleResults || {})[
      configId
    ] || {}) as any;
    if (
      previousModuleResults?.history &&
      previousModuleResults.history.length > 0
    ) {
      const lastModHistory =
        previousModuleResults.history[previousModuleResults.history.length - 1];

      if (lastModHistory.text) {
        setTextInputValue(lastModHistory.text);
      }

      if (lastModHistory.table) {
        setTableValue(JSON.parse(lastModHistory.table));
      }

      if (lastModHistory.multi) {
        setMultiTextValue(JSON.parse(lastModHistory.multi));
      }
    }

    if (
      config.type === "simple-chat-gpt-integration" ||
      config.type === "output-chat-gpt-integration"
    ) {
      let sessionId;
      let isNewSession = false;

      if (previousModuleResults.chatSessionId) {
        sessionId = previousModuleResults.chatSessionId;
        newSessionLock.current = false;
        setChatSessionId(sessionId);
      } else {
        if (newSessionLock.current) {
          return;
        }
        newSessionLock.current = true;
        isNewSession = true;
        sessionId = `${configId}-${uuidv4()}`;
      }

      // console.log(sessionId);

      if (chatSessionId !== sessionId) {
        const newChatRequests = _.cloneDeep(dataCtx.chatRequests);

        let prompt = "";
        prompt += config.userInputPrefix + "\n";
        prompt +=
          "Please format " +
          (config.type === "output-chat-gpt-integration"
            ? "The first part of your output"
            : "your output") +
          " as simple, valid and unstyled html, including <ul>, <li>, <strong>, etc where appropriate.\n";

        prompt += config.includedModuleIds
          .map((id) =>
            JSON.stringify({
              label: id,
              text: dataCtx.user.moduleResults[id]?.text,
              result: dataCtx.user.moduleResults[id]?.result,
            })
          )
          .join("\n");

        if (config.includedModuleIds.indexOf("ideabotIdeaTypes") > -1) {
          prompt +=
            "\nPlease only generate business ideas that fall into the category of: " +
            dataCtx.user.moduleResults["ideabotIdeaTypes"]?.result?.label;
        }

        if (config.type === "output-chat-gpt-integration") {
          prompt +=
            "Please format the footer of your output as <DONE> followed by valid JSON\nEx:\n";
          prompt +=
            "<DONE>" +
            JSON.stringify(
              config.outputFields.reduce((acc, field) => {
                acc[field.label] = field.decription;
                return acc;
              }, {} as any),
              null,
              2
            );
        }

        newChatRequests[sessionId] = {
          sessionId,
          systemPrompt: config.systemPrompt,
          createdAt: new Date().getTime(),
          includedModuleResponses: config.includedModuleIds,
          userPrompt: prompt,
        };

        if (config.gptModelId) {
          newChatRequests[sessionId].model = config.gptModelId;
        }

        if (isNewSession) {
          (async () => {
            console.log("setting session id", sessionId);
            setChatSessionId(sessionId);

            await setDoc(
              doc(collection(db, "chatRequests"), authCtx.user.uid),
              newChatRequests
            );

            await dataCtx.updateUser({
              moduleResults: {
                ...(dataCtx.user.moduleResults || {}),
                [configId]: {
                  ...dataCtx.user.moduleResults[configId],
                  chatSessionId: sessionId,
                },
              },
            });

            newSessionLock.current = false;
          })();
        }
      }
    } else {
      setChatSessionId(null);
    }
  }, [configId, chatSessionId, dataCtx.doingInitialLoad]);

  let steps:
    | {
        name: string;
        status: "complete" | "current" | "upcoming";
      }[]
    | undefined = undefined;

  if (config.stepperSteps && config.stepperCurrent !== undefined) {
    steps = config.stepperSteps.map((x, ix) => ({
      name: x,
      status:
        ix < config.stepperCurrent
          ? "complete"
          : ix === config.stepperCurrent
          ? "current"
          : "upcoming",
    }));
  }

  if (windowSize < 500 && steps && steps.length > 2) {
    const currStepIx = steps.findIndex((s) => s.status === "current");
    console.log(currStepIx);
    if (currStepIx === 0) {
      steps = steps.slice(0, 3);
    } else {
      steps = steps.slice(currStepIx - 1, currStepIx + 2);
    }
  }

  let body = (
    <div className="p-2 md:p-8 pb-0 relative z-10">
      <div className={classNames(shrinking && "topia-shrink-fade", "")}>
        {(config.title ||
          config.body ||
          config.bigText ||
          config.youtubeId ||
          config.paragraphs) && (
          <div className="portal-csv-content">
            {/* <div
                className="aspect-video p-2 px-4 border-cyan-500 ring-8 ring-gray-200 border-b-8 border-l-4 border-r-4 border-topia-black shadow-lg rounded-2xl overflow-hidden"
                style={{
                  backgroundImage: "url(/sarah-chat.png)",
                  backgroundSize: "cover",
                }}
              > */}
            <div className="">
              {config.title && (
                <h1 className="text-topia-black text-center mt-3 text-lg lg:text-xl  mb-4">
                  {config.title}
                </h1>
              )}
              {config.bigText && (
                <p
                  className="text-neutral-600 md:text-center text-md md:text-xl w-[100%] md:w-[80%] mx-auto"
                  dangerouslySetInnerHTML={{
                    __html: config.bigText,
                  }}
                ></p>
              )}
              {config.youtubeId && (
                <YoutubeEmbed youtubeId={config.youtubeId} />
              )}
              {config.body}
              {config.paragraphs &&
                config.paragraphs.map((x) => (
                  <p
                    key={x}
                    className="text-md md:text-lg leading-8 text-topia-black mt-4 max-w-5xl mx-auto"
                    dangerouslySetInnerHTML={{
                      __html: x,
                    }}
                  ></p>
                ))}
              {config.steps && <StepsConfig config={config} />}
            </div>
          </div>
        )}
        {(config.type === "output-chat-gpt-integration" ||
          config.type === "simple-chat-gpt-integration") && (
          <ChatGPTIntegration
            nextId={config.nextId}
            nextLabel={config.nextButtonLabel}
            session={session}
            activePlaybook={activePlaybook}
            onGetNewResults={() => {
              dataCtx.updateUser({
                moduleResults: {
                  [configId]: {
                    ...dataCtx.user.moduleResults[configId],
                    chatSessionId: null,
                  },
                },
              });
              setChatSessionId(null);
            }}
            onNext={(nextId, text) => {
              gotoNextModule(nextId, null, null, {
                moduleResults: {
                  ...dataCtx.user.moduleResults,
                  [config.nextId]: {
                    ...dataCtx.user.moduleResults[config.nextId],
                    text: text,
                    history: [
                      ...(dataCtx.user.moduleResults[config.nextId]?.history ||
                        []),
                      {
                        text: text,
                      },
                    ],
                  },
                },
              });
            }}
          />
        )}
        {config.type === "inner-circle" && <InnerCirclePitch />}
        {config.type === "welcome-video" && <WelcomeVideo />}
        {config.type === "big-get-started-button" && (
          <BigGetStartedButton
            onPress={() => {
              gotoNextModule(config.nextId);
            }}
          />
        )}
        {config.type === "pre-inner-circle" && (
          <PreInnerCircle
            onChartYourOwnCourse={() => {
              gotoNextModule("diyBuild1");
            }}
            onJoinInnerCircle={() => {
              gotoNextModule("innerCircle");
            }}
          />
        )}
        {config.type === "idea-gen-primer" && <IdeaGenPrimer />}
        {(config.type === "playbook-select" ||
          config.type === "existing-idea-playbook-select") && (
          <PlaybookSelectMain
            windowSize={windowSize}
            flow={
              config.type === "playbook-select" ? "new-idea" : "existing-idea"
            }
            onNextModule={(nextModuleId) => {
              gotoNextModule(nextModuleId);
            }}
            onAlreadyHaveAnIdea={() => {
              gotoNextModule("diyIdeaResults");
            }}
          />
        )}
        {config.type === "playbook-select2" && (
          <PlaybookSelectSecondary
            onGoToNextModule={(nextModuleId) => {
              gotoNextModule(nextModuleId);
            }}
          />
        )}
        {config.type === "single-choice" && (
          <SingleChoice
            choices={config.choices}
            onNext={(nextChoiceId, choice) => {
              gotoNextModule(nextChoiceId, choice);
            }}
          />
        )}
        {config.type === "textarea" && (
          <TextArea
            windowSize={windowSize}
            helpText={config.helpText ? config.helpText : undefined}
            textInputValue={textInputValue}
            onTextChange={(val) => {
              setTextInputValue(val);
            }}
            textHeader={config.inputs ? config.inputs[0] : undefined}
          />
        )}
        {config.type === "multi-text" &&
          config.inputs.map((c, cIx) => (
            <div className="my-5">
              <MultiText
                inputIx={cIx}
                inputTitle={c}
                multiTextValue={multiTextValue[cIx] || ""}
                onChange={(val) => {
                  setMultiTextValue((s) => {
                    const newTable = [...s];
                    newTable[cIx] = val;
                    return newTable;
                  });
                }}
              />
            </div>
          ))}
        {config.type === "free-welcome" && <FreeAccountWelcome />}
        {config.type === "free-paywall" && <FreePaywall />}

        {config.type === "table" && (
          <Table
            config={config}
            tableValue={tableValue}
            onAddRow={() => {
              setTableValue((s) => [...s, []]);
            }}
            onRemoveRow={() => {
              setTableValue((s) => s.slice(0, s.length - 1));
            }}
            onTableValueInputChange={(textVal, rowIx, configIx) => {
              setTableValue((s) => {
                const newTable = [...s];
                newTable[rowIx][configIx] = textVal;
                return newTable;
              });
            }}
          />
        )}
        <div
          className={classNames(
            "my-2 md:my-8 flex  flex-col items-center justify-center gap-4"
          )}
        >
          {config.nextId &&
            config.type !== "simple-chat-gpt-integration" &&
            config.id !== "influencerValidateOptions" &&
            config.id !== "contentValidateOptions" &&
            config.id !== "fbaValidateOptions" &&
            config.id !== "diyValidateOptions" && (
              <div className="flex flex-col w-full md:flex-row items-center justify-center gap-1 md:gap-4">
                <button
                  className={classNames(
                    "align-center inline-flex items-center justify-center md:py-2 md:px-6 p-2 text-center  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md text-xl font-black text-black focus:ring-gray-500 w-full md:w-[300px]",
                    " shadow-md border-topia-black border rounded-full",
                    config.nextClasses || "bg-future-blue"
                  )}
                  onClick={() => {
                    gotoNextModule(config.nextId);
                  }}
                >
                  {config.nextButtonLabel || "Next"}
                </button>
                {config.skippable && (
                  <button
                    className={classNames(
                      "align-center inline-flex items-center justify-center p-2 md:py-2 md:px-8 text-center  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md text-xl font-black text-black focus:ring-gray-500 w-full md:w-[300px]",
                      " shadow-md border-topia-black border rounded-full bg-neutral-200"
                    )}
                    onClick={() => {
                      setTextInputValue("Not sure");
                      setTimeout(() => {
                        gotoNextModule(config.nextId);
                      }, 1);
                    }}
                  >
                    I'm not sure (skip)
                  </button>
                )}
              </div>
            )}
        </div>
        {config.footerText && (
          <p className="text-md text-gray-500 my-2 px-8 text-center">
            {config.footerText}
          </p>
        )}
      </div>
    </div>
  );

  if (questionOpen) {
    body = (
      <DummyLoader key="q">
        <div className="my-8">
          <div className="mb-8 md:mb-4 cursor-pointer hover:opacity-50 transition-all">
            <div
              className="flex-row items-center flex cursor-pointer hover:opacity-50"
              onClick={() => {
                setQuestionOpen(false);
                onQuestionOpen(false);
              }}
            >
              <ArrowLeftCircleIcon width={40} height={40} color={"black"} />
              <div className="ml-4">
                <p className="text-topia-black text-xl">Go Back</p>
              </div>
            </div>
          </div>

          <h2 className="md:text-3xl text-xl md:mb-8 mb-2 text-center text-topia-black">
            Have a question?
          </h2>

          <div className="w-full flex flex-col md:grid md:grid-cols-2 md:gap-4">
            <div className="bg-future-blue shadow-md rounded-xl p-4">
              <h2 className="md:text-2xl text-lg text-center text-topia-black">
                Ask Grant
              </h2>
              <img
                src="/grant.jpg"
                className="mx-auto shadow-lg rounded-full w-[200px] h-[200px]"
              />
              <p className="md:text-md text-sm text-topia-black my-4">
                <strong>Expert Guidance:</strong> Directly ask Grant in his
                inner circle. Leverage his extensive business experience for
                your queries.
              </p>
              <a
                className="md:text-xl place-self-end text-white text-md p-3 bg-topia-black rounded-full text-center md:p-8 w-full block"
                href="/join-the-inner-circle"
                target="_blank"
              >
                Join Grant’s Inner Circle
              </a>
            </div>
            <div className="bg-neutral-100 shadow-md rounded-xl p-4">
              <h2 className="md:text-2xl text-lg text-center text-topia-black">
                Ask your Fellow FIpreneurs
              </h2>
              <p className="text-md text-topia-black my-4">
                <strong>Harness the Hive Mind: </strong> Tap into the collective
                intelligence of the FIpreneur community. Share your challenges
                and queries in our FIpreneur Slack channel to receive insights
                and solutions from fellow FIpreneurs who've walked your path
              </p>

              {/* <iframe
                src="https://discord.com/widget?id=1100905829118529680&theme=dark"
                width="600"
                style={{ maxWidth: "100%" }}
                height="300"
                allowTransparency
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
              ></iframe> */}

              <a
                href="https://join.slack.com/t/fipreneurs/shared_invite/zt-2dtc5ygiu-FxH_Gt0MGlZh4XiOhNQkMg"
                target="_blank"
                className="rounded-full mt-4 border border-topia-black hover:opacity-90  bg-future-blue cursor-pointer  md:p-2 p-3 md:py-4 flex w-full "
              >
                <span className="text-center w-full">
                  Ask your question in the slack
                </span>
              </a>
            </div>
          </div>
        </div>
      </DummyLoader>
    );
  }

  return (
    <DummyLoader key={configId} loaderClassNames={classNames("min-h-[60vh]")}>
      <div
        className={classNames(
          config.classNames,
          "oveflow-y-scroll min-h-[60vh] relative"
        )}
        style={{
          zIndex: 100000000,
        }}
      >
        {!questionOpen && config.type !== "welcome-video" && (
          <div className="mb-4 md:mb-0">
            <div className="flex flex-col gap-5 md:gap-0 md:flex-row md:items-center">
              {!noBackButton && (
                <div className="div absolute z-[1000] left-0 top-0 md:relative">
                  <div className="md:mb-4 cursor-pointer hover:opacity-50 transition-all">
                    <div
                      className="flex-row items-center flex cursor-pointer hover:opacity-50"
                      onClick={() => {
                        if (
                          !dataCtx.user.moduleHistory ||
                          dataCtx.user.moduleHistory.length === 0
                        ) {
                          return;
                        }

                        const history = [...(dataCtx.user.moduleHistory || [])];
                        const lastModule = history.pop();

                        if (isLockedOut) {
                          modalCtx.closeModal();
                        }

                        setShrinking(true);
                        setTimeout(() => {
                          dataCtx.updateUser({
                            ...dataCtx.user,
                            currentModuleId: lastModule,
                            playbookStates: {
                              ...dataCtx.user.playbookStates,
                              ...(dataCtx.user.activePlaybook
                                ? {
                                    [dataCtx.user.activePlaybook]: lastModule,
                                  }
                                : {}),
                            },
                            moduleHistory: history,
                          });
                        }, 250);
                      }}
                    >
                      <ArrowLeftCircleIcon
                        width={40}
                        height={40}
                        color={"black"}
                      />
                      <div className="ml-2 md:ml-4">
                        <p className="text-topia-black hidden text-xl md:block">
                          Go Back
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {steps ? (
                <div className="pl-14 md:pl-0 flex-1 w-full">
                  <TopiaStepper steps={steps} />
                </div>
              ) : (
                <div className="flex-1"></div>
              )}
              {!isLockedOut &&
                (dataCtx.user.isInTheInnerCircle ? (
                  <a
                    href={`/ask-grant-a-question?moduleId=${config.id}`}
                    className="p-4 rounded-full  bg-pale-yellow border border-topia-black cursor-pointer transition-all hover:scale-105 shadow-sm"
                  >
                    Ask Grant
                  </a>
                ) : (
                  <TopiaButton
                    className="mt-2"
                    onClick={() => {
                      setQuestionOpen(true);
                      if (onQuestionOpen) {
                        onQuestionOpen(true);
                      }
                    }}
                  >
                    I have a question!
                  </TopiaButton>
                ))}
            </div>
          </div>
        )}

        {body}

        {/* {isLockedOut && (
          <p className="flex flex-row gap-4 text-center mt-8 text-gray-400 items-center justify-end text-xs">
            <span>For Support:</span>
            <a className="underline" href="mailto:logan@topiafi.com">
              logan@topiafi.com
            </a>
          </p>
        )} */}
      </div>
    </DummyLoader>
  );
}

export function DashboardModule(props: {
  configId: string;
  className?: string;
  noLockOut?: boolean;
  noBack?: boolean;
  onQuestionOpen?: (questionOpen: boolean) => void;
  onNext?: (nextId?: string) => void;
}) {
  const modalCtx = useModalContext();

  const config = DASHBOARD_MODULES[props.configId];

  if (!config) {
    return null;
  }

  const isLockedOut = config.isLockedOut && !props.noLockOut;

  const body = (
    <div className={classNames("flex w-full")}>
      <DashboardModuleBody
        config={config}
        noBack={props.noBack}
        configId={props.configId}
        isLockedOut={isLockedOut}
        onNext={props.onNext}
        onQuestionOpen={props.onQuestionOpen}
      />
    </div>
  );

  useEffect(() => {
    if (isLockedOut) {
      modalCtx.openModal(body, {
        forced: true,
        noPanel: config.noPanel,
        videoBg: config.videoBg,
      });
    }
  }, [props.configId]);

  if (isLockedOut) {
    return <></>;
  }

  return (
    <div className={classNames("bg-white p-4 rounded-md", props.className)}>
      {body}
    </div>
  );
}
