import stories from "../stories.json";
import { StoryblokComponent } from "@storyblok/react";

export function TopiaStoryblokComponent(props: { slug: string }) {
  // Try finding by full slug or slug
  let story = stories.find((x) => x.full_slug === props.slug);
  if (!story) {
    story = stories.find((x) => x.slug === props.slug);
  }

  if (!story) {
    throw new Error("Story not found: " + props.slug);
  }

  return <StoryblokComponent blok={story.content} />;
}
