import React, { useState, Fragment, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ModalContext, ModalProvider } from "./context/ModalContext";
import { ApplicationShell } from "./components/ApplicationShell";
import { LoginPage } from "./pages/LoginPage";
import { Navigate, Router, RouterProvider } from "react-router-dom";
import { router } from "./router";
import { AuthProvider } from "./auth";

import { apiPlugin, getStoryblokApi, storyblokInit } from "@storyblok/react";
import { defaultStoryblokConfig } from "@topia-app/topia-react-web";
import TBSideHustlePage from "./components/PlaybookIntro";
import { DataProvider } from "./context/DataContext";
import { set } from "lodash";
import { DummyLoader } from "./components/DummyLoader";

import { backofficeLog } from "@topia-app/topia-lib";

import "./App.css";
import "driver.js/dist/driver.css";

export function GlobalModal(props: {}) {
  const modalCtx = useContext(ModalContext);
  return (
    <Transition.Root show={modalCtx.mounted} as={Fragment}>
      <Dialog
        as="div"
        className="relative border z-[1000]"
        onClose={() => {
          if (!modalCtx.forced) {
            modalCtx.closeModal();
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
            <div className="topia-slow-fade-in">
              {modalCtx.videoBg && (
                <video poster="" controls={false} autoPlay muted loop>
                  <source src={modalCtx.videoBg.mp4} type="video/mp4" />
                </video>
              )}
            </div>
          </div>
        </Transition.Child>

        {modalCtx.noPanel ? (
          <Dialog.Overlay className="fixed inset-0 bg-transparent border-0 outline-0 flex items-center justify-center">
            {modalCtx.modal}
          </Dialog.Overlay>
        ) : (
          <div className="fixed inset-0 z-[9999]  overflow-y-auto">
            <div className="flex min-h-full md:items-center justify-center p-0 md:p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative ${
                    modalCtx.forced && `h-screen rounded-none md:rounded-2xl`
                  } transform min-w-full md:min-w-0 px-4 pt-8 pb-4 text-left shadow-xl transition-all md:my-8 md:w-full md:max-w-4xl md:p-6 bg-white border-2 md:max-h-[90vh] overflow-auto border-white/20 md:rounded-2xl`}
                >
                  {!modalCtx.forced && (
                    <div className="absolute top-4 right-4 ">
                      <XCircleIcon
                        className="w-8 h-8 z-10 text-topia-black cursor-pointer hover:text-rgb-blue"
                        onClick={() => {
                          modalCtx.closeModal();
                        }}
                      />
                    </div>
                  )}
                  <div className="mt-4">{modalCtx.modal}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        )}
      </Dialog>
    </Transition.Root>
  );
}

function App() {
  useEffect(() => {
    window.addEventListener("error", function (ev) {
      backofficeLog(
        {
          clientId: "fipreneurs-members-site",
          message: ev.message,
          level: "error",
          data: { ...ev.error },
        },
        (process.env["REACT_APP_ENV"] as any)!
      );
    });
  }, []);

  // useEffect(() => {
  //   const int = setInterval(() => {
  //     const url = window.location.href;
  //     const $intercomEl = document.querySelector(".intercom-lightweight-app");

  //     if ($intercomEl) {
  //       console.log(url.indexOf("/playbook"));
  //       if (url.indexOf("/playbook/") > -1) {
  //         console.log("true");
  //         // @ts-ignore
  //         $intercomEl.style.display = "none";
  //       } else {
  //         // @ts-ignore
  //         $intercomEl.style.display = "none";
  //       }
  //     }
  //   }, 500);

  //   return () => {
  //     clearInterval(int);
  //   };
  // }, []);
  return (
    <>
      <DummyLoader key="root" rippleClassNames="white">
        <ModalProvider>
          <AuthProvider>
            <DataProvider>
              <RouterProvider router={router} />
              <GlobalModal />
            </DataProvider>
          </AuthProvider>
        </ModalProvider>
      </DummyLoader>
    </>
  );
}

export default App;
