import { TopiaButton } from "@topia-app/topia-react-web";
import { TopiaAccordion } from "./TopiaAccordion";
import React, { FC, ReactElement, useState } from "react";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";

export function UKFreePaywall() {
  const benefits: {
    id: string;
    title: string;
    benefitDetail: string;
    specificDetails: string[];
  }[] = [
    {
      id: "validate",
      title: "Work directly with your coach to validate your idea",
      benefitDetail:
        "80% of side businesses fail because their ideas aren't viable. Work directly with your coach to make sure your idea is a winner right from the start.",
      specificDetails: [
        "Your coach will personally review your idea to make sure it's a winner",
      ],
    },
    {
      id: "roadmap",
      title: "Your Custom-Built Roadmap to Success",
      benefitDetail:
        "Your coach will help you build out a  customized roadmap that shows you exactly what you need to do to start and scale your side business.",
      specificDetails: [
        "Tailored roadmap for you",
        "Step-by-step instructions",
        "Q&A chat support with coaches",
      ],
    },
    {
      id: "accountability",
      title: "Weekly Accountability Sessions",
      benefitDetail:
        "Keep your momentum and stay on track with our structured accountability sessions.",
      specificDetails: [
        "Focused 'Power Hours'",
        "Online work zones for collaboration with other FIpreneurs",
      ],
    },
    {
      id: "tools",
      title: "Exclusive Tools & Resources",
      benefitDetail:
        "Dive into a treasure trove of resources and connect with a community of ambitious FIpreneurs.",
      specificDetails: [
        "Curated content vault",
        "Discover my favorite tools and resources",
        "Collaborate with hundreds of FIpreneurs",
      ],
    },
    {
      id: "support",
      title: "Support Every Step of the Way",
      benefitDetail:
        "Continuous support and guidance from your coach and the FIpreneur team, keeping you on track and focused.",
      specificDetails: [
        "24/7 chat support",
        "Weekly progress tracking & check-ins",
      ],
    },
    // {
    //   id: "bonus",
    //   title: "BONUS:  Grant’s Financial Freedom 8-module Online Course",
    //   benefitDetail:
    //     "Get the exact step-by-step process tens of thousands of people around the world are using to lay the groundwork to achieve financial freedom and live the life they actually want.",
    //   specificDetails: [
    //     "50+ exclusive videos",
    //     "Wealth-building frameworks",
    //     "Time-saving tools & calculators",
    //   ],
    // },
  ];
  const faqs: {
    id: string;
    question: string;
    answer: string[] | ReactElement<any, any>[];
  }[] = [
    {
      id: "who-is-community-for",
      answer: [
        "The FIpreneur community is for anyone who is ready to start taking major steps toward starting a side business they love to rapidly create more freedom in their life.",
        "Whether you’re just getting started with making money, stuck in a job that’s eating away at your life, or trying to figure it out way later in life… this community will help you achieve huge, lasting results across all areas of your financial life.",
      ],
      question: "Who’s this community for?",
    },
    {
      id: "already-have-business",
      answer: [
        "Absolutely! I've successfully scaled and exited my own businesses, and I'm eager to pass that invaluable experience on to you. Whether you're just starting out or looking to expand, my personalized coaching and extensive resources are tailored to help you overcome obstacles and rapidly advance towards more freedom in your life.",
        "Plus, in our FIpreneur community, you'll be in good company with people at all stages of business, fostering a supportive and diverse environment for everyone's growth.",
      ],
      question:
        "What if I already have a business? Can the FIpreneur community still help me?",
    },
    {
      id: "no-business-idea",
      answer: [
        "There's no need to worry if you haven't found your business idea yet.",
        "In the FIpreneur community, we have custom-built AI tools specifically designed to help you uncover an idea that resonates with you.",
        "Then, I personally work with every member to validate their options.",
        "We'll guide you in leveraging your unique skills to generate and test multiple ideas for profitability. This way, you'll quickly discern which idea is truly promising, ensuring you don't spend months on concepts that lack potential.",
      ],
      question: "What if I don't have a business idea?",
    },
    {
      id: "business-knowledge",
      answer: [
        "None! You don’t need to be some sort of business genius to pull any of this off.",
        "(Take it from a guy who’s never taken a business or finance class in his entire life.)",
        "That’s why I built this community in such a way that I think anyone can jump in and pick up the basic principles to come up with a strategy that works best for them and execute for immediate results.",
        "And if you’ve already got some business experience and skills, you’ll be able to use the ideas and resources inside the community to jump to the next level in your financial freedom journey.",
      ],
      question: "How much business knowledge/experience do I need?",
    },
    {
      id: "how-community-works",
      answer: [
        "When you join our community, you gain immediate access to a wealth of tools, resources, and playbooks designed to propel your FIpreneur journey forward. These serve as invaluable blueprints to kickstart your path to financial independence.",
        "Additionally, you will be onboarded onto our exclusive 24/7 Discord support channel, providing you with round-the-clock assistance.",
        "Then, engage with the community through the channel and by participating in our weekly coaching calls to get guidance on any specific questions or challenges you may encounter. This is also an opportunity to be inspired by your fellow FIpreneurs!",
        "Lastly, our community hosts regular accountability working sessions, ensuring that we all commit to the necessary deep work required to make significant progress in our side businesses.",
      ],
      question: "How exactly does your community work?",
    },
    {
      id: "how-is-community-different",
      answer: [
        "The key difference with the FIpreneur community lies in the personalized support you receive directly from me.",
        "This isn't just another program where you're overloaded with content and left to figure things out alone. I'm actively involved, working alongside you, offering my time and energy to ensure your success.",
        "You'll have the opportunity to join calls where we can tackle your challenges together and keep you moving forward. My approach is hands-on, and I'm committed to helping you every step of the way. Together, we'll ensure that you're not just learning, but actively applying strategies to increase your income and unlock more freedom in your life.",
      ],
      question:
        "How is the FIpreneur community different from other programs?    ",
    },
    {
      id: "time-commitment",
      answer: [
        "Whatever works best for you.",
        "On average, a FIpreneur spends 2-4 hours per week working on their side business.",
        "Ultimately, it boils down to how quickly you want to achieve the next level of financial freedom in your journey.",
        "The more time you dedicate to learning and implementing the strategies found inside this community, the faster you’ll be able to build wealth, change your life, and reach financial freedom.",
      ],
      question: "What is the time commitment?    ",
    },
    {
      id: "coaching",
      answer: [
        "Each weekly group coaching session is live. Every session is recorded, so you can always catch up.",
      ],
      question: "Is the coaching live? What if I miss one?    ",
    },
    {
      id: "timezone",
      answer: [
        "No, our community is designed to accommodate members from all around the world. We understand the importance of inclusivity and have arranged our calls and activities to cater to different time zones.",
      ],
      question: "Does it matter what timezone I’m in?    ",
    },
    {
      id: "refund",
      answer: [
        <p>
          You’ve got a 30-day fee-back guarantee where you can try the community
          out and see if you like it. If at that point you’re somehow not
          satisfied with the value found inside, you can let us know and we’ll
          give you a full refund of your fee. Just email us at{" "}
          <a className={`text-underline text-rgb-blue`}>
            support@fipreneurs.com
          </a>
        </p>,
      ],
      question: "What’s the refund policy?    ",
    },
    {
      id: "more-questions",
      answer: [
        <p>
          No sweat! Just email us at{" "}
          <a className={`text-underline text-rgb-blue`}>
            support@fipreneurs.com
          </a>
        </p>,
      ],
      question: "What if I’ve got more questions?",
    },
  ];
  const benefitsShownObject = benefits.reduce((prev, acc, ix) => {
    return { ...prev, [acc.id]: true };
  }, {} as any);

  const [questionsShown, setQuestionsShown] = useState<{
    [id: string]: boolean;
  }>(benefitsShownObject);

  const videoTestimonials: {
    name: string;
    sideBusiness: string;
    description: string;
    video: string;
    link?: string;
  }[] = [
    {
      name: "John",
      description:
        "See how John combined Grant's strategies with insights from fellow FIpreneurs to launch his Amazon FBA brand",
      sideBusiness: "Amazon FBA - Pacifiers",
      video: "https://www.youtube.com/embed/Cw6F9gB4H1A",
    },
    {
      name: "Jess",
      description:
        "Jess joined the community with 3 half-baked ideas. See how Grant guided her to identify the winner and swiftly launch a thriving blog in just a month!",
      sideBusiness: "Japanese Food Blog",
      link: "https://veganjapanese.com/",
      video: "https://www.youtube.com/embed/SGOeFl6LduY",
    },
    {
      name: "Claire",
      description:
        "Check out how Grant helped Claire carve out a profitable niche and grow her email list to 500+ subscribers.",
      sideBusiness: "Self Sufficiency Blog",
      video: "https://www.youtube.com/embed/EyR8n3-1fIs",
      link: "https://becomingtraditional.com/about-becoming-traditional/",
    },
  ];

  const fiPreneurBenefits = [
    "Work directly with your coach to validate your idea",
    "Personalized step by step Roadmap to launching your side business",
    "2x Weekly Group Coaching Calls with your coach",
    "Weekly Accountability Working Sessions",
    "24/7 Chat Support",
    // "Access Grant's Exclusive Video Vault",
    // "BONUS: Grant's 8-Module Financial Freedom Course",
  ];

  const images = [
    "./cnbc.svg",
    "./yahoo-finance.svg",
    "/money.svg",
    "new-york-times.svg",
    "/npr.svg",
    "/rachael-ray-show.svg",
    "/bbc.svg",
    "/chicago-tribune.svg",
    "/forbes.svg",
    "/huffington-post.svg",
    "/inc.svg",
    "marketwatch.svg",
    "/msn.svg",
    "/usa-today.svg",
    "/washington-post.svg",
    "/wsj.svg",
  ];
  return (
    <div className={`flex flex-col gap-10`}>
      <div
        className={`flex flex-col-reverse gap-2 mt-6 md:mt-0 md:flex-row md:items-center`}
      >
        <div className={`flex-1 flex flex-col gap-4`}>
          <h1 className={`flex flex-col `}>
            <span
              className={`text-rgb-blue self-center font-sans font-bold text-xl `}
            >
              From Idea to Income
              <br />
            </span>
            <p className={`text-black text-lg font-sans font-bold`}>
              Launch Your Side Business in 90 Days and Start Unlocking More
              Freedom in Your Life
            </p>
          </h1>
          <p className={`text-md `}>
            Excited by your new idea but unsure about the next steps?
          </p>
          <p className={`text-md `}>
            The FIpreneur community was designed to provide all the tools and
            expertise necessary to transform your idea into an income-generating
            side business in just 90 days.
          </p>
          <ul className={`flex flex-col gap-2`}>
            {fiPreneurBenefits.map((benefit) => (
              <li
                className={`flex flex-row gap-1 ml-3 md:ml-4 flex-1  `}
                key={benefit}
              >
                <span
                  className={`w-[6px] h-[6px] mt-2 bg-rgb-blue rounded-full`}
                />
                <p className={` flex-1`}>{benefit}</p>
              </li>
            ))}
          </ul>
          <a
            className="bg-future-blue border-black border p-3 rounded-2xl font-bold text-center"
            href="https://buy.stripe.com/00gg2jg2EbVNgo09AU"
          >
            Start your 7 day free trial
          </a>
        </div>
      </div>

      <div className={`flex flex-col mt-4 gap-6`}>
        <h3 className={`text-lg text-rgb-blue font-bold font-sans text-center`}>
          Here’s How the FIpreneur Community Will Transform Your Idea into an
          Income-Generating Side Business in Just 90 Days
        </h3>
        <div className={`flex flex-col `}>
          {benefits.map((benefit, ix) => (
            <div className={``}>
              <div className={`flex flex-row `}>
                <div className={`flex     flex-col `}>
                  <p
                    className="p-2 md:w-[90px] w-[45px] text-center"
                    style={{
                      background:
                        "linear-gradient(105deg, transparent 25%, #75FBDE 0, #75FBDE 75%, transparent 0)",
                    }}
                  >
                    {ix + 1}.
                  </p>
                  <div
                    className={`w-[6px] self-center flex-1 bg-gray-300/20 ${
                      ix === benefits.length - 1 ? "hidden" : "block"
                    }`}
                  />
                </div>
                <div className={`flex flex-col gap-2  `}>
                  <p className={`font-sans text-lg font-bold`}>
                    {benefit.title}
                  </p>

                  <button
                    className={`text-start flex flex-row gap-1 items-center ${
                      !questionsShown[benefit.id] ? "pb-5" : "pb-0"
                    }`}
                    onClick={() => {
                      setQuestionsShown((prev) => ({
                        ...prev,
                        [benefit.id]: !prev[benefit.id],
                      }));
                    }}
                  >
                    <TbTriangleInvertedFilled
                      color="blue"
                      size={10}
                      className={`transition-transform duration-300 ${
                        questionsShown[benefit.id] ? "rotate-0" : "-rotate-90"
                      }`}
                    />

                    {questionsShown[benefit.id]
                      ? "Hide Benefit Details"
                      : "Show Benefit Details"}
                  </button>
                  {questionsShown[benefit.id] && (
                    <div className={`flex flex-col gap-3`}>
                      <p className={`font-bold`}>{benefit.benefitDetail}</p>
                      <ul className={`list-disc ml-4 pb-10`}>
                        {benefit.specificDetails.map((details) => (
                          <li>{details}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <a
          className="bg-future-blue border-black border p-3 rounded-2xl font-bold text-center"
          href="https://buy.stripe.com/00gg2jg2EbVNgo09AU"
        >
          Start your 7 day free trial
        </a>
      </div>
      <div className={`grid md:grid-cols-3 gap-7  grid-cols-1`}>
        {videoTestimonials.map((testimonial) => (
          <div className={`relative`}>
            <div
              className={`border z-20 relative bg-white border-rgb-blue p-3 h-[350px] md:h-[410px] flex flex-col gap-1`}
            >
              <iframe src={testimonial.video}></iframe>

              <p className={`my-2`}>{testimonial.description}</p>
              <div className={`flex flex-1 flex-col  justify-end`}>
                <div className={``}>
                  <p className={`font-bold`}>{testimonial.name}</p>
                  <p>
                    Side business:{" "}
                    {testimonial.link ? (
                      <a
                        className={`text-underline text-rgb-blue`}
                        href={testimonial.link}
                      >
                        {testimonial.sideBusiness}
                      </a>
                    ) : (
                      <span>{testimonial.sideBusiness}</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`w-[90%]  z-10 h-[95%] bg-rgb-blue -right-4 -bottom-4 absolute`}
            />
          </div>
        ))}
      </div>
      <div className={`flex flex-col gap-8`}>
        <div>
          <h4 className={`text-2xl text-hot-pink font-bold text-center`}>
            Smart Questions Answered
          </h4>
          <p className={`text-center`}>
            Here are answers to some of the most common questions about the
            FIpreneur community.
          </p>
        </div>
        {faqs.map((faq, ix) => (
          <div className={``}>
            <div className={`flex flex-row `}>
              <div className={`flex     flex-col `}>
                <p
                  className="p-2 md:w-[90px] w-[45px] text-center"
                  style={{
                    background:
                      "linear-gradient(105deg, transparent 25%, #75FBDE 0, #75FBDE 75%, transparent 0)",
                  }}
                >
                  {ix + 1}.
                </p>
                <div
                  className={`w-[6px] self-center flex-1 bg-gray-300/20 ${
                    ix === faqs.length - 1 ? "hidden" : "block"
                  }`}
                />
              </div>
              <div className={`flex flex-col gap-2  `}>
                <p className={`font-sans text-lg font-bold`}>{faq.question}</p>
                <button
                  className={`text-start flex flex-row gap-1 items-center ${
                    !questionsShown[faq.id] ? "pb-0" : "pb-2"
                  }`}
                  onClick={() => {
                    setQuestionsShown((prev) => ({
                      ...prev,
                      [faq.id]: !prev[faq.id],
                    }));
                  }}
                >
                  <TbTriangleInvertedFilled
                    color="blue"
                    size={10}
                    className={`transition-transform duration-300 ${
                      questionsShown[faq.id] ? "rotate-0" : "-rotate-90"
                    }`}
                  />

                  {questionsShown[faq.id] ? "Hide Answer" : "Show Answer"}
                </button>
                {questionsShown[faq.id] && (
                  <div className={`flex flex-col gap-3 pb-8`}>
                    {faq.answer.map((answer) => (
                      <p>{answer}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}

        <a
          className="bg-future-blue border-black border p-3 rounded-2xl font-bold text-center"
          href="https://buy.stripe.com/00gg2jg2EbVNgo09AU"
        >
          Start your 7 day free trial
        </a>
      </div>
    </div>
  );
}
