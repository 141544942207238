export function PlaybookSelectSecondary(props: {
  onGoToNextModule: (nextModuleId: string) => void;
}) {
  return (
    <div>
      {/* <h3 className="text-3xl text-center text-topia-black my-4">
              Which interests you most?
            </h3> */}
      {[
        {
          title: "Creating a Website",
          capitalRequired: "$0 - $500",
          timeToFirst1k: "8-12 months",
          body: "Take your passion and turn it into your business. We'll show you how to create content that people love and how to monetize it.",
          logo: "/content.png",
          bg: "bg-pale-yellow",
          nextId: "contentIdeaResults",
        },
        {
          title: "Social Media Influncer",
          capitalRequired: "$0 - $500",
          timeToFirst1k: "1-6 months",
          body: "Take your passion and turn it into your business. We'll show you how to create content that people love and how to monetize it.",
          logo: "/content.png",
          bg: "bg-future-blue",
          nextId: "contentIdeaResults",
        },
      ].map((pb, ix) => (
        <div
          className={`${pb.bg} mb-4 p-8 rounded-2xl border-b-4 border-topia-black cursor-pointer transition-all hover:scale-105 `}
          key={ix}
          onClick={() => {
            props.onGoToNextModule(pb.nextId);
          }}
        >
          <div className="flex items-center flex-row justify-between">
            <div
              style={{
                width: 200,
              }}
            >
              <img style={{ width: "100%", height: "auto" }} src={pb.logo} />
            </div>
            <div className="flex-1 ml-8">
              <h3 className="text-2xl text-center mb-1">{pb.title}</h3>

              <p className="text-lg">{pb.body}</p>

              <div className="flex items-center gap-8 justify-end flex-1 mt-6">
                <p className="text-sm text-center text-topia-black border border-topia-black rounded-full px-6">
                  Capital Required
                  <br />
                  <strong>{pb.capitalRequired}</strong>
                </p>
                <p className="text-sm text-center text-topia-black border border-topia-black rounded-full px-6">
                  Time to make $1,000
                  <br />
                  <strong>{pb.timeToFirst1k}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="flex items-center justify-center">
        <button className="cursor-pointer hover:opacity-50 hover:scale-105 transition-all my-4 px-4 text-center text-lg mx-auto w-[330px] py-4 border border-topia-black text-topia-black rounded-full">
          I have something else in mind
        </button>
      </div>
    </div>
  );
}
