import classNames from "classnames";

export function SingleChoice(props: {
  choices: {
    value?: string;
    label: string;
    subtext?: string;
    nextId: string;
  }[];
  onNext: (nextChoiceId: string, choice: any) => void;
}) {
  return (
    <>
      <div
        className={classNames(
          "flex gap-4 flex-col md:flex-row mt-8 md:gap-8 flex-wrap items-center justify-center"
        )}
      >
        {props.choices.map((choice) => (
          <div
            key={choice.nextId}
            className="md:mx-4 max-w-[300px] w-full flex-1 flex flex-col items-center"
          >
            <a
              onClick={() => {
                props.onNext(choice.nextId, choice);
              }}
              href={
                choice.nextId.startsWith("http") ? choice.nextId : undefined
              }
              className="p-[1px] min-h-[140px]  md:min-h-[220px] bg-neutral-800 hover:bg-black transition-all group  cursor-pointer flex flex-col items-center justify-center w-full rounded-md hover:scale-105  md:ring-4 md:ring-neutral-600 rounded-md"
            >
              <div className="flex flex-col items-center justify-center">
                <p className="text-sm md:text-[30px] font-black text-white text-center">
                  {choice.label}
                </p>
                {choice.subtext && (
                  <p className="text-xs text-center text-white font-medium mt-4">
                    {choice.subtext}
                  </p>
                )}
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  );
}
