import { HiInformationCircle } from "react-icons/hi";
import { Tooltip } from "react-tooltip";

export function TextArea(props: {
  helpText?: string;
  textInputValue: string;
  textHeader?: string;
  onTextChange: (val) => void;
  windowSize: number;
}) {
  const isTablet = props.windowSize <= 1200;
  const placeholder = props.helpText ? props.helpText : "";
  console.log(!isTablet);
  return (
    <div className="relative my-6">
      <p className="text-lg">{props.textHeader}</p>
      {!isTablet && props.helpText && (
        <div className="flex flex-row ">
          <HiInformationCircle
            size={20}
            color="blue"
            className="my-anchor-element rounded-full w-5 h-5 cursor-pointer absolute  right-2 top-2"
          />
          <Tooltip
            place="left-end"
            opacity={100}
            delayHide={100}
            // openOnClick
            clickable={true}
            // closeEvents={{ click: true }}
            anchorSelect=".my-anchor-element"
          >
            <div className="w-48 md:w-full md:max-w-xl">
              <p>{props.helpText}</p>
            </div>
          </Tooltip>
        </div>
      )}

      <textarea
        className=" p-4  rounded-xl   text-topia-black text-lg w-full bg-white shadow-md "
        value={props.textInputValue}
        placeholder={isTablet ? placeholder : ""}
        rows={5}
        onChange={(ev) => {
          props.onTextChange(ev.target.value);
        }}
      ></textarea>
    </div>
  );
}
