export function PreInnerCircle(props: {
  onJoinInnerCircle: () => void;
  onChartYourOwnCourse: () => void;
}) {
  return (
    <div>
      <h2 className="text-3xl mb-2 text-center text-topia-black">
        How can we help?
      </h2>

      <p className="text-lg mb-8 text-center text-topia-black font-medium">
        Discover how the FIpreneurs can help you overcome your challenge
      </p>

      <div className="w-full flex flex-col gap-3 md:grid md:grid-cols-2 md:gap-4">
        <div className="bg-future-blue shadow-md rounded-xl p-4">
          <h2 className="text-2xl text-center text-topia-black  mb-4">
            Hands On Support
          </h2>
          <img
            src="/grant.jpg"
            className="mx-auto shadow-lg rounded-full w-[200px] h-[200px]"
          />
          <p className="text-lg text-topia-black my-4">
            <strong>Grant will personally review your challenge</strong> and
            reach out with a custom roadmap crafted just for you. Enjoy ongoing
            weekly support and guidance from him, driving you towards faster
            success.
          </p>
          <button
            className="md:text-xl text-white bg-topia-black rounded-full text-center p-2 py-4 md:p-8 w-full"
            onClick={() => {
              props.onJoinInnerCircle();
            }}
          >
            Join Grant’s inner circle
          </button>
        </div>
        <div className="flex flex-col bg-neutral-100 shadow-md rounded-xl p-4">
          <h2 className="text-2xl text-center text-topia-black  mb-4">
            Chart Your Own Course
          </h2>

          <img
            src="/logo.svg"
            className="w-[80%] h-auto mx-auto my-8"
            style={
              {
                // filter: "invert(1)",
              }
            }
          />
          <p className="text-lg text-topia-black my-4">
            Use all the great tools, helpful resources, and advice from fellow
            FIpreneur members to tackle your business challenges.
          </p>

          {/* <iframe
                src="https://discord.com/widget?id=1100905829118529680&theme=dark"
                width="600"
                style={{ maxWidth: "100%" }}
                height="300"
                allowTransparency
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
              ></iframe> */}
          <div className="flex-1"></div>
          <a
            // href="http://discord.gg/3xkGr8wWmM"
            // target="_blank"
            onClick={() => {
              props.onChartYourOwnCourse();
            }}
            className="rounded-full mt-4 border border-topia-black hover:opacity-90  bg-pale-yellow cursor-pointer  p-2  py-8 flex w-full "
          >
            <span className="text-center w-full text-xl">Enter The Portal</span>
          </a>
        </div>
      </div>
    </div>
  );
}
