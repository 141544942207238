export function BigGetStartedButton(props: { onPress: () => void }) {
  return (
    <div className="flex w-full h-full items-end justify-end pt-[25%] pr-[16%]">
      {/* <div className="p-4 md:p-8 font-black text-topia-black shadow-xl text-white text-xl md:text-3xl uppercase rounded-full bg-gradient-to-r from-hot-pink to-rgb-blue border border-white"> */}
      {/* <ArrowDownCircleIcon
        width={60}
        height={60}
        color={"white"}
        className="animate-bounce"
      /> */}
      <button
        className="p-4 font-black shadow-xl text-white  text-2xl md:text-4xl uppercase rounded-full backdrop-blur-md bg-black/20 border border-topia-black/10 cursor-pointer transition-all hover:scale-110 md:w-[33vw]"
        onClick={() => {
          props.onPress();
        }}
        style={{
          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="w-full h-full bg-future-blue/90 hover:bg-future-blue backdrop-blue-md rounded-full p-4">
          <p className="drop-shadow-md text-topia-black">Get Started</p>
        </div>
      </button>
    </div>
  );
}
