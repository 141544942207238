import { CheckIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

export function TopiaStepper({
  steps,
}: {
  steps: {
    name: string;
    status: "complete" | "current" | "upcoming";
  }[];
}) {
  return (
    <nav aria-label="Progress" className="px-8 md:px-16 w-full">
      <ol
        role="list"
        className="flex flex-1 justify-center mb-8 md:mb-16 items-center"
      >
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pr-[60px] sm:pr-20" : "",
              "relative flex-1"
            )}
          >
            {step.status === "complete" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-royal-purple" />
                </div>
                <a
                  href="#"
                  className="relative flex w-6 h-6 md:h-8 md:w-8 items-center justify-center rounded-full bg-royal-purple hover:bg-indigo-900"
                >
                  <CheckIcon
                    className="h-4 w-4 md:w-5 md:h-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : step.status === "current" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  href="#"
                  className="relative flex h-5 w-5 md:h-8 md:w-8 items-center justify-center rounded-full border-2 border-royal-purple bg-white"
                  aria-current="step"
                >
                  <span
                    className="w-1.5 h-1.5 md:h-2.5 md:w-2.5 rounded-full bg-royal-purple"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  href="#"
                  className="group relative flex w-5 h-5 md:h-8 md:w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                >
                  <span
                    className="w-1.5 h-1.5 md:h-2.5 md:w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            )}
            <div className="absolute flex flex-col items-center justify-center -bottom-[75px] -left-[33px] w-[90px] md:w-[100px] h-[100px]">
              <p
                className={`text-center  text-neutral-700 text-[11px] md:text-sm, ${
                  step.status === "complete" ? "" : ""
                }`}
              >
                {step.name}
              </p>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
