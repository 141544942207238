import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { db } from "../firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
export const NotesModal = ({ closeModal, uid }) => {
  const [adminUser, setAdminUser] = useState<any>();

  useEffect(() => {
    async function getAdminUserInfo() {
      const userInfo = await getDoc(doc(collection(db, "adminUsers"), uid));
      const adminInfo = userInfo.data();
      setAdminUser(adminInfo);
    }
    getAdminUserInfo();
  }, []);

  return (
    <AnimatePresence>
      {
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => closeModal(false)}
          className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
        >
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className="bg-gradient-to-br from-violet-600 to-indigo-600 text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
          >
            <FiAlertCircle className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
            <div className="relative z-10">
              <h3 className="text-xl font-bold text-center mb-2">Notes</h3>
              <textarea
                className="w-full h-[200px] text-black"
                value={adminUser?.notes || ""}
                onChange={(ev) => {
                  setAdminUser((prev) => ({ ...prev, notes: ev.target.value }));
                }}
              />
              <div className="flex gap-2 mt-10">
                <button
                  onClick={() => closeModal(false)}
                  className="bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setDoc(
                      doc(collection(db, "adminUsers"), uid),
                      {
                        notes: adminUser.notes,
                      },
                      {
                        merge: true,
                      }
                    );
                    closeModal(false);
                  }}
                  className="bg-white hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      }
    </AnimatePresence>
  );
};
