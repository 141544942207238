const sarah = require("../Images/sarah.mp4");

export function FreeAccountWelcome() {
  return (
    <div>
      <p className="text-lg md:text-xl text-center font-bold text-topia-black">
        Ready to find your perfect side business idea?
      </p>
      <div className="flex flex-1 mt-3 flex-col  md:gap-4 shadow-md bg-neutral-100 p-2 md:p-4 rounded-md">
        <p className="text-lg text-center ">
          Answer 3 quick questions, and Sarah will generate 5 winning side
          business ideas tailored to your passions and interests
        </p>
        {/* <video
        controls={true}
        autoPlay={true}
        loop
        // ref={(x) => {
        //   setTimeout(() => {
        //     x.play();
        //   }, 100);
        // }}
      >
        <source src={sarah} type="video/mp4" />
      </video> */}
        <img
          src="/sarah-waving.png"
          className="w-[160px] h-auto rounded-full mx-auto"
        />
        <p className="text-md text-topia-black text-center">
          Meet Sarah, the leading AI for generating side business ideas.
        </p>
      </div>
    </div>
  );
}
