export function WelcomeVideo() {
  return (
    <>
      <h1 className="text-topia-black text-center text-xl lg:text-2xl mb-2">
        Welcome to the FIpreneurs!
      </h1>
      {/* <p className="text-center px-8 mb-8 text-xl text-topia-black font-medium mb-4 md:mb-0 flex-1">
      Let's meet your team! We'll work together to make a plan to unlock
      freedom in your life.
    </p> */}
      <div
        className="aspect-video mt-4"
        // style={{
        //   boxShadow: "4px 4px 0px #E0FEA1 ",
        // }}
      >
        <video
          controls={true}
          // autoPlay={true}
          // ref={(x) => {
          //   setTimeout(() => {
          //     x.play();
          //   }, 100);
          // }}
        >
          <source
            src={"https://router.topiafi.com/fipreneurs_welcome.mp4"}
            type="video/mp4"
          />
        </video>
      </div>
    </>
  );
}
