import React, { useEffect, useState } from "react";
import { useContext } from "react";
import {
  TopiaPromptModal,
  TopiaPromptModalProps,
} from "../components/TopiaPromptModal";
import { useQuery } from "../hooks/useQuery";
import { StoryblokComponent } from "@storyblok/react";
import { TopiaStoryblokComponent } from "../components/TopiaStoryblokComponent";
import { set } from "lodash";

export type ModalOptions = {
  forced?: boolean;
  noPanel?: boolean;
  videoBg?: {
    mp4?: string;
    mov?: string;
    poster?: string;
  };
};

export const ModalContext = React.createContext<{
  modal: React.ReactElement | undefined;
  open: boolean;
  mounted: boolean;
  forced: boolean;
  noPanel: boolean;
  videoBg: ModalOptions["videoBg"];
  openModal: (el: JSX.Element | undefined, options?: ModalOptions) => void;
  prompt(props: TopiaPromptModalProps): void;
  closeModal: () => void;
}>(undefined as any);

export function ModalProvider(props: { children: any }) {
  const [modal, setModal] = useState<React.ReactElement | undefined>(undefined);
  const [forced, setForced] = useState(false);
  const [noPanel, setNoPanel] = useState(false);
  const [videoBg, setVideoBg] = useState<null | ModalOptions["videoBg"]>(null);
  const [mounted, setMounted] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  function prompt(props: TopiaPromptModalProps) {
    setModal(<TopiaPromptModal {...props} />);
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      setMounted(true);
    } else {
      let _cancelled = false;
      setTimeout(() => {
        if (!_cancelled) {
          setMounted(false);
        }
      }, 300);
      return () => {
        _cancelled = true;
      };
    }
  }, [open]);

  useEffect(() => {
    // Hacks so that logan can pop lego modals from within the storyblok content
    // If he makes a link with the url formatted like `#lego-${lego slug} then this will open the modal
    function onHashChange() {
      const hash = window.location.hash;
      if (hash && hash.startsWith("#lego-")) {
        const legoSlug = hash.split("-").slice(1).join("-");
        if (!!legoSlug) {
          setModal(<TopiaStoryblokComponent slug={"legos/" + legoSlug} />);
          setOpen(true);
        }
      }
    }
    window.addEventListener("hashchange", onHashChange);
    return () => {
      window.removeEventListener("hashchange", onhashchange);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      window.location.hash = "";
    }
  }, [open, modal]);

  // console.log({
  //   forced,
  //   noPanel,
  //   modal: !!modal,
  //   open,
  //   mounted,
  // });
  return (
    <ModalContext.Provider
      value={{
        modal,
        open,
        prompt,
        videoBg,
        forced,
        noPanel,
        mounted,
        openModal: (body, options) => {
          setModal(body);
          setOpen(true);
          setForced(!!options?.forced);
          setNoPanel(options?.noPanel === true);
          setVideoBg(options?.videoBg || null);
        },
        closeModal: () => {
          setOpen(false);
        },
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
}

export const useModalContext = () => useContext(ModalContext);
