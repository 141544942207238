import { InnerCirclePitch } from "../components/InnerCirclePitch";

export function InnerCirclePitchPage() {
  return (
    <div className="p-8 pb-20 flex flex-col gap-10 max-w-6xl bg-white m-4 shadow-md mt-8 rounded-xl border-4 border-future-blue overflow-hidden">
      <h1 className="text-center text-2xl p-2 px-8">
        Accelerate Your Success in Grant's Inner Circle
      </h1>
      <InnerCirclePitch />
    </div>
  );
}
