import { storyblokEditable } from "@storyblok/react";
import {
  topiaBlockClassnames,
  useWindowDimensions,
} from "@topia-app/topia-react-web";

export const TBYoutubeEmbed = ({ blok }) => {
  const dimensions = useWindowDimensions();
  return (
    <div
      className={`mb-8 ${topiaBlockClassnames(blok)} cursor-pointer`}
      {...storyblokEditable(blok)}
    >
      <iframe
        style={
          dimensions.width > 600
            ? { height: "335px", width: "552px" }
            : { width: "300px", height: "182px" }
        }
        className="relative z-10 mx-auto my-8 rounded-lg lg:my-0"
        // src={`https://www.youtube.com/embed/VACCpfTHIcA`}
        src={`https://www.youtube.com/embed/${blok.youtube_id}`}
        title={blok.name}
        allowFullScreen
      ></iframe>
    </div>
  );
};
