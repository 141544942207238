import { CheckIcon } from "@heroicons/react/20/solid";
import { ReactElement } from "react";

export default function OutsourcePanel(props: {
  label: string;
  pitchCopy: string;
  price: string;
  stripeButton?: ReactElement;
}) {
  const includedFeatures = [
    "A Dedicated Expert From The FIpreneur Team",
    "Guaranteed High-Quality Results",
    "7-Day Rapid Turnaround",
    "Money-Back Guarantee",
  ];
  return (
    <div className="my-6">
      <div className="mx-auto max-w-7xl">
        <div className="mx-auto bg-slate-200 max-w-2xl rounded-3xl ring-1 ring-gray-200  lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8  lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              {props.label}
            </h3>
            <p className="mt-3 text-base leading-7 text-gray-600">
              {props.pitchCopy}
            </p>
            <div className="mt-6 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-4 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 "
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 py-6 flex flex-col  justify-center items-center lg:w-full lg:max-w-sm lg:flex-shrink-0">
            <div className="rounded-2xl bg-white  flex-1  text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs flex flex-row justify-center px-8">
                {props.stripeButton && props.stripeButton}
                {/* <p className="text-base font-semibold text-gray-600">
                  Pay once, own it forever
                </p> */}
                {/* <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    $349
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                    USD
                  </span>
                </p>
                <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get access
                </a> */}
                {/* <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
