import { useState } from "react";
import { useModalContext } from "../context/ModalContext";
import { useDataContext } from "../context/DataContext";
import { TopiaButton, TopiaTextInput } from "@topia-app/topia-react-web";
import classNames from "classnames";

export function BookCallWithLoganModal() {
  const dataCtx = useDataContext();
  const modalCtx = useModalContext();

  const [loading, setLoading] = useState(false);

  async function onSubmit() {
    setLoading(true);
    await dataCtx.updateUser({
      hasHadCallWithLogan: true,
      needleMovers: (dataCtx.user.needleMovers || []).filter(
        (x) => x.type !== "book-your-1-on-1"
      ),
    });
    modalCtx.closeModal();
  }
  async function onNoThanks() {
    setLoading(true);
    await dataCtx.updateUser({
      needleMovers: (dataCtx.user.needleMovers || []).filter(
        (x) => x.type !== "book-your-1-on-1"
      ),
    });
    modalCtx.closeModal();
  }
  return (
    <div className="p-4">
      <h2 className="text-3xl text-center text-topia-black">
        Book your onboarding call with Logan
      </h2>

      {/* <p className="text-xl text-center text-topia-black my-4">
        Get advice from peers who've been where you are. Post your questions in
        the FIPreneur Discord and tap into our collective wisdom
      </p> */}

      <div className=" pt-4 ">
        <div>
          {/* <h3 className="text-xl text-topia-black">Step 1</h3> */}
          <p className="text-lg text-center text-topia-black">
            Everyone’s journey is unique, which is why we offer these personal
            onboarding sessions. We’re eager to understand exactly where you are
            in your journey, helping us tailor our support so you can start
            unlocking freedom in your life ASAP!
          </p>
          <a
            href="https://calendly.com/logan-156/fipreneur-onboarding-call"
            target="_blank"
            className="rounded-full mt-4 border border-topia-black hover:opacity-60  bg-future-blue cursor-pointer  p-2  py-4 flex w-full "
          >
            <span className="text-center w-full">Book meeting</span>
          </a>
        </div>

        <div className="mt-8 pt-4 border-t border-neutral-200">
          {/* <h3 className="text-xl text-topia-black">Step 2</h3> */}

          <div className="flex flex-row items-end gap-2">
            <TopiaButton
              loading={loading}
              className={classNames("mb-3 w-full hover:opacity-60")}
              onClick={onSubmit}
            >
              Done, I have booked my call!
            </TopiaButton>
          </div>
          <button
            onClick={onNoThanks}
            className="border border-black rounded-md p-2 hover:bg-black/20 w-full text-center"
          >
            No, thanks
          </button>
        </div>
      </div>
    </div>
  );
}
