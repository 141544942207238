import { format } from "path";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth";
import * as Sentry from "@sentry/react";
import {
  Card,
  FullScreenTopiaLoader,
  TopiaButton,
  // TopiaStoryblokComponent,
  TopiaTextInput,
  defaultStoryblokConfig,
} from "@topia-app/topia-react-web";
import TBSideHustlePage from "../components/PlaybookIntro";
import {
  StoryblokComponent,
  getStoryblokApi,
  useStoryblokState,
} from "@storyblok/react";
import { useDataContext } from "../context/DataContext";
import { BiRightArrowAlt, BiCheck } from "react-icons/bi";
import { OnboardingSteps } from "../components/OnboardingSteps";
import {
  PLAYBOOK_TITLES,
  getCurrentPositionIDInPlaybook,
  mixpanelTrack,
  upperFirstLetter,
} from "../helpers";
import { DashboardModule } from "../dashboard-modules";
import { VIDEO_LIBRARY } from "../videolibrary";
import { DASHBOARD_MODULES } from "../module-data";
import classNames from "classnames";
import { DummyLoader } from "../components/DummyLoader";
import { UserIcon } from "@heroicons/react/24/outline";
import { ModalContext, useModalContext } from "../context/ModalContext";
import { driver } from "driver.js";
import { JoinTheDiscordModal } from "../components/JoinTheDiscordModal";
import { JoinTheWeeklyCallsModal } from "../components/JoinTheWeeklyCallsModal.tsx";
import { PLAYBOOK_TO_VIDEO_TAG } from "../constants";
import { VIDEO_VAULT } from "../video-vault-data";
import { router } from "../router";
import { BookCallWithLoganModal } from "../components/BookCallWithLoganModal";

const driverObj = driver({
  showProgress: true,
  steps: [
    {
      popover: {
        title: "Welcome to the Portal!",
        description: "Let's take a quick look at the dashboard",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#fip-needle-movers",
      popover: {
        title: "These are your needle movers",
        description:
          "Whenever you have time to work on your side business, simply take a quick look at your needle movers. Treat them as your compass, guiding you to the next tasks that require your attention and propel your progress towards unlocking more freedom in your life 🚀",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#recommended-resources",
      popover: {
        title: "Personalized Content",
        description:
          "Here, you'll discover content tailored to the specific step you're currently focused on. Need help choosing your brand name? Find a collection of videos directly related to that. Exploring your growth strategy? Dive into resources dedicated to growth channels 😀",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#sidebar",
      popover: {
        title: "Explore the Portal",
        description:
          "Check out all the other tools, resources and content we have to offer!",
        side: "left",
        align: "start",
      },
    },
  ],
});

function ProfileModal() {
  const dataCtx = useDataContext();
  const authCtx = useAuthContext();
  const modalCtx = useModalContext();

  const [name, setName] = useState(authCtx?.user?.displayName || "");
  const [discordHandle, setDiscordHandle] = useState(
    dataCtx?.user?.discordName || ""
  );

  return (
    <div className="p-4 flex flex-col">
      <TopiaTextInput
        label="Name"
        labelColor="text-topia-black"
        inputProps={{
          value: name,
          onChange: (ev) => setName(ev.target.value),
        }}
      />
      <TopiaTextInput
        label="Slack Handle"
        labelColor="text-topia-black"
        className="mt-4"
        inputProps={{
          value: discordHandle,
          onChange: (ev) => setDiscordHandle(ev.target.value),
        }}
      />
      <div className="flex flex-col gap-2 md:gap-4 md:flex-row justify-center items-center">
        <div
          className="md:p-4 mt-4 rounded-full p-2 bg-neutral-300 border border-topia-black w-[300px]  text-center cursor-pointer hover:opacity-50 md:h-[80px] items-center justify-center flex"
          onClick={() => {
            modalCtx.closeModal();
          }}
        >
          <p className="text-md">Done</p>
        </div>
        <div
          className="md:p-4 mt-4 rounded-full p-2 bg-pale-yellow border border-topia-black w-[300px] text-center cursor-pointer hover:opacity-50"
          onClick={() => {
            modalCtx.closeModal();
            setTimeout(() => {
              dataCtx.updateUser({
                currentModuleId: "welcome",
                activePlaybook: null,
                moduleHistory: [],
                hasDoneDashboardCoachmark: false,
              });
            });
          }}
        >
          <p className="text-md">
            <strong>Reset all Playbooks.</strong>
            <br />
            <span className="text-xs">
              Ready to start over with a new idea?
            </span>
          </p>
        </div>
        <button
          onClick={async () => {
            authCtx.signOut();
            modalCtx.closeModal();
          }}
          className=" text-left hover:bg-red-300/20 justify-self-center mt-4 block p-6 border border-black rounded-full text-black text-md"
        >
          Log Out
        </button>
      </div>
    </div>
  );
}

export function HomePage() {
  const dataCtx = useDataContext();
  const authCtx = useAuthContext();
  const modalCtx = useModalContext();
  const nav = useNavigate();
  const [discordName, setDiscordName] = useState("");
  useEffect(() => {
    mixpanelTrack("page_view_home");
  }, []);

  useEffect(() => {
    if (dataCtx.doingInitialLoad) return;
    if (!dataCtx.user.currentModuleId) return;

    if (
      !DASHBOARD_MODULES[dataCtx.user.currentModuleId]?.isLockedOut &&
      !dataCtx.user?.hasDoneDashboardCoachmark
    ) {
      dataCtx.updateUser({
        hasDoneDashboardCoachmark: true,
      });
      setTimeout(() => {
        driverObj.drive();
      }, 500);
    }
  }, [
    dataCtx.user?.currentModuleId,
    dataCtx.user?.hasDoneDashboardCoachmark,
    dataCtx.doingInitialLoad,
  ]);

  const currentModule = DASHBOARD_MODULES[dataCtx.user.currentModuleId];
  useEffect(() => {
    if (!currentModule) {
      const history = dataCtx.user.moduleHistory;

      // Ensure history is truthy before entering the loop
      if (history && history.length > 0) {
        // Start iterating from the end of the history array
        for (let i = history.length - 1; i >= 0; i--) {
          if (DASHBOARD_MODULES[history[i]]) {
            // Update currentModuleId and exit the loop if a valid module is found
            dataCtx.updateUser({ currentModuleId: history[i] });

            break;
          }
        }
      }
    }
  }, [currentModule]);

  if (dataCtx.doingInitialLoad) {
    return <FullScreenTopiaLoader />;
  }
  const recommendedVideos = currentModule
    ? VIDEO_VAULT.filter((v) => {
        if (
          !currentModule.videoVaultTags ||
          currentModule.videoVaultTags.length === 0
        ) {
          return true;
        }

        return currentModule.videoVaultTags.includes(v.id);
      })
    : [];

  // const grantMP4 = require("../Images/grant-welcome.mp4");
  // const grantWebM = require("../Images/grant-welcome.webm");

  const nextID = getCurrentPositionIDInPlaybook(
    DASHBOARD_MODULES[dataCtx.user.currentModuleId]?.playbook,
    dataCtx.user
  );

  const noBackButton =
    dataCtx.user.created_free_account &&
    dataCtx.user.currentModuleId === "diyValidation0";
  return (
    <div className="bg-gradient-to-tr min-h-screen from-neutral-600 to-neutral-800">
      <div className="px-4 max-w-7xl">
        <div className="py-4 border-b border-neutral-500 flex flex-row items-center mb-4">
          {/* <p className="text-md text-neutral-200 flex flex-row items-center gap-2 font-light">
            <p className="font-black text-2xl text-white">
              {Object.keys(dataCtx.user.moduleResults).length}
            </p>
            Modules Completed
          </p> */}
          <a
            className="text-md transition-all p-2 pl-0 text-white hover:underline cursor-pointer hover:underlien"
            onClick={() => {
              modalCtx.openModal(
                <div>
                  <p className="text-md text-black font-bold">Need Help?</p>
                  <p>
                    If you need any assitance or guidance please email{" "}
                    <a
                      className="underline"
                      href={"mailto:support@fipreneurs.com"}
                    >
                      support@fipreneurs.com
                    </a>
                  </p>
                </div>
              );
              // driverObj.drive();
            }}
          >
            Help
          </a>

          <div className="flex-1"></div>
          <div
            className="hover:opacity-50 cursor-pointer flex flex-row items-center"
            onClick={() => {
              modalCtx.openModal(<ProfileModal />, {
                forced: false,
              });
            }}
          >
            <UserIcon className="w-10 h-10 mr-4 text-white rounded-full border border-white p-2" />
            <p className="text-md text-white">{authCtx.user.displayName}</p>
          </div>
        </div>
      </div>
      <DummyLoader key="home" rippleClassNames={"white"}>
        <div className="p-4 max-w-7xl">
          {currentModule && currentModule.playbook && (
            <div className="p-4 bg-neutral-900 border-b border-neutral-600 rounded-tl-lg rounded-tr-lg shadow-xl">
              <div className="flex flex-row justify-between gap-4 mb-2">
                <h2 className="md:text-2xl text-lg text-white">
                  Side Biz:{" "}
                  <strong>{PLAYBOOK_TITLES[currentModule.playbook]}</strong>
                </h2>
                <h2 className="md:text-2xl text-lg text-white">
                  Stage:{" "}
                  <strong>
                    {upperFirstLetter(currentModule?.playbookStage)}
                  </strong>
                </h2>
              </div>

              <div className="h-10 bg-gradient-to-r shadow-md from-white to-gray-100 border border-gray-500 rounded-full w-full relative overflow-hidden">
                <div
                  className="h-10 relative bg-gradient-to-tr from-future-blue to-blue-500 rounded-full shadow-sm"
                  style={{
                    width: `${currentModule.playbookPct}%`,
                  }}
                >
                  <p
                    className={classNames(
                      "absolute top-1 text-lg font-medium",
                      currentModule.playbookPct > 80
                        ? "right-4 text-white"
                        : "-right-10 text-topia-black"
                    )}
                  >
                    {currentModule.playbookPct}%
                  </p>
                </div>
              </div>

              <div className="flex flex-row justify-end">
                {/* <p className="text-lg text-gray-800">$0/month</p> */}
                {/* <p className="text-lg text-gray-300">$2k/month</p> */}
              </div>
            </div>
          )}

          {/* <p className="text-white text-3xl">{authCtx.user?.uid}</p> */}
          {/* <OnboardingSteps /> */}

          {/* <button className="p-8 bg-red-500 text-white" onClick={() => {
  dataCtx.updateUser({
    currentModuleId: "welcome"
  })
}}>reset</button> */}
          {currentModule?.isLockedOut &&
            !dataCtx.user.hasDoneDashboardCoachmark && (
              <>
                <DashboardModule
                  configId={dataCtx.user.currentModuleId}
                  noBack={noBackButton}
                />
              </>
            )}
          <div className="p-4 border-4 rounded-bl-lg rounded-br-lg bg-neutral-800  border-neutral-800 shadow-xl">
            <div className="flex flex-col gap-2 md:gap-0 md:flex-row w-full">
              <h4 className="text-lg md:hidden text-white">
                Recently Completed
              </h4>
              <div className="md:w-[240px] md:mr-4 flex flex-col ">
                <h4 className="text-lg hidden md:block text-white">
                  Recently Completed
                </h4>
                {dataCtx.user.moduleHistory
                  .filter(
                    (x) =>
                      !!DASHBOARD_MODULES[x]?.moduleLabel ||
                      !!DASHBOARD_MODULES[x]?.title
                  )
                  .slice(
                    dataCtx.user.moduleHistory.length -
                      Math.min(5, dataCtx.user.moduleHistory.length - 1),
                    dataCtx.user.moduleHistory.length - 1
                  )
                  .map((v, ix) => (
                    <div
                      key={ix}
                      className="p-2 flex flex-row cursor-pointer group gap-4 items-center hover:scale-105 transition-all"
                    >
                      <p className="text-xs text-gray-100 group-hover:underline">
                        ☑️&nbsp;&nbsp;&nbsp;
                        {DASHBOARD_MODULES[v]?.moduleLabel ||
                          DASHBOARD_MODULES[v]?.title}
                      </p>
                    </div>
                  ))}
              </div>
              <div className="flex-row flex-1">
                <div
                  className="rounded-md shadow-lg bg-neutral-900 border border-future-blue/50 p-4"
                  id="fip-needle-movers"
                >
                  <h3 className="text-2xl text-white">Your Needle Movers:</h3>
                  <div className="md:grid md:grid-cols-2">
                    {[
                      ...dataCtx.user.needleMovers.filter(
                        (x) => x.type !== "join-the-discord"
                      ),
                      ...(dataCtx.user.currentModuleId
                        ? [
                            {
                              type: "module",
                              moduleId: nextID,
                              name: PLAYBOOK_TITLES[
                                DASHBOARD_MODULES[nextID]?.playbook
                              ]
                                ? PLAYBOOK_TITLES[
                                    DASHBOARD_MODULES[nextID]?.playbook
                                  ] +
                                  "-" +
                                  (DASHBOARD_MODULES[nextID]?.title ||
                                    DASHBOARD_MODULES[nextID]?.moduleLabel)
                                : DASHBOARD_MODULES[nextID]?.title ||
                                  DASHBOARD_MODULES[nextID]?.moduleLabel,
                            },
                          ]
                        : []),
                    ].map((v, ix) => (
                      <div
                        key={ix}
                        className="p-2 flex flex-row cursor-pointer group gap-8 items-center hover:scale-105 transition-all"
                        onClick={() => {
                          if (v.type === "module") {
                            const moduleConfig = DASHBOARD_MODULES[v.moduleId];
                            if (moduleConfig.playbook) {
                              nav(`/playbook/${moduleConfig.playbook}`);
                            } else {
                              modalCtx.openModal(
                                <>
                                  <DashboardModule
                                    configId={v.moduleId}
                                    noBack={true}
                                    noLockOut={true}
                                  />
                                </>
                              );
                            }
                          }
                          // else if (v.type === "join-the-discord") {
                          //   modalCtx.openModal(<JoinTheDiscordModal />);
                          // }
                          else if (v.type === "join-the-calls") {
                            modalCtx.openModal(<JoinTheWeeklyCallsModal />);
                          } else if (v.type === "book-your-1-on-1") {
                            modalCtx.openModal(<BookCallWithLoganModal />);
                          }
                        }}
                      >
                        <div className="shrink-0 w-4 h-4 border border-white group-hover:bg-gray-700 group-hover:scale-110 transition-all"></div>
                        <p className="text-md text-white font-bold group-hover:underline">
                          {v.name}
                        </p>
                        {/* <p className="text-gray-700">
              {v.t}
            </p> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col md:flex-row md:gap-4 items-center mt-6 bg-neutral-800 shadow-md rounded-lg overflow-hidden border border-gray-900"
            id="recommended-resources"
          >
            <div className="md:w-[250px] h-[42vh] bg-sky-900 p-2">
              <div className="relative p-2 pr-2 h-full border border-sky-100">
                <h2 className="text-white md:text-right text-xl pt-4">
                  Recommended Resources
                </h2>
                <p className="text-sm text-white md:text-right">
                  based on where you are on your journey with{" "}
                  {
                    PLAYBOOK_TITLES[
                      DASHBOARD_MODULES[dataCtx.user.currentModuleId]?.playbook
                    ]
                  }
                </p>
                <img
                  src="/sarah-waving.png"
                  className="md:-right-[60%] absolute right-[60%]"
                  style={{
                    width: "200px",
                    bottom: "-10px",
                  }}
                />
              </div>
            </div>
            <div className="">
              <div className="flex flex-col md:place-items-end md:grid p-4 px-10 md:grid-cols-3 md:gap-8 ">
                {recommendedVideos.slice(0, 3).map((v, ix) => (
                  <div className="">
                    <p className="text-white text-md mb-1">{v.title}</p>

                    <div className="flex w-full items-center justify-center my-8">
                      <iframe
                        src={`https://share.descript.com/embed/${v.filename}`}
                        width="100%"
                        height="360"
                        frameBorder="0"
                        className="rounded-lg"
                        allowFullScreen
                      ></iframe>
                      {/* <iframe
                        width="100%"
                        height="180"
                        src={"https://www.youtube.com/embed/" + v.youtubeId}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="mt-4">
            <iframe
              src="https://discord.com/widget?id=1100905829118529680&theme=dark"
              width="100%"
              height="350"
              allowTransparency
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            ></iframe>
          </div> */}
        </div>
      </DummyLoader>
    </div>
  );
}
