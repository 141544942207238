import { PortalModule } from "../module-types";

export function Table(props: {
  config: PortalModule;
  tableValue: any[];
  onTableValueInputChange: (
    textVal: string,
    rowIx: number,
    configIx: number
  ) => void;
  onRemoveRow: () => void;
  onAddRow: () => void;
}) {
  return (
    <div className="md:max-w-5xl  mx-auto  shadow-md bg-gray-100 md:p-4 p-1 rounded-md my-4">
      <div
        className={`flex flex-col gap-2  grid-flow-col md:grid md:gap-4   w-full`}
      >
        {props.config.inputs.map((c, cIx) => (
          <div key={cIx} className="flex flex-col justify-end">
            <p className="text-topia-black text-md md:text-lg  mb-2 font-bold">
              {c}
            </p>
            {props.tableValue.map((row, rowIx) => (
              <input
                type="text"
                className="p-2 bg-white border border-gray-300 rounded-md w-full mb-2"
                key={cIx + "_" + rowIx}
                value={row[cIx] || ""}
                onChange={(ev) => {
                  props.onTableValueInputChange(ev.target.value, rowIx, cIx);
                }}
              />
            ))}
          </div>
        ))}
      </div>
      <div className="flex items-center justify-end mt-2 gap-8">
        <button
          className="md:p-4 p-2 flex-1 py-1 text-lg cursor-pointer bg-red-500 text-white hover:scale-110 transition-all rounded-md"
          onClick={() => {
            props.onRemoveRow();
          }}
        >
          Remove Row
        </button>
        <button
          className="md:p-4 p-2 flex-1 py-1 text-lg cursor-pointer bg-topia-black text-white hover:scale-110 transition-all rounded-md"
          onClick={() => {
            props.onAddRow();
          }}
        >
          + New Row
        </button>
      </div>
    </div>
  );
}
