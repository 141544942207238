import { useDataContext } from "../context/DataContext";

export function PlaybookSelectMain(props: {
  windowSize: number;
  onNextModule: (nextModuleId: string) => void;
  onAlreadyHaveAnIdea: () => void;
  flow: "existing-idea" | "new-idea";
}) {
  const flow = props.flow;
  const dataCtx = useDataContext();

  const playbookSelections: {
    title: string;
    capitalRequired: string;
    timeToFirst1k: string;
    body: string;
    logo: string;
    bg: string;
    fg?: string;
    nextId: string;
    playbook: "fba" | "content" | "influencer";
  }[] = [
    {
      title: "E-commerce: Start Selling Online",
      capitalRequired: "$1,000 - $3,000",
      timeToFirst1k: "4-6 months",
      body: "Interested in selling products online? Let's work together to find a unique product that you can start selling online",
      logo: "/fba.png",
      bg: "bg-pale-yellow",
      nextId: flow === "existing-idea" ? "fbaIdeaEntry" : "fbaIdeaInterests",
      playbook: "fba",
    },
    {
      title: "Start a Blog: Share Your Passion",
      capitalRequired: "$100",
      timeToFirst1k: "8-12 months",
      body: "Can you imagine dedicating hours to write about your passions? If crafting engaging and inspiring blogs appeals to you, let's explore some unique ideas to begin your blogging adventure.",
      logo: "/content.png",
      bg: "bg-future-blue",
      playbook: "content",
      nextId:
        flow === "existing-idea" ? "contentTellGrant" : "contentIdeaInterests",
    },
    {
      title: "Influencer: Connect and Inspire",
      capitalRequired: "None!",
      timeToFirst1k: "8-12 months",
      body: "Do you shine in front of the camera and love engaging on social media? If so, let's dive into some potential ideas tailored for you. ",
      logo: "/influencer.png",
      bg: "bg-royal-purple",
      fg: "text-white",
      playbook: "influencer",
      nextId:
        flow === "existing-idea"
          ? "influencerWritten1"
          : "influencerIdeaInterests",
    },
  ];

  return (
    <div>
      <h3 className="text-lg md:text-3xl text-center text-topia-black my-4">
        {flow === "existing-idea"
          ? "Does your idea fall into one of these categories?"
          : "Which option resonates with you most?"}
      </h3>
      {playbookSelections.map((pb, ix) =>
        props.windowSize >= 550 ? (
          <div
            className={`${pb.bg} mb-4 shadow-md border border-black/40 px-8 py-4 rounded-2xl border-topia-black cursor-pointer transition-all hover:scale-105`}
            key={ix}
            onClick={() => {
              props.onNextModule(pb.nextId);
            }}
          >
            <div className="flex items-center flex-row justify-between">
              <div
                style={{
                  width: 140,
                }}
              >
                <img style={{ width: "100%" }} src={pb.logo} />
              </div>
              <div className="flex-1 ml-8">
                <h3 className={`text-xl ${pb.fg}`}>{pb.title}</h3>

                <p className={`text-md text-topia-black ${pb.fg}`}>{pb.body}</p>

                <div className="flex items-center gap-8 justify-end flex-1 mt-6">
                  <p
                    className={`text-xs text-center text-topia-black border border-topia-black rounded-full px-6 ${pb.fg}`}
                  >
                    Capital Required
                    <br />
                    <strong>{pb.capitalRequired}</strong>
                  </p>
                  <p
                    className={`text-xs text-center text-topia-black border border-topia-black rounded-full px-6 ${pb.fg}`}
                  >
                    Time to make $1,000
                    <br />
                    <strong>{pb.timeToFirst1k}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`${pb.bg} mb-4 shadow-md border border-black/40 py-4 rounded-2xl border-topia-black cursor-pointer transition-all hover:scale-105`}
            key={ix}
            onClick={() => {
              props.onNextModule(pb.nextId);
            }}
          >
            <div className="flex items-center px-2 flex-row justify-between">
              <div className="flex-1 flex flex-col gap-3">
                <div className="flex flex-row px-2 gap-4 justify-between items-center">
                  <img style={{ width: "30%" }} src={pb.logo} />
                  <h3 className={`text-lg text-center ${pb.fg}`}>{pb.title}</h3>
                </div>
                <p className={`text-xs text-topia-black ${pb.fg}`}>{pb.body}</p>

                <div className="flex flex-row justify-between px-2 pt-2 mt-2 border-t border-topia-black/50 gap-2">
                  <p
                    className={`text-xs text-center text-topia-black ${pb.fg}`}
                  >
                    Capital Required
                    <br />
                    <strong>{pb.capitalRequired}</strong>
                  </p>
                  <p
                    className={`text-xs text-center text-topia-black  ${pb.fg}`}
                  >
                    Time to make $1,000
                    <br />
                    <strong>{pb.timeToFirst1k}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      )}
      <div className="flex items-center justify-center">
        <button
          className="cursor-pointer hover:opacity-50 hover:scale-105 transition-all my-4 px-4 text-center text-lg mx-auto w-[330px] py-4 border border-topia-black text-topia-black rounded-full"
          onClick={() => {
            props.onAlreadyHaveAnIdea();
          }}
        >
          {flow === "existing-idea"
            ? "Nope, my idea is unique!"
            : "Already have an idea?"}
        </button>
      </div>
    </div>
  );
}
