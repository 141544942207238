import { format } from "path";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth";
import { TopiaButton } from "@topia-app/topia-react-web";
import {
  TopiaEmailInput,
  TopiaPasswordInput,
  TopiaTextInput,
} from "@topia-app/topia-react-web";
// import { TopiaLogo } from "@topia-app/topia-react-web";
import * as Sentry from "@sentry/react";
import { mixpanelTrack } from "../helpers";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export function LoginPage() {
  const authCtx = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoaing] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [err, setErr] = useState<any>();

  const navigate = useNavigate();

  const form = useForm();

  useEffect(() => {
    mixpanelTrack(`page_view_login`);
  }, []);

  async function submitLogin(vals) {
    setLoading(true);
    try {
      await authCtx.signIn({
        email: vals.email.trim(),
        password: vals.password,
      });
      // This is a hack
      setTimeout(() => {
        setLoading(false);
        navigate("/");
      }, 100);
    } catch (ex) {
      if (ex.code === "auth/user-not-found") {
        setErr(
          <div className="my-4">
            <p className="text-red-500 text-md">User not found</p>
          </div>
        );
      } else if (ex.code === "auth/wrong-password") {
        setErr(
          <div className="my-4">
            <p className="text-red-500 text-md">Incorrect password</p>
          </div>
        );
      } else if (ex.code === "auth/user-disabled") {
        setErr(
          <div className="my-4">
            <p className="text-red-500 text-md">
              Your account is disabled. Please reach out to
              support@fipreneurs.com.
            </p>
          </div>
        );
      } else {
        console.error(ex);
        setErr(
          <div className="my-4">
            <p className="text-red-500 text-md">{ex.code}</p>
          </div>
        );
      }
      setLoading(false);
    }
  }

  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
      <div className="lg:grid lg:grid-cols-5 min-h-full justify-center py-12 sm:px-6 lg:px-8 bg-topia-black ">
        <div className="col-span-2 px-16">
          <div className="w-full">
            <div className="">
              {/* <TopiaLogo svgProps={{ style: { fill: "white", width: 103 } }} /> */}
            </div>
            <h2 className="my-8 text-5xl tracking-tight text-white font-serif font-light">
              Sign In
            </h2>
          </div>

          <div className="w-full">
            <div className="">
              <form
                className="space-y-6"
                onSubmit={form.handleSubmit(submitLogin)}
              >
                <div>
                  <TopiaEmailInput
                    label="Email address"
                    inputProps={form.register("email", {
                      required: true,
                    })}
                    id="login-email"
                    className=""
                  />
                  <TopiaPasswordInput
                    label="Password"
                    inputProps={form.register("password", {
                      required: true,
                    })}
                    id="login-password"
                    className="mt-4"
                  />
                </div>

                {/* <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-white"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none   border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                </div> */}

                <div className="flex items-center justify-between">
                  {/* <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-white"
                  >
                    Remember me
                  </label>
                </div> */}

                  <div className="text-sm">
                    <Link
                      to="/forgot-password"
                      className="font-medium text-center text-underline text-future-blue hover:text-white"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>

                <div>
                  <TopiaButton
                    type="submit"
                    className="w-full"
                    analyticsEvent="sign_in_clicked"
                    btnStyle="primary"
                    loading={loading}
                  >
                    Sign In
                  </TopiaButton>
                </div>
              </form>
              <p className="text-xs text-white mt-2">
                By proceeding you agree to the FIPreneur Community{" "}
                <a
                  href="/fipreneurs_terms_and_conditions.pdf"
                  className="text-future-blue underline"
                  target="_blank"
                >
                  Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  href="/fipreneurs_community_privacy_policy.pdf"
                  className="text-future-blue underline"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </p>

              <div className="flex flex-col p-4 items-center justify-center">
                {err}
              </div>
            </div>
          </div>
        </div>
        <div className="self-center col-span-3  p-8">
          <img
            src="/logo.svg"
            className="max-w-[500px] h-auto"
            style={{
              filter: "invert(1)",
            }}
          />
          {/* <div
            className="rounded-xl  flex items-center justify-center"
            style={{
              backgroundImage: "url(logo.svg)",
              backgroundSize: "100% auto",
              width: "40vw",
              height: "50vh",
              backgroundRepeat: "no-repeat",
              filter: "invert(1)",
            }}
          > */}
          {/* <h2 className="text-white" style={{
              fontSize: "80px"
            }}> */}
          {/* Accelerate<br />your journey<br />to FI */}
          {/* </h2> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
