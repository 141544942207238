import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { renderRichText, storyblokEditable } from "@storyblok/react";
import { topiaBlockClassnames } from "@topia-app/topia-react-web/lib/helpers";
import { useState } from "react";
import { AccordionItem } from "./AccordionItem";

export function TBAccordion({ blok }) {
  return (
    <div
      className={` mx-auto max-w-5xl px-4 xl:px-0  ${topiaBlockClassnames(
        blok
      )}`}
      {...storyblokEditable(blok)}
    >
      {/* <pre>{JSON.stringify(blok, null, 2)}</pre> */}
      <div
        className="topia-sb-rich-text"
        dangerouslySetInnerHTML={{
          __html: renderRichText(blok.title),
        }}
      />
      <section className="flex w-full flex-col ">
        {blok.accordion_block.map((acc) => {
          return (
            <AccordionItem
              detail={acc.detail}
              header={acc.header}
              key={acc._uid}
            />
          );
        })}
      </section>
    </div>
  );
}
