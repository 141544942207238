import React, { useEffect, useState } from "react";
import { useAuthContext } from "../auth";
import {
  FirestoreError,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { DbChatGptRequests, DbPrivateUser, DbUser } from "../types";

export type DataContext = {
  user: DbUser;
  chatRequests: DbChatGptRequests;
  privateUser: DbPrivateUser;
  doingInitialLoad: boolean;
  updateUser: (user: Partial<DbUser>) => Promise<void>;
};

const dataContext = React.createContext<DataContext>(undefined as any);
const DEBUG_LOG_SUBS = process.env.NODE_ENV === "development";

export function DataProvider(props: { children: any }) {
  const authCtx = useAuthContext();

  const [user, setUser] = useState<DbUser>({} as any);
  const [doingInitialLoad, setDoingInitialLoad] = useState(true);
  const [chatRequests, setChatRequests] = useState<DbChatGptRequests>(
    {} as any
  );
  const [privateUser, setPrivateUser] = useState<DbPrivateUser>({} as any);

  useEffect(() => {
    if (!authCtx?.user?.uid) {
      return;
    }

    function onError(type) {
      return (err: FirestoreError) => {
        if (
          err.code === "permission-denied" ||
          err.code === "unauthenticated"
        ) {
          // TODO:
          // authCtx.signOut();
        } else {
          console.log("Data error: ", type);
          // TODO: Logging
          throw err;
        }
      };
    }

    const uid = authCtx.user.uid;
    const _userRef = doc(collection(db, "users"), uid);
    const _userPrivateRef = doc(collection(db, "usersPrivate"), uid);
    const _chatRequestsRef = doc(collection(db, "chatRequests"), uid);

    const removeUserSubscription = onSnapshot(
      _userRef,
      (snapshot) => {
        if (DEBUG_LOG_SUBS) {
          console.log("👤 User snapshot: ", snapshot.data());
        }
        const snapshotData: DbUser = (snapshot.data() || {}) as any;
        let currentModuleId = snapshotData.currentModuleId;
        if (snapshotData?.name && !currentModuleId) {
          currentModuleId = "welcome";
        }
        //  Checks if there is not a current module Id and a name attached to snapshot --> (FREE ACCOUNT)

        if (!currentModuleId && !snapshotData.name) {
          currentModuleId = "freeIntro";
        }
        const newUser = {
          ...snapshotData,
          needleMovers: snapshotData.needleMovers || [
            // {
            //   type: "join-the-discord",
            //   name: "Join the Discord and tap into our hive mind of FIpreneurs",
            //   createdAt: Date.now(),
            // },
            {
              type: "join-the-calls",
              name: "Join our weekly FIpreneur coaching calls",
              createdAt: Date.now(),
            },
            {
              type: "book-your-1-on-1",
              name: "Book your 1:1 onboarding call",
              createdAt: Date.now(),
            },
          ],
          moduleHistory: snapshotData.moduleHistory || [],
          moduleResults: snapshotData.moduleResults || {},
          currentModuleId: currentModuleId,
        };

        // Check if the user has upgraded to inner circle while on one of the IC pitche screens, redirect accordingly
        if (newUser.isInTheInnerCircle) {
          switch (newUser.currentModuleId) {
            case "preInnerCircle":
            case "innerCircle":
            case "innerCircleNoThanks":
              newUser.currentModuleId = "diyValidateOptionsInnerCircle";
              break;
          }
        }
        console.log(newUser);
        setUser(newUser);
        setDoingInitialLoad(false);
      },
      onError("user")
    );

    const removeChatRequestSubscription = onSnapshot(
      _chatRequestsRef,
      (snapshot) => {
        setChatRequests((snapshot.data() || {}) as any);
      },
      onError("chatRequests")
    );

    const removeUserPrivateSubscription = onSnapshot(
      _userPrivateRef,
      (snapshot) => {
        if (DEBUG_LOG_SUBS) {
          console.log("👤 User private snapshot: ", snapshot.data());
        }
        setPrivateUser((snapshot.data() || {}) as any);
      },
      onError("private user")
    );

    return () => {
      removeUserSubscription();
      removeChatRequestSubscription();
      removeUserPrivateSubscription();
    };
  }, [authCtx.user?.uid]);

  async function updateUser(user: Partial<DbUser>) {
    const uid = authCtx.user.uid;
    const _userRef = doc(collection(db, "users"), uid);
    await setDoc(_userRef, user, { merge: true });
  }

  return (
    <dataContext.Provider
      value={{
        user,
        privateUser,
        updateUser,
        chatRequests,
        doingInitialLoad,
      }}
    >
      {props.children}
    </dataContext.Provider>
  );
}

export const useDataContext = () => React.useContext(dataContext);
