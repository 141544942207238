import { useState } from "react";
import { formatCurrency } from "../helpers";
import { Card, TopiaTextInput } from "@topia-app/topia-react-web";

export function RealHourlyWageCalculator(props: { monthlyProfit: number }) {
  const [state, setState] = useState({
    hoursPerMonth: 4,
  });

  return (
    <Card className="">
      <p className="mb-4 text-md text-white">
        Is it worth it? Let's calculate your
        <span className="font-bold"> Real Hourly Wage </span>
        from this hustle
      </p>
      <div className="flex flex-col-reverse gap-4 md:flex-row md:items-center">
        <TopiaTextInput
          label="Hours per month"
          inputProps={{
            value: state.hoursPerMonth,
            onChange: (ev) => {
              const number = Number(ev.target.value);
              if (isNaN(number)) {
                setState((s) => ({ ...s, hoursPerMonth: 0 }));
              } else {
                setState((s) => ({
                  ...s,
                  hoursPerMonth: number,
                }));
              }
            },
            type: "number",
          }}
        />
        {/* <DataPoint
          value={state.hoursPerMonth}
          editable
          label="Hours per month"
          onFinishEditing={(v) => setState((s) => ({ ...s, hoursPerMonth: v }))}
        /> */}

        <div className="flex-1"></div>
        <div className="flex-col items-center justify-center text-center">
          <p className="text-2xl font-bold text-future-blue">
            {formatCurrency(props.monthlyProfit / state.hoursPerMonth)}
            <sub>/hr</sub>
          </p>
          <p className="text-sm text-topia-gray">Real hourly wage</p>
        </div>
      </div>
    </Card>
  );
}
