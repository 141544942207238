import { useEffect, useMemo, useState } from "react";
import { getAllUsers } from "../api";
import { useNavigate } from "react-router-dom";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { TopiaTextInput } from "@topia-app/topia-react-web";
import { startOfWeek, add, format } from "date-fns";
import React from "react";
import { TbNotes } from "react-icons/tb";
import { NotesModal } from "../components/NotesModal";
import { DbUser } from "../types";
export function AdminPage() {
  const navigate = useNavigate();
  // const [users, setUsers] = useState([]);
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   async function populateUsers() {
  //     try {
  //       setLoading(true);
  //       const { data } = await getAllUsers();
  //       setUsers(data.users);
  //       setLoading(false);
  //     } catch (err) {
  //       console.log("Error", err);
  //     }
  //   }
  //   populateUsers();
  // }, []);

  const now = new Date();
  const firstWeek = startOfWeek(now);
  const weeksToCheck = [
    firstWeek,
    add(firstWeek, { days: -7 }),
    add(firstWeek, { days: -14 }),
    // add(firstWeek, { days: -21 }),
  ].map((x) => format(x, "MM/dd/yyyy"));

  const [users, setUsers] = useState<
    {
      id: string;
      user: DbUser;
      disabled?: boolean;
    }[]
  >([]);

  const [openUserId, setOpenUserId] = useState<string | null>(null);

  const [userAction, setUserAction] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<Record<string, boolean>>(
    {}
  );

  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    function onError(type) {
      return (err) => {
        alert(
          "Oops an error occured, please refresh and try again. Reach out to Matt or Chad if this keeps happening"
        );
        console.error("Data error: ", type);
      };
    }

    const _userRef = collection(db, "users");

    const removeUserSubscription = onSnapshot(
      _userRef,
      async (snapshot) => {
        const allUsers = await getAllUsers();
        const inactiveUsers = allUsers.data.users.map((user) => ({
          id: user.uid,
          disabled: user.disabled,
        }));

        setUsers(
          snapshot.docs.map((x) => ({
            id: x.id,
            user: x.data(),
            disabled: !!inactiveUsers.find((u) => u.id === x.id && u.disabled),
          })) as any
        );
      },
      onError("user")
    );

    return () => {
      removeUserSubscription();
    };
  }, []);

  const displayedUsers = useMemo(() => {
    const s = search.toLowerCase();

    return users.filter((x) => {
      if (search.length > 0) {
        return (
          (x.user?.name && x.user.name.toLowerCase().indexOf(s) > -1) ||
          (x.user?.email && x.user.email.toLowerCase().indexOf(s) > -1) ||
          (x.user?.discordName &&
            x.user.discordName.toLowerCase().indexOf(s) > -1)
        );
      }
      return true;
    });
  }, [users, search]);

  const numSelected = Object.values(selectedUsers).filter((x) => x).length;

  function submitAction() {
    if (userAction == "undefined") return;

    const actionParts = userAction.split("-");
    const action = actionParts[0];
    const usersToUpdate = users.filter((x) => selectedUsers[x.id]);

    if (action === "logan") {
      const val = actionParts[1] === "yes";
      for (let u of usersToUpdate) {
        setDoc(
          doc(collection(db, "users"), u.id),
          { hasHadCallWithLogan: val },
          { merge: true }
        );
      }
    }

    if (action === "intro") {
      const val = actionParts[1] === "yes";

      for (let u of usersToUpdate) {
        setDoc(
          doc(collection(db, "users"), u.id),
          {
            hasCompletedOrientation: val,
          },
          {
            merge: true,
          }
        );
      }
    } else if (action === "engagement") {
      const val = actionParts[1] === "yes";
      const week = actionParts[2];
      for (let u of usersToUpdate) {
        // console.log("setting key", "eng_" + week);
        setDoc(
          doc(collection(db, "users"), u.id),
          {
            ["eng_" + week]: val,
          },
          {
            merge: true,
          }
        );
      }
    }
  }

  // useEffect(() => {
  //   const getDisabledUsers = async () => {
  //     const allUsers = await getAllUsers();
  //     const disabledUsers = allUsers.data.users
  //       .map((user) => ({
  //         id: user.uid,
  //         disabled: user.disabled,
  //       }))
  //       .filter((x) => x.disabled);

  //     const usersAdjusted = users.map((user) => {
  //       const userDisabled = !!disabledUsers.find((u) => u.id === user.id);
  //       if (userDisabled) {
  //         return { ...user, disabled: true };
  //       } else {
  //         return { ...user };
  //       }
  //     });
  //     setUsers(usersAdjusted);
  //   };
  //   getDisabledUsers().catch((err) => {
  //     console.log(err);
  //   });
  // }, []);

  return (
    <div className="p-8">
      <div className="">
        <h1 className="text-lg text-white my-4">All Users</h1>
        <div className="flex-row pb-2 items-center flex">
          <label className="text-xs text-gray-400">Search</label>
          <input
            type="text"
            className="p-1 ml-2 rounded-md"
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
          />
          <div className="flex-1"></div>
          {numSelected > 0 && (
            <div className="p-2 px-4 bg-white rounded-md flex flex-row gap-2 items-center">
              <p className="text-lg text-black">{numSelected} Selected</p>
              <select
                value={userAction}
                onChange={(ev) => {
                  setUserAction(ev.target.value);
                }}
              >
                <option value="undefined">Select action</option>
                <option value="logan-yes">
                  Mark 1:1 call with Logan Completed - YES
                </option>
                <option value="logan-no">
                  Mark 1:1 call with Logan Completed - NO
                </option>
                <option value="intro-yes">
                  Mark Intro on Discord Completed - YES
                </option>
                <option value="intro-no">
                  Mark Intro on Discord Completed - NO
                </option>
                {weeksToCheck.map((x) => (
                  <React.Fragment key={x}>
                    <option value={`engagement-yes-${x}`}>
                      Mark {x} Engagement Check - YES
                    </option>
                    <option value={`engagement-no-${x}`}>
                      Mark {x} Engagement Check - NO
                    </option>
                  </React.Fragment>
                ))}
              </select>
              <button
                className="bg-black text-white text-lg p-4"
                onClick={submitAction}
              >
                Submit
              </button>
            </div>
          )}
        </div>
        <div className="my-4 flex flex-row gap-6 items-center">
          <p className="text-white">Legend:</p>
          <div className="flex flex-row items-center gap-2">
            <div className="bg-slate-600/80 w-10 h-2" />
            <p className="text-white">No longer a member</p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-700">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-white sm:pl-0"
                    >
                      Select
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                    >
                      Discord Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                    >
                      Intro post
                      <br />
                      on Discord
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                    >
                      1:1 Call w/ Logan
                    </th>
                    {weeksToCheck.map((x) => (
                      <th
                        scope="col"
                        key={x}
                        className="px-3 py-3.5 text-left text-[10px] font-semibold text-white"
                      >
                        Engagement
                        <br />
                        {x}
                      </th>
                    ))}
                    {/* <th className="text-white">Notes</th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {displayedUsers.map((user) => (
                    <tr
                      key={user.id}
                      className={`${user.disabled ? "bg-slate-600/80" : ""}`}
                      // onClick={() => {
                      //   navigate(`/admin/${user.id}`);
                      // }}
                    >
                      <td>
                        <input
                          type="checkbox"
                          className="w-8 h-8"
                          checked={selectedUsers[user.id]}
                          onChange={(ev) => {
                            setSelectedUsers({
                              ...selectedUsers,
                              [user.id]: ev.target.checked,
                            });
                          }}
                        />
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                        {user.user.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {user.user.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {user.user.discordName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {user.user.hasCompletedOrientation ? "✅" : "❌"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {user.user.hasHadCallWithLogan ? "✅" : "❌"}
                      </td>

                      {weeksToCheck.map((x) => (
                        <td
                          className="whitespace-nowrap px-3 py-4 text-sm text-gray-300"
                          key={x}
                        >
                          {user.user["eng_" + x] ? "✅" : "❌"}
                        </td>
                      ))}
                      <td>
                        <TbNotes
                          onClick={() => {
                            setOpenUserId(user.id);
                          }}
                          size={20}
                          className="cursor-pointer"
                          color="#FFFFFF"
                        />
                      </td>
                    </tr>
                  ))}
                  {/* {people.map((person) => (
                        <tr key={person.email}>
                        </tr>
                      ))} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {openUserId && (
          <NotesModal
            closeModal={() => {
              setOpenUserId(null);
            }}
            uid={openUserId}
          />
        )}
      </div>
    </div>
  );
}
