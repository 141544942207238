import { motion } from 'framer-motion'

const variants: Parameters<typeof motion.div>[0]['variants'] = {
  initial: {
    scaleY: 0.5,
    opacity: 0,
  },

  animate: {
    scaleY: 1,
    opacity: 1,

    transition: {
      repeat: Infinity,

      repeatType: 'mirror',

      duration: 1,

      ease: 'circIn',
    },
  },
}

export const BarLoader = (props: { color: string }) => {
  return (
    <motion.div
      transition={{
        staggerChildren: 0.25,
      }}
      initial="initial"
      animate="animate"
      className="flex gap-1"
    >
      <motion.div
        variants={variants}
        className={`h-12 w-2 bg-${props.color || 'white'}`}
      />

      <motion.div
        variants={variants}
        className={`h-12 w-2 bg-${props.color || 'white'}`}
      />

      <motion.div
        variants={variants}
        className={`h-12 w-2 bg-${props.color || 'white'}`}
      />

      <motion.div
        variants={variants}
        className={`h-12 w-2 bg-${props.color || 'white'}`}
      />

      <motion.div
        variants={variants}
        className={`h-12 w-2 bg-${props.color || 'white'}`}
      />
    </motion.div>
  )
}
