/* Autogenerated by scripts/import-portal-modules.js */
import { PortalModule } from "./module-types";
export const DASHBOARD_MODULES: Record<string, PortalModule> = {
  "welcome": {
    "id": "welcome",
    "type": "welcome-video",
    "nextId": "doYouHaveASidebusiness",
    "nextButtonLabel": "Let's get started",
    "videoVaultTags": [
      ""
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false
  },
  "doYouHaveASidebusiness": {
    "id": "doYouHaveASidebusiness",
    "type": "single-choice",
    "title": "Where are you in your journey?",
    "nextButtonLabel": "Next",
    "bigText": "Do you have a business or an idea you're excited about?",
    "moduleLabel": "Journey Status",
    "stepperSteps": [
      "Getting to Know You",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 0,
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "choices": [
      {
        "label": "Yes",
        "subtext": "",
        "nextId": "doYouHaveAnIdea"
      },
      {
        "label": "No",
        "subtext": "",
        "nextId": "ideaGenPrimer"
      }
    ]
  },
  "doYouHaveAnIdea": {
    "id": "doYouHaveAnIdea",
    "type": "single-choice",
    "nextButtonLabel": "Next",
    "bigText": "Is your business already operational, or is it still in the idea phase?",
    "moduleLabel": "Business Stage Check",
    "stepperSteps": [
      "Getting to Know You",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "choices": [
      {
        "label": "Yup!",
        "subtext": "Ahead of the game.",
        "nextId": "whatIsYourBiz"
      },
      {
        "label": "Not yet",
        "subtext": "Don't worry, that's why we're here!",
        "nextId": "existingIdeaPlaybookSelect"
      }
    ]
  },
  "ideaGenPrimer": {
    "id": "ideaGenPrimer",
    "type": "idea-gen-primer",
    "title": "Let's find your winning side business idea: ",
    "nextId": "playbookSelect",
    "nextButtonLabel": "Let's get started",
    "moduleLabel": "Find Your Business",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false
  },
  "waitingForGrantFeedbackIdeaVideo": {
    "id": "waitingForGrantFeedbackIdeaVideo",
    "type": "simple",
    "title": "Upload a video for Grant to review!",
    "nextId": "waitingForGrantFeedbackIdeaVideo",
    "nextButtonLabel": "Next",
    "bigText": "We'll get back to you within the next few days. But don't wait up – go ahead and explore the portal in the meantime. Check out the blueprints, dive into the resources, and see what other FIPreneurs are up to. It's all here waiting for you to get started. It's time to unlock more freedom in your life!",
    "moduleLabel": "Video for Grant",
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "paragraphs": [
      "<div class=\"w-full flex items-center justify-center py-8\">\n        <a\n          href=\"https://senja.io/p/fipreneurs/r/nY2O7c\"\n          target=\"_blank\"\n          class=\"p-8 bg-future-blue text-black rounded-full cursor-pointer text-center text-2xl mx-auto font-black shadow-xl\"\n        >\n          <span>Record a video to send to Grant</span>\n        </a>\n      </div>"
    ]
  },
  "waitingForGrantFeedbackIdea": {
    "id": "waitingForGrantFeedbackIdea",
    "type": "simple",
    "title": "Grant is reviewing your idea!",
    "moduleLabel": "Get Grant's Feedback",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "steps": [
      {
        "text": "<p>Unlock Grant as your personal coach</p>\n",
        "subtext": "<p>Add Grant's weekly group coaching calls to your calendar <a href=\"https://www.addevent.com/calendar/XK624050\">here</a>. Don't miss out on the opportunity to be personally coached by Grant as he helps you unlock that life you dream of.</p>\n"
      },
      {
        "text": "<p>Meet your fellow FIPreneurs</p>\n",
        "subtext": "<p>Join Slack <a href=\"https://join.slack.com/t/fipreneurs/shared_invite/zt-2dtc5ygiu-FxH_Gt0MGlZh4XiOhNQkMg\">here</a>, our messaging platform to meet the rest of the community, see what everyone is working on, and share your own progress.</p>\n"
      },
      {
        "text": "<p>Learn with Grant</p>\n",
        "subtext": "<p>Start watching Grant's 8-module Financial Freedom video course <a href=\"https://members.fipreneurs.com/grants-financial-freedom-course\">here</a> to begin forging your path to independence.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Grant will be in touch in the next few days with his thoughts and feedback on your side business idea.</p>\n",
      "<p>While you're waiting, you can start exploring the FIpreneur community!</p>\n"
    ]
  },
  "whatIsYourBiz": {
    "id": "whatIsYourBiz",
    "type": "textarea",
    "nextId": "whatIsYourBiggestChallenge",
    "nextButtonLabel": "Next",
    "bigText": "Awesome! 😎 Tell Grant about your business",
    "footerText": "The more details you share, the better Grant can understand and assist you.",
    "moduleLabel": "Your Business",
    "stepperSteps": [
      "Getting to Know You",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "paragraphs": [
      "<p>Grant would love to hear the elevator pitch for your business. What exactly does your business do? How do you generate revenue? Who are your main customers? Feel free to include a link to your website or any online presence you have.</p>\n"
    ]
  },
  "whatIsYourBiggestChallenge": {
    "id": "whatIsYourBiggestChallenge",
    "type": "textarea",
    "title": "Your biggest challenge",
    "nextId": "preInnerCircle",
    "nextButtonLabel": "Next",
    "bigText": "What's the one thing preventing you from unlocking the life you love?",
    "moduleLabel": "Main Challenge",
    "stepperSteps": [
      "Getting to Know You",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false
  },
  "existingBizSarah": {
    "id": "existingBizSarah",
    "type": "simple-chat-gpt-integration",
    "title": "Sarah's thoughts",
    "nextId": "diyValidate0",
    "nextButtonLabel": "Next",
    "bigText": "Let's brainstorm on that challenge",
    "moduleLabel": "Sarah's Insights",
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "includedModuleIds": [
      "whatIsYourBiz",
      "whatIsYourBiggestChallenge"
    ],
    "systemPrompt": "Your name is Sarah. You work for Topia, a startup helping people achieve financial independence through entrepreneurship, or fipreneurship.  Your job is to generate ideas for someone to start a side hustle. these ideas should be online and semi-passive so that people don't need to trade their time for money. Or they have to have a clear roadmap to becoming passive. You give tangible, specific ideas and feedback. You recommend specific product or business ideas or clear next steps to get started.",
    "userInputPrefix": "Can you please give me some thoughts for how I could solve my business's biggest challenge?"
  },
  "preInnerCircle": {
    "id": "preInnerCircle",
    "type": "pre-inner-circle",
    "nextButtonLabel": "Next",
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false
  },
  "innerCircle": {
    "id": "innerCircle",
    "type": "inner-circle",
    "title": "Accelerate your success in Grant's inner circle",
    "nextId": "innerCircleNoThanks",
    "nextButtonLabel": "No Thanks",
    "moduleLabel": "Inner Circle Access",
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false
  },
  "ideabotInterests": {
    "id": "ideabotInterests",
    "type": "textarea",
    "nextId": "ideabotSkills",
    "nextButtonLabel": "Next",
    "bigText": "What do you love doing in your free time?",
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true
  },
  "ideabotSkills": {
    "id": "ideabotSkills",
    "type": "textarea",
    "nextId": "playbookSelect",
    "nextButtonLabel": "Next",
    "bigText": "Reflect on your personal and professional journey. What unique skills or expertise have you acquired?",
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true
  },
  "playbookSelect": {
    "id": "playbookSelect",
    "type": "playbook-select",
    "title": "Let's start narrowing it down!",
    "nextButtonLabel": "Next",
    "moduleLabel": "Narrowing Down",
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false
  },
  "ideabotResult": {
    "id": "ideabotResult",
    "type": "simple-chat-gpt-integration",
    "title": "Sarah's Ideas",
    "nextId": "grantValidateOptions",
    "nextButtonLabel": "Next",
    "moduleLabel": "Sarah's Suggestions",
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "paragraphs": [
      "<p>Here's some ideas from Sarah to get the brainstorming started! Think of these as a starting point, and once you've got something you like move onto the next screen where we'll start diving in on validating one of these ideas.</p>\n"
    ],
    "includedModuleIds": [
      "ideabotInterests",
      "ideabotSkills",
      "ideabotChallenges",
      "ideabotIdeaTypes"
    ],
    "systemPrompt": "Your name is Sarah. You work for Topia, a startup helping people achieve financial independence through entrepreneurship, or fipreneurship.  Your job is to generate ideas for someone to start a side hustle. these ideas should be online and semi-passive so that people don't need to trade their time for money. Or they have to have a clear roadmap to becoming passive. You give tangible, specific ideas and feedback. You recommend specific product or business ideas or clear next steps to get started.",
    "userInputPrefix": "Can you please generate 10 ideas based on my information?"
  },
  "existingIdeaPlaybookSelect": {
    "id": "existingIdeaPlaybookSelect",
    "type": "existing-idea-playbook-select",
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false
  },
  "helpIdeaGenerationOptionsFBA": {
    "id": "helpIdeaGenerationOptionsFBA",
    "type": "single-choice",
    "title": "Let Grant help you discover an idea you'll love",
    "bigText": "Stuck on finding the perfect business idea? Grant will personally review your inputs and help unveil an idea you'll love.",
    "moduleLabel": "Submit Your Idea to Our Coach",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "idea",
    "playbookStageStep": 0,
    "playbookPct": 0,
    "choices": [
      {
        "label": "Submit your written answers",
        "subtext": "And get a WRITTEN response from Grant",
        "nextId": "fbaWaitingForGrantFeedbackIdea"
      },
      {
        "label": "Record a video to send to Grant",
        "subtext": "And get a more detailed VIDEO response from Grant. 80% of FIPreneurs opt for video validation",
        "nextId": "fbaWaitingForGrantFeedbackIdeaVideo"
      }
    ]
  },
  "helpIdeaGenerationOptionsContent": {
    "id": "helpIdeaGenerationOptionsContent",
    "type": "single-choice",
    "title": "Let Grant help you discover an idea you'll love",
    "bigText": "Stuck on finding the perfect business idea? Grant will personally review your inputs and help unveil an idea you'll love.",
    "moduleLabel": "Submit Your Idea to Our Coach",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "idea",
    "playbookStageStep": 0,
    "playbookPct": 0,
    "choices": [
      {
        "label": "Submit your written answers",
        "subtext": "And get a WRITTEN response from Grant",
        "nextId": "contentWaitingForGrantFeedbackIdea"
      },
      {
        "label": "Record a video to send to Grant",
        "subtext": "And get a more detailed VIDEO response from Grant. 80% of FIPreneurs opt for video validation",
        "nextId": "contentWaitingForGrantFeedbackIdeaVideo"
      }
    ]
  },
  "helpIdeaGenerationOptionsInfluencer": {
    "id": "helpIdeaGenerationOptionsInfluencer",
    "type": "single-choice",
    "title": "Let Grant help you discover an idea you'll love",
    "bigText": "Stuck on finding the perfect business idea? Grant will personally review your inputs and help unveil an idea you'll love.",
    "moduleLabel": "Submit Your Idea to Our Coach",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "idea",
    "playbookStageStep": 0,
    "playbookPct": 0,
    "choices": [
      {
        "label": "Submit your written answers",
        "subtext": "And get a WRITTEN response from Grant",
        "nextId": "influencerWaitingForGrantFeedbackIdea"
      },
      {
        "label": "Record a video to send to Grant",
        "subtext": "And get a more detailed VIDEO response from Grant. 80% of FIPreneurs opt for video validation",
        "nextId": "influencerWaitingForGrantFeedbackIdeaVideo"
      }
    ]
  },
  "innerCircleNoThanks": {
    "id": "innerCircleNoThanks",
    "type": "simple",
    "nextId": "diyBuild1",
    "nextButtonLabel": "Explore the Portal",
    "bigText": "Learn How The FIpreneur Community Can Help You Solve Your Biggest Challenge",
    "moduleLabel": "Personalized Guidance: Book a 1-on-1 Call with Our Community Manager",
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "paragraphs": [
      "<p>If the inner circle wasn't the right fit for you, there's no need to worry. Every new member gets a free one-on-one onboarding call with our community manager. He'll review your business, pinpoint your specific challenges, and show you exactly how to use the community to solve those challenges and speed up your progress.</p>\n",
      "<p><a href=\"https://calendly.com/logan-156/fipreneur-onboarding-call\">Book your free one-on-one call</a></p>\n"
    ]
  },
  "fbaWelcome": {
    "id": "fbaWelcome",
    "type": "simple",
    "nextId": "fbaCommonIssuesQuestion",
    "nextButtonLabel": "Next",
    "bigText": "Let's generate some potential product ideas with Sarah",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 1,
    "paragraphs": [
      "<p>Embark on your E-commerce journey by uncovering the perfect product idea. The key to a successful start lies in identifying products that are in high demand but face low competition. This sweet spot is where opportunity meets profitability. Your goal is to find products that consumers are actively seeking, yet aren't swamped by too many sellers. This balance ensures visibility for your product and a higher chance of success in the bustling Amazon marketplace.</p>\n"
    ]
  },
  "fbaIdeaInterests": {
    "id": "fbaIdeaInterests",
    "type": "textarea",
    "nextId": "fbaIdeaSkills",
    "nextButtonLabel": "Next",
    "bigText": "What do you love doing in your free time?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Hobbies & Passions",
    "helpText": "Example Answer: I love reading, exploring new hiking trails, and cooking Italian cuisine. ",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true,
    "playbook": "fba",
    "playbookStage": "idea",
    "playbookStageStep": 0,
    "playbookPct": 0,
    "paragraphs": [
      "<p>Share with us your hobbies or things that excite you. Whether it's painting, fitness, or gardening, knowing what you love can lead us to products you'd be passionate about selling.</p>\n"
    ]
  },
  "fbaIdeaSkills": {
    "id": "fbaIdeaSkills",
    "type": "textarea",
    "nextId": "fbaCommonIssuesQuestion",
    "bigText": "What's something special you're really good at?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Unique Skills",
    "helpText": "Example Answer: For work, I program, specifically, I develop mobile apps. I am also a relatively skilled painter. ",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true,
    "playbook": "fba",
    "playbookStage": "idea",
    "playbookStageStep": 0,
    "playbookPct": 0,
    "paragraphs": [
      "<p>Think about what makes you unique. It could be your cooking skills, tech knowledge, or a knack for DIY crafts. This special skill of yours could be the key to discovering a unique product that you can offer to others.</p>\n"
    ]
  },
  "fbaCommonIssuesQuestion": {
    "id": "fbaCommonIssuesQuestion",
    "type": "textarea",
    "nextId": "fbaIdeaResults",
    "bigText": "Have you ever thought 'I wish this product existed'?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Personal Pain Points",
    "helpText": "Example Answer: I hate feeling cold, I wish a self-heating scarf existed that's stylish and can keep me warm wherever I go. I also struggle with keeping my plants alive, so a smart, self-watering plant pot would be pretty great.",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true,
    "playbook": "fba",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 1,
    "paragraphs": [
      "<p>Recall a time when you couldn't find the right product. Maybe it was a specific type of home decor, a gadget to make life easier, or an eco-friendly version of something. Your experience in searching for this could lead us to an exciting and marketable product idea.</p>\n"
    ]
  },
  "fbaIdeaResults": {
    "id": "fbaIdeaResults",
    "type": "simple-chat-gpt-integration",
    "title": "Tailored Ideas Just for You",
    "nextId": "fbaIdeaEntry",
    "nextButtonLabel": "I have an idea",
    "moduleLabel": "Your Ideas",
    "gptModelId": "ft:gpt-3.5-turbo-1106:topia::8k1ojbxi",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "idea",
    "playbookStageStep": 4,
    "playbookPct": 4,
    "paragraphs": [
      "<p>Select the idea that resonates most with you.</p>\n",
      "<p>There’s no need to sweat the small stuff right now. In the next phase, our coaches will be by your side to help you refine your idea into a winning side business.</p>\n"
    ],
    "includedModuleIds": [
      "fbaIdeaInterests",
      "fbaIdeaSkills",
      "fbaCommonIssuesQuestion"
    ],
    "systemPrompt": "Your name is Sarah, and you're an idea generation specialist at the FIpreneur community. Your task today is to help someone brainstorm Amazon FBA product ideas. This person has specific skills and interests, and we're looking to leverage these in finding the right niche product opportunities. We aim to avoid saturated markets, so the ideas should focus on niche opportunities with potential for growth and uniqueness. We're focusing on physical products or E-printables. Your name is Sarah, and you're an idea generation specialist at the FIpreneur community. Your task today is to help someone brainstorm Amazon FBA product ideas. This person has specific skills and interests, and we're looking to leverage these in finding the right niche product opportunities. We aim to avoid saturated markets, so the ideas should focus on niche opportunities with potential for growth and uniqueness. We're focusing on physical products. For each  idea, please also include an enthusiastic estimate of how much money they could potentially earn each month from these ventures. YOUR OUTPUT SHOULD BE FORMATTED AS VALID HTML FOLLOWING THIS TEMPLATE: <ol><li><h4>{IDEA TITLE}</h4><p>{IDEA DESCRIPTION}</p></li></ol>",
    "userInputPrefix": "Can you please generate 5 to 10 Physical product ideas to sell on Amazon FBA based on my information?"
  },
  "fbaIdeaEntry": {
    "id": "fbaIdeaEntry",
    "type": "textarea",
    "nextId": "fbaValidation1",
    "bigText": "Tell us about your idea",
    "footerText": "Don't worry, we're here to guide you. On the next screen, our coaches will help ensure your idea has winning potential.",
    "moduleLabel": "Your Ideas",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "amaznidea",
      "ecommideagen1",
      "ecommideagen2"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 1,
    "paragraphs": [
      "<p>Give us a brief summary of your product idea.</p>\n"
    ]
  },
  "fbaValidation1": {
    "id": "fbaValidation1",
    "type": "simple",
    "title": "Let's make sure your idea is a winner",
    "nextId": "fbaValidation2",
    "bigText": "Over 80% of side businesses fail because the idea isn't viable. Answer these questions and our e-commerce coach will personally review your idea.",
    "footerText": "Almost there! Just a few quick questions, and then our coaches will step in to bring your idea to life.",
    "moduleLabel": "Let's Start Validating",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "amaznidea",
      "ecommideagen1",
      "ecommideagen2"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 1,
    "paragraphs": [
      "<h2 style='text-align: center; margin-bottom: 10px; font-size: 32px;'>Your Ecommerce Coach</h2><img src='https://a-us.storyblok.com/f/1010894/1112x1192/b2af6d24e7/logan-leckie-poolside-2.PNG' style='width: 200px; height: 200px; border-radius: 100%; margin: 10px auto;' />",
      "<p style='text-align: center; font-weight: 900; font-size: 24px; margin-bottom: -10px;'>Logan Leckie</p>",
      "<p style='text-align: center; margin-bottom: -10px;'>Launched 6 Successful Products</p>",
      "<p style='text-align: center'>$20K+ Monthly Revenue</p>"
    ]
  },
  "fbaValidation2": {
    "id": "fbaValidation2",
    "type": "table",
    "title": "Let's explore the demand & competition for your idea",
    "nextId": "fbaValidateOptions",
    "footerText": "Almost there! After this step our coach will step in to review your idea.",
    "youtubeId": "_dtOaji0zjs",
    "moduleLabel": "Market Research",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "",
      "amaznvalidate",
      "amzn"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "validate",
    "playbookStageStep": 3,
    "playbookPct": 5,
    "steps": [
      {
        "text": "<p>Search for your product on Amazon</p>\n",
        "subtext": "<p>Use the Amazon search bar to look up your product. Try different keyword variations to ensure a comprehensive search.</p>\n"
      },
      {
        "text": "<p>Compile a list of competing products</p>\n",
        "subtext": "<p>As you find listings that match or closely resemble your product idea, copy the URL of each listing and paste it the table below.</p>\n"
      },
      {
        "text": "<p>Note down the number of ratings</p>\n",
        "subtext": "<p>For each product you add to your list, record the number of ratings it has. This gives you an insight into the product's demand and market presence.</p>\n"
      },
      {
        "text": "<p>Assessment</p>\n",
        "subtext": "<p>We're targeting a sweet spot: look for products with an average of 50+ ratings and fewer than 10 sellers. This criteria helps us zero in on products that are in demand but not oversaturated, indicating a viable opportunity.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Ok, you've got an idea you're excited about. In the validation stage, we use Amazon FBA because it's the simplest way to launch and validate an e-commerce product. It gives us clear insights into demand and competition. Your mission is to identify a product that not only has a demonstrable demand on Amazon but also isn't swamped by too many competitors.</p>\n",
      "<p>A critical aspect to consider is the number of ratings a product has. A high number of ratings indicates strong demand. Ideally, you're looking for products that are popular enough to have a proven market but not so popular that you're up against an army of established sellers. The sweet spot is often found in products with demand (evidenced by ratings) but with fewer than 10 sellers.</p>\n"
    ],
    "inputs": [
      "Link to Competitor's Product",
      "Total Number of Ratings"
    ]
  },
  "fbaValidateOptions": {
    "id": "fbaValidateOptions",
    "type": "single-choice",
    "title": "Submit your answers to your coach ",
    "nextId": "fbaWaitingForGrantFeedbackIdea",
    "bigText": "You're just a click away from getting closer to that winning idea. How would you like to submit your answers to your coach? ",
    "moduleLabel": "Submit to Coach",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "",
      "amaznvalidate",
      "amzn"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "validate",
    "playbookStageStep": 4,
    "playbookPct": 2,
    "choices": [
      {
        "label": "Submit your written answers",
        "subtext": "And get a WRITTEN response from Grant",
        "nextId": "fbaWaitingForGrantFeedbackIdea"
      },
      {
        "label": "Record a video to send to Grant",
        "subtext": "And get a more detailed VIDEO response from Grant. 80% of FIPreneurs opt for video validation",
        "nextId": "fbaWaitingForGrantFeedbackIdeaVideo"
      }
    ]
  },
  "fbaWaitingForGrantFeedbackIdea": {
    "id": "fbaWaitingForGrantFeedbackIdea",
    "type": "simple",
    "title": "Your coach is reviewing your idea!",
    "nextId": "fbaDetailedValidation1",
    "bigText": "We'll get back to you within the next few days. But don't wait up – go ahead and explore the portal in the meantime. Check out the blueprints, dive into the resources, and see what other FIPreneurs are up to. It's all here waiting for you to get started on unlocking more freedom in your life!",
    "moduleLabel": "Your Coach is Reviewing",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "ecommideagen1",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "validate",
    "playbookStageStep": 5,
    "playbookPct": 5,
    "steps": [
      {
        "text": "<p>Unlock Grant as your personal coach</p>\n",
        "subtext": "<p>Add Grant's weekly group coaching calls to your calendar <a href=\"https://www.addevent.com/calendar/XK624050\">here</a>. Don't miss out on the opportunity to be personally coached by Grant as he helps you unlock that life you dream of.</p>\n"
      },
      {
        "text": "<p>Meet your fellow FIpreneurs</p>\n",
        "subtext": "<p>Join Slack <a href=\"https://join.slack.com/t/fipreneurs/shared_invite/zt-2dtc5ygiu-FxH_Gt0MGlZh4XiOhNQkMg\">here</a>, our messaging platform to meet the rest of the community, see what everyone is working on, and share your own progress.</p>\n"
      },
      {
        "text": "<p>Learn with Grant</p>\n",
        "subtext": "<p>Start watching Grant's 8-module Financial Freedom video course <a href=\"https://members.fipreneurs.com/grants-financial-freedom-course\">here</a> to begin forging your path to independence.</p>\n"
      }
    ]
  },
  "fbaWaitingForGrantFeedbackIdeaVideo": {
    "id": "fbaWaitingForGrantFeedbackIdeaVideo",
    "type": "simple",
    "title": "Grant is reviewing your idea!",
    "nextId": "fbaWaitingForGrantFeedbackIdea",
    "bigText": "We'll get back to you within the next few days. But don't wait up – go ahead and explore the portal in the meantime. Check out the blueprints, dive into the resources, and see what other FIPreneurs are up to. It's all here waiting for you to get started on unlocking more freedom in your life!",
    "moduleLabel": "Your Coach is Reviewing",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "ecommideagen1",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "validate",
    "playbookStageStep": 5,
    "playbookPct": 5,
    "steps": [
      {
        "text": "<p>Unlock Grant as your personal coach</p>\n",
        "subtext": "<p>Add Grant's weekly group coaching calls to your calendar <a href=\"https://www.addevent.com/calendar/XK624050\">here</a>. Don't miss out on the opportunity to be personally coached by Grant as he helps you unlock that life you dream of.</p>\n"
      },
      {
        "text": "<p>Meet your fellow FIpreneurs</p>\n",
        "subtext": "<p>Join Slack <a href=\"https://join.slack.com/t/fipreneurs/shared_invite/zt-2dtc5ygiu-FxH_Gt0MGlZh4XiOhNQkMg\">here</a>, our messaging platform to meet the rest of the community, see what everyone is working on, and share your own progress.</p>\n"
      },
      {
        "text": "<p>Learn with Grant</p>\n",
        "subtext": "<p>Start watching Grant's 8-module Financial Freedom video course <a href=\"https://members.fipreneurs.com/grants-financial-freedom-course\">here</a> to begin forging your path to independence.</p>\n"
      }
    ],
    "paragraphs": [
      "<div class=\"w-full flex items-center justify-center py-8\">\n        <a\n          href=\"https://senja.io/p/fipreneurs/r/nY2O7c\"\n          target=\"_blank\"\n          class=\"p-8 bg-future-blue text-black rounded-full cursor-pointer text-center text-2xl mx-auto font-black shadow-xl\"\n        >\n          <span>Record a video to send to Grant</span>\n        </a>\n      </div>"
    ]
  },
  "fbaDetailedValidation1": {
    "id": "fbaDetailedValidation1",
    "type": "table",
    "title": "Detailed validation of supply & demand",
    "nextId": "fbaBuild1",
    "nextButtonLabel": "Mark as Completed",
    "bigText": "Use Helium10 to dive into the data and confirm your idea is a sure winner",
    "youtubeId": "34-js4P5660",
    "moduleLabel": "Detailed Market Analysis",
    "outsourcePrice": "$49",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Short on time? Leave the market analysis to us. We dive deep into the data, delivering comprehensive insights and a clear assessment of your business idea's viability. Within 7 working days, you'll have the information you need to proceed with confidence, knowing your opportunity has been thoroughly vetted by experts.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1Odfz1DAbzSOakWBpVhxfqBv\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "validate1",
      "amazon1",
      "ecommerce2",
      ""
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "validate",
    "playbookStageStep": 3,
    "playbookPct": 5,
    "steps": [
      {
        "text": "<p>Setting Up Helium 10</p>\n",
        "subtext": "<p>Unlock your exclusive FIPreneur 20% discount on Helium 10, a powerful suite of tools for Amazon market research. Click <a href=\"https://cc.helium10.com/?crsh_reqid=5871031&amp;aid=11898&amp;pg=1&amp;coupon=TOPIA6M20&amp;PURL-006319\">this</a> link to access your discount and download and install it, enhancing your ability to analyze market trends, validate product ideas, and craft winning strategies.</p>\n"
      },
      {
        "text": "<p>Assessing Competitors' Monthly Revenue</p>\n",
        "subtext": "<p>With Cerebro, accurately estimate the monthly revenue of your competitors. This insight is invaluable in understanding the financial potential of your market segment, helping you make informed decisions about pricing, marketing, and product development.</p>\n"
      },
      {
        "text": "<p>Evaluating Listing Quality</p>\n",
        "subtext": "<p>Use Cerebro’s listing quality score to assess the effectiveness of competitors’ product presentations. A lower score reveals weaknesses in their approach, presenting an opportunity for you to differentiate with a more attractive, informative, and SEO-optimized listing.</p>\n"
      },
      {
        "text": "<p>Competitor Listing Assessment</p>\n",
        "subtext": "<p>Carefully review competitors' listings, focusing on the quality of their written content. Poorly articulated value propositions or unclear descriptions are your opportunities to stand out. By crafting concise, clear, and benefit-focused product descriptions, you can capture the attention of potential customers more effectively.</p>\n"
      },
      {
        "text": "<p>Final Decision: Determining Your Product's Market Viability</p>\n",
        "subtext": "<p>In your final decision phase, assess if your product meets two crucial criteria: having less than six direct competitors and each competitor's average monthly revenue exceeding $1,500. Meeting these conditions indicates a strong market potential for your product. Furthermore, an added advantage lies in the quality of competitors' listings. If these are poorly written or have a low-quality score, it presents a significant opportunity for you to gain a competitive edge by creating superior and more compelling listings.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Congratulations, your coach has certified that your idea is a winner! In this crucial validation step, we'll leverage Helium 10 to thoroughly assess your product's market potential. You'll explore the sales volumes of competitors, evaluate the effectiveness of their listings, and identify potential market gaps your product could fill. This detailed analysis will offer a clearer understanding of the competitive landscape, confirming the real opportunity for your product to succeed in the marketplace.</p>\n",
      "<p>Helium 10 is not just a tool; it's an essential part of your e-commerce journey. As a comprehensive suite designed for Amazon sellers, it's crucial for conducting in-depth market research, uncovering keywords, validating products, and much more. Beyond ensuring that your product is a winner, it plays a key role as you launch and scale your product.</p>\n"
    ],
    "inputs": [
      "Link to Competitor's Product",
      "Total Number of Ratings",
      "Monthly Revenue ",
      "Helium Listing Quality (1-10)",
      "Written Quality of Listing (1-10)"
    ]
  },
  "fbaBuild1": {
    "id": "fbaBuild1",
    "type": "table",
    "nextId": "fbaBuild2",
    "nextButtonLabel": "Mark as Completed",
    "bigText": "Selecting the right manufacturer for your product",
    "moduleLabel": "Find Your Manufacturer",
    "outsourcePrice": "$39",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Finding the right manufacturer can be a complex process, but you don't have to do it alone. We take care of finding a manufacturer that meets your criteria for quality and reliability. Our thorough due diligence ensures their credentials and capabilities align with your needs. Plus, we'll negotiate the best prices on your behalf, streamlining your production to be both cost-effective and efficient. Trust us to secure the foundation of your supply chain.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrrsDAbzSOakWBWsHiv57m\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "amazon1",
      "amazonmanufacture"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 1,
    "playbookPct": 10,
    "steps": [
      {
        "text": "<p>Use Helium 10 X-ray Tool:</p>\n",
        "subtext": "<p>Utilize the Helium 10 X-ray tool on Amazon listings to understand where successful sellers source their products.</p>\n"
      },
      {
        "text": "<p>Search on Alibaba:</p>\n",
        "subtext": "<p>Perform direct searches on (Alibaba)[www.alibaba.com]. Use filters to search either by “Products” or “Manufacturers” to find potential manufacturers for your product.</p>\n"
      },
      {
        "text": "<p>Alternative Methods:</p>\n",
        "subtext": "<p>If you’re facing challenges in finding a suitable manufacturer, consider using Alibaba's RFQ (Request for Quotation) feature for more personalized supplier suggestions. As another option, you can use the FIpreneurs community manufacturer scout service for expert assistance in locating the right manufacturer for your needs.</p>\n"
      },
      {
        "text": "<p>Recording Manufacturer Details:</p>\n",
        "subtext": "<p>Keep track of potential manufacturers by recording their information in the portal table. Ensure you note their Alibaba URL, verification status, years on Alibaba, Alibaba rating, and if they have provided references.</p>\n"
      },
      {
        "text": "<p>Conducting Thorough Due Diligence:</p>\n",
        "subtext": "<p>Verify each supplier’s status on Alibaba to ensure they are reliable and legitimate. Assess their responsiveness and clarity in communication. Also, ask for contact details of their recent three clients to gauge their service and product quality.</p>\n"
      },
      {
        "text": "<p>Assessing Your Findings</p>\n",
        "subtext": "<p>Prioritize manufacturers who are officially verified on Alibaba, have been actively selling for more than three years (the longer, the better), and maintain a rating above 4. These benchmarks are vital in assessing the credibility and reliability of manufacturers. Once a manufacturer meets these criteria, they can be considered for the next crucial phase of your process: initiating negotiations.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Now that you've locked in a promising product idea, the next crucial step is to find a suitable manufacturer. This involves identifying a manufacturer that aligns with your budget and quality standards.</p>\n"
    ],
    "inputs": [
      "Alibaba Link",
      "Verified? Yes/No",
      "Years on Alibaba",
      "Alibaba Rating (/5)",
      "References Received?"
    ]
  },
  "fbaBuild2": {
    "id": "fbaBuild2",
    "type": "table",
    "nextId": "fbaBuild3",
    "nextButtonLabel": "Mark as Completed",
    "bigText": "Negotiating with manufacturers",
    "moduleLabel": "Negotiate with Manufacturer",
    "outsourcePrice": "$39",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "We will find you the right manufacturer, ensuring they meet your standards for quality and reliability. Our process includes thorough due diligence to verify their credentials and capabilities. Additionally, we negotiate on your behalf to secure the best prices, making your production process not only efficient but also cost-effective.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrtEDAbzSOakWBQbCQfHqi\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "ecommideagen1",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 2,
    "playbookPct": 12,
    "steps": [
      {
        "text": "<p>Initial Contact for Basic Information:</p>\n",
        "subtext": "<p>Reach out to each manufacturer on your shortlist through Alibaba. Inquire about their MOQ and unit price for the product you're interested in. This initial step lays the groundwork for your negotiations.</p>\n"
      },
      {
        "text": "<p>Begin the Negotiation Process:</p>\n",
        "subtext": "<p>Once you receive their standard terms, initiate the negotiation by asking if they can offer a 20% reduction in both unit price and MOQ. It's a starting point to gauge their flexibility and willingness to accommodate your needs.</p>\n"
      },
      {
        "text": "<p>Making Your Decision:</p>\n",
        "subtext": "<p>Choose your manufacturer based on their final offer, MOQ, and your overall impression of their responsiveness and reliability. Trust your instincts about the “vibe” you get from interacting with them – effective communication and a sense of mutual respect are crucial in a business partnership. Remember, the lowest price isn't always the best option if it compromises quality or reliability. Before we order a sample to check the quality of your chosen manufacturer, we need to create our name and branding, which are pivotal in establishing your product's identity in the market.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>You should now have a shortlist of manufacturers to consider for your product. Securing a favorable deal with a manufacturer is a critical step in your e-commerce journey. Every manufacturer has their set unit price (the cost to produce one unit of your product) and a MOQ (Minimum Order Quantity), which is the least number of units you must order. Negotiating these aspects effectively can significantly impact your overall business costs and profit margins.</p>\n"
    ],
    "inputs": [
      "Manufacturer's Alibaba Link",
      "MOQ",
      "Unit Price  ",
      "General Impression of Manufacturer"
    ]
  },
  "fbaBuild3": {
    "id": "fbaBuild3",
    "type": "multi-text",
    "title": "Naming your product",
    "nextId": "fbaBuild4",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Create Product Name",
    "outsourcePrice": "$29",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Choosing a product name is a critical step, but it doesn't have to be stressful. Our team will validate your product name, conducting comprehensive trademark and domain searches to ensure it's legally safe and available. You'll receive a detailed report within 7 working days, giving you the clarity and confidence to move forward with your chosen name, worry-free.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrjVDAbzSOakWB0v2htVyQ\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "name",
      "name2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 3,
    "playbookPct": 13,
    "steps": [
      {
        "text": "<p>Identify Your Brand Essence:</p>\n",
        "subtext": "<p>Reflect on the core values, mission, and unique selling propositions of your product. What do you want the name to convey?</p>\n"
      },
      {
        "text": "<p>Understand Your Target Audience:</p>\n",
        "subtext": "<p>Consider the interests, preferences, and language of your target demographic. What type of names might appeal to them?</p>\n"
      },
      {
        "text": "<p>Brainstorm Name Ideas:</p>\n",
        "subtext": "<p>Create a list of potential names that are catchy, memorable, and easy to pronounce. Think about how they sound and feel when said aloud. Struggling to find a name? Check out the Namelix name generator <a href=\"https://namelix.com/\">here</a>.</p>\n"
      },
      {
        "text": "<p>Combine Words Creatively:</p>\n",
        "subtext": "<p>Experiment with combinations of two or three relevant words. Avoid overly long names, aiming for something concise yet impactful. Use <a href=\"https://namelix.com/\">this</a>  AI powered tool to help you generate names.</p>\n"
      },
      {
        "text": "<p>Check Domain Availability:</p>\n",
        "subtext": "<p>Prioritize a '.com' domain for better brand recognition and SEO benefits. Utilize GoDaddy's domain search <a href=\"https://www.godaddy.com/en-uk/offers/domain?isc=sem3year&amp;countryview=1&amp;currencyType=EUR&amp;swp_countrycode=FR&amp;cdtl=c_17643108404.g_138780513776.k_kwd-21292142838.a_676833332556.d_c.ctv_g&amp;bnb=b&amp;gad_source=1&amp;gclid=Cj0KCQiA2eKtBhDcARIsAEGTG43Z74iRJbIQNUWHh_OCSEq9xqIPFK3MwG33z875kpk0fv_4Pdr13JkaAnf5EALw_wcB\">tool</a> to check if your blog's .com domain is available.</p>\n"
      },
      {
        "text": "<p>Conduct a Trademark Search:</p>\n",
        "subtext": "<p>Verify that your chosen name isn’t already trademarked to avoid potential legal issues. <a href=\"https://www.uspto.gov/trademarks/search\">Here's</a> where you can search if it's trademarked (just do a basic Word Mark Search).</p>\n"
      },
      {
        "text": "<p>Final Selection:</p>\n",
        "subtext": "<p>Narrow down your list based on feedback, domain availability, and trademark checks. Choose the name that best resonates with your brand vision and audience. Purchase your domain name via Godaddy.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>The name of your product or brand is more than just a label; it’s the first impression and a significant part of your identity in the market. A well-chosen name can make a lasting impact on your target audience and set the tone for your brand's perception.</p>\n"
    ],
    "inputs": [
      "Brand Name: What is your brand name?",
      "Brand Name Rationale: Why did you choose this brand name?"
    ]
  },
  "fbaBuild4": {
    "id": "fbaBuild4",
    "type": "simple",
    "title": "Design your packaging",
    "nextId": "fbaBuild5",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Design Packaging",
    "videoVaultTags": [
      "amaznpackaging",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 4,
    "playbookPct": 14,
    "steps": [
      {
        "text": "<p>Define Your Brand Personality:</p>\n",
        "subtext": "<p>What personality does your brand have? Is it luxury, eco-friendly, playful, or professional? This will guide your packaging design.</p>\n"
      },
      {
        "text": "<p>Consider Your Target Customer:</p>\n",
        "subtext": "<p>Think about the preferences, lifestyles, and values of your target customer. What design elements would appeal to them?</p>\n"
      },
      {
        "text": "<p>Choose Colors and Fonts:</p>\n",
        "subtext": "<p>Select colors and fonts that align with your brand personality and resonate with your target audience. Consider color psychology and font readability.</p>\n"
      },
      {
        "text": "<p>Create a Logo Concept:</p>\n",
        "subtext": "<p>Click <a href=\"https://looka.com/onboarding\">here</a> to use the FIpreneurs community’s AI-powered tool to generate your first logo concept. This tool can help you visualize the brand name in a tangible form.</p>\n"
      },
      {
        "text": "<p>Mockup Packaging Design:</p>\n",
        "subtext": "<p>Use <a href=\"https://www.pacdora.com/3d-creator?utm_source=googleads&amp;utm_medium=search&amp;gad_source=1&amp;gclid=CjwKCAiApuCrBhAuEiwA8VJ6JqPVdzDApbl_56OEBIfAhusJozpljovmE2yisChSQI6SWrJzyCCVWxoCrfIQAvD_BwE\">this</a> tool to help you mock up various packaging designs.</p>\n"
      },
      {
        "text": "<p>Gather Feedback:</p>\n",
        "subtext": "<p>Share your designs with a small group from your target market, or within the FIPreneurs community, for feedback. Use their insights to refine your design.</p>\n"
      },
      {
        "text": "<p>Finalize Your Packaging Design:</p>\n",
        "subtext": "<p>Based on the feedback, finalize your packaging design, ensuring it aligns with your brand identity and resonates with your target customers.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Your product's packaging and branding play a pivotal role in defining your brand identity and connecting with your target audience. While the packaging, encompassing design, color, and font, is crucial for certain products, it's essential to recognize that not every product requires elaborate packaging. However, every product should feature your logo to establish and reinforce your brand presence.</p>\n"
    ],
    "inputs": [
      "Submit screenshots of your logo and packaging."
    ]
  },
  "fbaBuild5": {
    "id": "fbaBuild5",
    "type": "table",
    "title": "Take a good look at your sample and get ready for the exciting next step!",
    "nextId": "fbaBuild6",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Order & Test Sample",
    "videoVaultTags": [
      "ecommideagen1",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 5,
    "playbookPct": 20,
    "steps": [
      {
        "text": "<p>Requesting a Sample with Specifics:</p>\n",
        "subtext": "<p>When ordering the sample, specify to your manufacturer that you want it to include your packaging and logo design. This is crucial to see the final product in its real-world context.</p>\n"
      },
      {
        "text": "<p>Utilize Waiting Time Efficiently:</p>\n",
        "subtext": "<p>While waiting for the sample to arrive, don't stay idle. Move onto the next step in your business journey. This could involve marketing planning, further market research, or preparing your Amazon listing.</p>\n"
      },
      {
        "text": "<p>Evaluate Quality Upon Arrival:</p>\n",
        "subtext": "<p>Inspect the sample's materials, workmanship, and durability. Ensure it matches the specifications and your quality expectations.</p>\n"
      },
      {
        "text": "<p>Test Functionality:</p>\n",
        "subtext": "<p>Test the product to make sure it performs as intended. Check compliance with any relevant safety standards or regulations.</p>\n"
      },
      {
        "text": "<p>Assess the Packaging:</p>\n",
        "subtext": "<p>Review the packaging for quality, practicality, and branding alignment. Confirm it provides adequate protection and fits your brand image.\nOnce you're happy with your sample, place the order with your manufacturer.\nWhile you wait for your sample to arrive please move onto the next step.</p>\n"
      },
      {
        "text": "<p>Placing Your Order:</p>\n",
        "subtext": "<p>Once your sample meets your expectations and aligns with your requirements, it's time to place your order. Remember, it's wise to start with the smallest order your manufacturer allows. This step is crucial in ensuring that what you've envisioned becomes a reality without overcommitting resources initially.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Alright, you've selected your manufacturer, finalized your logo, packaging, and product name. The next crucial step is to order a sample to verify its quality aligns with your expectations. Ordering a sample from your supplier is a vital phase in the product development process, allowing you to ensure the product meets your standards and requirements. Remember, once you've placed your sample order, there might be a waiting period of a few weeks before it arrives. Use this time effectively by progressing to subsequent stages in your project plan.</p>\n"
    ],
    "inputs": [
      "Quality (high, moderate, low)",
      "Packaging",
      "Feebdack from friends/family:"
    ]
  },
  "fbaBuild6": {
    "id": "fbaBuild6",
    "type": "simple",
    "title": "Placing your order\n",
    "nextId": "fbaBuild7",
    "moduleLabel": "Placing Your Order\n",
    "videoVaultTags": [
      "ecommideagen1",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 0,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Place Your Order:</p>\n",
        "subtext": "<p>Reach out to your manufacturer to let them know you're ready to place your order. Provide them with your home address for this initial shipment. It’s important to communicate clearly about the quantity and any specific details regarding the product.</p>\n"
      },
      {
        "text": "<p>Inspect the Products Upon Arrival:</p>\n",
        "subtext": "<p>Once the products arrive at your home, thoroughly check each one to confirm they are as expected. If, for any reason, the products do not meet your standards or match the sample, you have the option to raise a dispute with Alibaba for a full refund.</p>\n"
      },
      {
        "text": "<p>Prepare for Amazon Shipment:</p>\n",
        "subtext": "<p>If all products meet your satisfaction, the next step is to send them to an Amazon warehouse. To do this, log into your Amazon Seller account and create a new shipment. Here’s how: In your Amazon Seller Central dashboard, navigate to the 'Inventory' section.\nSelect 'Manage Inventory', and then choose the products you wish to send.\nClick on ‘Send/Replenish Inventory’ and follow the prompts to create a new shipping plan.\nInput the necessary details such as quantity, shipping method, and destination warehouse.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>If you haven't yet received or tested your sample, it's advisable to skip this step for now.</p>\n",
      "<p>If your sample has arrived and meets all your expectations, it’s time to move forward with placing your order. Inform your manufacturer that you're ready to proceed. For this initial order, it’s recommended to have the products shipped to your home. This allows you to personally inspect each item, ensuring they match the quality and specifications you expect before sending them on to Amazon.</p>\n"
    ]
  },
  "fbaBuild7": {
    "id": "fbaBuild7",
    "type": "simple",
    "title": "Set up your Amazon seller account",
    "nextId": "fbaBuild8",
    "nextButtonLabel": "Mark as Completed",
    "youtubeId": "a5QMTKxYkeY",
    "moduleLabel": "Amazon Account Setup",
    "outsourcePrice": "$49",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Tired of navigating the complexities of setting up your Amazon Seller Account? Let us take the wheel. We'll manage every detail from account creation to configuration, ensuring your storefront is ready to welcome customers. In just 7 working days, your Amazon account will be set up and ready to go, hassle-free.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrqpDAbzSOakWBLbFZ5J3d\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "ecommideagen1",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 6,
    "playbookPct": 21,
    "steps": [
      {
        "text": "<p>Choose Account Type:</p>\n",
        "subtext": "<p>Go to <a href=\"https://sellercentral.amazon.com/gp/on-board/workflow/Registration/login.html?passthrough%2Faccount=fba_soa&amp;passthrough%2FmarketplaceID=ATVPDKIKX0DER&amp;passthrough%2FsuperSource=OAR&amp;ref_=sdus_soa_rp_n&amp;passthrough%2FinitialSessionID=145-1515638-7714449&amp;passthrough%2Fld=NSGoogle_SDRP_N&amp;passthrough%2FldStackingCodes=NSGoogle\">Amazon Seller Central</a> to start the process. We recommend starting out as an Individual seller, especially if you're new to this. This approach avoids the costs and complexities associated with setting up an LLC or similar business entity. As an Individual seller, you can test the waters without the upfront commitment of the monthly fee that comes with a Professional account.</p>\n"
      },
      {
        "text": "<p>Sign Up and Fill Out Relevant Information:</p>\n",
        "subtext": "<p>Proceed to sign up for an Amazon Seller Central account. You can either use an existing Amazon customer account or create a new one. During the sign-up process, you'll be asked to fill out various details including personal information, payment, and tax information. Ensure all your provided information is accurate and up-to-date. If you encounter any questions or uncertainties, don’t hesitate to use the help resources available or ask for assistance in our Slack community.</p>\n"
      },
      {
        "text": "<p>Checkpoint:</p>\n",
        "subtext": "<p>Once you've completed these steps, your Amazon Seller account will be set up, marking a significant milestone in your Amazon FBA journey. You are now one step closer to launching your product and starting your business on one of the world's largest marketplaces.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>While awaiting your product sample, an excellent use of time is to get some essential administration out of the way, like setting up your Amazon Sellers account. Becoming an Amazon seller involves a monthly fee of $30, which can be canceled at any time. Setting up this account is a crucial step to officially start your business on Amazon and get you ready for selling.</p>\n"
    ],
    "inputs": [
      "Experiment with combinations of two or three relevant words. Avoid overly long names, aiming for something concise yet impactful. Use [this](https://namelix.com/)  AI powered tool to help you generate names.",
      "Notes"
    ]
  },
  "fbaBuild8": {
    "id": "fbaBuild8",
    "type": "textarea",
    "title": "Craft a stellar product listing",
    "nextId": "fbaBuild9",
    "nextButtonLabel": "Mark as Completed",
    "youtubeId": "7jLDiL2Prvw",
    "moduleLabel": "Create Your Amazon Listing",
    "outsourcePrice": "$49",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Crafting the perfect product listing can be daunting. That's where we come in. With hundreds of successful listings under our belt, we know how to create one that grabs attention and drives sales. Give us 7 days, and you'll have a listing that's optimized for success.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1Ogrq9DAbzSOakWBWGPpTJtL\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "ecommideagen1",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 8,
    "playbookPct": 22,
    "steps": [
      {
        "text": "<p>Identify Target Keywords:</p>\n",
        "subtext": "<p>Begin by using Helium 10’s Magnet tool to conduct in-depth keyword research. Aim to find longer-tail, less competitive keywords which can help your product quickly gain traction and visibility on Amazon. These initial keywords are essential in establishing your product's early online presence.</p>\n"
      },
      {
        "text": "<p>Develop a Captivating Title:</p>\n",
        "subtext": "<p>Create a product title that is both informative and attractive, making sure to incorporate your primary keyword for maximum impact.</p>\n"
      },
      {
        "text": "<p>Engaging and Informative Product Description:</p>\n",
        "subtext": "<p>Write a narrative-like description that effectively highlights the benefits and features of your product, leveraging the targeted keywords to enhance findability and relevance.</p>\n"
      },
      {
        "text": "<p>Seamlessly Integrate Keywords:</p>\n",
        "subtext": "<p>Incorporate your selected keywords naturally throughout your listing, including in the title, bullet points, and product description. Ensure the narrative remains engaging and customer-focused, avoiding any unnatural keyword stuffing.</p>\n"
      },
      {
        "text": "<p>Use AI-Powered Tools for Listing Optimization:</p>\n",
        "subtext": "<p>Take advantage of Helium 10’s AI-powered listing builder and keyword index checker tools. These resources are invaluable in creating an optimized listing that not only appeals to Amazon’s search algorithm but also resonates well with potential customers.</p>\n"
      },
      {
        "text": "<p>Incorporate High-Quality Images:</p>\n",
        "subtext": "<p>Select professional, high-resolution images that showcase your product from various angles, providing a clear and detailed visual representation.</p>\n"
      },
      {
        "text": "<p>Emphasize Unique Selling Points:</p>\n",
        "subtext": ""
      }
    ],
    "paragraphs": [
      "<p>Your Amazon listing is a vital component in your journey to e-commerce success. It's not just a display of your product; it's an essential tool for converting viewers into customers. A compelling listing is a synergy of engaging copy, strategic keyword usage, and captivating images, all working together to present your product persuasively. The aim is to ensure every element of your listing speaks to potential buyers, driving them towards making a purchase.</p>\n"
    ],
    "inputs": [
      "Let us know your listing's link"
    ]
  },
  "fbaBuild9": {
    "id": "fbaBuild9",
    "type": "table",
    "title": "Laying the growth foundations",
    "nextId": "fbaBuild10",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Growth Foundation",
    "videoVaultTags": [
      "amznidea",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 9,
    "playbookPct": 30,
    "steps": [
      {
        "text": "<p>Conduct Thorough Online Research:</p>\n",
        "subtext": "<p>Investigate where your potential customers are most active online. This could include social media platforms, forums, niche websites, and online communities.</p>\n"
      },
      {
        "text": "<p>Document Key Hangout Spots:</p>\n",
        "subtext": "<p>Note down specific platforms and sites where your target audience congregates. Pay attention to details like the size of the audience, the level of engagement, and the nature of discussions related to your product category.</p>\n"
      },
      {
        "text": "<p>Identify Influencers and Gatekeepers:</p>\n",
        "subtext": "<p>In each platform or community, identify key individuals or 'gatekeepers' who influence the audience. These could be moderators in forums, influencers on social media, or admins of niche groups.</p>\n"
      },
      {
        "text": "<p>Assess Audience Size and Engagement:</p>\n",
        "subtext": "<p>For each identified platform, estimate the size of the audience and the level of engagement. This information is crucial for prioritizing your efforts.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>A critical, yet often overlooked, aspect of launching your Amazon listing is the strategic drive of targeted traffic to your product page. This step is essential and is where 99% of people falter, leading to underwhelming growth. The key to elevating your product's visibility lies in directing a consistent flow of traffic to your listing. This isn't just about getting eyes on your product; it's about boosting your position in Amazon’s search rankings. Without this concerted effort, your product risks remaining invisible, never reaching the coveted first page of Amazon search results.</p>\n",
      "<p>The ultimate goal here is to rank as high as possible, ideally on the first page, for your chosen keywords. This high visibility is paramount, as products on the first page naturally receive more attention and potential sales. To achieve this, you need to understand where your potential customers spend their time online and target those platforms effectively. Whether it's through social media marketing, influencer collaborations, paid ads, or email marketing, each channel can play a pivotal role in driving traffic to your listing.</p>\n",
      "<p>Remember, your launch phase is a golden opportunity to make a strong impression. By directing a substantial amount of targeted traffic to your Amazon listing right from the start, you significantly enhance its visibility. This not only improves your search ranking on Amazon but also lays the foundation for long-term success and sustainability of your product in a highly competitive marketplace.</p>\n"
    ],
    "inputs": [
      "Hangout Spot (FB groups, influencers, blogs, etc.):",
      "Estimate the size of the audience in each spot.",
      "Who is the gatekeeper for each audience spot?"
    ]
  },
  "fbaBuild10": {
    "id": "fbaBuild10",
    "type": "table",
    "title": "Growth strategy",
    "nextId": "fbaBuild11",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Growth Strategy",
    "outsourcePrice": "$69",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Building an influencer marketing strategy from scratch can be time-consuming. We simplify the process by handpicking 100 influencers that align with your brand, ready to forge impactful collaborations. Trust us to set the stage for your success.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1Odfw7DAbzSOakWBimPMoBOM\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "amznidea",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 10,
    "playbookPct": 31,
    "steps": [
      {
        "text": "<p>Craft Your Pitch:</p>\n",
        "subtext": "<p>Start with a personal touch. Compliment them on specific posts or aspects of their journey that you genuinely admire. Introduce yourself and your brand. Briefly tell your story and the inspiration behind your product. Explain why you think your product aligns with their audience. Make it clear that you’ve done your homework and understand their follower base. Propose a paid collaboration. Be straightforward but courteous about your intention to engage in a paid partnership. Mention that you’re approaching them for your product launch, highlighting the potential for them to be part of something exciting and new. Provide a clear call-to-action. If they’re interested, let them know what the next steps would be.</p>\n"
      },
      {
        "text": "<p>Personalization is Key:</p>\n",
        "subtext": "<p>Customize each message for the individual influencer. Avoid generic, templated messages. Show that you’ve invested time in understanding who they are and what their brand stands for.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Reaching out to your selected influencers and platforms is a pivotal step, and it's important to approach this with clarity and personalization. When communicating, emphasize how a collaboration would be mutually beneficial. Clearly present your product's unique features and how they align with their audience's interests, aiming to forge a partnership that extends beyond mere promotion.</p>\n",
      "<p>It's crucial to be concise and authentic in your outreach. Given that influencers and platform managers often receive numerous proposals, make your pitch stand out. Be upfront about the benefits, including compensation. Being prepared to pay influencers is a key aspect of this process, as it often ensures a higher level of commitment and engagement from them. Alongside monetary compensation, consider offering additional incentives like exclusive discounts or free samples, which can further entice them.</p>\n",
      "<p>This approach is not about widespread, impersonal contact; it’s about establishing genuine relationships with carefully chosen influencers and platforms. By focusing your efforts on a targeted group, you can significantly enhance the efficacy of your promotions, leading to increased traffic to your Amazon listing and a stronger position in the marketplace.</p>\n"
    ],
    "inputs": [
      "Influencer/Gatekeeper Name:",
      "What is your unique pitch? "
    ]
  },
  "fbaBuild11": {
    "id": "fbaBuild11",
    "type": "table",
    "title": "Reaching out to influencers",
    "nextId": "fbaBuild12",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Contacting Influencers",
    "videoVaultTags": [
      "ecommideagen1",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 11,
    "playbookPct": 32,
    "steps": [
      {
        "text": "<p>Send Your Well-Crafted Emails:</p>\n",
        "subtext": "<p>Using the personalized pitches you've crafted, reach out to 10-20 influencers who align with your brand and audience. Make sure each email maintains its personalized touch and relevance to the specific influencer.</p>\n"
      },
      {
        "text": "<p>Organize and Track Your Outreach:</p>\n",
        "subtext": "<p>Create a simple tracking system, like a spreadsheet, to keep a record of your outreach efforts. This can be as straightforward as a list in a document or a detailed spreadsheet, whichever you prefer. Record the following for each influencer: Influencer’s name and contact information. Date you sent the initial email. Any specific notes or key points about your pitch to them. A column for follow-up dates and responses.</p>\n"
      },
      {
        "text": "<p>Set Reminders for Follow-ups:</p>\n",
        "subtext": "<p>Set a reminder for yourself to follow up if you haven’t received a response within a week or two. A respectful follow-up can sometimes catch an influencer's attention if your initial email was missed.</p>\n"
      },
      {
        "text": "<p>Stay Organized and Patient:</p>\n",
        "subtext": "<p>Regularly update your tracking system with any responses received or additional follow-ups made. Staying organized will help you keep track of which influencers have been contacted and the status of each outreach effort. Remember, patience is key. Influencer partnerships can take time to materialize, so don’t be discouraged by a lack of immediate responses.</p>\n"
      },
      {
        "text": "<p>Get Creative in Your Approach:</p>\n",
        "subtext": "<p>After sending out your personalized emails, think outside the box to further engage with the influencers. This could involve commenting thoughtfully on their posts, which not only shows your genuine interest but also helps in building a connection. Sometimes, even sending a handwritten letter or a unique package can make your brand stand out. These unconventional methods can be surprisingly effective in catching an influencer's attention, especially in a world dominated by digital communication. This extra effort can significantly enhance your chances of securing a partnership, as it demonstrates your commitment and creativity in forming meaningful collaborations. Remember, in the influencer marketing realm, a little creativity can go a long way in making lasting impressions.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Approaching influencers is a numbers game and an exercise in persistence. It's essential to understand that not all influencers will respond to your outreach, and that's perfectly normal. Your goal is to connect with those who align with your brand and are interested in forging a mutually beneficial relationship. By reaching out to a substantial number of influencers, you increase your chances of finding the right fit for your product launch.</p>\n"
    ],
    "inputs": [
      "Influencer/Gatekeeper Name:",
      "Outreach Date:",
      "Follow-up Date:"
    ]
  },
  "fbaBuild12": {
    "id": "fbaBuild12",
    "type": "table",
    "title": "Rallying friends and family for launch",
    "nextId": "fbaLaunch1",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Friends & Family Rally",
    "videoVaultTags": [
      "ecommideagen1",
      "ecommideagen2",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 12,
    "playbookPct": 33,
    "steps": [
      {
        "text": "<p>Create a Support List:</p>\n",
        "subtext": "<p>Make a list of friends, family members, colleagues, and acquaintances who you believe will be willing to support you. Aim for a diverse group in terms of demographics to get a broad range of perspectives in the reviews.</p>\n"
      },
      {
        "text": "<p>Prepare Your Outreach:</p>\n",
        "subtext": "<p>Draft a message explaining your new business venture and how crucial their support is at this early stage. Be transparent about your request for them to purchase the product and leave an honest review.</p>\n"
      },
      {
        "text": "<p>Offer a Reimbursement:</p>\n",
        "subtext": "<p>Let them know that in exchange for their honest review, you will reimburse them for the cost of the product. This is not a strategy for obtaining false praise but rather a way to facilitate genuine feedback without financial barriers for your supporters.</p>\n"
      },
      {
        "text": "<p>Communicate the Process Clearly:</p>\n",
        "subtext": "<p>Explain how to purchase the product and the process for leaving a review on Amazon. Ensure they understand that their reviews should be honest and reflect their true experience with the product.</p>\n"
      },
      {
        "text": "<p>Set a Timeline:</p>\n",
        "subtext": "<p>Give them a specific timeline for buying and reviewing the product, ideally coordinated with your product launch date. This helps ensure that you have a number of reviews available right from the start.</p>\n"
      },
      {
        "text": "<p>Track and Follow-Up:</p>\n",
        "subtext": "<p>Keep track of who you’ve reached out to, who has agreed to help, and who has completed the purchase and review process. Follow up respectfully with those who may have forgotten or been too busy.</p>\n"
      },
      {
        "text": "<p>Show Gratitude:</p>\n",
        "subtext": "<p>Express your genuine appreciation for their support. Acknowledge their contribution to your entrepreneurial journey, regardless of the size.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>When launching a new product on Amazon, one of the most crucial elements for gaining traction is accumulating reviews. Reviews not only enhance credibility but also significantly influence buyers' decisions. A practical and often necessary strategy at this early stage is to enlist the help of friends and family. The goal here is to secure an initial batch of honest reviews to kickstart your product's presence. Aim for around 20 reviews at launch, which means reaching out to a slightly larger circle of 20-30 people to account for those who might not follow through.</p>\n"
    ],
    "inputs": [
      "Friend/Family Name",
      "Engaged? Yes/No",
      "Purchased? Yes/No",
      "Reviewed? Yes/No"
    ]
  },
  "fbaLaunch1": {
    "id": "fbaLaunch1",
    "type": "simple",
    "title": "The countdown to launch",
    "nextId": "fbaLaunch2",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Final Launch Preparations",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "build",
    "playbookStageStep": 13,
    "playbookPct": 35,
    "steps": [
      {
        "text": "<p>Expand Influencer Outreach:</p>\n",
        "subtext": "<p>Continue to reach out to more influencers. The wider you cast your net, the higher your chances of securing valuable partnerships. Regularly follow up with influencers who haven't responded yet, while maintaining a polite and respectful tone.</p>\n"
      },
      {
        "text": "<p>Broaden Your Support Network:</p>\n",
        "subtext": "<p>Identify more friends, family members, and acquaintances who might be interested in supporting your launch. Extend your list beyond the initial circle to include secondary connections. Reach out to them with the same transparency and offer of reimbursement in exchange for honest reviews.</p>\n"
      },
      {
        "text": "<p>Engage with Early Supporters:</p>\n",
        "subtext": "<p>Keep in touch with those who have already agreed to help. Share updates about your launch and express your gratitude for their support.</p>\n"
      },
      {
        "text": "<p>Finalize Your Launch Plan:</p>\n",
        "subtext": "<p>Review your launch strategy to ensure everything is in place. Check your Amazon listing, promotional materials, and any marketing content you plan to use.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>As you approach the launch date for your product on Amazon, it’s crucial to maintain and even intensify your momentum-building efforts. If you've diligently followed all the previous steps and are still waiting for your product to arrive, this is an opportune time to double down on your pre-launch strategies. The more groundwork you lay now, the more momentum you'll inject into your launch. This phase is about reinforcing your efforts with influencer outreach and expanding your network of support among friends and family.</p>\n"
    ]
  },
  "fbaLaunch2": {
    "id": "fbaLaunch2",
    "type": "table",
    "title": "Activate friends and family ",
    "nextId": "fbaLaunch3",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Activate friends & family ",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "launch",
    "playbookStageStep": 1,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Announce Your Product Availability:</p>\n",
        "subtext": "<p>Send a message to your list of friends and family, announcing that your product is now available for purchase on Amazon. Ensure this communication is enthusiastic and expresses the importance of their timely support.</p>\n"
      },
      {
        "text": "<p>Provide Clear Instructions:</p>\n",
        "subtext": "<p>Include a link to your product listing to make the purchasing process as straightforward as possible. Reiterate the steps for leaving a review on Amazon to ensure everyone is clear about what to do after their purchase.</p>\n"
      },
      {
        "text": "<p>Outline the Reimbursement Process:</p>\n",
        "subtext": "<p>Explain how and when you’ll provide reimbursement for their purchase. Whether it's through PayPal, bank transfer, or another method, make sure the process is simple and transparent. Set expectations for the timeline of reimbursement – for instance, confirming that you'll process refunds within a certain number of days after their review is posted.</p>\n"
      },
      {
        "text": "<p>Monitor Purchases and Reviews:</p>\n",
        "subtext": "<p>Keep an eye on your Amazon listing for new purchases and reviews from your friends and family. Maintain a record of who has made a purchase and left a review, to manage reimbursements efficiently.</p>\n"
      },
      {
        "text": "<p>Follow Up as Needed:</p>\n",
        "subtext": "<p>Send gentle reminders to those who may have forgotten to make their purchase or leave a review. Remember, everyone has different schedules, so a little nudge can be helpful.</p>\n"
      },
      {
        "text": "<p>Show Your Gratitude:</p>\n",
        "subtext": "<p>As reviews start coming in, don’t forget to express your gratitude to each person who supported you. A personalized thank you message can go a long way.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>You've reached a pivotal moment – the launch of your product on Amazon. Now it's time to activate the network of friends and family you've rallied to generate those crucial initial reviews. Prompt action from your personal network can significantly influence your product's early performance, providing valuable social proof to future customers. As they purchase and review your product, you'll follow through on your promise to reimburse them, reinforcing their support and ensuring an honest appraisal of your product.</p>\n"
    ],
    "inputs": [
      "Friends / Family member",
      "Purchased Product?",
      "Left a Review?"
    ]
  },
  "fbaLaunch3": {
    "id": "fbaLaunch3",
    "type": "table",
    "title": "Activating influencers",
    "nextId": "fbaLaunch4",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Influencer Launch Support",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "launch",
    "playbookStageStep": 2,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Communicate Your Readiness:</p>\n",
        "subtext": "<p>Reach out to the influencers you've successfully partnered with and inform them that your product is ready for promotion. Share your excitement about the launch and the positive reviews you’ve garnered.</p>\n"
      },
      {
        "text": "<p>Provide Essential Details:</p>\n",
        "subtext": "<p>Send them the direct link to your Amazon product page. Make sure it’s accurate to avoid any confusion or misdirection for their audience. If there are specific aspects or features of the product you want them to highlight, communicate this clearly.</p>\n"
      },
      {
        "text": "<p>Confirm the Agreement and Compensation:</p>\n",
        "subtext": "<p>Reiterate the terms of your agreement, including what the influencer will deliver (e.g., posts, stories, blog entries) and the timeline for these deliverables. Confirm the payment details. Ensure that the influencer is clear about how and when they will be compensated for their promotional efforts.</p>\n"
      },
      {
        "text": "<p>Coordinate the Promotion:</p>\n",
        "subtext": "<p>Discuss and agree upon the timing of the promotion. Ideally, it should be soon after you inform them to capitalize on the early positive reviews. If you’re working with multiple influencers, consider staggering their promotions to maintain a steady flow of traffic to your product page.</p>\n"
      },
      {
        "text": "<p>Monitor the Campaign:</p>\n",
        "subtext": "<p>Keep an eye on the influencers’ posts and the engagement they receive. This can provide valuable insights into your target audience's reception of your product. Track any uptick in sales or traffic to your Amazon page during the influencer promotion period.</p>\n"
      },
      {
        "text": "<p>Provide Support and Show Appreciation:</p>\n",
        "subtext": "<p>Be available to answer any questions or provide additional information the influencer might need during the promotion. After the promotion, thank the influencer for their work. Building a good relationship can lead to future collaborations.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>With over 20 reviews in place, your product now has the social proof needed to make the most of influencer collaborations. This step is all about activating your influencer partners to widen your reach and inject further momentum into your product's presence on Amazon. The goal is to have these influencers promote your product to their audiences, thereby driving additional traffic and potential sales.</p>\n"
    ],
    "inputs": [
      "Influencer/Gatekeeper Name:",
      "Influencer Activated? Yes/No",
      "Promo Post Link"
    ]
  },
  "fbaLaunch4": {
    "id": "fbaLaunch4",
    "type": "simple",
    "title": "Maximizing visibility with Amazon Ads: Enhancing your product’s reach\n\n\n\n\n\n\n",
    "nextId": "fbaLaunch5",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Maximize with Amazon Ads",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "launch",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Set Up Your Amazon Ads Account:</p>\n",
        "subtext": "<p>If you haven’t already, set up your Amazon Ads account through Seller Central. This is where you'll manage all your advertising campaigns.</p>\n"
      },
      {
        "text": "<p>Define Your Campaign Goals:</p>\n",
        "subtext": "<p>Determine what you want to achieve with your ads – whether it's increasing sales, boosting brand awareness, or launching a new product. This will guide your campaign strategy.</p>\n"
      },
      {
        "text": "<p>Choose the Right Type of Ad:</p>\n",
        "subtext": "<p>Amazon offers several types of ads, including Sponsored Products, Sponsored Brands, and Sponsored Display. For most new sellers, Sponsored Products are a good starting point as they directly target customers searching for similar products.</p>\n"
      },
      {
        "text": "<p>Set a Budget and Duration:</p>\n",
        "subtext": "<p>Allocate your $200 budget to this campaign. Decide on the campaign duration – a shorter, more focused campaign can provide a quick influx of traffic and data to analyze.</p>\n"
      },
      {
        "text": "<p>Select Products to Advertise:</p>\n",
        "subtext": "<p>Choose your newly launched product for advertising. Ensure your listing is fully optimized before you start driving more traffic to it.</p>\n"
      },
      {
        "text": "<p>Target Your Ads:</p>\n",
        "subtext": "<p>Use automatic targeting for your first campaign, which allows Amazon to target your ads based on keywords and products similar to yours. As you gain more experience and data, you can experiment with manual targeting, choosing specific keywords to bid on.</p>\n"
      },
      {
        "text": "<p>Monitor and Adjust Your Campaign:</p>\n",
        "subtext": "<p>Regularly check the performance of your ad campaign. Look at metrics like click-through rate (CTR), conversion rate, and advertising cost of sales (ACoS). Be prepared to adjust your bid amounts, targeting, or even pause the campaign to optimize its performance.</p>\n"
      },
      {
        "text": "<p>Analyze the Results:</p>\n",
        "subtext": "<p>At the end of the campaign, review the results. Analyze what worked well and what didn’t to improve your future ad campaigns.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>With a solid foundation of reviews and influencer marketing underway, it's time to supercharge your product’s visibility on Amazon using paid advertising. Amazon ads serve as a powerful tool to increase your product's exposure to potential buyers actively searching for items like yours. By allocating a budget of $200 for an Amazon ad campaign, you can significantly boost traffic to your listing, increasing both visibility and sales opportunities.</p>\n"
    ]
  },
  "fbaLaunch5": {
    "id": "fbaLaunch5",
    "type": "textarea",
    "title": "Expanding your reach: Leveraging personal social networks for product promotion",
    "nextId": "fbaScale1",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Social Network Expansion",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "launch",
    "playbookStageStep": 4,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Craft Your Social Media Posts:</p>\n",
        "subtext": "<p>Develop engaging and authentic content that tells the story of your product and your entrepreneurial journey. Include compelling visuals or even a short video.</p>\n"
      },
      {
        "text": "<p>Customize for Each Platform:</p>\n",
        "subtext": "<p>Tailor your message to fit the style of each social media platform. For LinkedIn, focus on the business and entrepreneurial aspects of your journey. On Instagram, leverage visuals and a more casual tone.</p>\n"
      },
      {
        "text": "<p>Include a Call to Action:</p>\n",
        "subtext": "<p>Encourage your network to check out your product on Amazon. You can include a link to your product page to make it easy for them to find it.</p>\n"
      },
      {
        "text": "<p>Leverage Instagram Reels or Stories:</p>\n",
        "subtext": "<p>Create a short, engaging video for Instagram Reels or Stories, showcasing your product or sharing a glimpse of your launch excitement. These formats are great for capturing attention and driving engagement.</p>\n"
      },
      {
        "text": "<p>Post on LinkedIn:</p>\n",
        "subtext": "<p>Share your journey and achievements on LinkedIn. This platform is excellent for reaching professional contacts who might be interested in your entrepreneurial story and product.</p>\n"
      },
      {
        "text": "<p>Engage with Your Audience:</p>\n",
        "subtext": "<p>Respond to comments and messages resulting from your posts. Engaging with your audience can foster relationships and potentially lead to more sales.</p>\n"
      },
      {
        "text": "<p>Encourage Shares and Referrals:</p>\n",
        "subtext": "<p>Ask your friends, family, and network to share your post. Word-of-mouth can be incredibly effective, especially within personal networks.</p>\n"
      },
      {
        "text": "<p>Track Engagement:</p>\n",
        "subtext": "<p>Monitor the engagement levels on your posts (likes, shares, comments) and any increase in traffic or sales on your Amazon listing that might correlate with these social media activities.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>At this stage, you’ve laid a strong foundation for your product's success on Amazon. To keep the momentum going and broaden your reach even further, it’s time to leverage your personal social media networks. By sharing your journey and product launch on platforms like LinkedIn, Instagram, or Facebook, you not only increase awareness but also open up opportunities for sales through friends of friends and extended networks. This approach can lead to unexpected connections and customers, amplifying the buzz around your product.</p>\n"
    ],
    "inputs": [
      "Screenshot your posted social media message"
    ]
  },
  "fbaScale1": {
    "id": "fbaScale1",
    "type": "simple",
    "title": "Analyze and adapt",
    "nextId": "fbaScale2",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Review and Adapt",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "scale",
    "playbookStageStep": 1,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Check Your Sales and Conversion Rates:</p>\n",
        "subtext": "<p>Regularly look at how many people are viewing your listing versus how many are actually buying. This gives you a quick sense of your listing's effectiveness.</p>\n"
      },
      {
        "text": "<p>Read Your Customer Reviews:</p>\n",
        "subtext": "<p>Pay attention to what buyers are saying in their reviews. Are they mostly positive, or are there common complaints or suggestions?</p>\n"
      },
      {
        "text": "<p>Look at Your Returns and Feedback:</p>\n",
        "subtext": "<p>Keep an eye on any returns or direct customer feedback you might receive. This can be a valuable source of information for product improvements.</p>\n"
      },
      {
        "text": "<p>Monitor Stock Levels:</p>\n",
        "subtext": "<p>Make sure you have enough stock to meet demand, but not so much that you’re overstocked.</p>\n"
      },
      {
        "text": "<p>Simple SEO Check:</p>\n",
        "subtext": "<p>Make sure your product title and description still include keywords that are relevant to what your customers might be searching for.</p>\n"
      },
      {
        "text": "<p>Observe Any Trends:</p>\n",
        "subtext": "<p>Are there times when your product sells more? Are there external factors like seasons or holidays that affect your sales?</p>\n"
      },
      {
        "text": "<p>Quick Competitor Scan:</p>\n",
        "subtext": "<p>Take a cursory look at what your competitors are doing. Are they offering something new or different?</p>\n"
      }
    ],
    "paragraphs": [
      "<p>In the growth stage of your Amazon journey, it's crucial to keep an eye on how your product is performing and what your customers are saying. This doesn't have to be complex. A simple, ongoing review of key elements can help you understand what's working and what might need a tweak. The goal is to fine-tune your approach based on real-world feedback and data to keep improving your product's appeal and sales performance.</p>\n"
    ]
  },
  "fbaScale2": {
    "id": "fbaScale2",
    "type": "simple",
    "title": "Strategic growth scaling: Amplifying what works in your Amazon sales strategy\n\n\n\n\n\n\n",
    "nextId": "fbaScale3",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Amplify Sales Strategy",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "scale",
    "playbookStageStep": 2,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Analyze Your Sales Drivers:</p>\n",
        "subtext": "<p>Take a closer look at your sales data to identify the primary drivers. Is it the Amazon ads bringing in the most sales, or are influencer partnerships proving more effective?</p>\n"
      },
      {
        "text": "<p>Optimize and Expand Amazon Ads:</p>\n",
        "subtext": "<p>If Amazon ads are your main sales driver, consider increasing your ad spend. Before doing so, optimize your existing campaigns for better conversion rates and ROI. Experiment with different types of Amazon ads (like Sponsored Products or Sponsored Brands) to see which works best for your product.</p>\n"
      },
      {
        "text": "<p>Strengthen Influencer Partnerships:</p>\n",
        "subtext": "<p>If influencers are bringing in significant sales and boosting your Amazon rankings, it’s time to expand your network. Reach out to more influencers with similar audience demographics. Consider diversifying the types of influencers you work with – micro-influencers, for instance, can have highly engaged audiences.</p>\n"
      },
      {
        "text": "<p>Refine Your Targeting:</p>\n",
        "subtext": "<p>For both ads and influencer partnerships, refine your targeting strategies. Use the insights gained from your initial campaigns to target more precisely.</p>\n"
      },
      {
        "text": "<p>Monitor and Adjust Budgets:</p>\n",
        "subtext": "<p>As you increase spending, whether on ads or influencer collaborations, closely monitor the impact on sales and profitability. Be ready to adjust your budgets based on performance.</p>\n"
      },
      {
        "text": "<p>Seek Feedback and Iterate:</p>\n",
        "subtext": "<p>Continuously gather feedback from customers and analyze performance metrics. Use these insights to make iterative improvements to your product, marketing message, and targeting.</p>\n"
      },
      {
        "text": "<p>Explore Additional Marketing Channels:</p>\n",
        "subtext": "<p>Don’t limit yourself to just Amazon ads and influencers. Explore other marketing channels like email marketing, social media ads, or content marketing to attract a wider audience.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Now that you have some initial data and insights into what's driving your sales, it's time to focus on scaling those successful elements. Whether it's your Amazon ads or influencer partnerships that are yielding the best results, doubling down on these strategies can significantly amplify your growth. The idea is to invest more in what’s working, optimizing your approach to maximize returns.</p>\n"
    ]
  },
  "fbaScale3": {
    "id": "fbaScale3",
    "type": "simple",
    "title": "Leveraging social media power: Building brand presence and community on platforms like TikTok and Instagram",
    "nextId": "fbaScale4",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Social Media Power",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "scale",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Evaluate Your Product's Social Media Potential:</p>\n",
        "subtext": "<p>Assess whether your product has visual appeal, unique features, or aligns with interests that resonate well on social media platforms.</p>\n"
      },
      {
        "text": "<p>Set Up Social Media Accounts:</p>\n",
        "subtext": "<p>Create dedicated accounts for your brand on TikTok and Instagram. Choose a handle that matches or closely resembles your brand name for consistency.</p>\n"
      },
      {
        "text": "<p>Develop a Content Strategy:</p>\n",
        "subtext": "<p>Plan a content strategy that showcases your product in an engaging way. This can include product demonstrations, customer testimonials, behind-the-scenes looks, or even educational content related to your product’s niche.</p>\n"
      },
      {
        "text": "<p>Consistency is Key:</p>\n",
        "subtext": "<p>Post content regularly to keep your audience engaged. Consistency helps in building a loyal following and improving your visibility on these platforms.</p>\n"
      },
      {
        "text": "<p>Engage with Your Audience:</p>\n",
        "subtext": "<p>Respond to comments, messages, and engage with other users’ content. Building a community is about interaction, not just broadcasting your message.</p>\n"
      },
      {
        "text": "<p>Utilize Hashtags and Trends:</p>\n",
        "subtext": "<p>Research and use relevant hashtags to increase the discoverability of your posts. Participate in relevant trends or challenges on TikTok and Instagram to tap into wider audiences.</p>\n"
      },
      {
        "text": "<p>Leverage Influencer Collaborations:</p>\n",
        "subtext": "<p>Collaborate with influencers on these platforms to reach new audiences. Influencers can create authentic content that showcases your product to their followers.</p>\n"
      },
      {
        "text": "<p>Track Your Progress:</p>\n",
        "subtext": "<p>Monitor the growth of your following and the engagement on your posts. Use insights and analytics tools provided by these platforms to understand your audience better.</p>\n"
      },
      {
        "text": "<p>Drive Traffic to Your Amazon Listing:</p>\n",
        "subtext": "<p>Include a link to your Amazon product page in your social media profiles. Occasionally direct your followers to your Amazon listing through posts and stories.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>In today's digital age, having a strong social media presence can be a game-changer for your brand. If your product has unique features or appeals to a specific demographic, platforms like TikTok and Instagram offer tremendous opportunities to engage with audiences and drive traffic to your Amazon listing. Growing a social media following not only enhances brand visibility but also establishes a community around your product, which can be invaluable for long-term success.</p>\n"
    ]
  },
  "fbaScale4": {
    "id": "fbaScale4",
    "type": "simple",
    "title": "Exploring other growth channels",
    "nextId": "fbaScale5",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Explore Growth Avenues",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "scale",
    "playbookStageStep": 4,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Deep Dive into Customer Habits:</p>\n",
        "subtext": "<p>Analyze your customer data and insights from your Amazon sales, social media interactions, and any other available sources to understand where your customers are most active. Are they frequenting specific online forums, websites, or social media platforms?</p>\n"
      },
      {
        "text": "<p>Research Industry Trends:</p>\n",
        "subtext": "<p>Stay updated on the latest trends in your industry. Attend webinars, read industry blogs, and join relevant online communities to understand where your potential customers might be gathering.</p>\n"
      },
      {
        "text": "<p>Explore Online Communities:</p>\n",
        "subtext": "<p>Look for niche online communities, forums, or groups where your customers might be active. Platforms like Reddit, Quora, or specific Facebook groups can be valuable for this.</p>\n"
      },
      {
        "text": "<p>Consider Collaborations and Partnerships:</p>\n",
        "subtext": "<p>Identify potential partnerships with other brands, influencers, or content creators who share a similar audience. Collaborations can open doors to new customer segments.</p>\n"
      },
      {
        "text": "<p>Leverage Content Marketing:</p>\n",
        "subtext": "<p>Create valuable and engaging content tailored to your audience. This might include blog posts, how-to guides, webinars, or podcasts, depending on where your customers are most engaged.</p>\n"
      },
      {
        "text": "<p>Experiment with Different Advertising Platforms:</p>\n",
        "subtext": "<p>Beyond Amazon ads, consider other advertising platforms like Google Ads, Facebook Ads, or even niche-specific ad networks. Test different platforms to see where you get the best ROI.</p>\n"
      },
      {
        "text": "<p>Attend and Participate in Events:</p>\n",
        "subtext": "<p>Depending on your product, attending trade shows, expos, or local events can be a great way to get your product in front of potential customers.</p>\n"
      },
      {
        "text": "<p>Utilize Email Marketing:</p>\n",
        "subtext": "<p>Build an email list to keep in touch with your customers and prospects. Share updates, promotions, and valuable content to keep your brand top-of-mind.</p>\n"
      },
      {
        "text": "<p>Monitor and Adjust Your Strategies:</p>\n",
        "subtext": "<p>Regularly review the performance of your outreach efforts across different channels. Be ready to adjust your strategy based on what’s most effective.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Understanding where your customers spend their time and what interests them is key to expanding your product's reach. Each customer base has unique habits and preferences. By identifying and leveraging the channels where your customers are most active, you can create targeted strategies to engage them effectively. This approach is not just about increasing visibility; it's about connecting with your customers in spaces they already value and trust.</p>\n"
    ]
  },
  "fbaScale5": {
    "id": "fbaScale5",
    "type": "simple",
    "title": "Optimizing multi-platform sales with Amazon's fulfillment services",
    "nextId": "fbaScale6",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Optimize Sales Platforms",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "scale",
    "playbookStageStep": 5,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Build Your Website with Shopify:</p>\n",
        "subtext": "<p>Use Shopify to create your e-commerce website. Shopify offers an API that integrates smoothly with Amazon FBA, automating the fulfillment process for orders placed on your site.</p>\n"
      },
      {
        "text": "<p>List Your Product on Multiple Platforms:</p>\n",
        "subtext": "<p>Expand your product listings to other platforms like eBay, Etsy, or niche-specific marketplaces. Choose platforms where your target customers are likely to shop.</p>\n"
      },
      {
        "text": "<p>Integrate Amazon FBA for Fulfillment:</p>\n",
        "subtext": "<p>Set up Amazon FBA to handle the shipping for orders from all platforms. This provides a consistent, reliable shipping experience for your customers, no matter where they purchase your product.</p>\n"
      },
      {
        "text": "<p>Optimize Listings for Each Platform:</p>\n",
        "subtext": "<p>Tailor your product listings for each platform according to their unique format and audience preferences. Ensure your branding remains consistent across all platforms.</p>\n"
      },
      {
        "text": "<p>Manage Your Inventory on Amazon:</p>\n",
        "subtext": "<p>Keep a close eye on your inventory levels in Amazon FBA to ensure you can fulfill orders from all platforms effectively.</p>\n"
      },
      {
        "text": "<p>Market Your Product Across Platforms:</p>\n",
        "subtext": "<p>Create marketing strategies tailored to each platform. Utilize the unique features of each (like eBay's auction format or Etsy's community engagement) to promote your product.</p>\n"
      },
      {
        "text": "<p>Monitor Sales and Feedback Across Platforms:</p>\n",
        "subtext": "<p>Track which platforms are bringing in the most sales and gather customer feedback from each to understand different audience preferences.</p>\n"
      },
      {
        "text": "<p>Leverage Analytics for Strategic Decisions:</p>\n",
        "subtext": "<p>Use analytics tools provided by Shopify and other platforms to make informed decisions about inventory, marketing, and sales strategies.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Once you've achieved consistent sales on Amazon, expanding to your own site and other platforms like eBay, Etsy, or any niche marketplaces where your target customers hang out is a strategic move. The beauty of Amazon's fulfillment service (Fulfillment by Amazon - FBA) is that it can handle inventory storage and shipping for sales made outside of Amazon. When you sell a product on eBay, Etsy, your own site, or another platform, Amazon can fulfill that order, charging only a shipping fee. This flexibility allows you to tap into various marketplaces and reach a wider audience without the hassle of managing separate inventory and shipping processes.</p>\n"
    ]
  },
  "fbaScale6": {
    "id": "fbaScale6",
    "type": "simple",
    "title": "Expanding your brand through product line diversification",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Expand Product Line",
    "videoVaultTags": [
      "amazonscale2",
      "amazonscale",
      "amazon1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "fba",
    "playbookStage": "scale",
    "playbookStageStep": 6,
    "playbookPct": 0,
    "paragraphs": [
      "<p>Congratulations on reaching a pivotal milestone - establishing a successful product on Amazon! Now that you've gained valuable insights into your market and understand your dream customer intimately, it's time to think about growth through product line expansion. This step involves identifying additional needs and pain points of your customer base and introducing new products that align with your established brand. By expanding your product line, you not only increase your market presence but also reinforce customer loyalty and brand strength.</p>\n"
    ]
  },
  "contentIdeaInterests": {
    "id": "contentIdeaInterests",
    "type": "textarea",
    "nextId": "contentIdeaSkills",
    "bigText": "What topics or activities are you passionate about?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Your Passions",
    "helpText": "Example Answer: I'm passionate about environmental conservation, sustainable living, and eco-tourism, always seeking out ways to reduce my carbon footprint through sustainable practices and technology. I also love digital art and animation. I spend hours creating and discussing digital artworks and exploring the latest animation techniques",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "videoVaultTags": [
      "ideablog",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true,
    "playbook": "content",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 1,
    "paragraphs": [
      "<p>Tell us about the subjects that captivate your interest. Are there specific areas like travel, technology, or lifestyle that you find yourself frequently talking about?</p>\n",
      "<p>Delving into the lesser-known aspects of these passions can help us identify a niche that's both exciting and uniquely suited to your interests.</p>\n"
    ]
  },
  "contentIdeaSkills": {
    "id": "contentIdeaSkills",
    "type": "textarea",
    "nextId": "contentIdeaWish",
    "bigText": "What unique experiences or skills do you have that others might find intriguing?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Unique Skills",
    "helpText": "Example Answer: I have a niche skillset in urban gardening. I have transformed 3 small city spaces into lush, productive gardens using tech-driven solutions like automated watering systems and growth tracking apps. Another skillset is my culinary arts. I am always creating innovative recipes from the produce I grow. ",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "videoVaultTags": [
      "ideablog",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true,
    "playbook": "content",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 2,
    "paragraphs": [
      "<p>Reflect on the skills or knowledge you possess that set you apart. It might be an unusual hobby, a rare professional insight, or an uncommon approach to a common problem.</p>\n",
      "<p>We're looking for something that not only interests you but also represents a unique angle or perspective, giving your blog a distinct voice in a crowded space.</p>\n"
    ]
  },
  "contentIdeaWish": {
    "id": "contentIdeaWish",
    "type": "textarea",
    "title": "Last question before that golden idea!",
    "nextId": "contentIdeaResults",
    "bigText": "Have you ever thought 'I wish this blog existed'?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Gaps in the Market",
    "helpText": "Example Answer: I've never seen a blog purely focused on urban gardening. Furthermore, I've never seen a blog that merges urban gardening and culinary art.",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "videoVaultTags": [
      "ideablog",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true,
    "playbook": "content",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 3,
    "paragraphs": [
      "<p>Is there a topic you've found lacking sufficient coverage online? Your blog could fill this void.</p>\n",
      "<p>Reflect on times you've searched for information but didn't find what you were looking for.</p>\n"
    ]
  },
  "contentIdeaResults": {
    "id": "contentIdeaResults",
    "type": "simple-chat-gpt-integration",
    "title": "Tailored Ideas Just for You",
    "nextId": "contentTellGrant",
    "nextButtonLabel": "I have an idea",
    "moduleLabel": "Your Ideas",
    "gptModelId": "ft:gpt-3.5-turbo-1106:topia::8k1p5CCA",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "videoVaultTags": [
      "ideablog",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 4,
    "paragraphs": [
      "<p>Select the idea that resonates most with you.</p>\n",
      "<p>There’s no need to sweat the small stuff right now. In the next phase, our coaches will be by your side to help you refine your idea into a winning side business.</p>\n"
    ],
    "includedModuleIds": [
      "contentIdeaSkills",
      "contentIdeaInterests"
    ],
    "systemPrompt": "Your name is Sarah, and you're an idea generation specialist at the FIpreneur community. Your task today is to help an aspiring blogger brainstorm ideas for starting a blog. This person has specific skills and interests, and the goal is to leverage these in identifying the right niche blogging opportunities. The focus should be on finding untapped niches with potential for growth and a devoted readership. The blog ideas should be distinctive and not widely covered in existing blogospheres. Your name is Sarah, and as an idea generation specialist at the FIpreneur community, you have the task of assisting an aspiring influencer in carving out their niche. This individual has unique skills and interests, which should be the foundation of their influencer persona and content strategy. The aim is to identify creative and untapped niches suitable for the influencer's platform of choice (e.g., Instagram, YouTube, TikTok), focusing on areas with growth potential and an opportunity to build a devoted following. We're looking for niches that are distinct and not heavily saturated in the influencer landscape. For each idea, please also include a short sentence on potential monetization strategies, like affiliate marketing, sponsored content, or digital products. Additionally, provide an enthusiastic estimate of how much money they could potentially earn each month from these ventures. YOUR OUTPUT SHOULD BE FORMATTED AS VALID HTML FOLLOWING THIS TEMPLATE: <ol><li><h4>{IDEA TITLE}</h4><p>{IDEA DESCRIPTION}</p></li></ol>",
    "userInputPrefix": "Can you please generate 5 to 10 blog ideas based on my information?"
  },
  "contentTellGrant": {
    "id": "contentTellGrant",
    "type": "textarea",
    "title": "Tell Grant about your idea ",
    "nextId": "contentValidation0",
    "moduleLabel": "Tell Grant",
    "videoVaultTags": [
      "ideablog",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 5,
    "paragraphs": [
      "<p>What's the basic concept, and why does it excite you?</p>\n"
    ]
  },
  "contentValidation0": {
    "id": "contentValidation0",
    "type": "simple",
    "title": "Let's make sure your idea is a winner",
    "nextId": "contentWritten2",
    "bigText": "Over 80% of side businesses fail because the idea isn't viable. Answer these next questions and Grant will personally review your idea.",
    "moduleLabel": "Starting Your Validation",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "ideablog",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 5,
    "paragraphs": [
      "<img src='/grant.jpg' style='width: 200px; height: 200px; border-radius: 100%; margin: 10px auto;' />",
      "<p style='text-align: center; font-weight: 900; font-size: 24px; margin-bottom: -10px;'>Grant Sabatier</p>",
      "<p style='text-align: center; margin-bottom: -10px;'>Launched 8+ businesses</p>",
      "<p style='text-align: center'>Generates $1M+ in annual revenue</p><p style='text-align: center'>Sold the 'Millennial Money' blog for an 8-figure sum</p>"
    ]
  },
  "contentWritten2": {
    "id": "contentWritten2",
    "type": "multi-text",
    "title": "Let Grant know about your target audience",
    "nextId": "contentWritten3",
    "footerText": "Almost there! Answer a few questions, then Grant will review them to help bring your idea to life.",
    "moduleLabel": "Your Target Audience",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "validate1",
      "",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "validate",
    "playbookStageStep": 2,
    "playbookPct": 3,
    "paragraphs": [
      "<p>Understanding your target audience is paramount. It's the foundation that enables your blog to provide tailored solutions, insights, and value to your readers' specific needs and challenges.</p>\n"
    ],
    "inputs": [
      "What age group does your blog cater to?",
      "What are their main interests or hobbies?",
      "What else sets your readers apart? Do they have certain jobs, ways of living, or problems they face?"
    ]
  },
  "contentWritten3": {
    "id": "contentWritten3",
    "type": "table",
    "title": "Let's check out the competition",
    "nextId": "contentWritten4",
    "footerText": "Almost there! Answer a few questions, then Grant will review them to help bring your idea to life.",
    "moduleLabel": "Competitor Analysis",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "validate1",
      "",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "validate",
    "playbookStageStep": 2,
    "playbookPct": 3,
    "paragraphs": [
      "<p>Knowing your competition is the first step to carving out your unique spot in the market.</p>\n",
      "<p>Who are your main three competitors? Tell us what they're good at and what they're not. We'll dive into how your blog can outshine them or bring something distinct to the table</p>\n"
    ],
    "inputs": [
      "Competitor blog link",
      "Summarize your competitors' strengths & weaknesses"
    ]
  },
  "contentWritten4": {
    "id": "contentWritten4",
    "type": "table",
    "nextId": "contentValidateOptions",
    "bigText": "How many people are searching for your blog topic each month?",
    "footerText": "Almost there! Answer a few questions, then Grant will review them to help bring your idea to life.",
    "moduleLabel": "Demand ",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "validate1",
      "",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "validate",
    "playbookStageStep": 2,
    "playbookPct": 3,
    "paragraphs": [
      "<p>Let's make sure there's enough interest in your blog's subject. Start by thinking about the search terms people might use on Google to find topics like yours. Use Google Trends <a href=\"https://trends.google.com/trends/\">here</a> to see how many people are searching for these terms each month. Then, enter the number of monthly searches below so Grant can gauge the level of demand for this topic.</p>\n"
    ],
    "inputs": [
      "Search Term",
      "No. Monthly Searches"
    ]
  },
  "contentValidateOptions": {
    "id": "contentValidateOptions",
    "type": "single-choice",
    "title": "Grant will now personally review your idea!",
    "nextId": "contentWaitingForGrantFeedbackIdea",
    "bigText": "You're just a click away from getting closer to that winning idea. How would you like to submit your answers to Grant? ",
    "moduleLabel": "Get Grant's Feedback",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "validate1",
      "",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 2,
    "choices": [
      {
        "label": "Submit your written answers",
        "subtext": "And get a WRITTEN response from Grant",
        "nextId": "contentWaitingForGrantFeedbackIdea"
      },
      {
        "label": "Record a video to send to Grant",
        "subtext": "And get a more detailed VIDEO response from Grant. 80% of FIPreneurs opt for video validation",
        "nextId": "contentWaitingForGrantFeedbackIdeaVideo"
      }
    ]
  },
  "contentWaitingForGrantFeedbackIdea": {
    "id": "contentWaitingForGrantFeedbackIdea",
    "type": "simple",
    "title": "Grant is reviewing your idea!",
    "nextId": "contentBuild1",
    "moduleLabel": "Your Coach is Reviewing",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "ideablog",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "validate",
    "playbookStageStep": 3,
    "playbookPct": 5,
    "steps": [
      {
        "text": "<p>Unlock Grant as your personal coach</p>\n",
        "subtext": "<p>Add Grant's weekly group coaching calls to your calendar <a href=\"https://www.addevent.com/calendar/XK624050\">here</a>. Don't miss out on the opportunity to be personally coached by Grant as he helps you unlock that life you dream of.</p>\n"
      },
      {
        "text": "<p>Meet your fellow FIpreneurs</p>\n",
        "subtext": "<p>Join Slack <a href=\"https://join.slack.com/t/fipreneurs/shared_invite/zt-2dtc5ygiu-FxH_Gt0MGlZh4XiOhNQkMg\">here</a>, our messaging platform to meet the rest of the community, see what everyone is working on, and share your own progress.</p>\n"
      },
      {
        "text": "<p>Learn with Grant</p>\n",
        "subtext": "<p>Start watching Grant's 8-module Financial Freedom video course <a href=\"https://members.fipreneurs.com/grants-financial-freedom-course\">here</a> to begin forging your path to independence.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Grant will be in touch in the next few days with his thoughts and feedback on your side business idea.</p>\n",
      "<p>While you're waiting, you can start exploring the FIpreneur community!</p>\n"
    ]
  },
  "contentWaitingForGrantFeedbackIdeaVideo": {
    "id": "contentWaitingForGrantFeedbackIdeaVideo",
    "type": "simple",
    "title": "Upload a video for Grant to review!",
    "nextId": "contentWaitingForGrantFeedbackIdea",
    "bigText": "We'll get back to you within the next few days. But don't wait up – go ahead and explore the portal in the meantime. Check out the blueprints, dive into the resources, and see what other FIPreneurs are up to. It's all here waiting for you to get started on unlocking more freedom in your life!",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "ideablog",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "validate",
    "playbookStageStep": 3,
    "playbookPct": 5,
    "paragraphs": [
      "<div class=\"w-full flex items-center justify-center py-8\">\n        <a\n          href=\"https://senja.io/p/fipreneurs/r/nY2O7c\"\n          target=\"_blank\"\n          class=\"p-8 bg-future-blue text-black rounded-full cursor-pointer text-center text-2xl mx-auto font-black shadow-xl\"\n        >\n          <span>Record a video to send to Grant</span>\n        </a>\n      </div>"
    ]
  },
  "contentBuild1": {
    "id": "contentBuild1",
    "type": "multi-text",
    "title": "Brainstorming a captivating name for your blog",
    "nextId": "contentBuild3",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Name Your Blog",
    "outsourcePrice": "$29",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "We conduct thorough trademark and domain searches, ensuring your chosen name is legally safe and available. You'll receive a detailed report with the outcomes of our searches, providing clarity and confidence as you move forward with your venture.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrjVDAbzSOakWB0v2htVyQ\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "blogname1",
      "name",
      "nameyourblog",
      "blogging1",
      "blogging3",
      "blogging4",
      ""
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "build",
    "playbookStageStep": 1,
    "playbookPct": 10,
    "steps": [
      {
        "text": "<p>Identify Your Brand Essence:</p>\n",
        "subtext": "<p>Reflect on the core values, mission, and unique selling propositions of your blog. What do you want the name to convey?</p>\n"
      },
      {
        "text": "<p>Understand Your Target Audience:</p>\n",
        "subtext": "<p>Consider the interests, preferences, and language of your target demographic. What type of names might appeal to them?</p>\n"
      },
      {
        "text": "<p>Brainstorm Name Ideas:</p>\n",
        "subtext": "<p>Create a list of potential names that are catchy, memorable, and easy to pronounce. Think about how they sound and feel when said aloud. Struggling to find a name? Check out the Namelix name generator <a href=\"https://namelix.com/\">here</a>.</p>\n"
      },
      {
        "text": "<p>Assess Social Media Availability:</p>\n",
        "subtext": "<p>Look up your blog name on key social media platforms to verify username availability. Having the same name on your website and social media helps solidify your brand identity.</p>\n"
      },
      {
        "text": "<p>Check Domain Availability:</p>\n",
        "subtext": "<p>Prioritize a '.com' domain for better brand recognition and SEO benefits. Utilize GoDaddy's domain search <a href=\"https://www.godaddy.com/en-uk/offers/domain?isc=sem3year&amp;countryview=1&amp;currencyType=EUR&amp;swp_countrycode=FR&amp;cdtl=c_17643108404.g_138780513776.k_kwd-21292142838.a_676833332556.d_c.ctv_g&amp;bnb=b&amp;gad_source=1&amp;gclid=Cj0KCQiA2eKtBhDcARIsAEGTG43Z74iRJbIQNUWHh_OCSEq9xqIPFK3MwG33z875kpk0fv_4Pdr13JkaAnf5EALw_wcB\">tool</a> to check if your blog's .com domain is available.</p>\n"
      },
      {
        "text": "<p>Conduct a Trademark Search:</p>\n",
        "subtext": "<p>Verify that your chosen name isn’t already trademarked to avoid potential legal issues. <a href=\"https://www.uspto.gov/trademarks/search\">Here's</a> where you can search if it's trademarked (just do a basic Word Mark Search).</p>\n"
      },
      {
        "text": "<p>Finalize Your Decision:</p>\n",
        "subtext": "<p>With these search results, pick a name that’s available and legally clear for your blog’s identity</p>\n"
      },
      {
        "text": "<p>Register Your Domain:</p>\n",
        "subtext": "<p>Once you’ve confirmed availability, in the next step you can buy your domain as you setup your wordpress site. Alternatively, you can use (Godaddy)[https://members.cj.com/member/6522636/account/publisher/users.cj] to purchase your domain name.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Congratulations, you've got a viable blog idea. Let's get building!</p>\n",
      "<p>Choosing the right name for your blog is essential. It's the first impression for your readers and sets the tone for your brand. A memorable, unique name can make your blog stand out and convey its theme or focus effectively. This step guides you through the brainstorming process to find a name that resonates with both you and your target audience.</p>\n"
    ],
    "inputs": [
      "Let us know the name you've chosen! "
    ]
  },
  "contentBuild3": {
    "id": "contentBuild3",
    "type": "simple",
    "title": "Building your WordPress site\n",
    "nextId": "contentBuild4",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Setting up WordPress",
    "videoVaultTags": [
      "ideablog",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "build",
    "playbookStageStep": 3,
    "playbookPct": 15,
    "steps": [
      {
        "text": "<p>Set Up Website Hosting with Bluehost:</p>\n",
        "subtext": "<p>Once you have your domain name, the next step is to set up hosting. Bluehost is recommended for its simplicity and integration with WordPress. You can save 50% using <a href=\"https://bluehost.sjv.io/VmDra3\">this</a> exclusive link.</p>\n"
      },
      {
        "text": "<p>Visit Bluehost and click on the “Get Started Now” button.</p>\n",
        "subtext": "<p>Select a hosting plan that suits your needs. For beginners, the cheapest plan is often sufficient, but consider an upgraded package for more resources if you're serious about blogging.</p>\n"
      },
      {
        "text": "<p>Register Your Domain:</p>\n",
        "subtext": "<p>During the Bluehost setup, search for your chosen domain name. Aim for a .com domain for better recognition. Fill in your account and billing information. Review the package information and select any desired add-ons. Decide on your hosting term – 12, 24, 36, or 60 months. A longer term often provides better value.</p>\n"
      },
      {
        "text": "<p>Install WordPress:</p>\n",
        "subtext": "<p>With Bluehost, WordPress installation is typically automatic. Log into your Bluehost account and you should find WordPress already installed. If not, Bluehost provides easy instructions for manual installation.</p>\n"
      },
      {
        "text": "<p>Choose a WordPress Theme:</p>\n",
        "subtext": "<p>Browse through the available WordPress themes. These are the design templates for your blog. Select a theme that aligns with your blog’s niche and your personal style. Remember, you can change your theme later.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>WordPress is renowned for its ease of use and flexibility, making it an excellent choice for bloggers. Whether you're a beginner or an experienced web developer, WordPress offers a range of features and an intuitive interface that simplifies the process of building and managing a blog. Combined with Bluehost’s reliable hosting, you’re set to create a professional and efficient blogging platform.</p>\n"
    ]
  },
  "contentBuild4": {
    "id": "contentBuild4",
    "type": "simple",
    "title": "Crafting your site's story",
    "nextId": "contentBuild5",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Populating Your Site",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "build",
    "playbookStageStep": 5,
    "playbookPct": 19,
    "steps": [
      {
        "text": "<p>Your Home Page:</p>\n",
        "subtext": "<p>Your home page should captivate visitors immediately, clearly communicating what you offer and directing them further into your site. Ensure it includes your brand logo, a compelling headline, and brief introductions to your main services or products. Use high-quality, relevant imagery that aligns with your brand identity. Navigation should be intuitive, guiding visitors to key sections of your site with ease.</p>\n"
      },
      {
        "text": "<p>About Page:</p>\n",
        "subtext": "<p>This is where your brand's story shines. Share the inception story of your business, your journey, and the vision that drives you. Humanize your brand by including personal stories, experiences, and photos that relate to your mission. Detail your expertise, achievements, and what distinguishes you in the market.</p>\n"
      },
      {
        "text": "<p>Contact Page:</p>\n",
        "subtext": "<p>An accessible contact page fosters trust and invites engagement. Provide multiple contact options, such as an email form, phone number, and possibly a physical address. Ensure any social media links are up to date and consider adding a FAQ section to answer common queries.</p>\n"
      },
      {
        "text": "<p>Footer:</p>\n",
        "subtext": "<p>The footer is a constant across your website, offering an opportunity to present essential links and information. Include quick links to your privacy policy, terms of use, social media profiles, and contact information. Adding a newsletter sign-up form here can also be effective for growing your email list.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Your WordPress site is ready; now it's time to bring it to life with content.</p>\n",
      "<p>Your site is the canvas for your business's story. From the foundational 'About Us' to dynamic content-marketing pages, every piece of content plays a role in attracting and converting your audience. This module guides you through creating evergreen content, engaging content-marketing pages, and conversion-centric pages, all while embracing the principles of E-E-A-T (Experience, Expertise, Authoritativeness, and Trustworthiness) to rank and resonate.</p>\n",
      "<p>We want to focus on 3 types of content:</p>\n<ol>\n<li>Evergreen Content:</li>\n</ol>\n<p>The Core of Your Brand: These pages tell your story and establish your brand identity. Focus on creating compelling and informative evergreen pages that detail who you are, what you offer, and why you're different.</p>\n<ol start=\"2\">\n<li>Content Marketing Pages:</li>\n</ol>\n<p>Showcase Your Expertise: Regularly updated pages that highlight your knowledge and answer your audience's queries. This content will most likely show up in search results, driving targeted traffic to your site.</p>\n<ol start=\"3\">\n<li>Conversion Pages:</li>\n</ol>\n<p>Turn Visitors into Followers: Design pages that encourage your visitors to take action, whether that's subscribing to a newsletter, making a purchase, or following your brand on social media.</p>\n"
    ]
  },
  "contentBuild5": {
    "id": "contentBuild5",
    "type": "simple",
    "title": "Setting up Google Analytics for your content platform",
    "nextId": "contentBuild6.5",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Set Up Analytics",
    "outsourcePrice": "$19",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Avoid the headache of technical setups with our Google Analytics integration service. Within 7 days, we'll have Google Analytics running smoothly with your WordPress site, giving you access to invaluable insights that will guide your content strategy.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrohDAbzSOakWB9VLXBc3r\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "ideablog",
      "blogging1",
      "scaleblog",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "build",
    "playbookStageStep": 4,
    "playbookPct": 17,
    "steps": [
      {
        "text": "<p>Create a Google Analytics Account:</p>\n",
        "subtext": "<p>Visit the Google Analytics website and sign up for a free account. Use your Google account for easier integration.</p>\n"
      },
      {
        "text": "<p>Set Up a Property:</p>\n",
        "subtext": "<p>In Google Analytics, create a new 'property' for your website. This is where your site’s data will be collected.</p>\n"
      },
      {
        "text": "<p>Install the Tracking Code:</p>\n",
        "subtext": "<p>Once your property is created, you’ll receive a unique tracking code. Embed this code into the header of your website. For specific platforms like WordPress, use available plugins to simplify this process.</p>\n"
      },
      {
        "text": "<p>Verify Installation:</p>\n",
        "subtext": "<p>After installing the code, verify it through your Google Analytics account to ensure it’s working correctly.</p>\n"
      },
      {
        "text": "<p>Set Goals and Track Events:</p>\n",
        "subtext": "<p>Define specific goals in Google Analytics to track conversions or other significant actions on your site. Customize your dashboard to highlight the most relevant data.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Setting up Google Analytics for your content platform is a critical step in measuring your site's performance, from visitor behavior to content engagement. This powerful tool offers insights into how users find and interact with your site, enabling you to make data-driven decisions to enhance your content strategy and improve user experience.</p>\n"
    ]
  },
  "contentBuild6.5": {
    "id": "contentBuild6.5",
    "type": "simple",
    "title": "SEO mastery: Boost your site's visibility",
    "nextId": "contentBuild6",
    "nextButtonLabel": "Mark as Completed",
    "footerText": "By following these steps, your content strategy will not only attract your target audience but also provide them with real value, positioning your brand as a trusted and helpful authority in your industry.",
    "moduleLabel": "SEO",
    "videoVaultTags": [
      "scaleblog",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "build",
    "playbookStageStep": 6,
    "playbookPct": 21,
    "steps": [
      {
        "text": "<p>Understand Your Audience's Search Intent:</p>\n",
        "subtext": "<p>Start by identifying the topics and questions your target audience is searching for. This understanding will guide your content creation, ensuring it meets their needs and ranks well in search results.</p>\n"
      },
      {
        "text": "<p>Perform Keyword Research:</p>\n",
        "subtext": "<p>Use tools like Google Keyword Planner or ahrefs to discover the keywords and phrases your audience uses. Focus on those relevant to your content and within a medium search volume range for better ranking potential.</p>\n"
      },
      {
        "text": "<p>Analyze Competitors:</p>\n",
        "subtext": "<p>Look at what content ranks well for your chosen keywords. Understand what your competitors do well and where there are gaps you can fill. This insight helps you create content that stands out and captures traffic.</p>\n"
      },
      {
        "text": "<p>Optimize Your Content:</p>\n",
        "subtext": "<p>Ensure your posts are structured for readability and include your target keywords naturally. Use headers, meta titles, and descriptions to improve SEO and user experience.</p>\n"
      },
      {
        "text": "<p>Build Quality Backlinks:</p>\n",
        "subtext": "<p>Links from reputable sites to your content signal to search engines that your site is a credible source of information. Guest blogging, collaborations, and creating shareable content can help build backlinks.</p>\n"
      },
      {
        "text": "<p>Monitor Your SEO Progress:</p>\n",
        "subtext": "<p>Use tools like Google Analytics to track your site's performance. Keep an eye on your rankings, traffic, and engagement metrics to understand what works and adjust your strategy accordingly.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Now that your WordPress site is set up, it's time to optimize it for search engines.\nSearch Engine Optimization (SEO) is crucial for increasing your site's visibility in search engine results, drawing more visitors who are actively searching for what you offer. Good SEO practices ensure your site is found by those looking for your content, products, or services, making it a vital component of your digital presence.</p>\n"
    ]
  },
  "contentBuild6": {
    "id": "contentBuild6",
    "type": "table",
    "title": "Crafting a compelling content strategy: Resonating with your audience",
    "nextId": "contentBuild7",
    "nextButtonLabel": "Mark as Completed",
    "footerText": "By following these steps, your content strategy will not only attract your target audience but also provide them with real value, positioning your brand as a trusted and helpful authority in your industry.",
    "moduleLabel": "Content Strategy",
    "videoVaultTags": [
      "contentplan",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "build",
    "playbookStageStep": 6,
    "playbookPct": 21,
    "steps": [
      {
        "text": "<p>Identify Key Pain Points:</p>\n",
        "subtext": "<p>Reflect on what challenges and problems your target audience faces. Use customer feedback, surveys, and market research to gain insights into their most pressing issues.</p>\n"
      },
      {
        "text": "<p>Align Content with Customer Needs:</p>\n",
        "subtext": "<p>Tailor your content topics to address these pain points directly. Each piece of content should aim to offer a solution, provide useful information, or ease a specific challenge.</p>\n"
      },
      {
        "text": "<p>Ensure Uniqueness and Authenticity:</p>\n",
        "subtext": "<p>Develop a unique angle or perspective for your content. Avoid generic topics; instead, infuse your personal expertise or unique insights to make your content stand out.</p>\n"
      },
      {
        "text": "<p>Create a Content Calendar:</p>\n",
        "subtext": "<p>Plan your content in advance with a calendar. This should include a mix of formats (like blog posts, videos, infographics) and topics, all aligned with your customer's interests and needs.</p>\n"
      },
      {
        "text": "<p>Incorporate Storytelling:</p>\n",
        "subtext": "<p>Use storytelling to make your content more engaging. Share real stories, case studies, or examples that your audience can relate to and learn from.</p>\n"
      },
      {
        "text": "<p>Optimize for Search Engines:</p>\n",
        "subtext": "<p>Make sure your content is SEO-friendly so it’s easily discoverable by those looking for solutions in your niche. Use relevant keywords but prioritize natural, reader-friendly language.</p>\n"
      },
      {
        "text": "<p>Regularly Update and Refresh Content:</p>\n",
        "subtext": "<p>Keep your content up-to-date with the latest information, trends, and developments in your field. Regular updates can also help improve your SEO ranking.</p>\n"
      },
      {
        "text": "<p>Measure and Adapt:</p>\n",
        "subtext": "<p>Use analytics tools to track how your content performs. Look at metrics like engagement rates, shares, and feedback to understand what resonates best with your audience and adjust your strategy accordingly.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Developing an effective content strategy is essential for ensuring your communications not only capture attention but also genuinely resonate with your audience. The key is to produce content that is not just unique, but also insightful and relevant to your audience's needs and challenges. By leveraging your deep understanding of your customer's pain points, you can create content that not only engages but also provides real value, helping to build trust and establish your brand as a helpful resource in your field.</p>\n"
    ],
    "inputs": [
      "Content Topic:",
      "Audience Resonance: Explain why this topic will resonate with your audience.",
      "Target Keywords: Identify keywords to target for SEO."
    ]
  },
  "contentBuild7": {
    "id": "contentBuild7",
    "type": "table",
    "title": "Developing a consistent content schedule for your blog",
    "nextId": "contentBuild8",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Content Schedule",
    "outsourcePrice": "$19",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Struggling to establish a consistent content schedule? Let us do the heavy lifting. We'll create a personalized content calendar, complete with resources and templates, all within 7 days. It's time to focus on what you do best, knowing your content strategy is in good hands.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrmfDAbzSOakWBHVz8fAUR\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "contentplan",
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "build",
    "playbookStageStep": 7,
    "playbookPct": 23,
    "steps": [
      {
        "text": "<p>Evaluate Your Availability and Commitment:</p>\n",
        "subtext": "<p>Determine the amount of time you can allocate to blogging each week, factoring in both writing and research.</p>\n"
      },
      {
        "text": "<p>Decide on Your Posting Frequency:</p>\n",
        "subtext": "<p>Choose how often you will publish new content based on your availability. Consistency is key.</p>\n"
      },
      {
        "text": "<p>Generate Content Ideas with ChatGPT:</p>\n",
        "subtext": "<p>Use ChatGPT to brainstorm topic ideas. Input your blog's theme and target audience characteristics to get suggestions that will likely resonate with your readers.</p>\n"
      },
      {
        "text": "<p>Organize Ideas into a Content Calendar:</p>\n",
        "subtext": "<p>Plot the generated ideas on a calendar, assigning specific topics to particular dates. Consider seasonality and current trends in your scheduling.</p>\n"
      },
      {
        "text": "<p>Allow for Flexibility in Your Schedule:</p>\n",
        "subtext": "<p>Keep some space in your calendar for impromptu topics or trending issues to keep your content dynamic and timely.</p>\n"
      },
      {
        "text": "<p>Set Clear Deadlines:</p>\n",
        "subtext": "<p>Assign deadlines for completing drafts and publishing posts to ensure adherence to your schedule.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>A well-planned content schedule is key to maintaining a successful blog. It helps you organize your topics, ensures regular posting, and keeps your audience engaged. By creating a content schedule, you're committing to a consistent blogging rhythm, which is crucial for building a loyal readership and improving SEO.</p>\n"
    ],
    "inputs": [
      "Post Title",
      "Drafted? Yes/No",
      "Post Date",
      "Published? Yes/No"
    ]
  },
  "contentBuild8": {
    "id": "contentBuild8",
    "type": "simple",
    "title": "Adding value: Authentic engagement strategies",
    "nextId": "contentBuild9",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Engagement Tactics",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "build",
    "playbookStageStep": 9,
    "playbookPct": 30,
    "steps": [
      {
        "text": "<p>Deep Dive into Audience Needs:</p>\n",
        "subtext": "<p>Revisit your understanding of your audience. What are their interests, challenges, and aspirations? This knowledge is crucial for tailoring your value-added initiatives.</p>\n"
      },
      {
        "text": "<p>Plan Value-Adding Initiatives:</p>\n",
        "subtext": "<p>Based on your audience insights, brainstorm activities or resources that would significantly benefit them. Ideas could include a free Q&amp;A session on topics they care about, creating and sharing helpful e-printables, or offering insightful webinars.</p>\n"
      },
      {
        "text": "<p>Develop Engaging and Useful Content:</p>\n",
        "subtext": "<p>Create content that is directly useful to your audience. This could be informative posts, how-to guides, tips, or even motivational content, depending on your audience's preferences.</p>\n"
      },
      {
        "text": "<p>Offer Free Resources:</p>\n",
        "subtext": "<p>Develop and offer free resources like e-printables, eBooks, or checklists that your audience can use. Ensure these resources are not only branded but also packed with valuable information.</p>\n"
      },
      {
        "text": "<p>Host Q&amp;A Sessions:</p>\n",
        "subtext": "<p>Regularly engage with your audience through live Q&amp;A sessions. Use these sessions to answer their questions, discuss their concerns, and share your expertise.</p>\n"
      },
      {
        "text": "<p>Showcase Authenticity and Transparency:</p>\n",
        "subtext": "<p>In all your interactions and content, maintain authenticity. Share your experiences and stories that resonate with your audience's situations.</p>\n"
      },
      {
        "text": "<p>Evaluate Engagement and Feedback:</p>\n",
        "subtext": "<p>Monitor how your audience responds to these value-adding activities. Use their feedback and engagement levels to refine your approach continuously.</p>\n"
      },
      {
        "text": "<p>Nurture Relationships:</p>\n",
        "subtext": "<p>Consistently engage with your audience in the comments. Building relationships is about two-way communication and showing that you genuinely care. By focusing on adding value in a way that's authentic and directly relevant to your audience, you create a foundation of trust and loyalty. This approach not only enhances your online presence but also strengthens the community around your brand.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>In the realm of content, adding genuine value to your audience's lives is fundamental to building lasting relationships and trust. Understanding your audience's needs and interests is key to determining how you can best serve them. This step is about going beyond mere content creation and actively contributing to your audience's well-being or knowledge. Whether it's through interactive Q&amp;A sessions, offering free e-printables, or providing other valuable resources, the focus should be on authentic engagement that enriches their experience.</p>\n"
    ]
  },
  "contentBuild9": {
    "id": "contentBuild9",
    "type": "simple",
    "title": "Capturing emails with a compelling offer using Convertkit",
    "nextId": "contentBuild10",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Capture Emails",
    "outsourcePrice": "$29",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Technical setups not your thing? No problem. We'll not only integrate Convertkit with your platform but also craft compelling initial email copy that resonates with your audience. Leave the technicalities to us, and focus on connecting with your community.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrlvDAbzSOakWBYwe9ZVMf\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "build",
    "playbookStageStep": 8,
    "playbookPct": 25,
    "steps": [
      {
        "text": "<p>Set Up Your Convertkit Account</p>\n",
        "subtext": "<p>Click <a href=\"https://convertkit.com/?lmref=wM-I_g\">here</a> to create your Convertkit account and start your email marketing journey.</p>\n"
      },
      {
        "text": "<p>Integrate Convertkit with WordPress</p>\n",
        "subtext": "<p>Embed Convertkit forms into your WordPress site to seamlessly gather subscribers.</p>\n"
      },
      {
        "text": "<p>Create a Lead Magnet</p>\n",
        "subtext": "<p>Develop a compelling lead magnet, such as a free eBook or exclusive content, to entice visitors to subscribe. Lead magnets are incentives offered in return for contact information, effectively turning visitors into subscribers.</p>\n"
      },
      {
        "text": "<p>Welcome New Subscribers</p>\n",
        "subtext": "<p>Utilize a Convertkit template to craft a warm welcome email for new sign-ups, making them feel valued and part of your community.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Building an email list is a powerful way to connect directly with your readers and nurture a loyal community. The key to growing your list is offering something valuable in exchange for their email address – this could be a free guide, a newsletter, or exclusive content. Using a service like Convertkit, you can effectively manage your subscribers and create attractive sign-up forms and offers.</p>\n"
    ],
    "inputs": [
      "Email Sign-Up: Input a link to your email sign-up page."
    ]
  },
  "contentBuild10": {
    "id": "contentBuild10",
    "type": "simple",
    "title": "Kickstarting your writing fourney and sticking to your content schedule",
    "nextId": "contentLaunch1",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Start Writing",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "build",
    "playbookStageStep": 9,
    "playbookPct": 27,
    "steps": [
      {
        "text": "<p>Writing Software:</p>\n",
        "subtext": "<p>Recommendations for writing and editing software to streamline the writing process.</p>\n"
      },
      {
        "text": "<p>Productivity Tools:</p>\n",
        "subtext": "<p>Apps and techniques for maintaining focus and managing time effectively.</p>\n"
      },
      {
        "text": "<p>Content Quality Checklists:</p>\n",
        "subtext": "<p>Guidelines to ensure each post meets a high standard of quality and relevance.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Embarking on your writing journey and consistently following your content schedule is pivotal for the success of your blog. This phase involves transforming your ideas into engaging blog posts and establishing a disciplined writing routine. Leveraging tools like ChatGPT for idea generation, Grammarly for editing, and a keyword analysis tool ensures the quality and SEO-friendliness of your content.</p>\n"
    ]
  },
  "contentLaunch1": {
    "id": "contentLaunch1",
    "type": "table",
    "title": "Launching your blog: Harnessing the power of friends and family\n\n",
    "nextId": "contentLaunch2",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Launch",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "launch",
    "playbookStageStep": 1,
    "playbookPct": 30,
    "steps": [
      {
        "text": "<p>Prepare Your Announcement:</p>\n",
        "subtext": "<p>Craft a personal and heartfelt message that introduces your blog. Explain why you started it, what it's about, and what you hope to achieve.</p>\n"
      },
      {
        "text": "<p>Highlight Your Unique Story:</p>\n",
        "subtext": "<p>Share your journey and the passion behind your blog. This personal touch can create a deeper connection and interest in your content.</p>\n"
      },
      {
        "text": "<p>Provide Direct Links:</p>\n",
        "subtext": "<p>Make it easy for them to access your blog by including direct links to your website and specific posts you're particularly proud of.</p>\n"
      },
      {
        "text": "<p>Ask for Feedback:</p>\n",
        "subtext": "<p>Encourage them to provide honest feedback. Constructive criticism from people who know you well can be incredibly valuable in improving your blog.</p>\n"
      },
      {
        "text": "<p>Request Their Support:</p>\n",
        "subtext": "<p>Ask them to support you by subscribing to your newsletter, following your blog’s social media pages, and sharing your content with their networks.</p>\n"
      },
      {
        "text": "<p>Express Gratitude:</p>\n",
        "subtext": "<p>Show appreciation for their support. A simple thank you can go a long way in fostering goodwill and encouraging them to engage with your blog.</p>\n"
      },
      {
        "text": "<p>Follow Up:</p>\n",
        "subtext": "<p>After the initial launch, keep your friends and family updated with your progress. Regular updates can keep them involved and interested in your blogging journey.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Now that you've crafted your blog and prepared engaging content, the exciting moment has arrived: it's time to introduce your work to the world. Launching your blog to friends and family marks a pivotal first step in your blogging journey. This warm, supportive audience offers invaluable feedback, helping you lay a solid foundation for your blog's growth. Sharing your blog with those closest to you initiates a wave of support and engagement, providing a unique chance to refine your content and approach based on their insights.</p>\n"
    ],
    "inputs": [
      "Name of Friend/Family Member",
      "Message Sent? Yes/No"
    ]
  },
  "contentLaunch2": {
    "id": "contentLaunch2",
    "type": "multi-text",
    "title": "Announcing Your Blog to the World",
    "nextId": "contentLaunch3",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Blog Announcement",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "launch",
    "playbookStageStep": 2,
    "playbookPct": 40,
    "steps": [
      {
        "text": "<p>Choose Your Platform Wisely:</p>\n",
        "subtext": "<p>Decide on which social media platforms you want to make your announcement. Consider where your potential audience spends their time. LinkedIn is great for professional and industry-related content, while platforms like Facebook, Instagram, and Twitter are ideal for a more personal approach.</p>\n"
      },
      {
        "text": "<p>Craft a Compelling Message:</p>\n",
        "subtext": "<p>Your announcement should be more than just “I started a blog.” Share the story behind it. Why did you start this blog? What inspires you about your chosen topic? What do you hope to achieve?</p>\n"
      },
      {
        "text": "<p>Be Authentic and Vulnerable:</p>\n",
        "subtext": "<p>Authenticity attracts. Share your journey, including the challenges and the milestones. Being vulnerable about your experiences can make your story relatable and inspiring.</p>\n"
      },
      {
        "text": "<p>Include Visuals:</p>\n",
        "subtext": "<p>A captivating image or a short video can make your post stand out. This could be a behind-the-scenes photo, a sneak peek of your blog content, or even a simple graphic with your blog’s name.</p>\n"
      },
      {
        "text": "<p>Provide a Link to Your Blog:</p>\n",
        "subtext": "<p>Ensure that you include a link to your blog. You want to make it as easy as possible for people to visit your site.</p>\n"
      },
      {
        "text": "<p>Encourage Engagement:</p>\n",
        "subtext": "<p>Ask your audience to check out your blog and leave feedback. Encourage them to share your post, which can help increase your reach.</p>\n"
      },
      {
        "text": "<p>Express Gratitude:</p>\n",
        "subtext": "<p>Show appreciation for any support, engagement, and encouragement you receive. A grateful tone can foster goodwill and ongoing support.</p>\n"
      },
      {
        "text": "<p>Monitor and Respond:</p>\n",
        "subtext": "<p>Keep an eye on your post and engage with any comments or messages you receive. Responding promptly and thoughtfully can help build a strong early community for your blog.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Telling the world about your new blog is a momentous step. It's your opportunity to introduce your passion project to a broader audience. Crafting an authentic and vulnerable post on social media and LinkedIn can resonate with a wider community, drawing in readers who connect with your story and your content.</p>\n"
    ],
    "inputs": [
      "Input link to your announcement post"
    ]
  },
  "contentGrow1": {
    "id": "contentGrow1",
    "type": "simple",
    "title": "The blogger's journey: Cultivating consistency in content creation\n\n",
    "nextId": "contentGrow2",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Content Consistency",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "scale",
    "playbookStageStep": 1,
    "playbookPct": 50,
    "steps": [
      {
        "text": "<p>Revisit and Update Your Content Schedule:</p>\n",
        "subtext": "<p>After the launch, reassess your content calendar. Update it based on any new insights about your audience's preferences and your own writing pace.</p>\n"
      },
      {
        "text": "<p>Set Realistic Writing Goals:</p>\n",
        "subtext": "<p>Define achievable writing goals. Whether it's a certain number of posts per week or dedicated writing hours, make sure these goals are manageable.</p>\n"
      },
      {
        "text": "<p>Create a Writing Ritual:</p>\n",
        "subtext": "<p>Develop a writing ritual that signals it's time to write. This could be a specific time of day, a particular playlist, or a designated writing space.</p>\n"
      },
      {
        "text": "<p>Use Writing Prompts and Tools:</p>\n",
        "subtext": "<p>Utilize tools like ChatGPT for content ideas and prompts, especially when facing writer's block.</p>\n"
      },
      {
        "text": "<p>Track Your Progress:</p>\n",
        "subtext": "<p>Regularly review your writing progress. Adjust your goals and schedule as needed to maintain a sustainable rhythm.</p>\n"
      },
      {
        "text": "<p>Engage with Your Audience:</p>\n",
        "subtext": "<p>Use reader feedback and engagement to guide your content creation. Responding to comments and requests can also provide ideas for new content.</p>\n"
      },
      {
        "text": "<p>Stay Informed and Inspired:</p>\n",
        "subtext": "<p>Keep abreast of trends in your niche. Read other blogs, books, and attend webinars or courses to stay inspired and informed.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>The launch of your blog is just the beginning. The key to growing your audience and strengthening your online presence is consistent writing. This step is about establishing and adhering to a regular writing routine, ensuring that your blog stays active, relevant, and engaging for your readers.</p>\n"
    ]
  },
  "contentGrow2": {
    "id": "contentGrow2",
    "type": "simple",
    "title": "SEO mastery: Fueling your blog's long-term growth and visibility\n\n",
    "nextId": "contentGrow3",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "SEO Mastery",
    "outsourcePrice": "$69",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Dreading the thought of diving into SEO? We've got you covered with our comprehensive SEO service. From content optimization to site structure improvements, we handle it all. In just 7 days, your site will be optimized and ready to climb the search rankings.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrlEDAbzSOakWBTWotPVm4\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "scale",
    "playbookStageStep": 2,
    "playbookPct": 55,
    "steps": [
      {
        "text": "<p>Understand the Basics of SEO:</p>\n",
        "subtext": "<p>Familiarize yourself with fundamental SEO concepts like keywords, meta descriptions, backlinks, and search engine algorithms.</p>\n"
      },
      {
        "text": "<p>Conduct Keyword Research:</p>\n",
        "subtext": "<p>Use tools like Google Keyword Planner or SEM Rush to find relevant keywords with high search volume and low competition. Incorporate these keywords naturally into your blog posts.</p>\n"
      },
      {
        "text": "<p>Optimize Blog Post Titles and Headers:</p>\n",
        "subtext": "<p>Craft compelling and keyword-rich titles and headers. This not only improves SEO but also entices readers to click on your content.</p>\n"
      },
      {
        "text": "<p>Create Quality Content:</p>\n",
        "subtext": "<p>Focus on writing informative, engaging, and valuable content. High-quality content is more likely to earn backlinks, which are critical for SEO.</p>\n"
      },
      {
        "text": "<p>Optimize Your Images:</p>\n",
        "subtext": "<p>Use descriptive file names and alt tags for all images. This improves their discoverability in image searches and enhances overall SEO.</p>\n"
      },
      {
        "text": "<p>Utilize Internal Linking:</p>\n",
        "subtext": "<p>Link to your own relevant content within your blog posts. This helps in spreading link equity and keeps readers engaged on your site longer.</p>\n"
      },
      {
        "text": "<p>Leverage Google Search Console:</p>\n",
        "subtext": "<p>Sign up for Google Search Console to track your site's performance in Google searches. Use the insights to optimize your content strategy.</p>\n"
      },
      {
        "text": "<p>Use SEO Tools:</p>\n",
        "subtext": "<p>Consider investing in advanced SEO tools like SEM Rush for deeper insights and strategies. These tools offer valuable data on keyword ranking, competitor analysis, and more.</p>\n"
      },
      {
        "text": "<p>Stay Updated on SEO Best Practices:</p>\n",
        "subtext": "<p>SEO is an ever-evolving field. Stay informed about the latest trends and algorithm updates by following SEO blogs, attending webinars, and participating in online forums.</p>\n"
      },
      {
        "text": "<p>Monitor and Adjust:</p>\n",
        "subtext": "<p>Regularly review your blog's SEO performance. Be prepared to adjust your strategies based on analytics and changing search engine algorithms.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Focusing on Search Engine Optimization (SEO) is crucial for the long-term growth and visibility of your blog. While social media can provide immediate, albeit sometimes fleeting, traffic boosts, SEO offers a more sustainable and organic growth path. By optimizing your content for search engines, particularly Google, you can achieve a steady increase in traffic, enhance your blog's discoverability, and build a solid foundation for monetization.</p>\n"
    ]
  },
  "contentGrow3": {
    "id": "contentGrow3",
    "type": "table",
    "title": "Building blogger networks: The art of connecting and collaborating",
    "nextId": "contentGrow4",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Blogger Networks",
    "outsourcePrice": "$69",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "If building a network of bloggers seems overwhelming, we're here to help. We'll compile a list of 100 bloggers in your niche, perfect for collaborations and networking, all within 7 days. Let's get your content the visibility it deserves.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrkNDAbzSOakWBxVwcaiRQ\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "scale",
    "playbookStageStep": 3,
    "playbookPct": 60,
    "steps": [
      {
        "text": "<p>Establish Your Blog First:</p>\n",
        "subtext": "<p>Before reaching out, ensure your blog has substantial content (at least 20 posts). This shows you’re serious and committed, making others more likely to engage with you.</p>\n"
      },
      {
        "text": "<p>Identify Influential Bloggers in Your Niche:</p>\n",
        "subtext": "<p>Research and make a list of bloggers who are influential in your niche. Look for those who share similar interests or whose work you admire.</p>\n"
      },
      {
        "text": "<p>Engage on Social Media:</p>\n",
        "subtext": "<p>Follow these bloggers on platforms like Twitter, Instagram, and LinkedIn. Engage with their content by commenting, sharing, and participating in discussions.</p>\n"
      },
      {
        "text": "<p>Connect Through Email and Blog Comments:</p>\n",
        "subtext": "<p>Send personalized emails expressing your appreciation for their work. Comment thoughtfully on their blog posts to initiate a dialogue.</p>\n"
      },
      {
        "text": "<p>Participate in Online Communities:</p>\n",
        "subtext": "<p>Join forums, Reddit threads, and Facebook groups relevant to your niche. Actively contribute to these communities.</p>\n"
      },
      {
        "text": "<p>Attend Conferences and Meet-ups:</p>\n",
        "subtext": "<p>Look for blogging conferences, webinars, and local meet-ups where you can connect with other bloggers in person.</p>\n"
      },
      {
        "text": "<p>Offer Value:</p>\n",
        "subtext": "<p>When reaching out, think about how you can offer value. This could be through sharing their content, offering to guest post, or collaborating on projects.</p>\n"
      },
      {
        "text": "<p>Seek Mentorship and Offer Support:</p>\n",
        "subtext": "<p>Politely ask for advice or feedback on your blog. Be open to offering support in return, such as helping with content ideas or promotion.</p>\n"
      },
      {
        "text": "<p>Build Genuine Relationships:</p>\n",
        "subtext": "<p>Focus on building genuine, long-term relationships rather than just seeking immediate benefits.</p>\n"
      },
      {
        "text": "<p>Leverage Each Other’s Audiences:</p>\n",
        "subtext": "<p>Once you have established relationships, explore ways to mutually benefit each other’s audiences, like guest blogging, podcast interviews, or co-hosting webinars.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Networking with other bloggers in your niche is a pivotal step towards growing your blog. Establishing connections with peers can lead to various opportunities, including collaboration, knowledge sharing, mutual promotion, and more. It’s about building a community around your blog, where relationships can translate into traffic growth and revenue. The key is to engage authentically and offer value to both your audience and fellow bloggers.</p>\n"
    ],
    "inputs": [
      "Influencer/Blogger",
      "Outreach Date",
      "Follow-Up Date"
    ]
  },
  "contentGrow4": {
    "id": "contentGrow4",
    "type": "simple",
    "title": "Strategic cocial media use: Balancing brand building and time investment",
    "nextId": "contentGrow5",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Social Media Strategy",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "scale",
    "playbookStageStep": 4,
    "playbookPct": 65,
    "steps": [
      {
        "text": "<p>Choose Your Platforms Wisely:</p>\n",
        "subtext": "<p>Analyze which social media platforms are most popular with your target audience. Consider factors like the nature of your content and where it is likely to resonate the most.</p>\n"
      },
      {
        "text": "<p>Pinterest Strategy:</p>\n",
        "subtext": "<p>If your blog is in niches like fashion, travel, or food, Pinterest can be an effective platform for driving traffic. Dedicate around 5 hours a week to pinning and engaging on Pinterest to maintain consistent traffic.</p>\n"
      },
      {
        "text": "<p>Facebook Approach:</p>\n",
        "subtext": "<p>Organic reach on Facebook can be challenging due to algorithm changes. Consider using Facebook more actively once you have substantial content on your blog. Facebook ads might be an option, but only after gaining some traction with your content.</p>\n"
      },
      {
        "text": "<p>Instagram Engagement:</p>\n",
        "subtext": "<p>Use Instagram to build a brand following rather than direct blog traffic. Engage with your audience through stories and posts but remember that converting followers to blog readers can be challenging.</p>\n"
      },
      {
        "text": "<p>Twitter Networking:</p>\n",
        "subtext": "<p>Leverage Twitter for networking and engaging in conversations within your niche. It's more about community building and less about driving traffic to your blog.</p>\n"
      },
      {
        "text": "<p>Consistency is Key:</p>\n",
        "subtext": "<p>Choose one or two platforms where you can post consistently. It's better to be highly engaged on one platform than sporadically active on multiple.</p>\n"
      },
      {
        "text": "<p>Content Tailoring:</p>\n",
        "subtext": "<p>Tailor your content for each platform. What works on Instagram may not work on Facebook or Pinterest.</p>\n"
      },
      {
        "text": "<p>Monitor and Adjust:</p>\n",
        "subtext": "<p>Regularly review your social media strategy's effectiveness. Use analytics tools to track engagement and adjust your strategy as needed.</p>\n"
      },
      {
        "text": "<p>Integrate with Your SEO Strategy:</p>\n",
        "subtext": "<p>Remember that a strong presence on Google through SEO is more sustainable for long-term traffic. Balance your social media efforts with your SEO strategy.</p>\n"
      },
      {
        "text": "<p>Explore our Influencer Blueprint</p>\n",
        "subtext": "<p>Discover key strategies for successful influencing with our Influencer Blueprint. It's your quick guide to growing your social media presence.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>For bloggers, social media is a double-edged sword. While it can be a powerful tool for building your brand and engaging with your audience, it can also be time-consuming and may not always directly contribute to significant traffic growth. The key is to select and focus on a few social media channels where you can consistently engage with your audience without spreading yourself too thin.</p>\n"
    ]
  },
  "contentGrow5": {
    "id": "contentGrow5",
    "type": "simple",
    "title": "Monetizing your blog: Partnering with products and creating E-products\n\n",
    "nextId": "contentGrow6",
    "nextButtonLabel": "Mark as Completed",
    "footerText": "By strategically partnering with relevant products and creating your own digital offerings, you can effectively monetize your blog while providing substantial value to your audience.",
    "moduleLabel": "Monetization",
    "videoVaultTags": [
      "blogging1",
      "blogging3",
      "blogging4"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "content",
    "playbookStage": "scale",
    "playbookStageStep": 5,
    "playbookPct": 90,
    "steps": [
      {
        "text": "<p>Identify Potential Product Partnerships:</p>\n",
        "subtext": "<p>Look for products or services that align with your blog's theme and audience interests. These could include tools, apps, books, or other resources relevant to your niche.</p>\n"
      },
      {
        "text": "<p>Reach Out for Affiliate Opportunities:</p>\n",
        "subtext": "<p>Contact companies or check if they have affiliate programs. Joining these programs allows you to earn a commission for every sale made through your referral.</p>\n"
      },
      {
        "text": "<p>Create and Promote E-Products:</p>\n",
        "subtext": "<p>Consider developing your own digital products, such as e-books, courses, webinars, or printable resources. These products should provide value related to your blog's content and appeal to your readers' interests.</p>\n"
      },
      {
        "text": "<p>Develop a Marketing Strategy for Your E-Products:</p>\n",
        "subtext": "<p>Utilize your blog and social media channels to market your e-products. Create compelling content that highlights the benefits of your products and how they address the needs or problems of your audience.</p>\n"
      },
      {
        "text": "<p>Leverage Email Marketing:</p>\n",
        "subtext": "<p>Use your email list to promote these products and partnerships. Craft engaging newsletters that introduce your offerings and explain their relevance to your subscribers.</p>\n"
      },
      {
        "text": "<p>Monitor and Analyze Performance:</p>\n",
        "subtext": "<p>Track the performance of your partnered products and your own e-products. Use analytics to understand sales patterns and reader preferences, allowing you to refine your approach and offerings.</p>\n"
      },
      {
        "text": "<p>Seek Feedback and Iterate:</p>\n",
        "subtext": "<p>Regularly seek feedback from your audience on both the partnered products and your own. Use this feedback to make improvements and ensure your offerings remain aligned with your audience's evolving needs.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Monetizing your blog effectively involves exploring various revenue streams that align with your content and audience. One of the most lucrative ways to generate income is through partnerships with products and the creation of your own digital products. This approach not only enhances your earning potential but also adds value for your readers, offering them products and services that resonate with their interests and needs.</p>\n"
    ]
  },
  "influencerIdeaInterests": {
    "id": "influencerIdeaInterests",
    "type": "textarea",
    "nextId": "influencerIdeaSkills",
    "bigText": "What topics or activities are you passionate about?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Your Passions",
    "helpText": "Example Answer: I love spending my weekends hiking and camping. Also really into making my own furniture, I teach myself how to do most of it. ",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "videoVaultTags": [
      "validate1",
      "influenceridea",
      "influencer2",
      "",
      "influencer3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true,
    "playbook": "influencer",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 1,
    "paragraphs": [
      "<p>Tell us about the subjects that captivate your interest. Are there specific areas like travel, technology, or lifestyle that you find yourself frequently talking about?</p>\n",
      "<p>Delving into the lesser-known aspects of these passions can help us identify a niche that's both exciting and uniquely suited to your interests.</p>\n"
    ]
  },
  "influencerIdeaSkills": {
    "id": "influencerIdeaSkills",
    "type": "textarea",
    "nextId": "influencerIdeaWish",
    "bigText": "What unique experiences or skills do you have that others might find intriguing?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Unique Skills",
    "helpText": "Example Answer: I'm an award-winning home brewer (I brew my own beer!)",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "videoVaultTags": [
      "validate1",
      "influenceridea",
      "influencer2",
      "",
      "influencer3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true,
    "playbook": "influencer",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 2,
    "paragraphs": [
      "<p>Reflect on the skills or knowledge you possess that set you apart. It might be an unusual hobby, a rare professional insight, or an uncommon approach to a common problem.</p>\n",
      "<p>We're looking for something that not only interests you but also represents a unique angle or perspective, giving your channel a distinct voice in a crowded space.</p>\n"
    ]
  },
  "influencerIdeaWish": {
    "id": "influencerIdeaWish",
    "type": "textarea",
    "title": "Last question before that golden idea!",
    "nextId": "influencerIdeaResults",
    "bigText": "Have you ever thought 'I wish this channel existed'?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Gaps in the Market",
    "helpText": "Example Answer: I'd love to see more content around sustainable travel. I'm a bit fed up with seeing beautiful beaches on travel accounts. What if that country/place has no recycling? Or has epic recycling? I'd find it interesting to see the behind-the-scenes (from an environmental standpoint) of the top travel destinations",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "videoVaultTags": [
      "validate1",
      "influenceridea",
      "influencer2",
      "",
      "influencer3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": true,
    "playbook": "influencer",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 3,
    "paragraphs": [
      "<p>Is there a topic you've found lacking sufficient coverage on Social Media? You could fill this void - reflect on times you've searched for information but didn't find what you were looking for.</p>\n"
    ]
  },
  "influencerIdeaResults": {
    "id": "influencerIdeaResults",
    "type": "simple-chat-gpt-integration",
    "title": "Tailored Ideas Just for You",
    "nextId": "influencerWritten1",
    "nextButtonLabel": "I have an idea",
    "moduleLabel": "Your Ideas",
    "gptModelId": "ft:gpt-3.5-turbo-1106:topia::8k1pBO5v",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 1,
    "videoVaultTags": [
      "validate1",
      "influenceridea",
      "influencer2",
      "",
      "influencer3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 4,
    "paragraphs": [
      "<p>Select the idea that resonates most with you.</p>\n",
      "<p>There’s no need to sweat the small stuff right now. In the next phase, our coaches will be by your side to help you refine your selection into a winning idea and ensure it's the perfect match to unlock more freedom in your life.</p>\n"
    ],
    "includedModuleIds": [
      "influencerIdeaSkills",
      "influencerIdeaInterests"
    ],
    "systemPrompt": "Your name is Sarah, and as an idea generation specialist at the FIpreneur community, you have the task of assisting an aspiring influencer in carving out their niche. This individual has unique skills and interests, which should be the foundation of their influencer persona and content strategy.The aim is to identify creative and untapped niches suitable for the influencer's platform of choice (e.g., Instagram, YouTube, TikTok), focusing on areas with growth potential and an opportunity to build a devoted following. We're looking for niches that are distinct and not heavily saturated in the influencer landscape. For each idea, please also include a short sentence on potential monetization strategies, like affiliate marketing, sponsored content, or digital products. Additionally, provide an enthusiastic estimate of how much money they could potentially earn each month from these ventures. YOUR OUTPUT SHOULD BE FORMATTED AS VALID HTML FOLLOWING THIS TEMPLATE: <ol><li><h4>{IDEA TITLE}</h4><p>{IDEA DESCRIPTION}</p></li></ol>",
    "userInputPrefix": "Can you please generate 10 influencer ideas based on my information?"
  },
  "influencerWritten1": {
    "id": "influencerWritten1",
    "type": "textarea",
    "title": "Tell Grant about your idea ",
    "nextId": "influencerValidation0",
    "moduleLabel": "Tell Grant",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "validate1",
      "influenceridea",
      "influencer2",
      "",
      "influencer3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 5,
    "paragraphs": [
      "<p>What's the basic concept, and why does it excite you?</p>\n"
    ]
  },
  "influencerValidation0": {
    "id": "influencerValidation0",
    "type": "simple",
    "title": "Let's make sure your idea is a winner",
    "nextId": "influencerWritten3",
    "bigText": "Over 80% of side businesses fail because the idea isn't viable. Answer these next questions and Grant will personally review your idea.",
    "moduleLabel": "Starting Your Validation",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "validate1",
      "influenceridea",
      "influencer2",
      "",
      "influencer3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "validate",
    "playbookStageStep": 2,
    "playbookPct": 3,
    "paragraphs": [
      "<img src='/grant.jpg' style='width: 200px; height: 200px; border-radius: 100%; margin: 10px auto;' />",
      "<p style='text-align: center; font-weight: 900; font-size: 24px; margin-bottom: -10px;'>Grant Sabatier</p>",
      "<p style='text-align: center; margin-bottom: -10px;'>Build a global personal brand</p>",
      "<p style='text-align: center'>Generates $1M+ in Annual Revenue</p><p style='text-align: center'>Sold the 'Millennial Money' blog for an 8-figure sum</p>"
    ]
  },
  "influencerWritten3": {
    "id": "influencerWritten3",
    "type": "multi-text",
    "title": "Let Grant know about your target audience",
    "nextId": "influencerWritten4",
    "footerText": "Almost there! Answer a few questions, then Grant will review them to help bring your idea to life.",
    "moduleLabel": "Your Target Audience",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "validate1",
      "influenceridea",
      "influencer2",
      "",
      "influencer3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "validate",
    "playbookStageStep": 2,
    "playbookPct": 3,
    "paragraphs": [
      "<p>Understanding your target audience is paramount. It's the foundation that enables your channel to provide tailored solutions, insights, and value to your readers' specific needs and challenges.</p>\n"
    ],
    "inputs": [
      "What age group does your channel cater to?",
      "What are their main interests or hobbies?",
      "What else sets your audience apart? Do they have certain jobs, ways of living, or problems they face?"
    ]
  },
  "influencerWritten4": {
    "id": "influencerWritten4",
    "type": "table",
    "title": "Let's check out the competition",
    "nextId": "influencerValidateOptions",
    "footerText": "Almost there! Answer a few questions, then Grant will review them to help bring your idea to life.",
    "moduleLabel": "Competitor Analysis",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "validate1",
      "influenceridea",
      "influencer2",
      "",
      "influencer3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "validate",
    "playbookStageStep": 2,
    "playbookPct": 3,
    "paragraphs": [
      "<p>Knowing your competition is the first step to carving out your unique spot in the market.</p>\n",
      "<p>Who are your main three competitors? Tell us what they're good at and what they're not. We'll dive into how your channel can outshine them or bring something distinct to the table</p>\n"
    ],
    "inputs": [
      "Competitor channel link",
      "Number of followers / subscribers",
      "Summarize your competitors' strengths and weaknesses"
    ]
  },
  "influencerValidateOptions": {
    "id": "influencerValidateOptions",
    "type": "single-choice",
    "title": "Grant will now personally review your idea!",
    "nextId": "influencerWaitingForGrantFeedbackIdea",
    "bigText": "You're just a click away from getting closer to that winning idea. How would you like to submit your answers to Grant? ",
    "moduleLabel": "Get Grant's Feedback",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "validate1",
      "influenceridea",
      "influencer2",
      "",
      "influencer3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 2,
    "choices": [
      {
        "label": "Submit your written answers",
        "subtext": "And get a WRITTEN response from Grant",
        "nextId": "influencerWaitingForGrantFeedbackIdea"
      },
      {
        "label": "Record a video to send to Grant",
        "subtext": "And get a more detailed VIDEO response from Grant. 80% of FIPreneurs opt for video validation",
        "nextId": "influencerWaitingForGrantFeedbackIdeaVideo"
      }
    ]
  },
  "influencerWaitingForGrantFeedbackIdeaVideo": {
    "id": "influencerWaitingForGrantFeedbackIdeaVideo",
    "type": "simple",
    "title": "Upload a video for Grant to review!",
    "nextId": "influencerWaitingForGrantFeedbackIdea",
    "bigText": "We'll get back to you within the next few days. But don't wait up – go ahead and explore the portal in the meantime. Check out the blueprints, dive into the resources, and see what other FIPreneurs are up to. It's all here waiting for you to get started on unlocking more freedom in your life!",
    "moduleLabel": "Grant's Video Review",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "influenceridea",
      "influencer2",
      "influencer3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 5,
    "paragraphs": [
      "<div class=\"w-full flex items-center justify-center py-8\">\n        <a\n          href=\"https://senja.io/p/fipreneurs/r/nY2O7c\"\n          target=\"_blank\"\n          class=\"p-8 bg-future-blue text-black rounded-full cursor-pointer text-center text-2xl mx-auto font-black shadow-xl\"\n        >\n          <span>Record a video to send to Grant</span>\n        </a>\n      </div>"
    ]
  },
  "influencerWaitingForGrantFeedbackIdea": {
    "id": "influencerWaitingForGrantFeedbackIdea",
    "type": "simple",
    "title": "Grant is reviewing your idea!",
    "nextId": "influencerBuild0",
    "moduleLabel": "Your Coach is Reviewing",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "influenceridea",
      "influencer2",
      "influencer3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "validate",
    "playbookStageStep": 3,
    "playbookPct": 5,
    "steps": [
      {
        "text": "<p>Unlock Grant as your personal coach</p>\n",
        "subtext": "<p>Add Grant's weekly group coaching calls to your calendar <a href=\"https://www.addevent.com/calendar/XK624050\">here</a>. Don't miss out on the opportunity to be personally coached by Grant as he helps you unlock that life you dream of.</p>\n"
      },
      {
        "text": "<p>Meet your fellow FIpreneurs</p>\n",
        "subtext": "<p>Join Slack <a href=\"https://join.slack.com/t/fipreneurs/shared_invite/zt-2dtc5ygiu-FxH_Gt0MGlZh4XiOhNQkMg\">here</a>, our messaging platform to meet the rest of the community, see what everyone is working on, and share your own progress.</p>\n"
      },
      {
        "text": "<p>Learn with Grant</p>\n",
        "subtext": "<p>Start watching Grant's 8-module Financial Freedom video course <a href=\"https://members.fipreneurs.com/grants-financial-freedom-course\">here</a> to begin forging your path to independence.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Grant will be in touch in the next few days with his thoughts and feedback on your side business idea.</p>\n",
      "<p>While you're waiting, you can start exploring the FIpreneur community!</p>\n"
    ]
  },
  "influencerBuild0": {
    "id": "influencerBuild0",
    "type": "simple",
    "title": "Choose your platform",
    "nextId": "influencerBuild1",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Choose Your Platform",
    "videoVaultTags": [
      "influenceridea",
      "influencer2",
      "influencer3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "build",
    "playbookStageStep": 2,
    "playbookPct": 5,
    "steps": [
      {
        "text": "<p>Assess Your Audience and Niche:</p>\n",
        "subtext": "<p>Consider where your target audience spends their time and which platforms align with the nature of your content. If your niche is visually oriented, platforms like Instagram and Pinterest are key; for professional services, LinkedIn might be your go-to.</p>\n"
      },
      {
        "text": "<p>Choose Platforms Wisely:</p>\n",
        "subtext": "<p>Based on your assessment, select one or two primary platforms to focus your efforts. Spreading yourself too thin across multiple platforms can dilute your message and impact.</p>\n"
      },
      {
        "text": "<p>Set Social Media Boundaries:</p>\n",
        "subtext": "<p>Decide in advance how much time you can dedicate to social media without compromising your well-being. Stick to this limit to maintain a healthy balance between online engagement and offline life.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Congratulations on uncovering an awesome niche to start your influencer journey! The next step is selecting the right social media platforms. It's about finding where your unique voice can resonate the most with your audience and where you can effectively showcase your niche</p>\n"
    ]
  },
  "influencerBuild1": {
    "id": "influencerBuild1",
    "type": "textarea",
    "title": "Choose your social media handle",
    "nextId": "influencerBuild3",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Social Media Handle",
    "outsourcePrice": "$29",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "We conduct thorough trademark and domain searches, ensuring your chosen name is legally safe and available. You'll receive a detailed report with the outcomes of our searches, providing clarity and confidence as you move forward with your venture.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrjVDAbzSOakWB0v2htVyQ\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "name",
      "name2",
      "influencer2",
      "buidgeneral1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "build",
    "playbookStageStep": 3,
    "playbookPct": 6,
    "steps": [
      {
        "text": "<p>Identify Your Brand Essence:</p>\n",
        "subtext": "<p>Reflect on the core values, mission, and unique selling propositions of your channel. What do you want the name to convey?</p>\n"
      },
      {
        "text": "<p>Understand Your Target Audience:</p>\n",
        "subtext": "<p>Consider the interests, preferences, and language of your target demographic. What type of names might appeal to them?</p>\n"
      },
      {
        "text": "<p>Brainstorm Name Ideas:</p>\n",
        "subtext": "<p>Create a list of potential names that are catchy, memorable, and easy to pronounce. Think about how they sound and feel when said aloud. Struggling to find a name? Check out the Namelix name generator <a href=\"https://namelix.com/\">here</a>.</p>\n"
      },
      {
        "text": "<p>Verify Social Media Platform Availability:</p>\n",
        "subtext": "<p>Ensure the handle is available across all key social media platforms for brand consistency.</p>\n"
      },
      {
        "text": "<p>Seek Audience Feedback:</p>\n",
        "subtext": "<p>Obtain reactions and perceptions from your target audience and personal network regarding your handle.</p>\n"
      },
      {
        "text": "<p>Check Domain Availability:</p>\n",
        "subtext": "<p>Prioritize a '.com' domain for better brand recognition and SEO benefits. Utilize GoDaddy's domain search <a href=\"https://www.godaddy.com/en-uk/offers/domain?isc=sem3year&amp;countryview=1&amp;currencyType=EUR&amp;swp_countrycode=FR&amp;cdtl=c_17643108404.g_138780513776.k_kwd-21292142838.a_676833332556.d_c.ctv_g&amp;bnb=b&amp;gad_source=1&amp;gclid=Cj0KCQiA2eKtBhDcARIsAEGTG43Z74iRJbIQNUWHh_OCSEq9xqIPFK3MwG33z875kpk0fv_4Pdr13JkaAnf5EALw_wcB\">tool</a> to check if your .com domain is available.</p>\n"
      },
      {
        "text": "<p>Conduct a Trademark Search:</p>\n",
        "subtext": "<p>Verify that your chosen name isn’t already trademarked to avoid potential legal issues. <a href=\"https://www.uspto.gov/trademarks/search\">Here's</a> where you can search if it's trademarked (just do a basic Word Mark Search).</p>\n"
      },
      {
        "text": "<p>Finalize Your Decision:</p>\n",
        "subtext": "<p>With these search results, pick a name that’s available and legally clear for your channel's identity.</p>\n"
      },
      {
        "text": "<p>Purchase the Domain:</p>\n",
        "subtext": "<p>If the domain is available, consider purchasing it on <a href=\"https://members.cj.com/member/6522636/account/publisher/users.cj\">Godaddy</a> to secure your brand's online identity and for future website development.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Your social media handle is more than just a username; it's a part of your brand identity. It should be memorable, unique, and reflective of your niche or personal brand. A good handle helps you stand out and can be easily found by your audience.</p>\n"
    ],
    "inputs": [
      "What handle did you go for? "
    ]
  },
  "influencerBuild3": {
    "id": "influencerBuild3",
    "type": "textarea",
    "title": "Crafting your social media profile",
    "nextId": "influencerBuild4",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Craft Your Profile",
    "videoVaultTags": [
      "name",
      "name2",
      "influencer2",
      "buidgeneral1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "build",
    "playbookStageStep": 4,
    "playbookPct": 8,
    "steps": [
      {
        "text": "<p>Create an Engaging Bio:</p>\n",
        "subtext": "<p>Write a concise, captivating bio that clearly states your niche, interests, and what makes you unique. Keep it relevant to your target audience.</p>\n"
      },
      {
        "text": "<p>Choose a Professional Profile Picture:</p>\n",
        "subtext": "<p>Select a high-quality, clear profile picture that represents you and your brand. Consistency in your profile picture across platforms can aid in brand recognition.</p>\n"
      },
      {
        "text": "<p>Design a Cohesive Visual Theme:</p>\n",
        "subtext": "<p>Develop a consistent color scheme, font style, and overall aesthetic for your profile. This visual consistency should extend to your content as well.</p>\n"
      },
      {
        "text": "<p>Include Contact Information and Links:</p>\n",
        "subtext": "<p>Make it easy for followers and brands to contact you. Include relevant contact information and links to your other social media profiles or website.</p>\n"
      },
      {
        "text": "<p>Highlight Key Achievements or Offers:</p>\n",
        "subtext": "<p>If applicable, mention any notable achievements, collaborations, or special offers in your profile to enhance credibility and engagement.</p>\n"
      },
      {
        "text": "<p>Optimize for Search and Discovery:</p>\n",
        "subtext": "<p>Use keywords relevant to your niche in your bio to improve discoverability in search results on the platform.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Your social media profile is often the first point of contact with your audience and potential collaborators. It should encapsulate who you are, what your brand stands for, and what followers can expect from your content. A well-crafted profile helps to attract and retain followers, setting the tone for your online persona.</p>\n"
    ],
    "inputs": [
      "Let us know the link to your profile "
    ]
  },
  "influencerBuild4": {
    "id": "influencerBuild4",
    "type": "table",
    "title": "Develop a content strategy\n",
    "nextId": "influencerBuild5",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Content Strategy",
    "videoVaultTags": [
      "name",
      "name2",
      "influencer2",
      "buidgeneral1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "build",
    "playbookStageStep": 5,
    "playbookPct": 9,
    "steps": [
      {
        "text": "<p>Identify Your Core Themes:</p>\n",
        "subtext": "<p>Determine the main themes or topics of your content that resonate with your niche and personal brand.</p>\n"
      },
      {
        "text": "<p>Understand Your Audience:</p>\n",
        "subtext": "<p>Research your target audience's preferences, pain points, and interests to create content that appeals to them.</p>\n"
      },
      {
        "text": "<p>Plan Content Types:</p>\n",
        "subtext": "<p>Decide on the types of content you will produce (e.g., photos, videos, blog posts, stories) based on your strengths and platform norms.</p>\n"
      },
      {
        "text": "<p>Incorporate Engagement Strategies:</p>\n",
        "subtext": "<p>Plan interactive elements like polls, Q&amp;A sessions, contests, and collaborations to boost engagement.</p>\n"
      },
      {
        "text": "<p>Monitor Trends and Adapt:</p>\n",
        "subtext": "<p>Stay updated on current trends within your niche and on the platform. Adapt your content strategy to include relevant and timely topics.</p>\n"
      },
      {
        "text": "<p>Evaluate and Optimize:</p>\n",
        "subtext": "<p>Regularly assess the performance of your content. Use insights and analytics to refine your strategy for better engagement and growth.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>A robust content strategy is essential for any successful social media influencer. It involves planning the types of content you'll create, the themes you'll cover, and how you'll engage with your audience. This strategy should align with your niche, appeal to your target audience, and leverage the unique features of your chosen social media platforms.</p>\n"
    ],
    "inputs": [
      "Content Topic",
      "Why will this topic resonate with your audience?"
    ]
  },
  "influencerBuild5": {
    "id": "influencerBuild5",
    "type": "table",
    "title": "Build a content schedule and stick to it",
    "nextId": "influencerBuild6",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Content Schedule",
    "outsourcePrice": "$19",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "Struggling to establish a consistent content schedule? Let us do the heavy lifting. We'll create a personalized content calendar, complete with resources and templates, all within 7 days. It's time to focus on what you do best, knowing your content strategy is in good hands.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrhcDAbzSOakWBOdiBVRgc\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "name",
      "name2",
      "influencer2",
      "buidgeneral1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "build",
    "playbookStageStep": 7,
    "playbookPct": 11,
    "steps": [
      {
        "text": "<p>Assess Your Capacity:</p>\n",
        "subtext": "<p>Determine how much time you can realistically dedicate to content creation, including planning, production, and engagement.</p>\n"
      },
      {
        "text": "<p>Define Your Posting Frequency:</p>\n",
        "subtext": "<p>Decide how often you will post based on your capacity and the expectations of your audience. Consistency is more important than frequency.</p>\n"
      },
      {
        "text": "<p>Plan Content in Advance:</p>\n",
        "subtext": "<p>Use a content calendar to plan out your posts in advance. This should include main themes, specific post ideas, and any seasonal content.</p>\n"
      },
      {
        "text": "<p>Balance Different Types of Content:</p>\n",
        "subtext": "<p>Ensure your schedule includes a variety of content types (e.g., informational, entertaining, interactive) to keep your audience engaged.</p>\n"
      },
      {
        "text": "<p>Set Clear Deadlines:</p>\n",
        "subtext": "<p>For each piece of content, have a clear deadline for when it should be ready to post. This includes time for creation, editing, and finalization.</p>\n"
      },
      {
        "text": "<p>Allow for Flexibility:</p>\n",
        "subtext": "<p>While consistency is key, leave some room in your schedule for spontaneous posts or to capitalize on trending topics.</p>\n"
      },
      {
        "text": "<p>Review and Adjust Your Schedule Regularly:</p>\n",
        "subtext": "<p>Regularly review your content performance and audience feedback to adjust your schedule and strategy as needed.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Creating and adhering to a content schedule is an essential step for a social media influencer, as it ensures consistency and helps in managing and maintaining a strong online presence. Here's a structured outline for this step:</p>\n",
      "<p>A well-planned content schedule is crucial for influencers to maintain consistency, manage workload, and keep the audience engaged. Sticking to a schedule helps in building a reliable brand presence and can significantly improve audience retention and growth.</p>\n"
    ],
    "inputs": [
      "Post Title",
      "Draft Status Yes/No.",
      "Scheduled Posting Date",
      "Posted Yes/No."
    ]
  },
  "influencerBuild6": {
    "id": "influencerBuild6",
    "type": "simple",
    "title": "Build your content creation skills",
    "nextId": "influencerBuild7",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Skill Development",
    "videoVaultTags": [
      "name",
      "name2",
      "influencer2",
      "buidgeneral1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "build",
    "playbookStageStep": 6,
    "playbookPct": 10,
    "steps": [
      {
        "text": "<p>Identify Key Skills to Develop:</p>\n",
        "subtext": "<p>Assess which content creation skills are most important for your niche and platform. This could include photography, video production, graphic design, or writing.</p>\n"
      },
      {
        "text": "<p>Engage in Continuous Learning:</p>\n",
        "subtext": "<p>Take online courses, attend workshops, or watch tutorials to improve your skills. Practice regularly to refine your techniques.</p>\n"
      },
      {
        "text": "<p>Experiment with Different Content Formats:</p>\n",
        "subtext": "<p>Try creating different types of content (e.g., carousel posts, stories, live streams) to see what resonates with your audience and what you enjoy creating.</p>\n"
      },
      {
        "text": "<p>Invest in Quality Tools and Equipment:</p>\n",
        "subtext": "<p>Consider investing in quality equipment like a good camera, microphone, or editing software to enhance the production value of your content.</p>\n"
      },
      {
        "text": "<p>Gather Feedback and Iterate:</p>\n",
        "subtext": "<p>Share your content with a trusted group for feedback. Use their insights to improve and evolve your content creation approach.</p>\n"
      },
      {
        "text": "<p>Stay Updated with Trends and Technology:</p>\n",
        "subtext": "<p>Keep abreast of the latest trends and technological advancements in content creation relevant to your niche.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>As a social media influencer, your ability to create high-quality, engaging content is crucial. Enhancing your skills in areas such as photography, videography, editing, and copywriting can significantly improve the impact of your content and attract more followers.</p>\n"
    ]
  },
  "influencerBuild7": {
    "id": "influencerBuild7",
    "type": "simple",
    "title": "Film one month's worth of content",
    "nextId": "influencerBuild8",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Filming Content",
    "videoVaultTags": [
      "name",
      "name2",
      "influencer2",
      "buidgeneral1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "build",
    "playbookStageStep": 8,
    "playbookPct": 25,
    "steps": [
      {
        "text": "<p>Allocate Time for Filming:</p>\n",
        "subtext": "<p>Set aside dedicated days or blocks of time for filming. This helps maintain focus and efficiency during content production.</p>\n"
      },
      {
        "text": "<p>Prepare Content Outlines and Scripts:</p>\n",
        "subtext": "<p>For each piece of content, prepare a brief outline or script. This ensures you cover all key points and maintain consistency in messaging.</p>\n"
      },
      {
        "text": "<p>Gather Necessary Equipment and Resources:</p>\n",
        "subtext": "<p>Ensure you have all the necessary equipment, props, and resources ready before filming. This includes cameras, lighting, and any special items needed for your content.</p>\n"
      },
      {
        "text": "<p>Film with Editing in Mind:</p>\n",
        "subtext": "<p>While filming, consider how the footage will be edited. Capture different angles and takes to give yourself options during the editing phase.</p>\n"
      },
      {
        "text": "<p>Organize and Backup Your Footage:</p>\n",
        "subtext": "<p>After filming, organize your footage clearly and ensure it's backed up securely to prevent any loss of content.</p>\n"
      },
      {
        "text": "<p>Edit and Finalize in Batches:</p>\n",
        "subtext": "<p>Edit your content in batches to maintain efficiency. Once edited, have your content ready to go in a scheduled queue.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Creating a buffer of content by filming one month's worth of posts in advance can significantly reduce stress and ensure a steady stream of material for your audience. This approach allows you to stay ahead of the curve, giving you flexibility and the ability to focus on engagement and strategy without the constant pressure of content creation.</p>\n"
    ]
  },
  "influencerBuild8": {
    "id": "influencerBuild8",
    "type": "simple",
    "title": "Adding value: Authentic engagement strategies",
    "nextId": "influencerBuild9",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Engagement Tactics",
    "videoVaultTags": [
      "name",
      "name2",
      "influencer2",
      "buidgeneral1"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "build",
    "playbookStageStep": 9,
    "playbookPct": 30,
    "steps": [
      {
        "text": "<p>Deep Dive into Audience Needs:</p>\n",
        "subtext": "<p>Revisit your understanding of your audience. What are their interests, challenges, and aspirations? This knowledge is crucial for tailoring your value-added initiatives.</p>\n"
      },
      {
        "text": "<p>Plan Value-Adding Initiatives:</p>\n",
        "subtext": "<p>Based on your audience insights, brainstorm activities or resources that would significantly benefit them. Ideas could include a free Q&amp;A session on topics they care about, creating and sharing helpful e-printables, or offering insightful webinars.</p>\n"
      },
      {
        "text": "<p>Develop Engaging and Useful Content:</p>\n",
        "subtext": "<p>Create content that is directly useful to your audience. This could be informative posts, how-to guides, tips, or even motivational content, depending on your audience's preferences.</p>\n"
      },
      {
        "text": "<p>Offer Free Resources:</p>\n",
        "subtext": "<p>Develop and offer free resources like e-printables, eBooks, or checklists that your audience can use. Ensure these resources are not only branded but also packed with valuable information.</p>\n"
      },
      {
        "text": "<p>Host Q&amp;A Sessions:</p>\n",
        "subtext": "<p>Regularly engage with your audience through live Q&amp;A sessions. Use these sessions to answer their questions, discuss their concerns, and share your expertise.</p>\n"
      },
      {
        "text": "<p>Showcase Authenticity and Transparency:</p>\n",
        "subtext": "<p>In all your interactions and content, maintain authenticity. Share your experiences and stories that resonate with your audience's situations.</p>\n"
      },
      {
        "text": "<p>Evaluate Engagement and Feedback:</p>\n",
        "subtext": "<p>Monitor how your audience responds to these value-adding activities. Use their feedback and engagement levels to refine your approach continuously.</p>\n"
      },
      {
        "text": "<p>Nurture Relationships:</p>\n",
        "subtext": "<p>Consistently engage with your audience in the comments, messages, and during live sessions. Building relationships is about two-way communication and showing that you genuinely care. By focusing on adding value in a way that's authentic and directly relevant to your audience, you create a foundation of trust and loyalty. This approach not only enhances your social media presence but also strengthens the community around your brand.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>In the realm of social media, adding genuine value to your audience's lives is fundamental to building lasting relationships and trust. Understanding your audience's needs and interests is key to determining how you can best serve them. This step is about going beyond mere content creation and actively contributing to your audience's well-being or knowledge. Whether it's through interactive Q&amp;A sessions, offering free e-printables, or providing other valuable resources, the focus should be on authentic engagement that enriches their experience.</p>\n"
    ]
  },
  "influencerLaunch1": {
    "id": "influencerLaunch1",
    "type": "textarea",
    "title": "Crafting your debut: Making an impact with your first social media post\n\n",
    "nextId": "influencerLaunch2",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Your 1st Post",
    "videoVaultTags": [
      "influenceridea",
      "influencer2",
      "influencer3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "launch",
    "playbookStageStep": 1,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Choose Content That Represents Your Brand:</p>\n",
        "subtext": "<p>Select a piece of content that best introduces your brand and personality to your audience. It should align with your overall content strategy and appeal to your target demographic.</p>\n"
      },
      {
        "text": "<p>Craft an Engaging Caption:</p>\n",
        "subtext": "<p>Write a caption that complements your content, communicates your message, and invites engagement. Be authentic and true to your voice.</p>\n"
      },
      {
        "text": "<p>Include a Call-to-Action (CTA):</p>\n",
        "subtext": "<p>Encourage your audience to engage with your post. This could be asking a question, inviting comments, or prompting them to follow you for more content.</p>\n"
      },
      {
        "text": "<p>Optimize with Relevant Hashtags:</p>\n",
        "subtext": "<p>Use relevant hashtags to increase the visibility of your post. Research and select hashtags that are popular within your niche but not so broad that your post gets lost.</p>\n"
      },
      {
        "text": "<p>Time Your Post Strategically:</p>\n",
        "subtext": "<p>Post at a time when your target audience is most active. Use insights from your chosen platform or research the best times to post for your audience demographic.</p>\n"
      },
      {
        "text": "<p>Promote Your Post:</p>\n",
        "subtext": "<p>Share your post on other social media platforms or with personal networks to gain initial traction.</p>\n"
      },
      {
        "text": "<p>Monitor and Respond to Engagement:</p>\n",
        "subtext": "<p>Keep an eye on the engagement your post receives and respond promptly to comments and messages to build a relationship with your audience.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>You've laid the foundation, and now it's time to step into the spotlight by launching your influencer account. Your first post is more than just content; it's your grand introduction to potential followers. This pivotal moment sets the tone for your entire social media presence, giving you a unique opportunity to make a lasting first impression, highlight what sets you apart, and start cultivating your community. Ensure this initial post mirrors your brand's essence, captivates your audience, and sparks meaningful interaction.</p>\n"
    ],
    "inputs": [
      "Provide a link to your first social media post."
    ]
  },
  "influencerLaunch2": {
    "id": "influencerLaunch2",
    "type": "table",
    "title": "Announce your social media presence to friends, family, and the FIPreneur community",
    "nextId": "influencerLaunch4",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Launching ",
    "videoVaultTags": [
      "influenceridea",
      "influencer2",
      "influencer3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "launch",
    "playbookStageStep": 2,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Prepare Your Announcement Message:</p>\n",
        "subtext": "<p>Craft a message that is enthusiastic, personal, and explains what you are doing. Share your passion and what followers can expect from your social media presence.</p>\n"
      },
      {
        "text": "<p>Personalize Messages Where Possible:</p>\n",
        "subtext": "<p>For closer contacts, personalize your message. A direct, personal appeal is more engaging and likely to garner support.</p>\n"
      },
      {
        "text": "<p>Utilize Different Communication Channels:</p>\n",
        "subtext": "<p>Announce through various channels like personal messages, emails, social media posts, or even a phone call, depending on your relationship with the recipient.</p>\n"
      },
      {
        "text": "<p>Involve the FIpreneur Community:</p>\n",
        "subtext": "<p>Share your journey with the FIpreneur community. Highlight how your content aligns with the community’s values and can offer value to members.</p>\n"
      },
      {
        "text": "<p>Encourage Sharing:</p>\n",
        "subtext": "<p>Ask your friends, family, and community members to share your profile or a specific post. This can significantly increase your reach.</p>\n"
      },
      {
        "text": "<p>Express Gratitude:</p>\n",
        "subtext": "<p>Show appreciation for any support you receive. A simple thank you can go a long way in fostering positive relationships.</p>\n"
      },
      {
        "text": "<p>Follow Up with Engaging Content:</p>\n",
        "subtext": "<p>After the announcement, ensure that your social media profile has engaging content for new followers to see. This helps to retain their interest and support.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Leveraging your personal and professional networks is a key strategy in the early stages of establishing your social media presence. Sharing your new venture with friends, family, and the FIPreneur community can provide a supportive base of initial followers and create a buzz around your brand.</p>\n"
    ],
    "inputs": [
      "Friend/Family Member",
      "Outreach Message Sent? Yes/No."
    ]
  },
  "influencerLaunch4": {
    "id": "influencerLaunch4",
    "type": "simple",
    "title": "Cultivating early adopter relationships for influencer success\n\n",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Build Relationships",
    "videoVaultTags": [
      "influenceridea",
      "influencer2",
      "influencer3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "launch",
    "playbookStageStep": 4,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Identify and Connect with Early Adopters:</p>\n",
        "subtext": "<p>Recognize the followers who frequently engage with your content and make an effort to connect with them. Personal responses, mentions, or direct messages can strengthen these relationships.</p>\n"
      },
      {
        "text": "<p>Actively Respond to Comments and Messages:</p>\n",
        "subtext": "<p>Make it a habit to actively and thoughtfully respond to comments and messages. This interaction shows that you value your audience’s engagement.</p>\n"
      },
      {
        "text": "<p>Encourage Community Interaction:</p>\n",
        "subtext": "<p>Create posts that encourage discussions and interactions among your followers. This can help in building a sense of community around your brand.</p>\n"
      },
      {
        "text": "<p>Share User-Generated Content:</p>\n",
        "subtext": "<p>If followers are creating content related to your brand or niche, share it on your platform. This not only appreciates their effort but also encourages others to engage similarly.</p>\n"
      },
      {
        "text": "<p>Gather Feedback:</p>\n",
        "subtext": "<p>Regularly ask your audience for feedback on your content and what they would like to see more of. This can be done through direct questions in posts, stories, or polls.</p>\n"
      },
      {
        "text": "<p>Host Q&amp;A Sessions or Live Streams:</p>\n",
        "subtext": "<p>Engage with your audience in real-time through Q&amp;A sessions, live streams, or interactive stories. This direct interaction can significantly boost engagement.</p>\n"
      },
      {
        "text": "<p>Analyze Engagement Patterns:</p>\n",
        "subtext": "<p>Keep an eye on which types of posts and interactions receive the most engagement. Use these insights to inform your content and engagement strategies.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Building a relationship with your audience, especially early adopters, is key to growing as a social media influencer. Early adopters often become loyal fans and can provide invaluable feedback and advocacy for your brand. Engaging with your audience helps in understanding their preferences, fosters community, and enhances your social media presence.</p>\n"
    ]
  },
  "influencerScale1": {
    "id": "influencerScale1",
    "type": "simple",
    "title": "Analyze and adapt your strategy for growth",
    "nextId": "influencerScale2",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Growth Strategy",
    "videoVaultTags": [
      "influenceridea",
      "influencer2",
      "influencer3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "scale",
    "playbookStageStep": 1,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Regularly Review Analytics:</p>\n",
        "subtext": "<p>Utilize the analytics tools provided by social media platforms to track the performance of your posts. Look at metrics like engagement rates, follower growth, and content reach.</p>\n"
      },
      {
        "text": "<p>Gather Audience Feedback:</p>\n",
        "subtext": "<p>Pay attention to comments, messages, and other forms of direct feedback from your audience. This qualitative data is crucial for understanding audience preferences and expectations.</p>\n"
      },
      {
        "text": "<p>Conduct Periodic Surveys:</p>\n",
        "subtext": "<p>Occasionally, run surveys or polls to gather structured feedback from your followers about your content and what they’d like to see more of.</p>\n"
      },
      {
        "text": "<p>Identify Successful Content:</p>\n",
        "subtext": "<p>Analyze which types of content are performing best and why. This can inform your future content creation.</p>\n"
      },
      {
        "text": "<p>Experiment with New Ideas:</p>\n",
        "subtext": "<p>Regularly experiment with new content formats, themes, or posting times to see what yields better results and keeps your content fresh.</p>\n"
      },
      {
        "text": "<p>Stay Informed About Platform Changes:</p>\n",
        "subtext": "<p>Keep up-to-date with any changes in the social media platform's algorithms or features that could impact your content’s performance.</p>\n"
      },
      {
        "text": "<p>Adapt Your Strategy:</p>\n",
        "subtext": "<p>Based on your analyses and the feedback received, make necessary adjustments to your content strategy, posting schedule, and engagement tactics.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Having launched your account, settled into a routine of frequent posts, and begun interacting with your early adopters, the next phase in your influencer journey is expansion. Growth as a social media influencer hinges on your ability to consistently evaluate and refine your content based on performance insights. This means tapping into what truly engages your audience, pinpointing opportunities for enhancement, and remaining flexible to the ever-evolving trends and algorithm updates of social media platforms.</p>\n"
    ]
  },
  "influencerScale2": {
    "id": "influencerScale2",
    "type": "table",
    "title": "Network with other bloggers and influencers",
    "nextId": "influencerScale3",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Networking",
    "outsourcePrice": "$69",
    "outsourceCTALabel": "Outsource This Step Now",
    "outsourcePitchCopy": "If building a network of influencers seems overwhelming, we're here to help. We'll compile a list of 100 influencers in your niche, perfect for collaborations and networking, all within 7 days. Let's get your content the visibility it deserves.",
    "stripe": "<stripe-buy-button\n  buy-button-id=\"buy_btn_1OgrV7DAbzSOakWBE0Ae1f2N\"\n  publishable-key=\"pk_live_51KdbuIDAbzSOakWBfau7JkDcHCKfNSU0Ga1z88S2JfJPnmlZG0vcyQMV4GBt6xrGrM9rz1jd9u1KcEN2iUOyw4OV003PPkBf88\"\n>\n</stripe-buy-button>",
    "videoVaultTags": [
      "influenceridea",
      "influencer2",
      "buidgeneral1",
      "influencer3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "scale",
    "playbookStageStep": 2,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Identify Potential Networking Opportunities:</p>\n",
        "subtext": "<p>Research and identify bloggers and influencers in your niche or related fields who align with your brand and content style.</p>\n"
      },
      {
        "text": "<p>Engage with Their Content:</p>\n",
        "subtext": "<p>Start by engaging with their content genuinely. Leave thoughtful comments, share their posts, and participate in discussions they initiate.</p>\n"
      },
      {
        "text": "<p>Reach Out Directly:</p>\n",
        "subtext": "<p>Send personalized messages or emails to introduce yourself and express genuine interest in their work. Avoid making immediate requests or pitches in your initial contact.</p>\n"
      },
      {
        "text": "<p>Collaborate on Content:</p>\n",
        "subtext": "<p>Propose collaborations that are mutually beneficial, such as guest posts, joint live sessions, or shared content series.</p>\n"
      },
      {
        "text": "<p>Attend Industry Events:</p>\n",
        "subtext": "<p>Participate in blogging conferences, webinars, and meetups to connect with influencers and bloggers in person or virtually.</p>\n"
      },
      {
        "text": "<p>Join Online Communities and Groups:</p>\n",
        "subtext": "<p>Become active in online communities, forums, and groups related to your niche. These platforms can be great for networking and sharing experiences.</p>\n"
      },
      {
        "text": "<p>Offer and Seek Support:</p>\n",
        "subtext": "<p>Be open to supporting others, such as by sharing their content or offering advice, and don’t hesitate to seek guidance or insights from more experienced influencers.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Networking with fellow bloggers and influencers can open up opportunities for collaborations, cross-promotion, and learning from shared experiences. Building relationships with peers in your niche or related fields can enhance your visibility, provide valuable insights, and contribute to your growth as a social media influencer.</p>\n"
    ],
    "inputs": [
      "Influencer/Blogger",
      "Date Outreach Email Sent",
      "Date Follow-Up Email Sent"
    ]
  },
  "influencerScale3": {
    "id": "influencerScale3",
    "type": "simple",
    "title": "Staying ahead: Embracing trends in social media engagement\n\n\n\n\n\n",
    "nextId": "influencerScale4",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Trend Embracing",
    "videoVaultTags": [
      "influenceridea",
      "influencer2",
      "influencer3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "scale",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Regularly Monitor Trending Topics:</p>\n",
        "subtext": "<p>Use social media tools and platforms to keep track of trending hashtags, topics, and discussions in your niche.</p>\n"
      },
      {
        "text": "<p>Follow Influential Figures and Thought Leaders:</p>\n",
        "subtext": "<p>Stay connected with key influencers and thought leaders in your field. They often set or predict upcoming trends.</p>\n"
      },
      {
        "text": "<p>Subscribe to Industry Newsletters and Blogs:</p>\n",
        "subtext": "<p>Keep up with industry news and updates by subscribing to relevant newsletters, blogs, and publications.</p>\n"
      },
      {
        "text": "<p>Participate in Online Communities:</p>\n",
        "subtext": "<p>Engage in online forums, groups, and social media communities related to your niche. These can be great sources for emerging trends and insights.</p>\n"
      },
      {
        "text": "<p>Experiment with Trending Formats and Platforms:</p>\n",
        "subtext": "<p>Try incorporating trending content formats (like Reels, TikToks) or explore emerging platforms to stay relevant and innovative.</p>\n"
      },
      {
        "text": "<p>Attend Workshops and Webinars:</p>\n",
        "subtext": "<p>Attend online workshops, webinars, and conferences that focus on the latest trends and strategies in social media and digital marketing.</p>\n"
      },
      {
        "text": "<p>Gather Feedback from Your Audience:</p>\n",
        "subtext": "<p>Regularly ask your audience for their thoughts on the latest trends or what they are currently interested in.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Being aware of and adapting to new trends is key to maintaining a dynamic and contemporary social media presence. Staying updated with the latest happenings in your niche, and the wider social media landscape can help you create content that resonates with current audience interests and preferences.</p>\n"
    ]
  },
  "influencerScale4": {
    "id": "influencerScale4",
    "type": "simple",
    "title": "Monetizing your audience: Strategic and trust-centric collaborations\n\n",
    "nextId": "influencerScale5",
    "nextButtonLabel": "Mark as Completed",
    "footerText": "By thoughtfully monetizing your audience through suitable collaborations, you not only create a stream of revenue but also enhance the overall experience for your followers, strengthening their loyalty and trust in your brand.",
    "moduleLabel": "Monetize Audience",
    "videoVaultTags": [
      "influenceridea",
      "influencer2",
      "influencer3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "influencer",
    "playbookStage": "scale",
    "playbookStageStep": 4,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Understand Your Audience's Preferences:</p>\n",
        "subtext": "<p>Dive deep into your audience's interests, needs, and preferences. Use surveys, polls, or direct interactions to gather insights into what products or services they value.</p>\n"
      },
      {
        "text": "<p>Identify Suitable Collaboration Opportunities:</p>\n",
        "subtext": "<p>Look for products and services that naturally align with your content and your audience's interests. The fit should feel organic, not forced.</p>\n"
      },
      {
        "text": "<p>Evaluate Potential Partnerships:</p>\n",
        "subtext": "<p>Carefully assess potential collaboration offers. Consider the reputation and values of the brands reaching out to you. Ensure they align with your principles and audience expectations.</p>\n"
      },
      {
        "text": "<p>Maintain Authenticity in Promotions:</p>\n",
        "subtext": "<p>When promoting a product or service, ensure your content remains authentic. Share your genuine experiences or insights about the product, maintaining the trust of your audience.</p>\n"
      },
      {
        "text": "<p>Communicate Transparency:</p>\n",
        "subtext": "<p>Be transparent about sponsored content. Your audience will appreciate honesty about paid partnerships, maintaining the credibility of your recommendations.</p>\n"
      },
      {
        "text": "<p>Monitor Audience Response:</p>\n",
        "subtext": "<p>After promoting a product or service, observe your audience's reaction. Engagement levels, comments, and feedback can provide valuable insights into their reception of the promoted content.</p>\n"
      },
      {
        "text": "<p>Refine Your Monetization Strategy:</p>\n",
        "subtext": "<p>Use the insights gained from each collaboration to refine your approach. Continuously adapt to ensure that your monetization efforts feel natural and continue to add value to your audience.</p>\n"
      },
      {
        "text": "<p>Build Long-term Relationships with Brands:</p>\n",
        "subtext": "<p>Aim to establish long-term relationships with brands that resonate with your audience. Consistent partnerships with trusted brands can lead to more meaningful content and reliable income streams.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Monetizing your audience as a social media influencer involves more than just finding opportunities to earn. It’s about carefully selecting collaborations that align with your audience's interests and maintaining the trust you've worked hard to build. This step focuses on understanding your audience's preferences and partnering with products and services that resonate genuinely with them. The goal is to offer value through these collaborations, ensuring that any promotional content is relevant, valuable, and in line with your audience's expectations and your brand ethos.</p>\n"
    ]
  },
  "diyIdeaResults": {
    "id": "diyIdeaResults",
    "type": "textarea",
    "title": "Tell Grant about your idea ",
    "nextId": "diyValidation0",
    "moduleLabel": "Your Ideas",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 1,
    "paragraphs": [
      "<p>What's the basic concept, and why does it excite you?</p>\n"
    ]
  },
  "diyValidation0": {
    "id": "diyValidation0",
    "type": "simple",
    "title": "Let's make sure your idea is a winner",
    "nextId": "diyWritten2",
    "bigText": "Over 80% of side businesses fail because the idea isn't viable. Answer these next questions and Grant will personally review your idea.",
    "moduleLabel": "Starting Your Validation",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 5,
    "paragraphs": [
      "<h2 style='text-align: center; margin-bottom: 10px; font-size: 32px;'>Your Coach</h2><img src='/grant.jpg' style='width: 200px; height: 200px; border-radius: 100%; margin: 10px auto;' />",
      "<p style='text-align: center; font-weight: 900; font-size: 24px; margin-bottom: -10px;'>Grant Sabatier</p>",
      "<p style='text-align: center; margin-bottom: -10px;'>Launched over 5 successful content businesses</p>",
      "<p style='text-align: center'>Generates $1M+ in annual revenue</p><p style='text-align: center'>Sold the 'Millennial Money' blog for an 8-figure sum</p>"
    ]
  },
  "diyWritten2": {
    "id": "diyWritten2",
    "type": "multi-text",
    "title": "Who is the target customer?",
    "nextId": "diyWritten3",
    "footerText": "Almost there! Answer a few questions, then Grant will review them to help bring your idea to life.",
    "moduleLabel": "Your Target Customer",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "validate",
    "playbookStageStep": 2,
    "playbookPct": 3,
    "paragraphs": [
      "<p>Understanding your target audience is paramount. It's the foundation that enables your side business to provide tailored solutions, insights, and value to your customer's specific needs and challenges.</p>\n"
    ],
    "inputs": [
      "What age group does your side business cater to?",
      "What are their main interests or hobbies?",
      "What else sets your target customer apart? Do they have certain jobs, ways of living, or problems they face?"
    ]
  },
  "diyWritten3": {
    "id": "diyWritten3",
    "type": "textarea",
    "title": "What problem does your idea solve for the target customer?",
    "nextId": "diyWritten4",
    "footerText": "Almost there! Answer a few questions, then Grant will review them to help bring your idea to life.",
    "moduleLabel": "The Problem",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "validate",
    "playbookStageStep": 2,
    "playbookPct": 3,
    "paragraphs": [
      "<p>Successful businesses often address specific challenges or pain points faced by their customers. What problem or need will your business idea address? How does it make the life of your target customer better or easier?</p>\n"
    ]
  },
  "diyWritten4": {
    "id": "diyWritten4",
    "type": "textarea",
    "title": "How do you know there is a market demand for this idea?",
    "nextId": "diyValidateOptions",
    "footerText": "Almost there! Answer a few questions, then Grant will review them to help bring your idea to life.",
    "moduleLabel": "Market Demand",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "validate",
    "playbookStageStep": 2,
    "playbookPct": 3,
    "paragraphs": [
      "<p>Evidence of market demand can be a strong validator for your business idea. Have you come across any trends, data, or even observed competitors that indicate a demand for what you're offering?</p>\n",
      "<p>Examples could be Google trends, market research reports, or the presence of businesses in a similar domain.</p>\n"
    ]
  },
  "diyValidateOptionsInnerCircle": {
    "id": "diyValidateOptionsInnerCircle",
    "type": "single-choice",
    "title": "Let Grant help solve your biggest challenge ",
    "nextId": "diyWaitingForGrantFeedbackIdea",
    "bigText": "Grant will personally review your business and work with you to build a tailored roadmap, guiding you towards creating a business that unlocks a life you love.",
    "moduleLabel": "Submit Your Validation",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 2,
    "choices": [
      {
        "label": "Submit written validation",
        "subtext": "And get a WRITTEN response from Grant",
        "nextId": "diyWaitingForGrantFeedbackIdea"
      },
      {
        "label": "Record a video to send to Grant",
        "subtext": "And get a more detailed VIDEO response from Grant. 80% of FIPreneurs opt for video validation",
        "nextId": "diyWaitingForGrantFeedbackIdeaVideo"
      }
    ]
  },
  "diyValidateOptions": {
    "id": "diyValidateOptions",
    "type": "single-choice",
    "title": "Let's make sure your idea is a winner",
    "nextId": "diyWaitingForGrantFeedbackIdeaVideo",
    "bigText": "You're just a click away from getting closer to that winning idea. How would you like to submit your answers to Grant? ",
    "moduleLabel": "Submit Your Validation",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 2,
    "choices": [
      {
        "label": "Submit written validation",
        "subtext": "And get a WRITTEN response from Grant",
        "nextId": "diyWaitingForGrantFeedbackIdea"
      },
      {
        "label": "Record a video to send to Grant",
        "subtext": "And get a more detailed VIDEO response from Grant. 80% of FIPreneurs opt for video validation",
        "nextId": "diyWaitingForGrantFeedbackIdeaVideo"
      }
    ]
  },
  "diyWaitingForGrantFeedbackIdeaVideo": {
    "id": "diyWaitingForGrantFeedbackIdeaVideo",
    "type": "simple",
    "title": "Upload a video for Grant to review!",
    "nextId": "diyWaitingForGrantFeedbackIdea",
    "nextButtonLabel": "Next",
    "bigText": "We'll get back to you within the next few days. But don't wait up – go ahead and explore the portal in the meantime. Check out the blueprints, dive into the resources, and see what other FIPreneurs are up to. It's all here waiting for you to get started on unlocking more freedom in your life!",
    "moduleLabel": "Get Grant's Feedback",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "validate",
    "playbookStageStep": 3,
    "playbookPct": 5,
    "paragraphs": [
      "<div class=\"w-full flex items-center justify-center py-8\">\n        <a\n          href=\"https://senja.io/p/fipreneurs/r/nY2O7c\"\n          target=\"_blank\"\n          class=\"p-8 bg-future-blue text-black rounded-full cursor-pointer text-center text-2xl mx-auto font-black shadow-xl\"\n        >\n          <span>Record a video to send to Grant</span>\n        </a>\n      </div>"
    ]
  },
  "diyWaitingForGrantFeedbackIdea": {
    "id": "diyWaitingForGrantFeedbackIdea",
    "type": "simple",
    "title": "Grant is reviewing your idea!",
    "nextId": "diyBuild1",
    "moduleLabel": "Grant's Reviewing",
    "stepperSteps": [
      "Getting to Know You",
      "Discover Your Perfect Idea",
      "Get Grant's Feedback",
      "Enter the FIpreneurs"
    ],
    "stepperCurrent": 2,
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "validate",
    "playbookStageStep": 3,
    "playbookPct": 5,
    "steps": [
      {
        "text": "<p>Unlock Grant as your personal coach</p>\n",
        "subtext": "<p>Add Grant's weekly group coaching calls to your calendar <a href=\"https://www.addevent.com/calendar/XK624050\">here</a>. Don't miss out on the opportunity to be personally coached by Grant as he helps you unlock that life you dream of.</p>\n"
      },
      {
        "text": "<p>Meet your fellow FIpreneurs</p>\n",
        "subtext": "<p>Join Slack <a href=\"https://join.slack.com/t/fipreneurs/shared_invite/zt-2dtc5ygiu-FxH_Gt0MGlZh4XiOhNQkMg\">here</a>, our messaging platform to meet the rest of the community, see what everyone is working on, and share your own progress.</p>\n"
      },
      {
        "text": "<p>Learn with Grant</p>\n",
        "subtext": "<p>Start watching Grant's 8-module Financial Freedom video course <a href=\"https://members.fipreneurs.com/grants-financial-freedom-course\">here</a> to begin forging your path to independence.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Grant will be in touch in the next few days with his thoughts and feedback on your side business idea.</p>\n",
      "<p>While you're waiting, you can start exploring the FIpreneur community!</p>\n"
    ]
  },
  "diyBuild1": {
    "id": "diyBuild1",
    "type": "simple",
    "title": "Choose the best platform to connect with your target customer",
    "nextId": "diyBuild2",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Choose Your Platform",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "build",
    "playbookStageStep": 1,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Research Potential Platforms:</p>\n",
        "subtext": "<p>Explore various platforms where your target audience might be active. This could include social media, online marketplaces, forums, or even offline channels.</p>\n"
      },
      {
        "text": "<p>Analyze Competitor Presence:</p>\n",
        "subtext": "<p>Look at where your competitors are successfully engaging with customers. Note the platforms where they have a strong presence and how they interact with their audience.</p>\n"
      },
      {
        "text": "<p>Evaluate Platform Suitability:</p>\n",
        "subtext": "<p>Assess each platform's suitability for your business based on factors like user demographics, engagement potential, and alignment with your product or service.</p>\n"
      },
      {
        "text": "<p>Test and Gather Insights:</p>\n",
        "subtext": "<p>Consider conducting small-scale tests on selected platforms to gauge audience response and engagement.</p>\n"
      },
      {
        "text": "<p>Make an Informed Decision:</p>\n",
        "subtext": "<p>Based on your research and testing, decide on the platform(s) that are most likely to connect effectively with your target customers.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Now that you've honed in on your unique business idea, the next crucial step is determining the optimal platform to communicate your vision to potential customers.</p>\n",
      "<p>In today's digital age, the internet, social media, digital marketing, and a plethora of online tools have significantly lowered barriers to entry for starting and promoting a business online. However, this ease of access has also intensified the competition.</p>\n",
      "<p>While the digital landscape offers equal tools to all, in the experimental phase of your business, you may not have the vast resources to maximize these tools fully. Your challenge is to carve out a niche for yourself amid the crowded digital marketplace. Fortunately, you already possess the three indispensable elements for entrepreneurial success:</p>\n<ol>\n<li>A unique and compelling personal story</li>\n<li>Valuable insights and experiences to share</li>\n<li>A platform that you control, allowing you to engage with your audience on your own terms</li>\n</ol>\n"
    ]
  },
  "diyBuild2": {
    "id": "diyBuild2",
    "type": "simple",
    "title": "Build and optimize your platform for your target customer",
    "nextId": "diyBuild2.5",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Optimize Your Platform",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "build",
    "playbookStageStep": 2,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Design Your Platform:</p>\n",
        "subtext": "<p>Focus on a design that reflects your brand identity and appeals to your target audience. This includes layout, color scheme, and user interface.</p>\n"
      },
      {
        "text": "<p>Develop Compelling Content:</p>\n",
        "subtext": "<p>Create content tailored to your audience's interests and needs. This could range from product descriptions to blog posts, depending on the platform.</p>\n"
      },
      {
        "text": "<p>Optimize for User Experience (UX):</p>\n",
        "subtext": "<p>Ensure your platform is easy to navigate, loads quickly, and is optimized for mobile users, providing a seamless user experience.</p>\n"
      },
      {
        "text": "<p>Implement SEO Best Practices:</p>\n",
        "subtext": "<p>For digital platforms, use SEO (Search Engine Optimization) strategies to improve your visibility and attract more organic traffic.</p>\n"
      },
      {
        "text": "<p>Integrate Social Proof:</p>\n",
        "subtext": "<p>Include elements of social proof like customer testimonials, reviews, or case studies to build trust and credibility.</p>\n"
      },
      {
        "text": "<p>Set Up Analytics Tools:</p>\n",
        "subtext": "<p>Utilize analytics tools to track visitor behavior, engagement metrics, and other key performance indicators.</p>\n"
      },
      {
        "text": "<p>Iterate Based on Feedback and Analytics:</p>\n",
        "subtext": "<p>Regularly review feedback and analytics data to make informed adjustments to your platform, continually improving its appeal to your target audience.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Once you've chosen the best platform to reach your target customers, the next step is to build and optimize this platform. This involves creating a presence that appeals directly to your audience’s needs and preferences, ensuring that every aspect of your platform - from design to content - resonates with them.</p>\n"
    ]
  },
  "diyBuild2.5": {
    "id": "diyBuild2.5",
    "type": "simple",
    "title": "Every business starts with a story: Your unique advantage\n",
    "nextId": "diyBuild2.7",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Telling Your Story",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "build",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Discover Your Narrative:</p>\n",
        "subtext": "<p>Reflect deeply on what makes your entrepreneurial journey unique. Why did you embark on this venture? What sets you apart from others in your field? Understanding and articulating your motivation and uniqueness is the first step in leveraging your story as a competitive advantage.</p>\n"
      },
      {
        "text": "<p>Share Authentically:</p>\n",
        "subtext": "<p>Be genuine in sharing your experiences, challenges, and victories. Authenticity resonates with people, creating connections that transcend mere transactions.</p>\n"
      },
      {
        "text": "<p>Incorporate Your Story:</p>\n",
        "subtext": "<p>As you build your brand and online presence, weave your story into every aspect. From your website to your social media profiles, let your narrative inform and enhance your brand identity.</p>\n"
      },
      {
        "text": "<p>Engage and Evolve:</p>\n",
        "subtext": "<p>Your story isn't static. Share its evolution with your audience, including both the highs and the lows. This ongoing narrative fosters deeper engagement and loyalty.</p>\n"
      },
      {
        "text": "<p>Consistency is Key:</p>\n",
        "subtext": "<p>Ensure your story is consistently reflected across all platforms and interactions. Consistency builds trust and reinforces your brand message.</p>\n"
      },
      {
        "text": "<p>The Power of Personal Connection:</p>\n",
        "subtext": "<p>In an era where artificial intelligence can mimic human interactions, the authentic human connection your story provides is invaluable. It differentiates you in a world of increasingly sophisticated technology.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Your entrepreneurial journey is powered by a story uniquely yours, a narrative that can set you apart in the bustling marketplace. My own story—transforming from having a mere $2.26 in my bank account to achieving a net worth of $1.25 million before turning thirty—illustrates the potential of a compelling personal narrative. This journey, marked by strategic savings, investments, and the initiation of side hustles, wasn't propelled by formal education in finance or business. My unconventional appearance and approach made my story not just relatable but distinct and memorable. Your story, too, is your competitive edge.</p>\n"
    ]
  },
  "diyBuild2.7": {
    "id": "diyBuild2.7",
    "type": "simple",
    "title": "The essence of content in business success",
    "nextId": "diyBuild2.8",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Making Great Content",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "build",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Uncover Your Unique Voice:</p>\n",
        "subtext": "<p>Your content strategy should be a blend of precision and creativity. Research your target audience's needs to produce content that addresses those specific needs. Combine this with your unique voice to differentiate your brand and attract your ideal customers.</p>\n"
      },
      {
        "text": "<p>Balance Education and Entertainment:</p>\n",
        "subtext": "<p>Your content should inform and amuse. Striking this balance can turn casual readers into loyal fans.</p>\n"
      },
      {
        "text": "<p>Choose Your Medium Wisely:</p>\n",
        "subtext": "<p>Determine whether written, audio, or visual content suits your strengths and delivers value most effectively. Initially, focus on the medium that best leverages your capabilities.</p>\n"
      },
      {
        "text": "<p>Identify Your Ideal Customer:</p>\n",
        "subtext": "<p>Understand who you're speaking to. This clarity helps in tailoring content that resonates deeply with your audience.</p>\n"
      },
      {
        "text": "<p>Engage Through Stories:</p>\n",
        "subtext": "<p>Share your journey, struggles, and successes. Authenticity builds trust and makes your brand memorable.</p>\n"
      },
      {
        "text": "<p>Optimize for SEO:</p>\n",
        "subtext": "<p>Use keyword-rich titles and URLs. Ensure your content is easily discoverable by those who need it most.</p>\n"
      },
      {
        "text": "<p>Call to Action:</p>\n",
        "subtext": "<p>Encourage readers to take the next step — whether subscribing, purchasing, or exploring more content.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>In the realm of business, content reigns supreme. It's the bridge that connects you to your customers, helping to build your brand's competitive edge. Engaging in content marketing — providing high-value content for free — bolsters your brand's stature and expands your following organically</p>\n",
      "<p>Effective content not only satisfies Google's criteria for expertise, authority, and trustworthiness (E-E-A-T) but also captivates your audience, nurturing a bond with your brand even before they make a purchase.</p>\n"
    ]
  },
  "diyBuild2.8": {
    "id": "diyBuild2.8",
    "type": "simple",
    "title": "Crafting your sales page",
    "nextId": "diyBuild2.9",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Crafting Your Sales Page",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "build",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Highlight the Pain Point:</p>\n",
        "subtext": "<p>Start by identifying a common problem or need your target audience faces. This connection makes your audience feel understood and opens them up to your solution.</p>\n"
      },
      {
        "text": "<p>Balance Education and Entertainment:</p>\n",
        "subtext": "<p>Introduce your product or service as the answer to the pain point. Clearly state how it provides relief or fulfills a desire, making a direct link between the audience's need and your offer.</p>\n"
      },
      {
        "text": "<p>Detail the Offer:</p>\n",
        "subtext": "<p>Break down what your product or service includes, emphasizing its features and benefits. Make it clear why it's an unbeatable solution to their problem.</p>\n"
      },
      {
        "text": "<p>Provide Proof:</p>\n",
        "subtext": "<p>Share success stories, testimonials, and data that validate your product's effectiveness. This social proof reassures potential buyers that they're making a wise choice.</p>\n"
      },
      {
        "text": "<p>Tackle Objections Head-on:</p>\n",
        "subtext": "<p>Anticipate and address common concerns or questions upfront. A well-thought-out FAQ section can dismantle barriers to purchase.</p>\n"
      },
      {
        "text": "<p>Incentivize the Decision:</p>\n",
        "subtext": "<p>Enhance your offer with bonuses, limited-time discounts, or exclusive content. These extras can tip the balance for those on the fence.</p>\n"
      },
      {
        "text": "<p>Guide to Action:</p>\n",
        "subtext": "<p>Make the next steps crystal clear with a bold, impossible-to-miss call to action. Whether it’s “Buy Now,” “Sign Up,” or “Learn More,” ensure it stands out and directs them precisely where they need to go.</p>\n"
      },
      {
        "text": "<p>Build Urgency:</p>\n",
        "subtext": "<p>Incorporate elements of scarcity or time sensitivity to compel immediate action. A countdown timer or a note about limited availability can create a sense of urgency.</p>\n"
      },
      {
        "text": "<p>Offer Assurance:</p>\n",
        "subtext": "<p>A robust money-back guarantee can alleviate any lingering doubts, showing confidence in your product and prioritizing customer satisfaction.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Creating a sales page is a critical step in turning your site visitors into customers. This page is where you combine the art of persuasion with clear, compelling information to convince your audience that your product or service is the solution they’ve been seeking. A well-designed sales page not only presents your offer attractively but also addresses any hesitations your potential customers might have, making it easier for them to decide to buy.</p>\n"
    ]
  },
  "diyBuild2.9": {
    "id": "diyBuild2.9",
    "type": "simple",
    "title": "Engaging customers with email mastery\n",
    "nextId": "diyBuild3",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Email Mastery",
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "build",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Sign up for Convertkit:</p>\n",
        "subtext": "<p>Click <a href=\"https://convertkit.com/?lmref=wM-I_g\">here</a> to create your Convertkit account and start your email marketing journey.</p>\n"
      },
      {
        "text": "<p>Implement an Effective Capture Strategy:</p>\n",
        "subtext": "<p>Your website should be designed to encourage email sign-ups. Use strategically placed opt-in forms, pop-ups, and incentives like discounts or exclusive content to convert visitors into subscribers.</p>\n"
      },
      {
        "text": "<p>Offer a Compelling Incentive:</p>\n",
        "subtext": "<p>Entice visitors to subscribe by offering something of value. This could be a discount code, a free guide, or access to exclusive content. The aim is to provide a tangible benefit that makes giving up their email address worthwhile.</p>\n"
      },
      {
        "text": "<p>Craft an Engaging Welcome Series:</p>\n",
        "subtext": "<p>Set up automated email sequences that welcome new subscribers, introduce your brand, and provide valuable content. This initial interaction sets the tone for your ongoing communication.</p>\n"
      },
      {
        "text": "<p>Determine Your Email Frequency:</p>\n",
        "subtext": "<p>Find the right balance in how often you contact your subscribers. Too frequent, and you risk overwhelming them; too infrequent, and you may be forgotten. The key is consistent, valuable communication that respects your audience's inbox.</p>\n"
      },
      {
        "text": "<p>Deliver Unparalleled Value:</p>\n",
        "subtext": "<p>Focus on providing immense value in every email. Whether through insightful content, exclusive offers, or engaging stories, ensure each email enriches your subscribers' lives in some way.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Harnessing the power of email is a game-changer for connecting with and expanding your audience. With email, you have a direct and personal line to your current and prospective customers, allowing for targeted communication that nurtures and grows your relationship with them. A strong email strategy can transform casual visitors into loyal customers, continuously reinforcing your brand's presence in their lives.</p>\n"
    ]
  },
  "diyBuild3": {
    "id": "diyBuild3",
    "type": "simple",
    "title": "Soliciting valuable feedback: Engaging your network for insightful responses",
    "nextId": "diyBuild4",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Gather Feedback",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "build",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Identify Your Feedback Group:</p>\n",
        "subtext": "<p>Compile a list of potential feedback providers. This group should include friends, family, peers, and even acquaintances. The broader the range, the more varied and comprehensive the feedback will be.</p>\n"
      },
      {
        "text": "<p>Prepare Your Feedback Request:</p>\n",
        "subtext": "<p>Craft a message explaining your request for feedback. Be clear about your goals and the areas where you specifically want input. Encourage honesty and constructive criticism.</p>\n"
      },
      {
        "text": "<p>Distribute Your Platform Widely:</p>\n",
        "subtext": "<p>Share your platform with your feedback group through email, social media, or direct messaging. Make it as easy as possible for them to access and review your content.</p>\n"
      },
      {
        "text": "<p>Offer Guiding Questions:</p>\n",
        "subtext": "<p>Along with your request, provide a few guiding questions to help focus the feedback. Questions could relate to user experience, content quality, design, or any specific features you’re testing.</p>\n"
      },
      {
        "text": "<p>Create a Simple Feedback Mechanism:</p>\n",
        "subtext": "<p>Use tools like online surveys or forms to collect feedback systematically. This structure can make it easier for people to respond and for you to analyze the feedback.</p>\n"
      },
      {
        "text": "<p>Thank Them for Their Input:</p>\n",
        "subtext": "<p>Show gratitude to everyone who takes the time to review your platform and provide feedback. A simple thank you can go a long way in showing appreciation for their effort.</p>\n"
      },
      {
        "text": "<p>Review and Categorize Feedback:</p>\n",
        "subtext": "<p>Once you’ve collected feedback, take the time to review and categorize it. Look for common themes, suggestions, and areas for improvement.</p>\n"
      },
      {
        "text": "<p>Act on the Feedback:</p>\n",
        "subtext": "<p>Use the insights gained to make informed adjustments to your platform. Prioritize changes based on the impact and feasibility.</p>\n"
      },
      {
        "text": "<p>Follow Up with Updates:</p>\n",
        "subtext": "<p>After implementing changes, consider following up with those who provided feedback. Share the improvements you’ve made based on their suggestions. This not only shows that you value their input but also keeps them engaged with your platform’s progress. Encouraging a wide range of feedback is instrumental in shaping a platform that resonates with your intended audience and meets your strategic goals. Each piece of advice, each perspective, brings with it an opportunity to refine and perfect your platform.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>The journey of refining and improving your platform is significantly enhanced by gathering feedback. At this stage, reaching out to a wide array of individuals for their thoughts and opinions is not just beneficial — it's crucial for your growth and development.</p>\n",
      "<p>By casting a wide net and encouraging honest feedback, you open the door to diverse perspectives and invaluable insights that can shape the future direction and effectiveness of your platform. Remember, every piece of feedback is an opportunity to learn, adapt, and evolve.</p>\n"
    ]
  },
  "diyBuild4": {
    "id": "diyBuild4",
    "type": "simple",
    "title": "Establishing your LLC: A foundation for growth",
    "nextId": "diyLaunch1",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Setting up an LLC ",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "build",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Decide on Your LLC's Name:</p>\n",
        "subtext": "<p>Begin by choosing a unique name for your LLC, ensuring it’s not already in use by conducting a search in your state's business registry.</p>\n"
      },
      {
        "text": "<p>Understand the Benefits:</p>\n",
        "subtext": "<p>Familiarize yourself with the advantages of an LLC, such as personal asset protection, tax flexibility, and credibility with customers and financial institutions.</p>\n"
      },
      {
        "text": "<p>Register Your LLC:</p>\n",
        "subtext": "<p>Follow the process to officially register your LLC in your state, which may involve filing articles of organization and paying a filing fee.</p>\n"
      },
      {
        "text": "<p>Draft an Operating Agreement:</p>\n",
        "subtext": "<p>Although not always legally required, creating an operating agreement is crucial, especially if you have partners. This document outlines the LLC's operating procedures and ownership structure.</p>\n"
      },
      {
        "text": "<p>Obtain an EIN:</p>\n",
        "subtext": "<p>Register for an Employer Identification Number (EIN) from the IRS. This is essential for tax purposes and to open a business bank account.</p>\n"
      },
      {
        "text": "<p>Set Up Your Business Finances:</p>\n",
        "subtext": "<p>Open a business bank account and obtain a business credit card to keep your personal and business finances separate. This simplifies bookkeeping and tax filing.</p>\n"
      },
      {
        "text": "<p>Choose an Accounting System:</p>\n",
        "subtext": "<p>Implement an accounting system, like QuickBooks, to manage your finances efficiently. Consider professional setup to ensure everything is correctly categorized from the start.</p>\n"
      },
      {
        "text": "<p>Stay on top of annual filings and fees to keep your LLC in good standing. This may include an annual report and a modest fee, depending on your state.</p>\n",
        "subtext": "<p>Maintain Compliance:</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Setting up an LLC is a significant step towards legitimizing your business. It not only offers protection for your personal assets but also provides tax benefits and paves the way for future growth. As you begin generating consistent revenue or plan for expansion, transitioning from a sole proprietorship to an LLC is a strategic move that can safeguard and propel your business forward.</p>\n"
    ]
  },
  "diyLaunch1": {
    "id": "diyLaunch1",
    "type": "simple",
    "title": "Friends & Family ",
    "nextId": "diyLaunch2",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Family & Friends",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "launch",
    "playbookStageStep": 1,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Prepare Your Announcement:</p>\n",
        "subtext": "<p>Create a personal and heartfelt message introducing your side business. Share your motivation for starting it, its purpose, and your aspirations.</p>\n"
      },
      {
        "text": "<p>Highlight Your Unique Story:</p>\n",
        "subtext": "<p>Choose how to reach your friends and family. Options include email, social media, messaging apps, or in-person conversations.</p>\n"
      },
      {
        "text": "<p>Choose Your Communication Channels:</p>\n",
        "subtext": "<p>Consider the best way to reach your friends and family. This could be through email, social media, messaging apps, or even in-person conversations.</p>\n"
      },
      {
        "text": "<p>Provide Direct Links:</p>\n",
        "subtext": "<p>Provide easy access to your business (like a website or online store) by including direct links.</p>\n"
      },
      {
        "text": "<p>Ask for Feedback:</p>\n",
        "subtext": "<p>Encourage friends and family to offer honest feedback. Constructive criticism can be invaluable for improving your business.</p>\n"
      },
      {
        "text": "<p>Request Their Support:</p>\n",
        "subtext": "<p>Ask them to support you by following your business’s social media pages, subscribing to updates, and sharing your content with their networks.</p>\n"
      },
      {
        "text": "<p>Express Gratitude:</p>\n",
        "subtext": "<p>Thank your friends and family for their support. This can foster goodwill and encourage continued engagement.</p>\n"
      },
      {
        "text": "<p>Follow Up:</p>\n",
        "subtext": "<p>Keep your network updated on your progress. Regular updates keep them involved and interested in your business's growth.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Introducing your side business to friends and family is a pivotal moment in your FIPreneur journey. This initial group, typically your most enthusiastic supporters, can offer invaluable feedback and help lay the groundwork for your business's customer base. Presenting your business to those closest to you not only secures early support and engagement but also provides a chance to fine-tune your offerings based on their insights</p>\n"
    ]
  },
  "diyLaunch2": {
    "id": "diyLaunch2",
    "type": "simple",
    "title": "Social Posts ",
    "nextId": "diyScale1",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Social Media Posts",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "launch",
    "playbookStageStep": 2,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Choose Your Platform Wisely:</p>\n",
        "subtext": "<p>Decide on the best social media platforms for your announcement, based on where your target audience is most active.</p>\n"
      },
      {
        "text": "<p>Craft a Compelling Message:</p>\n",
        "subtext": "<p>Your announcement should go beyond just introducing your business. Share the story behind it, your inspiration, and your goals.</p>\n"
      },
      {
        "text": "<p>Be Authentic and Vulnerable:</p>\n",
        "subtext": "<p>Share your journey authentically, including both challenges and successes. This honesty can make your story more relatable and engaging.</p>\n"
      },
      {
        "text": "<p>Include Visuals:</p>\n",
        "subtext": "<p>Use eye-catching images or videos in your post, like a glimpse of your product or service, to capture attention.</p>\n"
      },
      {
        "text": "<p>Provide a Link to Your Platform:</p>\n",
        "subtext": "<p>Make sure to include a link to your business for easy access.</p>\n"
      },
      {
        "text": "<p>Encourage Engagement:</p>\n",
        "subtext": "<p>Invite your audience to check out your business and leave feedback. Ask them to share your announcement to broaden your reach.</p>\n"
      },
      {
        "text": "<p>Monitor and Respond:</p>\n",
        "subtext": "<p>Stay active in responding to any comments or messages. Prompt and thoughtful engagement helps build a solid community for your business.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Telling the world about your new side biz is a momentous step. It's your opportunity to introduce your passion project to a broader audience. Crafting an authentic and vulnerable post on social media and LinkedIn can resonate with a wider community, drawing in readers who connect with your story and your content.</p>\n"
    ]
  },
  "diyScale1": {
    "id": "diyScale1",
    "type": "simple",
    "title": "Pinpoint tour target audience's favorite hangouts",
    "nextId": "diyScale2",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Find Audience Spaces",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "scale",
    "playbookStageStep": 1,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Conduct Audience Research:</p>\n",
        "subtext": "<p>Deepen your understanding of your target audience’s habits, preferences, and behaviors, especially where they spend their time online and offline.</p>\n"
      },
      {
        "text": "<p>Identify Popular Platforms and Channels:</p>\n",
        "subtext": "<p>Determine which social media platforms, forums, websites, or physical locations are popular with your target demographic.</p>\n"
      },
      {
        "text": "<p>Explore Niche Communities:</p>\n",
        "subtext": "<p>Look for niche communities and groups where your target customers are likely to be active. These can include online forums, Facebook groups, or community events.</p>\n"
      },
      {
        "text": "<p>Monitor Competitors:</p>\n",
        "subtext": "<p>Observe where your competitors are engaging with their audience and consider these spaces for your own outreach.</p>\n"
      },
      {
        "text": "<p>Gather Insights from Existing Customers:</p>\n",
        "subtext": "<p>If you have an existing customer base, directly ask them or conduct surveys to find out where they spend their time and seek information.</p>\n"
      },
      {
        "text": "<p>Participate and Observe:</p>\n",
        "subtext": "<p>Engage in these platforms and spaces yourself. Observation and participation can provide valuable insights into customer behaviors and preferences.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Understanding where your target customers are most active and engaged is essential for effective marketing and community building. This step involves researching and pinpointing the online and offline spaces where your target audience congregates, interacts, and seeks information or entertainment.</p>\n"
    ]
  },
  "diyScale2": {
    "id": "diyScale2",
    "type": "simple",
    "title": "Strategize to engage effectively in customer-favored spaces",
    "nextId": "diyScale3",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Engagement Strategy",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "scale",
    "playbookStageStep": 2,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Evaluate Platform-Specific Opportunities:</p>\n",
        "subtext": "<p>Look at the unique features and engagement opportunities each platform or space offers. For example, Instagram might allow for influencer partnerships and visually appealing ads.</p>\n"
      },
      {
        "text": "<p>Consider Influencer Partnerships:</p>\n",
        "subtext": "<p>Identify influencers who resonate with your target audience and explore potential collaboration opportunities, such as sponsored posts or co-created content.</p>\n"
      },
      {
        "text": "<p>Plan Targeted Advertising Campaigns:</p>\n",
        "subtext": "<p>Develop ideas for targeted ads that would appeal to your audience on these platforms. Tailor your messaging and creative elements to align with their interests and behaviors.</p>\n"
      },
      {
        "text": "<p>Create Engaging Content:</p>\n",
        "subtext": "<p>Brainstorm content ideas that would captivate your audience. Think about formats, themes, and storytelling techniques that align with their preferences.</p>\n"
      },
      {
        "text": "<p>Explore Community Engagement:</p>\n",
        "subtext": "<p>Consider how you can actively participate in community discussions, events, or groups to build a presence and foster relationships.</p>\n"
      },
      {
        "text": "<p>Leverage SEO and Content Marketing:</p>\n",
        "subtext": "<p>For online spaces, think about how SEO and content marketing can be used to attract and engage your target customers.</p>\n"
      },
      {
        "text": "<p>Innovate with New Engagement Methods:</p>\n",
        "subtext": "<p>Stay open to experimenting with new and emerging trends in digital marketing and customer engagement.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Once you know where your target customers hang out, the next step is to brainstorm creative and effective strategies to engage with them in those spaces. This involves thinking innovatively about how to make your presence felt, whether it’s through partnerships, advertising, content creation, or community involvement.</p>\n"
    ]
  },
  "diyScale3": {
    "id": "diyScale3",
    "type": "simple",
    "title": "Evaluate and optimize your channels for maximum growth impact",
    "nextId": "diyScale4",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Channel Optimization",
    "videoVaultTags": [
      "diy1",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "scale",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Select Channels for Testing:</p>\n",
        "subtext": "<p>Based on your brainstorming, choose a variety of channels to test. These could include social media platforms, email marketing, influencer collaborations, paid advertising, SEO, or community engagement.</p>\n"
      },
      {
        "text": "<p>Define Success Metrics:</p>\n",
        "subtext": "<p>Establish clear metrics for measuring success on each channel. This could include engagement rates, conversion rates, traffic, or sales.</p>\n"
      },
      {
        "text": "<p>Create Channel-Specific Strategies:</p>\n",
        "subtext": "<p>Develop tailored strategies for each channel. Ensure that your content and approach align with the unique aspects of each channel.</p>\n"
      },
      {
        "text": "<p>Implement Small-Scale Tests:</p>\n",
        "subtext": "<p>Start with small-scale experiments to minimize risk. This could involve running short-term ad campaigns, posting different content types, or trying varied posting schedules.</p>\n"
      },
      {
        "text": "<p>Monitor and Collect Data:</p>\n",
        "subtext": "<p>Use analytics tools to monitor performance and collect data on each channel's effectiveness.</p>\n"
      },
      {
        "text": "<p>Analyze and Compare Results:</p>\n",
        "subtext": "<p>Analyze the data to understand which channels are performing best. Look for trends, patterns, and insights that can inform future strategies.</p>\n"
      },
      {
        "text": "<p>Adapt and Refine Strategies:</p>\n",
        "subtext": "<p>Based on your analysis, refine your approach to each channel. Focus more resources on the most effective channels and consider dropping or adjusting those that underperform.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Experimenting with different growth channels allows you to determine which ones are most effective for reaching and engaging your target audience. This step involves setting up tests, monitoring performance, and analyzing results to identify the most productive channels for your business.</p>\n"
    ]
  },
  "diyScale4": {
    "id": "diyScale4",
    "type": "simple",
    "title": "Boosting Organic Growth Through Paid Marketing Strategie",
    "nextId": "diyScale5",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Paid Marketing",
    "videoVaultTags": [
      "paidads",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "scale",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Analyze Paid Traffic Insights:</p>\n",
        "subtext": "<p>Utilize tools like Google Ads' search terms report to uncover valuable keywords that lead to conversions. This data can reveal untapped content opportunities directly related to your products or services.</p>\n"
      },
      {
        "text": "<p>Refine Organic Content:</p>\n",
        "subtext": "<p>Use the insights from paid campaigns to optimize your website's titles, meta descriptions, and content. Focus on increasing organic click-through rates by aligning your content strategy with the phrases and topics that resonate with your target audience.</p>\n"
      },
      {
        "text": "<p>Experiment with Marketing Messages:</p>\n",
        "subtext": "<p>Employ paid campaigns across Google and social media to test various messaging strategies. Identify which messages generate the most engagement and conversions, then integrate these successful elements across your digital presence.</p>\n"
      },
      {
        "text": "<p>Build and Optimize an Affiliate Program:</p>\n",
        "subtext": "<p>Develop an affiliate marketing program that rewards partners for driving sales to your business. This performance-based approach ensures you only pay for actual results, making it a highly efficient marketing strategy.</p>\n"
      },
      {
        "text": "<p>Engage in Strategic Partnerships:</p>\n",
        "subtext": "<p>Collaborate with platforms and influencers who share an affinity with your brand. These partnerships can extend your reach into new audience segments, leveraging the trust and community built by your partners.</p>\n"
      },
      {
        "text": "<p>Explore Traditional Media Opportunities:</p>\n",
        "subtext": "<p>While traditional media's impact may vary, strategic placements in newspapers, TV, and online publications can enhance your brand's visibility. Focus on creating compelling stories or showcasing unique products worth sharing.</p>\n"
      },
      {
        "text": "<p>Maintain Authenticity with Brand Recommendations:</p>\n",
        "subtext": "<p>When engaging in affiliate marketing, prioritize partnerships with brands you genuinely recommend. Authenticity resonates with customers and maintains the credibility of your recommendations.</p>\n"
      },
      {
        "text": "<p>Cultivate Media Relationships:</p>\n",
        "subtext": "<p>Build connections with journalists and bloggers relevant to your industry. Providing valuable story ideas or products for review can facilitate media coverage, leveraging their platforms to amplify your message.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Integrating paid marketing efforts with organic growth strategies can significantly amplify your business's visibility and sales. Leveraging insights from paid advertising to enhance organic search performance and building strategic partnerships through affiliate programs can drive substantial growth and efficiency in your marketing efforts.</p>\n"
    ]
  },
  "diyScale5": {
    "id": "diyScale5",
    "type": "simple",
    "title": "Automating Your Business: Creating Self-Sustaining Systems\n",
    "nextButtonLabel": "Mark as Completed",
    "moduleLabel": "Automating Your Business",
    "videoVaultTags": [
      "outsource",
      "diy2",
      "diy3"
    ],
    "isLockedOut": false,
    "isOnboardingOnly": false,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "scale",
    "playbookStageStep": 3,
    "playbookPct": 0,
    "steps": [
      {
        "text": "<p>Evaluate Your Business Processes:</p>\n",
        "subtext": "<p>Identify the repetitive tasks that consume your time each day. This could range from customer service responses to invoicing and billing. Recognizing these tasks is the first step towards automation.</p>\n"
      },
      {
        "text": "<p>Research Automation Tools:</p>\n",
        "subtext": "<p>Explore tools and software designed to streamline or fully automate these tasks. For instance, customer service can be enhanced with chatbots, while billing can be automated with accounting software.</p>\n"
      },
      {
        "text": "<p>Implement Your Chosen Solutions:</p>\n",
        "subtext": "<p>Once you've selected the tools, take the time to implement them into your business operations. This may require some initial setup and adjustment to align with your specific needs.</p>\n"
      },
      {
        "text": "<p>Monitor and Adjust:</p>\n",
        "subtext": "<p>After implementation, closely monitor the performance of these automated processes. Be ready to make adjustments as necessary to ensure they are achieving the desired efficiency gains.</p>\n"
      },
      {
        "text": "<p>Document Your Processes:</p>\n",
        "subtext": "<p>Create detailed documentation for each automated process. This ensures clarity and consistency, making it easier for you or anyone else to make adjustments in the future.</p>\n"
      },
      {
        "text": "<p>Plan for Scalability:</p>\n",
        "subtext": "<p>Consider how these automated systems will scale with your business. The goal is to build a framework that supports growth without requiring a proportional increase in manual effort or oversight.</p>\n"
      }
    ],
    "paragraphs": [
      "<p>Building a business that thrives independently is essential for any entrepreneur. Automating processes not only frees up your time but ensures that your business can operate smoothly without your constant involvement. This is crucial for maintaining a balance between work and personal life, allowing you to focus on strategic growth rather than getting bogged down in day-to-day tasks.</p>\n"
    ]
  },
  "freeIntro": {
    "id": "freeIntro",
    "type": "free-welcome",
    "nextId": "freeIdeaInterests",
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false
  },
  "freeIdeaInterests": {
    "id": "freeIdeaInterests",
    "type": "textarea",
    "nextId": "freeIdeaSkills",
    "nextButtonLabel": "Next",
    "bigText": "What do you love doing in your free time?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Hobbies & Passions",
    "helpText": "Example Answer: I love reading, exploring new hiking trails, and cooking Italian cuisine. ",
    "stepperSteps": [
      "Your Passions",
      "Your Skills",
      "Personal Pain Points",
      " Your Tailored Ideas"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": true,
    "playbook": "diy",
    "playbookStage": "idea",
    "playbookStageStep": 0,
    "playbookPct": 0,
    "paragraphs": [
      "<p>Share with us your hobbies or things that excite you. Whether it's painting, fitness, or gardening, understanding what lights you up can guide us to a side business idea that you'd truly love to pursue.</p>\n"
    ]
  },
  "freeIdeaSkills": {
    "id": "freeIdeaSkills",
    "type": "textarea",
    "nextId": "freeCommonIssuesQuestion",
    "bigText": "What's something special you're really good at?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Unique Skills",
    "helpText": "Example Answer: For work, I program, specifically, I develop mobile apps. I am also a relatively skilled painter. ",
    "stepperSteps": [
      "Your Passions",
      "Your Skills",
      "Personal Pain Points",
      " Your Tailored Ideas"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": true,
    "playbook": "diy",
    "playbookStage": "idea",
    "playbookStageStep": 0,
    "playbookPct": 0,
    "paragraphs": [
      "<p>Think about what makes you unique. It could be your cooking skills, tech knowledge, or a knack for DIY crafts. This special skill of yours could be the key to unlocking the perfect side business for you.</p>\n"
    ]
  },
  "freeCommonIssuesQuestion": {
    "id": "freeCommonIssuesQuestion",
    "type": "textarea",
    "nextId": "freeIdeaResults",
    "bigText": "What frustrations or challenges have you encountered in your daily life?",
    "footerText": "Sarah, our custom-built AI tool, turns your answers into feasible ideas. The more detailed you are, the better the results!",
    "moduleLabel": "Personal Pain Points",
    "helpText": "Example Answer: I hate feeling cold, I wish a self-heating scarf existed that's stylish and can keep me warm wherever I go. I also struggle with keeping my plants alive, so a smart, self-watering plant pot would be pretty great.",
    "stepperSteps": [
      "Your Passions",
      "Your Skills",
      "Personal Pain Points",
      " Your Tailored Ideas"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": true,
    "playbook": "diy",
    "playbookStage": "idea",
    "playbookStageStep": 1,
    "playbookPct": 1,
    "paragraphs": [
      "<p>Think about moments when something irritated or hindered you because the right solution didn't seem to exist. It could be a struggle to find a particular home accessory, a gadget that could simplify tasks, or an environmentally friendly alternative to a common product.</p>\n"
    ]
  },
  "freeIdeaResults": {
    "id": "freeIdeaResults",
    "type": "simple-chat-gpt-integration",
    "title": "Tailored Ideas Just for You",
    "nextId": "freeIdeaEntry",
    "nextButtonLabel": "I have an idea",
    "moduleLabel": "Your Ideas",
    "gptModelId": "ft:gpt-3.5-turbo-1106:topia:free-general-v1:9CEPC47m",
    "stepperSteps": [
      "Your Passions",
      "Your Skills",
      "Personal Pain Points",
      " Your Tailored Ideas"
    ],
    "stepperCurrent": 1,
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "idea",
    "playbookStageStep": 4,
    "playbookPct": 4,
    "paragraphs": [
      "<p>Select the idea that resonates most with you.</p>\n",
      "<p>There’s no need to sweat the small stuff right now. In the next phase, we’ll help you refine your idea into a winning side business</p>\n"
    ],
    "includedModuleIds": [
      "freeIdeaInterests",
      "freeIdeaSkills",
      "freeCommonIssuesQuestion"
    ],
    "systemPrompt": "Your name is Sarah, and you're an idea generation specialist at the FIpreneur community. Your task today is to help someone brainstorm side hustle ideas. This person has specific skills and interests, and we're looking to leverage these in finding the right niche product opportunities. We aim to avoid saturated markets, so the ideas should focus on niche opportunities with potential for growth and uniqueness. For each  idea, please also include an enthusiastic estimate of how much money they could potentially earn each month from these ventures. YOUR OUTPUT SHOULD BE FORMATTED AS VALID HTML FOLLOWING THIS TEMPLATE: <ol><li><h4>{IDEA TITLE}</h4><p>{IDEA DESCRIPTION}</p></li></ol>",
    "userInputPrefix": "Can you please generate 5 to 10 side business ideas based on my information?"
  },
  "freeIdeaEntry": {
    "id": "freeIdeaEntry",
    "type": "textarea",
    "nextId": "freePaywall",
    "bigText": "Tell us about your idea",
    "footerText": "Ready to turn this idea into a recurring income stream? ",
    "moduleLabel": "Your Ideas",
    "stepperSteps": [
      "Your Passions",
      "Your Skills",
      "Personal Pain Points",
      " Your Tailored Ideas"
    ],
    "stepperCurrent": 2,
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false,
    "playbook": "diy",
    "playbookStage": "validate",
    "playbookStageStep": 1,
    "playbookPct": 1,
    "paragraphs": [
      "<p>Give us a brief summary of your product idea.</p>\n"
    ]
  },
  "freePaywall": {
    "id": "freePaywall",
    "type": "free-paywall",
    "isLockedOut": true,
    "isOnboardingOnly": true,
    "noBackButton": false,
    "skippable": false
  }
};