export function IdeaGenPrimer() {
  return (
    <div>
      <div className="flex mt-8 gap-3 flex-col md:flex-row md:justify-center  md:w-[80%] mx-auto md:gap-8">
        <div className="flex flex-1 flex-col  md:gap-4 shadow-md bg-neutral-100 p-2 md:p-4 rounded-md">
          <p className="text-lg text-center font-bold text-topia-black">
            Step 1: Idea Generation
          </p>
          <img
            src="/sarah-waving.png"
            className="w-[160px] h-auto rounded-full mx-auto"
          />
          <p className="text-md text-topia-black">
            Sarah, our custom FIpreneur AI will craft unique, tailored ideas for
            you
          </p>
        </div>
        <div className="flex flex-1 justify-between flex-col md:gap-4 shadow-md bg-pale-yellow p-2 md:p-4 rounded-md">
          <p className="text-lg text-center font-bold text-topia-black">
            Step 2: Validate
          </p>
          <div className="flex flex-col justify-center flex-1 w-full md:flex-row items-center gap-4">
            <img
              src="/grant.jpg"
              className="w-[50%] shadow-md h-auto rounded-full"
            />
          </div>
          <p className="text-md text-topia-black">
            Work directly with Grant &amp; our coaches to make sure your idea is
            a winner
          </p>
        </div>
      </div>
    </div>
  );
}
