import { format } from "path";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth";
import * as Sentry from "@sentry/react";

import Select from "react-select";
import {
  Card,
  TopiaButton,
  // TopiaStoryblokComponent,
  TopiaTextInput,
  defaultStoryblokConfig,
} from "@topia-app/topia-react-web";
import TBSideHustlePage from "../components/PlaybookIntro";
import {
  StoryblokComponent,
  getStoryblokApi,
  useStoryblokState,
} from "@storyblok/react";
import { useDataContext } from "../context/DataContext";
import { BiRightArrowAlt, BiCheck } from "react-icons/bi";
import { OnboardingSteps } from "../components/OnboardingSteps";
import { mixpanelTrack } from "../helpers";
import { VIDEO_VAULT } from "../video-vault-data";
import classNames from "classnames";
import { TagIcon } from "@heroicons/react/24/solid";
import { TagIcon as OutlineTagIcon } from "@heroicons/react/24/outline";

export function VideoLibraryPage() {
  const [searchVal, setSearchVal] = useState("");
  const [secondaryVal, setSecondaryVal] = useState<
    "all" | "idea" | "build" | "validate" | "scale"
  >("all");
  const [tagVal, setTagVal] = useState("all");
  const [searchLoading, setSearchLoading] = useState(true);

  useEffect(() => {
    if (tagVal === "all" || tagVal === "financial independence") {
      setSecondaryVal("all");
    }
  }, [tagVal]);

  useEffect(() => {
    setSearchLoading(true);
    const t = setTimeout(() => {
      setSearchLoading(false);
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, [searchVal, tagVal]);

  useEffect(() => {
    mixpanelTrack("page_view_home");
  }, []);

  const primaryDistinctTags = [
    "ecommerce",
    "influencer",
    "blogging",
    "financial independence",
  ];

  const distinctTags = [
    ...new Set(
      VIDEO_VAULT.flatMap((x) => x.tags).filter(
        (x) => x.length > 0 && primaryDistinctTags.includes(x)
      )
    ),
  ];

  const secondaryTags = [
    ...new Set(
      VIDEO_VAULT.flatMap((x) => x.tags).filter(
        (x) => x.length > 0 && !primaryDistinctTags.includes(x)
      )
    ),
  ];

  const selectorOptions = ["all", ...secondaryTags]
    .filter((x) => x !== "content")
    .map((tag) => ({
      value: tag,
      label: tag.toLocaleUpperCase(),
    }));

  return (
    <div className="p-4 pb-12 xl:p-8">
      <div className="flex flex-col  gap-4 mb-4 border-b p-4 border-white items-center">
        <h1 className="text-2xl text-white flex-1">
          The FIpreneur Video Vault
        </h1>
        <div className="flex flex-col gap-2 w-full md:flex-row md:justify-between">
          <div className="flex flex-row self-end pb-2  flex-wrap items-center gap-2">
            {distinctTags.map((x) => (
              <div
                className={classNames(
                  "cursor-pointer hover:bg-rgb-blue/70 p-2 py-1 rounded-full capitalize",
                  tagVal === x
                    ? "bg-rgb-blue text-white"
                    : "bg-black text-white opacity-70"
                )}
                onClick={() => {
                  setTagVal((prev) => {
                    if (prev === x) {
                      return "all";
                    } else {
                      return x;
                    }
                  });
                }}
                key={x}
              >
                {tagVal === x ? (
                  <TagIcon className="h-4 w-4 inline-block mr-1" />
                ) : (
                  <OutlineTagIcon
                    className="h-4 w-4 inline-block mr-1"
                    color="white"
                  />
                )}
                <span>{x}</span>
              </div>
            ))}
          </div>
          <TopiaTextInput
            label="Search"
            className=" w-full md:max-w-[400px]"
            inputProps={{
              value: searchVal,
              onChange: (e) => setSearchVal(e.target.value),
            }}
          />
        </div>
      </div>
      {tagVal !== "financial independence" && tagVal !== "all" && (
        <div className="md: max-w-[400px] mt-2 mb-6">
          <p className={`text-white text-md font-bold`}>Playbook Stage</p>
          <Select
            isSearchable={false}
            value={secondaryVal}
            //@ts-ignore
            options={selectorOptions}
            onChange={(val) => {
              //@ts-ignore
              setSecondaryVal(val.value);
            }}
            placeholder={secondaryVal.toLocaleUpperCase()}
            // placeholder={
            //   DASHBOARD_MODULES[currentModuleId].moduleLabel ||
            //   DASHBOARD_MODULES[currentModuleId].title
            // }
            styles={{
              groupHeading: (base, { data }) => {
                return {
                  ...base,
                  color: "black",
                  fontSize: 30,
                };
              },
              placeholder: (base) => ({ ...base, color: "black" }),
              option: (base, { data, label, type }) => {
                return {
                  ...base,
                  //@ts-ignore
                  fontStyle: data.value === secondaryVal ? "italic" : "normal",
                  //@ts-ignore
                  backgroundColor: data.value === secondaryVal ? "#d4cecd" : "",
                  ":hover": { backgroundColor: "rgb(135,206,250,.4)" },
                };
              },
              menu: (base, props) => ({
                backgroundColor: "white",
                position: "absolute",
                width: "100%",
                zIndex: 60,
              }),
            }}
          />
        </div>
      )}
      {searchLoading ? (
        <div className="w-full h-[400px] flex items-center justify-center">
          <div className="lds-ripple white">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="md:grid md:grid-cols-4 flex flex-col gap-4">
          {VIDEO_VAULT.filter(
            (x) =>
              (tagVal === "all" || x.tags.includes(tagVal)) &&
              (searchVal.length === 0 ||
                x.title.toLowerCase().indexOf(searchVal.toLowerCase()) > -1)
          )
            .filter(
              (video) =>
                secondaryVal === "all" || video.tags.includes(secondaryVal)
            )
            // .slice(0, 9)
            .map((v, ix) => (
              <div
                key={ix}
                className="flex-1 topia-fade-in bg-neutral-800 p-2 rounded-md"
                // style={{
                //   boxShadow: "4px 4px 0px #E0FEA1 ",
                // }}
              >
                <h3 className="text-white text-md mb-2">{v.title}</h3>

                <div className="flex w-full items-center justify-center">
                  <iframe
                    src={`https://share.descript.com/embed/${v.filename}`}
                    width="100%"
                    height="200"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
