import { PortalModule } from "../module-types";

export function StepsConfig(props: { config: PortalModule }) {
  return (
    <div className="bg-neutral-100 p-4 rounded-md mt-4 shadow-md">
      <h3 className="text-2xl text-topia-black">Your Guided Steps</h3>
      <ol className="mx-auto max-w-5xl mt-4 mb-10 pl-8 list-decimal text-xl">
        {props.config.steps.map((x) => (
          <li key={x.text} className="mb-4">
            <p
              className="text-xl font-bold text-topia-black"
              dangerouslySetInnerHTML={{
                __html: x.text,
              }}
            ></p>
            <p
              className="text-md text-topia-black"
              dangerouslySetInnerHTML={{
                __html: x.subtext,
              }}
            ></p>
          </li>
        ))}
      </ol>
    </div>
  );
}
