import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  createRoutesFromElements,
  Navigate,
  RouteProps,
} from "react-router-dom";
import { useAuthContext } from "./auth";
import { ApplicationShell } from "./components/ApplicationShell";
import { ErrorPage } from "./pages/ErrorPage";
import { LoginPage } from "./pages/LoginPage";
import ForgotPassWordPage from "./pages/ForgotPasswordPage";
import { HomePage } from "./pages/HomePage";
import { PlaybookEmbed } from "./pages/PlaybookEmbed";
import { AdminPage } from "./pages/AdminPage";
import { UserPage } from "./pages/UserPage";
import { WeeklyCallsPage } from "./pages/WeeklyCallsPage";
import { GrantPlaybookPage } from "./pages/GrantPlaybookPage";
import { CommunityInfoPage } from "./pages/CommunityInfoPage";
import { SignUpPage } from "./pages/SignUpPage";
import { VideoLibraryPage } from "./pages/VideoLibrary";
import { InnerCirclePitchPage } from "./pages/InnerCirclePitchPage";
import { InnerCircleMembersPage } from "./pages/InnerCircleMembersPage";
import { AskGrantPage } from "./pages/AskGrantPage";
import CoachesPage from "./pages/CoachesPage";
import { AskGrantWrittenPage } from "./pages/AskGrantWritten";
import FreeAccountSignUpPage from "./pages/FreeAccountSignUpPage";
import FreeAccountConverted from "./pages/FreeAccountConvertedPage";

function ProtectedRoute(props: { children: any }) {
  const authCtx = useAuthContext();
  // const globalLoading = useIsLoadingGlobal();

  if (authCtx.loading) {
    return <div className="text-white text-center text-2xl">Loading...</div>;
  }

  if (!authCtx.loading && !authCtx.user) {
    return <Navigate to="/login" />;
  }

  return props.children;
}

function ApplicationRoute(children: JSX.Element) {
  return (
    <ProtectedRoute>
      <ApplicationShell>{children}</ApplicationShell>
    </ProtectedRoute>
  );
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        errorElement={<ErrorPage />}
        path="/login"
        element={<LoginPage />}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/__fipreneur-community-sign-up-56e012a8-5a26-49c5-83fe-f4d1e05a5c95"
        element={<SignUpPage />}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/find-your-idea"
        element={<FreeAccountSignUpPage />}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/on-upgrade-from-free-tier-f77cd368-1e42-497c-8103-fce1e63057b4"
        element={
          <ProtectedRoute>
            <FreeAccountConverted />
          </ProtectedRoute>
        }
      />
      <Route
        errorElement={<ErrorPage />}
        path="/__inner-circle"
        element={ApplicationRoute(<InnerCircleMembersPage />)}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/forgot-password"
        element={<ForgotPassWordPage />}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/"
        element={ApplicationRoute(<HomePage />)}
      />

      <Route
        errorElement={<ErrorPage />}
        path="/playbook/:playbook"
        element={ApplicationRoute(<PlaybookEmbed />)}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/playbook/:slug/:page"
        element={ApplicationRoute(<PlaybookEmbed />)}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/admin"
        element={ApplicationRoute(<AdminPage />)}
      />

      <Route
        errorElement={<ErrorPage />}
        path="/ask-grant-a-question"
        element={ApplicationRoute(<AskGrantPage />)}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/ask-grant-a-question/written"
        element={ApplicationRoute(<AskGrantWrittenPage />)}
      />

      <Route
        errorElement={<ErrorPage />}
        path="/join-the-inner-circle"
        element={ApplicationRoute(<InnerCirclePitchPage />)}
      />

      <Route
        errorElement={<ErrorPage />}
        path="/weekly-calls"
        element={ApplicationRoute(<WeeklyCallsPage />)}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/grants-financial-freedom-course"
        element={ApplicationRoute(<GrantPlaybookPage />)}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/community-info"
        element={ApplicationRoute(<CommunityInfoPage />)}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/video-vault"
        element={ApplicationRoute(<VideoLibraryPage />)}
      />
      <Route
        errorElement={<ErrorPage />}
        path="/admin/:slug"
        element={ApplicationRoute(<UserPage />)}
      />

      <Route
        errorElement={<ErrorPage />}
        path="/your-coaches"
        element={ApplicationRoute(<CoachesPage />)}
      />
      <Route errorElement={<ErrorPage />} path="*" element={<LoginPage />} />
    </>
  )
);
