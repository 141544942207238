import classNames from "classnames";
import { useState, useEffect } from "react";

export function DummyLoader(props: {
  children: any;
  key: string;
  classNames?: any;
  rippleClassNames?: any;
  loaderClassNames?: any;
}) {
  const [dummyLoader, setDummyLoader] = useState(true);

  useEffect(() => {
    setDummyLoader(true);
    setTimeout(() => {
      setDummyLoader(false);
    }, 400);
  }, [props.key]);

  if (dummyLoader) {
    return (
      <div
        className={classNames(
          "flex items-center justify-center py-32 w-full",
          props.loaderClassNames
        )}
      >
        <div className={classNames("lds-ripple", props.rippleClassNames)}>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames("topia-fade-in w-full", props.classNames)}>
      {props.children}
    </div>
  );
}
