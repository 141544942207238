import { useEffect, useState } from "react";
import { useDataContext } from "../context/DataContext";
import { TopiaButton, TopiaLoader } from "@topia-app/topia-react-web";
import { useNavigate } from "react-router-dom";
import { RippleLoader } from "../components/RippleLoader";
import { useModalContext } from "../context/ModalContext";

export function InnerCircleMembersPage(props: {}) {
  const dataCtx = useDataContext();
  const modalCtx = useModalContext();

  const nav = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dataCtx.updateUser({
      isInTheInnerCircle: true,
    });
  }, []);

  if (loading) {
    return (
      <div className="p-4">
        <RippleLoader rippleClassNames="white" />
      </div>
    );
  }

  return (
    <div className="p-4 bg-white max-w-6xl m-4 my-8">
      <h1 className="text-5xl my-8 text-center text-topia-black">
        Welcome to Grant's Inner Circle
      </h1>
      <div className="w-full p-4">
        {/* <p className="text-2xl font-bold mb-8 px-8 text-topia-black text-center">
        Accelerate Your Success in Grant's Inner Circle
      </p> */}
        <div className="relative z-10 flex flex-col items-center md:flex-1 md:flex-row gap-4 md:justify-center">
          <div className="md:mr-8 flex flex-col items-center">
            <div className="w-[200px] h-[200px]">
              <img
                src="/grant.jpg"
                className="w-full h-auto rounded-full shadow-md"
              />
            </div>
            <p className="text-lg mt-2 mb-2 font-black text-center text-topia-black">
              Grant Sabatier
            </p>
            <ul className="list-disc flex-col gap-2 text-xs">
              <li>International best selling author</li>
              <li>Generates $1M+ in Annual Revenue from 8x businesses</li>
              <li>Sold The 'Millennial Money' Blog for an 8-Figure Sum</li>
            </ul>
          </div>
          <div>
            <ol className="text-lg md:m-0 mt-4 leading-6 text-topia-black list-decimal">
              <li className="mb-1">
                <strong>Personalized Feedback:</strong>&nbsp; Work directly with
                Grant to build and grow your business, he'll be there to help
                you at every step from idea all the way through scaling to a six
                figure+ business.
              </li>
              <li className="mb-1">
                <strong>Regular Check-ins with Grant</strong>&nbsp; Utilize this
                opportunity to interact directly with Grant in our weekly calls.
                Discuss your progress, get your questions answered, and stay
                aligned with your goals.
              </li>
              <li className="mb-1">
                <strong>Access to Expert Resources:</strong>&nbsp; As part of
                Grant's circle, tap into an exclusive network of tools and
                contacts, from cutting edge SEO solutions to top-tier
                consultants, all to enhance your personal growth.
              </li>
              <li className="mb-1">
                <strong>In-person event in NYC:</strong> A truly transformative
                experience with Grant, hear from keynote speakers, connect with
                high-achieving FIpreneurs, and accelerate your journey.{" "}
              </li>
            </ol>
            <div className="grid grid-cols-1 md:grid-cols-2 mt-8 gap-4">
              <a
                href="/ask-grant-a-question"
                className="p-4 bg-pale-yellow font-lg text-topia-black border font-bold border-topia-black rounded-full text-center"
              >
                Ask Grant Your Question
              </a>
              <button
                onClick={() => {
                  modalCtx.prompt({
                    title: "Are you sure you want to downgrade?",
                    body: (
                      <div className="p-4">
                        <p className="text-2xl text-topia-black">
                          Are you sure?
                        </p>
                        <p className="text-lg text-topia-black">
                          You will lose access to the following features:
                        </p>
                        <ul
                          role="list"
                          className="my-4 space-y-4 text-lg leading-4 text-topia-black list-disc"
                        >
                          <li className=" gap-x-3">
                            Customized Business Roadmap
                          </li>
                          <li className=" gap-x-3">
                            Unlimited Direct Access to Grant for Personalized
                            Advice &amp; Support{" "}
                          </li>
                          <li className=" gap-x-3">
                            Access to Exclusive Inner Circle Weekly Calls
                          </li>
                          <li className=" gap-x-3">
                            Access to Grant’s invite only in-Person event in NYC
                          </li>
                        </ul>
                      </div>
                    ),
                    actions: [
                      {
                        label: "Downgrade to standard membership",
                        className: "bg-red-300",
                        onClick: async () => {
                          modalCtx.closeModal();
                          setLoading(true);
                          await dataCtx.updateUser({
                            isInTheInnerCircle: false,
                          });
                          nav("/join-the-inner-circle");
                        },
                      },
                      {
                        label: "Stay in the inner circle",
                        onClick: async () => {
                          modalCtx.closeModal();
                        },
                      },
                    ],
                  });
                }}
                className="text-red-600 text-xs underline p-4 border-red-600 rounded-full border md:truncate"
              >
                Return to the standard FIpreneurs subscriptions - $79/month
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
