import React from "react";
import mixpanel from "mixpanel-browser";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  TBContainer,
  defaultStoryblokConfig,
  topiaBlockClassnames,
} from "@topia-app/topia-react-web";
import App from "./App";
import {
  RichTextSchema,
  StoryblokComponent,
  apiPlugin,
  getStoryblokApi,
  renderRichText,
  storyblokEditable,
  storyblokInit,
} from "@storyblok/react";
import TBSideHustlePage from "./components/PlaybookIntro";
import { TBChatBot } from "./components/TBChatBot";
import { TBCalculator } from "./components/TBCalculator";
import { TBAccordion } from "./components/TBAccordion";
import { TBYoutubeEmbed } from "./components/TBYoutubeEmbed";
import { TBCarousel } from "./components/TBCarousel";
import _ from "lodash";
import { enrichISbRichtext } from "./helpers";

try {
  storyblokInit({
    // ...defaultStoryblokConfig,
    components: {
      image: ({ blok }) => {
        return (
          <img
            className={`${blok.className}`}
            {...storyblokEditable(blok)}
            src={blok.image.filename}
            width={blok.width || undefined}
            height={blok.height || undefined}
            alt={blok.image.alt}
          />
        );
      },
      sidehustle_page: TBSideHustlePage,
      chatbot: TBChatBot,
      section: ({ blok }) => {
        let clipPath = "";
        if (blok.clip_path) {
          if (blok.clip_path === "bottom-edge-1") {
            clipPath =
              "polygon(0 0, 100% 0, 100% 30%, 100% 96%, 38% 98%, 0 93%, 0 73%, 0 16%)";
          }
        }
        return (
          <div
            className={topiaBlockClassnames(blok)}
            style={{
              clipPath,
            }}
            {...storyblokEditable(blok)}
          >
            {blok.body.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        );
      },
      youtube_embed: TBYoutubeEmbed,
      container: ({ blok }) => (
        <div
          className={`${topiaBlockClassnames(
            blok
          )}  mx-auto w-full max-w-5xl px-4 xl:px-0 `}
          {...storyblokEditable(blok)}
        >
          {blok.body.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      ),
      fipreneur_lego: ({ blok }) => (
        <div>
          {blok.body.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      ),
      grid: ({ blok }) => (
        <div
          className={`md:grid md:grid-cols-${
            blok.columns.length
          } gap-4 ${topiaBlockClassnames(blok)}`}
          {...storyblokEditable(blok)}
        >
          {blok.columns.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      ),
      flexbox: ({ blok }) => {
        const prefix = blok.min_flex_level ? `${blok.min_flex_level}:` : "lg:";

        return (
          <div
            className={`flex flex-${
              blok.mobile_flex_direction || blok.flex_direction
            } ${prefix}flex-${
              blok.flex_direction || "row"
            } ${topiaBlockClassnames(blok)}`}
            {...storyblokEditable(blok)}
          >
            {blok.children.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        );
      },
      carousel: ({ blok }) => <TBCarousel blok={blok} />,
      community_cta: () => <></>,
      h1: ({ blok }) => (
        <h1
          className={`text-3xl ${topiaBlockClassnames(blok)}`}
          {...storyblokEditable(blok)}
          dangerouslySetInnerHTML={{ __html: renderRichText(blok.text) }}
        ></h1>
      ),
      h2: ({ blok }) => (
        <h2
          className={`text-2xl ${topiaBlockClassnames(blok)}`}
          {...storyblokEditable(blok)}
          dangerouslySetInnerHTML={{ __html: renderRichText(blok.text) }}
        ></h2>
      ),
      h3: ({ blok }) => (
        <h3
          className={`text-xl ${topiaBlockClassnames(blok)}`}
          {...storyblokEditable(blok)}
          dangerouslySetInnerHTML={{ __html: renderRichText(blok.text) }}
        ></h3>
      ),
      h4: ({ blok }) => (
        <h4
          className={`text-lg ${topiaBlockClassnames(blok)}`}
          {...storyblokEditable(blok)}
          dangerouslySetInnerHTML={{ __html: renderRichText(blok.text) }}
        ></h4>
      ),
      h5: ({ blok }) => (
        <h5
          className={`text-lg ${topiaBlockClassnames(blok)}`}
          {...storyblokEditable(blok)}
          dangerouslySetInnerHTML={{ __html: renderRichText(blok.text) }}
        ></h5>
      ),
      calculator: ({ blok }) => <TBCalculator blok={blok} />,
      accordion: ({ blok }) => <TBAccordion blok={blok} />,
      content: ({ blok }) => (
        <div
          className={`${topiaBlockClassnames(blok)} topia-sb-rich-text`}
          {...storyblokEditable(blok)}
          dangerouslySetInnerHTML={{
            __html: renderRichText(enrichISbRichtext(blok.content)),
          }}
        ></div>
      ),
      // content: defaultStoryblokConfig.components.content,
      // sidehustle_page: ({ blok }) => (
      //   <>
      //     <div>
      //       {blok.left_hand_content?.map((content) => (
      //         <StoryblokComponent blok={content} key={content._uid} />
      //       ))}
      //     </div>
      //   </>
      // ),
      // community_cta: () => <></>,
      // sidehustle_page: ({ blok }) => <pre>{JSON.stringify(blok, null, 2)}</pre>,
    },
    apiOptions: {
      region: "us",
    },
    use: [apiPlugin],
    accessToken: "5m3jtLk6c0kVoQhRimpQDAtt",
  });
  // console.log("SB initialized", getStoryblokApi());
} catch (ex) {
  console.error("Error initializing storyblok");
}

try {
  if (
    process.env["REACT_APP_ENV"] === "staging" ||
    process.env.NODE_ENV === "development"
  ) {
    mixpanel.init("55e9da717a3ae335db20c4a154010989", {
      debug: true,
    });
  } else {
    mixpanel.init("679e433865d0f7e1f839189aaf812560", {
      debug: false,
    });
  }
} catch (ex) {
  console.log("Error initializing mixpanel");
  console.error(ex);
}

// try {
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     integrations: [new BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// } catch (ex) {
//   console.log("Error initializing sentry");
//   console.error(ex);
// }

// try {
//   initStoryblok();
// } catch (ex) {
//   console.log("Error initializing storyblok");
//   console.error(ex);
//   try {
//     Sentry.captureException(ex);
//   } catch {}
// }

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
