import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { DbChatGptSession } from "../types";
import classNames from "classnames";
import { useDataContext } from "../context/DataContext";
import { DASHBOARD_MODULES } from "../module-data";

export function ChatGPTIntegration(props: {
  session: DbChatGptSession;
  onGetNewResults: () => void;
  nextId: string;
  nextLabel: string;
  onNext: (nextId: string, text: string) => void;
  activePlaybook?: "fba" | "diy" | "content" | "influencer";
}) {
  const dataCtx = useDataContext();
  const activePlaybook =
    DASHBOARD_MODULES[dataCtx.user.currentModuleId]?.playbook;
  const isFreeAccount = !dataCtx.user.name && dataCtx.user.created_free_account;

  return (
    <div className="mt-16 md:mt-10">
      <div className="relative bg-slate-300 ring ring-future-blue rounded-3xl mb-2 mt-4 shadow-md border-2  border-rgb-blue">
        {(props.session?.gptResponse || props.session?.error) && (
          <div className="flex flex-row justify-between items-center absolute -top-14 md:-top-12 -right-4 md:-right-8">
            <div
              onClick={() => {
                props.onGetNewResults();
              }}
              className="flex z-20 flex-col items-end cursor-pointer hover:animate-none transition-all scale-100 hover:scale-110  hover:opacity-70 pt-2"
            >
              <div
                className={`${!props.session.error ? "animate-bounce" : ""}`}
              >
                <p className="bg-blue-300 text-[12px] md:text-sm font-bold rounded-md p-1">
                  Get new results
                </p>
              </div>
              <div className="bg-topia-black rounded-full mr-4  p-1 flex items-center justify-center w-[60px] h-[60px] shadow-md">
                <ArrowPathIcon width={40} height={40} color={"#ffffff"} />
              </div>
            </div>
          </div>
        )}
        <div className="h-[66vh] md:h-[50vh] overflow-y-scroll pt-6 p-4 pb-20">
          {props.session?.error && (
            <>
              <p className="text-red-500">
                {props.session?.error ||
                  "Oops! An error has occured. Sarah must not be feeling well. Our team is on it, please try again later by refreshing the page."}
              </p>
            </>
          )}
          {props.session?.gptResponse && (
            <div
              className="text-topia-black  sarah-gpt-response"
              ref={async (x) => {
                if (!x) return;
                x.addEventListener("click", async (ev) => {
                  let el = ev.target as HTMLElement;
                  while (el.tagName.toLowerCase() !== "li") {
                    if (!el.parentElement) return;
                    el = el.parentElement!;
                  }

                  const text = el.innerHTML.trim().replace(/<[^>]*>/g, "");
                  props.onNext(props.nextId, text);
                });
              }}
              dangerouslySetInnerHTML={{
                __html:
                  dataCtx.user.flow === "UK_canopy_rent"
                    ? props.session.gptResponse.replace(/\$/g, "£")
                    : props.session.gptResponse,
              }}
            ></div>
          )}
          {!props.session?.gptResponse && !props.session?.error && (
            <div className="flex flex-col gap-4 py-16 items-center justify-center">
              <div className="flex flex-row gap-4 items-center">
                <div className={classNames("flex items-center justify-center")}>
                  <div className="lds-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <p className="text-md text-center text-topia-black">
                  Generating Business Ideas....
                </p>
              </div>
              <div className="py-4 w-full">
                <div className="w-full h-8 border border-topia-black shadow shadow-md bg-topia-black overflow-hidden rounded-full relative">
                  <div className="bg-future-blue absolute left-0 top-0 bottom-0 h-full sarah-loading-bar-inner"></div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className=" p-4 flex flex-row pl-[25%] rounded-bl-3xl rounded-br-3xl bg-topia-black hidden md:flex">
          <p className="text-sm text-white">
            Using your answers and my insights into market needs & trends, here
            are 10 personalized side business ideas. Want to see more? Hit
            refresh for some new ideas.
          </p>
        </div>
        <img
          src="/sarah-waving.png"
          className="absolute -bottom-0 -left-12 w-[40%] h-auto opacity-100 hidden md:block"
        />
      </div>
      <div
        className={`w-full p-4 pt-16 pb-8 -mt-12 flex flex-col ${
          isFreeAccount ? "" : "md:grid md:grid-cols-2"
        } gap-4 bg-future-blue rounded-bl-xl rounded-br-xl`}
      >
        {!isFreeAccount && (
          <button
            className="w-full rounded-full border border-topia-black p-4 text-topia-black text-lg"
            onClick={() => {
              if (activePlaybook === "content") {
                props.onNext("helpIdeaGenerationOptionsContent", null);
              }
              if (activePlaybook === "fba") {
                props.onNext("helpIdeaGenerationOptionsFBA", null);
              }
              if (activePlaybook === "influencer") {
                props.onNext("helpIdeaGenerationOptionsInfluencer", null);
              }
              // props.onNext("helpIdeaGenerationOptions", null);
            }}
          >
            <p className="text-sm md:text-lg font-bold">
              Struggling to Find the Right Idea?
            </p>
            <p className="text-xs md:text-sm">No Stress! We’re Here to Help.</p>
          </button>
        )}

        <button
          className={`w-full  rounded-full border bg-pale-yellow border-topia-black p-4 text-topia-black text-xl font-bold`}
          onClick={() => {
            props.onNext(props.nextId, null);
          }}
        >
          I've got an idea!
        </button>
      </div>
    </div>
  );
}
