import { Card } from "@topia-app/topia-react-web";
import { IntercomIllustration } from "@topia-app/topia-react-web";

export function ErrorPage(props: {}) {
  return (
    <Card className="max-w-2xl mx-auto my-16">
      <h2 className="text-xl text-white p-2 border-b border-white/20">
        Oops. An error has occured. Please try again later.
      </h2>

      {/* <div className="rounded-xl bg-pale-yellow p-4 flex h-full flex items-center align-center justify-center intercom-launcher cursor-pointer hover:opacity-50 transition-all m-4"> */}
      {/* <div className="flex items-center justify-center align-center h-full mr-4"> */}
      {/* <IntercomIllustration /> */}
      {/* </div> */}
      {/* <p className="text-topia-black text-xs"> */}
      {/* Click here to contact support */}
      {/* </p> */}
      {/* </div> */}
    </Card>
  );
}
