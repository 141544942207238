export default function CoachesPage() {
  const coaches: {
    name: string;
    role: string;
    image: string;
    listItems: string[];
    detail: string;
  }[] = [
    {
      name: "Grant Sabatier",
      role: "Coach",
      image: "./grant.jpg",
      listItems: [
        "International Bestselling Author",
        "Founder of 8+ Successful Businesses",
        "Generates Over $1 Million in Annual Business Revenue",
        "Sold The Millennial Money Blog for an 8-Figure Sum",
      ],
      detail:
        "I'm the author of the international bestseller Financial Freedom. Using entrepreneurship, I rapidly accelerated my journey to financial independence, increasing my net worth from $2.27 to $1.25 million in just five years. Since then, I've founded eight businesses and created the FIpreneur community to help you fast-track your own path to a life you love through entrepreneurship. I'm excited to share this journey with you and am here to help every step of the way.",
    },
    {
      name: "Logan Leckie",
      role: "Coach / Community Manager",
      image:
        "https://a-us.storyblok.com/f/1010894/1112x1192/b2af6d24e7/logan-leckie-poolside-2.PNG",
      listItems: [
        "Achieved CashflowFI in Just 6 Months",
        "Generates $20k+ in monthly revenue from 6 different side businesses",
        "",
        "",
      ],
      detail:
        "Three years ago, I reached out to Grant seeking advice for my business. Under his mentorship, I embraced the FIpreneur philosophy and fast-tracked my progress to reaching CashflowFI and quitting my 9-5. I now spend my time starting and scaling side businesses, from growing e-commerce brands and TikTok travel accounts to launching a summer boat rental venture. As your community manager, I'm here to support you in every way I can.",
    },
  ];

  return (
    <div className="p-4 py-16 max-w-6xl mx-auto">
      <h2 className="text-3xl text-center my-8 tracking-tight text-white md:text-4xl">
        Meet Your Coaches
      </h2>
      <ul
        role="list"
        className="flex flex-col gap-4 md:flex-row items-center justify-around md:gap-12"
      >
        {coaches.map((person) => (
          <li key={person.name}>
            <img
              className="h-auto w-[300px] rounded-2xl"
              src={person.image}
              alt=""
            />
            <p className="mt-6 text-2xl font-semibold leading-8 text-white">
              {person.name}
            </p>

            <p className="text-lg mt-2 leading-7 text-slate-200">
              {person.role}
            </p>
            {/* <ul className=" ">
                {person.listItems.map((li) => (
                  <li
                    className={` text-gray-200  ${
                      li.length === 0 && "text-transparent"
                    }`}
                  >
                    {li}
                  </li>
                ))}
              </ul> */}

            <p className="mt-4 text-lg leading-8 text-gray-300">
              {person.detail}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
