export function YoutubeEmbed(props: {
  youtubeId: string;
  width?: string;
  height?: string;
  className?: any;
}) {
  return (
    <div className="flex w-full items-center justify-center my-8">
      <iframe
        width={props.width ? props.width : "560"}
        height={props.height ? props.height : "315"}
        src={"https://www.youtube.com/embed/" + props.youtubeId}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        className={props.className}
      ></iframe>
    </div>
  );
}
