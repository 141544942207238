import {
  storyblokEditable,
  StoryblokComponent,
  SbBlokData,
} from "@storyblok/react";
import { useState } from "react";
import { Card } from "@topia-app/topia-react-web";
import { topiaBlockClassnames } from "@topia-app/topia-react-web";
import React from "react";
import { SubscriptionRevCalculator } from "./SubscriptionRevCalculator";
import { PlaybookFAQ } from "../pages/PlaybookFAQ";
import { RealHourlyWageCalculator } from "./RealHourlyWageCalculator";
import { Whistle } from "../svg";

export default function PlaybookIntro({ blok }: { blok: any }) {
  const [profit, setProfit] = useState(0);
  // console.log(blok);
  const capitalRequirementFilters = {
    low: "$0 - $500",
    medium: "$1,000 - $3,000",
    high: "$5,000+",
  };

  const timeRequirementFilters = {
    low: "90 days",
    medium: "6 months",
    high: "1 year",
  };

  const weeklyTimeRequirement = {
    low: "1 - 4 hours",
    medium: "6 - 10 hours",
    high: "12 - 24 hours",
  };
  const block = blok.FAQs;

  return (
    <div className="bg-topia-black relative">
      <section className=" flex  w-full flex-row justify-center ">
        <div
          style={{ backgroundImage: `url(${blok.cover_image.filename})` }}
          className={`relative  flex w-full flex-col items-center bg-cover`}
        >
          <div className="absolute  top-0 left-0 bottom-0 right-0  bg-gradient-to-b  from-topia-black/20 via-topia-black/80 to-topia-black ">
            {/* <div className="absolute z-100 top-28 right-[20%] hidden md:block bg-transparent opacity-90">
              <img
                src="/get-started-arrow.svg"
                className="w-[150px] h-auto bg-transparent"
              />
            </div> */}
          </div>
          <article className="z-10 flex flex-col items-center gap-3">
            <h1 className=" mt-40 w-min text-center text-lg md:text-2xl font-bold uppercase md:leading-[92.50px] text-white md:text-5xl  ">
              {blok.title}
            </h1>
            <div className="flex flex-col items-center mx-4 lg:mx-0 pb-16 md:gap-1">
              <p className=" mb-3 text-white text-center">{blok.subtitle}</p>
              <div className="my-2 flex flex-row md:gap-4 ">
                <div className="flex flex-1 flex-col items-center justify-center rounded-md border border-white bg-slate-600/30 p-3 md:rounded-full">
                  <p className="text-2xs text-white md:text-xs">
                    Upfront Capital{" "}
                  </p>
                  <p className="text-2xs font-bold text-white md:text-sm">
                    {capitalRequirementFilters[blok.capital_requirement]}
                  </p>
                </div>
                <div className="flex flex-1 flex-col items-center justify-center  rounded-md border border-white bg-slate-600/30 p-3 md:rounded-full ">
                  <p className=" text-center text-2xs text-white md:text-xs">
                    Avg. Time to make $1,000
                  </p>
                  <p className="text-2xs font-bold text-white md:text-sm">
                    {timeRequirementFilters[blok.time_requirement]}
                  </p>
                </div>
                <div className="flex flex-1 flex-col items-center  justify-center rounded-md border border-white bg-slate-600/30 p-3 md:rounded-full">
                  <p className="text-center text-2xs text-white   md:text-xs">
                    Weekly Time Commitment
                  </p>
                  <p className="text-2xs  font-bold text-white  md:text-sm">
                    {weeklyTimeRequirement[blok.time_commitment]}
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>

      <section className="lg:mx-auto mx-6 flex max-w-6xl flex-col gap-8 px-4 pt-8 md:grid md:grid-cols-2 md:px-0">
        <div className="">
          {/* <h3 className="text-lg text-white">Getting Started</h3> */}
          {blok.left_hand_content?.map((content) => (
            <StoryblokComponent blok={content} key={content._uid} />
          ))}
        </div>
        <div className="">
          <h4 className="mb-1 text-lg text-white">The Cashflow Calculator</h4>
          <Card className="mb-8">
            <SubscriptionRevCalculator
              inputs={blok.formula.inputs}
              formula={blok.formula.formula}
              onProfitChange={(prof) => {
                setProfit(prof);
              }}
            />
          </Card>
          <h4 className="mb-1 text-lg text-white">Real Hourly Wage</h4>
          <RealHourlyWageCalculator monthlyProfit={profit} />

          {/* <div className="rounded-md bg-slate-700/70 p-3">
                  <SubscriptionRevCalculator />
                </div>
                <h4 className="mt-5 text-md text-white">Real Hourly Wage</h4>
                <div className="rounded-md bg-slate-700/70 p-3">
                  <RealHourlyWageCalculator monthlyProfit={1000} />
                </div> */}
        </div>
      </section>

      <div
        className="absolute top-2 right-2 p-2"
        style={{
          zIndex: 11,
        }}
      >
        <button className="cursor-pointer scale-100 hover:scale-105 hover:bg-pale-yellow transition-all p-4 rounded-md bg-future-blue  outline-0 shadow-md border-4 ring ring-white border-black">
          <div className="flex flex-row gap-4 justify-center items-center">
            <Whistle width={80} height={80} />
            <div>
              <p className="text-xl text-topia-black font-black">
                Choose this playbook!
              </p>
              <p className="text-md text-topia-black">
                Follow a proven step-by-step plan
                <br />
                to $2k monthly recurring revenue.
              </p>
            </div>
          </div>
        </button>
      </div>

      <div className="bg-white pb-28 p-4 mt-10">
        <div className="w-full relative">
          {blok.playbook_intro_body?.map((content) => (
            <StoryblokComponent blok={content} key={content._uid} />
          ))}
        </div>
        <p className="text-xl text-center my-4">FAQs</p>
        <div>
          {(block || []).map((content) => (
            <StoryblokComponent
              {...storyblokEditable(blok)}
              blok={content}
              key={content._uid}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
