import { ISbRichtext, RichTextSchema, renderRichText } from "@storyblok/react";
import _ from "lodash";
import mixpanel from "mixpanel-browser";
import { DASHBOARD_MODULES } from "./module-data";
import { PlaybookType, PortalModule } from "./module-types";
import { DbUser } from "./types";

export function mixpanelTrack(evName: string, props?: any) {
  mixpanel.track(evName, {
    ...props,
  });
}

export function uuidv4() {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

// export function makeDivComponent<TProps extends {}>((props: TProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => JSX.Element) {
//   return (props: TProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
//     return (
//       <div {...props}>
//         {props.children}
//       </div>
//     )
//   }
// }

export function getCurrencySymbol(): string {
  return global.region === "us" ? "$" : "£";
}

export function formatCurrency(
  input: string | number,
  overrideRegion?: "us" | "gb"
) {
  const region = overrideRegion || global.region;

  if (input === undefined || input === null) return "";

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: region === "us" ? "USD" : "GBP",
    maximumFractionDigits: 0,
  });

  const v = Number(input);
  return currencyFormatter.format(v);
}

export function toFixedPrecision(n: number | string) {
  return Number(n).toFixed(2);
}

// // you can make a copy of the default RichTextSchema
// // ... and edit the nodes and marks, or add your own.
// // Check the base RichTextSchema source here:
// //https://github.com/storyblok/storyblok-js-client/blob/main/src/schema.ts

// storyblokInit({
//   accessToken: "<your-token>",
//   richText: {
//     schema: mySchema,
//     resolver: (component, blok) => {
//       switch (component) {
//         case "my-custom-component":
//           return `<div class="my-component-class">${blok.text}</div>`;
//         default:
//           return "Resolver not defined";
//       }
//     },
//   },

// });

export function enrichISbRichtext(input: ISbRichtext): ISbRichtext {
  const result = { ...input };
  // console.log("result", result);
  return result;
}

export const TODO_STATUS_EMOJIS = {
  completed: "✅",
  doing: "🟡",
  todo: "🔲",
};

export function findOtherModulesInStep(
  playbook: PlaybookType,
  stage: any,
  step: number
) {
  const result: {
    status: "completed" | "doing" | "todo";
    title?: string;
    step: number;
  }[] = [];
  for (const m of Object.values(DASHBOARD_MODULES)) {
    if (m.playbook === playbook && m.playbookStage === stage) {
      let status: any = "todo";
      if (m.playbookStageStep < step) {
        status = "completed";
      } else if (m.playbookStageStep === step) {
        status = "doing";
      }

      result.push({
        status,
        title: m.title,
        step: m.playbookStageStep,
      });
    }
  }
  return result;
}

export function upperFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const PLAYBOOK_TITLES = {
  fba: "Ecommerce",
  content: "Blogging",
  diy: "DIY",
  influencer: "Influencer",
};

export function sortPlaybookByStages(module: PortalModule[]) {
  const sortedModule = module.sort((a, b) => {
    const order = ["idea", "validate", "build", "launch", "scale"];
    const orderedStages =
      order.indexOf(a.playbookStage) - order.indexOf(b.playbookStage);

    if (orderedStages === 0) {
      return a.playbookStageStep - b.playbookStageStep;
    }

    return orderedStages;
  });

  return sortedModule;
}

export function getCurrentPositionIDInPlaybook(
  playbook: PlaybookType,
  user: DbUser
) {
  const modules = Object.entries(DASHBOARD_MODULES)
    .filter(([id, x]) => x.playbook === playbook)
    .map(([id, x]) => ({ id, ...x }));
  // console.log("modules", user.moduleResults);
  if (!user.moduleResults) {
    // console.log("here");
    return modules[0].id;
  } else {
    const completedModules = Object.keys(user.moduleResults)
      .map((x) => {
        return DASHBOARD_MODULES[x];
      })
      .filter((x) => !!x);

    const modulesInPlaybook = completedModules.filter((x) => {
      return x.playbook === playbook;
    });

    const currentUserModuleIsSameAsPlaybook =
      DASHBOARD_MODULES[user.currentModuleId]?.playbook === playbook;

    if (currentUserModuleIsSameAsPlaybook) {
      // console.log("here", modulesInPlaybook[modulesInPlaybook.length - 1]);
      return modulesInPlaybook[modulesInPlaybook.length - 1]?.nextId
        ? modulesInPlaybook[modulesInPlaybook.length - 1].nextId
        : modules.filter((mod) => !mod.isOnboardingOnly)[0].id;
    } else {
      const sortedModule = sortPlaybookByStages(modulesInPlaybook);
      // console.log("test", sortedModule);
      return sortedModule[sortedModule.length - 1]?.nextId
        ? sortedModule[sortedModule.length - 1].nextId
        : modules.filter((mod) => !mod.isOnboardingOnly)[0].id;
    }
  }
}
