import { TopiaButton } from "@topia-app/topia-react-web";
import { mixpanelTrack } from "../helpers";
import { useEffect } from "react";

export function GrantPlaybookPage() {
  useEffect(() => {
    mixpanelTrack(`page_view_grant_course`);
  }, []);

  return (
    <div className="p-8">
      <p className="text-white text-lg font-bold mt-8">
        Through your FIPreneur membership, you have full access to:
      </p>
      <h3 className="text-white text-3xl mb-4 md:mb-8">
        Grant's Financial Freedom Course
      </h3>
      <div className="flex flex-col gap-6 xl:flex-row xl:gap-10">
        <div className="flex-1 flex-col gap-3 flex">
          <p className="text-lg  text-white">
            Included in your FIPreneur membership is unlimited access to Grant's
            course: How to Achieve Financial Independence and Freedom as Fast as
            Possible… Even if You’re Starting with Just $2.26 in Your Bank
            Account.
          </p>
          <p className="text-white">
            Get the exact step-by-step process tens of thousands of people
            around the world are using to achieve financial freedom and live the
            life they actually want.
          </p>
          <ul className="text-white list-disc text-lg ml-12">
            <li>8-Module Online Course</li>
            <li>50+ Exclusive Videos</li>
            <li>Wealth-Building Frameworks</li>
            <li>Time-Saving Tools & Calculators</li>
            <li>New Lessons</li>
            <li>Ongoing Access & Updates</li>
          </ul>
          <a href="https://course.grantsabatier.com/courses/take/financial-freedom-course/lessons/46131925-meet-grant">
            <TopiaButton className="mt-4 w-[300px] ">
              Access the Course here
            </TopiaButton>
          </a>
        </div>
        <div className="flex flex-1 items-center justify-center">
          <video
            poster="https://fipreneurs.wpengine.com/wp-content/themes/fipreneurs-theme/images/teaser-vid-poster.jpg"
            controls={true}
          >
            <source
              src="https://fipreneurs.wpengine.com/wp-content/themes/fipreneurs-theme/videos/financial-freedom-trailer.mp4"
              type="video/mp4"
            />
            <source
              src="https://fipreneurs.wpengine.com/wp-content/themes/fipreneurs-theme/videos/financial-freedom-trailer.webm"
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
}
