import { SVGProps } from "react";

export const Whistle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 53 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <circle cx={25.5} cy={25.5} r={25} fill="#E0FEA1" stroke="#1D1E20" />
      <g clipPath="url(#clip0_715_8279)">
        <g>
          <path
            d="M41.6254 34.5039L41.3168 38.6382L51.9494 36.3285L52.6327 32.0059L35.2326 17.9375L20.2573 25.5401L28.3842 33.3454"
            fill="#1D1D20"
          />
          <path
            d="M41.6254 34.5039L41.3168 38.6382L51.9494 36.3285L52.6327 32.0059L35.2326 17.9375L20.2573 25.5401L28.3842 33.3454"
            stroke="#1D1D20"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M18.2437 17.4092L29.5743 15.6932C31.1174 15.4615 32.6972 15.8814 33.9023 16.8589L52.6251 31.9989L41.6177 34.5041L23.8061 19.6755L18.2437 17.4092V17.4092Z"
          fill="#6436E3"
          stroke="#1D1D20"
          strokeLinejoin="round"
        />
        <path
          d="M41.28 34.5037L27.3114 22.6509C26.3708 21.8545 25.5038 20.9784 24.644 20.095C22.9613 18.3645 20.5659 17.3219 17.9206 17.416C13.4016 17.5681 9.66141 21.1522 9.38218 25.5979C9.05887 30.6807 13.1517 34.902 18.2439 34.902C20.9333 34.902 23.3361 33.7217 24.9674 31.8609L40.6774 38.3629L41.28 34.5037V34.5037Z"
          fill="#C84897"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M27.2378 20.3342L28.7735 21.6013L36.0334 20.5587L34.3213 19.2988L27.2378 20.3342Z"
          fill="#1D1D20"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M23.6006 30L20.125 31.535"
          stroke="#1D1D20"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M25.0552 24.5039L21.5796 26.0461"
          stroke="#1D1D20"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M44.7554 17.4082L41.2798 18.9432"
          stroke="#1D1D20"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M40.3028 13.6934L38.4878 16.9878"
          stroke="#1D1D20"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M44.6602 12.3613L39.9868 17.9365"
          stroke="#1D1D20"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M25.9594 26.6406L18.0015 29.9134"
          stroke="#1D1D20"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={44} height={27} fill="white" transform="translate(9 12)" />
      </clipPath>
    </defs>
  </svg>
);

export const Hourglass = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 51 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <circle cx={25.5} cy={25.5} r={25} fill="#C84897" stroke="#1D1E20" />
      <g clipPath="url(#clip0_715_8292)">
        <path
          d="M34.0588 22.1607V10.7661C34.0588 10.1281 33.54 9.62305 32.905 9.62305H19.1036C18.4595 9.62305 17.9497 10.137 17.9497 10.7661V22.1518C17.9497 24.0923 18.5937 25.9707 19.7654 27.5213L20.7493 28.8238C21.2502 30.02 21.2502 31.3757 20.7493 32.5718L19.9533 33.6174C18.7279 35.23 17.9587 37.1793 17.9497 39.1995V50.6296C17.9497 51.2676 18.4685 51.7726 19.1036 51.7726H32.905C33.549 51.7726 34.0588 51.2587 34.0588 50.6296V39.1995C34.0588 37.1793 33.2807 35.2389 32.0553 33.6174L31.2592 32.5718C30.7583 31.3757 30.7583 30.02 31.2592 28.8238L32.2431 27.5213C33.4148 25.9707 34.0588 24.0923 34.0588 22.1518V22.1607Z"
          fill="#E0FEA1"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M25.1413 20.1855L23.1108 22.2235"
          stroke="#1D1D20"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M24.2467 15.6406L22.2163 17.6785"
          stroke="#1D1D20"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M25.6869 17.0488L20.9463 21.5234"
          stroke="#1D1D20"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M35.5528 10.7317H16.4473C16.4473 7.96721 18.4956 8.38365 21.0269 8.38365H30.9821C33.5135 8.38365 35.5618 7.96721 35.5618 10.7317H35.5528Z"
          fill="#6B33EC"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M19.1396 38.5271C19.1396 38.5271 22.1361 35.4259 24.5064 35.86C27.1808 36.3474 27.798 38.1815 30.2219 38.1815C31.5547 38.1815 32.1897 37.7207 32.1897 37.7207"
          stroke="#1D1D20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g>
          <path
            d="M20.5439 24.9707C20.5439 24.9707 26.2774 26.1846 31.4473 24.9707H20.5439Z"
            fill="#DA3A9A"
          />
          <path
            d="M20.5439 24.9707C20.5439 24.9707 26.2774 26.1846 31.4473 24.9707"
            stroke="#1D1D20"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M19.8819 6.44336H31.9391C33.0035 6.44336 33.8712 7.30283 33.8712 8.35724H17.9409C17.9409 7.30283 18.8085 6.44336 19.8729 6.44336H19.8819Z"
          fill="#1D1D20"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M16.4473 51.2676H35.5528C35.5528 54.0321 33.5045 53.6156 30.9732 53.6156H21.0269C18.4956 53.6156 16.4473 54.0321 16.4473 51.2676Z"
          fill="#6B33EC"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M32.1181 55.5566H20.0609C18.9965 55.5566 18.1288 54.6972 18.1288 53.6428H34.0591C34.0591 54.6972 33.1915 55.5566 32.1271 55.5566H32.1181Z"
          fill="#1D1D20"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={20} height={50} fill="white" transform="translate(16 6)" />
      </clipPath>
    </defs>
  </svg>
);

export const MoneyMindset = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <circle cx={25.5} cy={25.5} r={25} fill="#0000D6" stroke="#1D1E20" />
      <g clipPath="url(#clip0_715_8260)">
        <path
          d="M37.0743 41.5427H14.8326C13.0859 41.5427 11.5985 40.28 11.3392 38.5739L6.44238 6.24805H35.8234L40.5676 37.5443C40.888 39.6487 39.2328 41.5352 37.0743 41.5352V41.5427Z"
          fill="#6B33EC"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M34.8623 42.0908H15.397C13.6198 42.0908 11.9571 40.6778 11.6901 38.9491L7.00684 8.01367H36.3878L40.56 35.567C41.1016 39.152 38.5388 42.0908 34.8547 42.0908H34.8623Z"
          fill="#6B33EC"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M36.0749 38.4521H16.6173C14.8401 38.4521 13.1773 37.0391 12.9103 35.3104L8.22705 4.375H37.608L41.7802 31.9283C42.3218 35.5134 39.759 38.4521 36.0749 38.4521Z"
          fill="#6B33EC"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M37.2114 40.9626H18.2496C16.5029 40.9626 15.0155 39.7 14.7562 37.9939L9.85938 5.66797H39.2403L43.4965 33.7699C44.0685 37.5579 41.0938 40.9626 37.2038 40.9626H37.2114Z"
          fill="#6B33EC"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <g>
          <path
            d="M14.6801 37.4899C14.6801 37.4899 15.4276 40.7593 11.7588 39.7672C11.7588 39.7672 13.2004 42.7811 19.6608 42.6082C24.6263 42.4729 31.5749 42.5406 35.7853 42.6082C40.583 42.6758 44.2975 38.4519 43.5043 33.7845C43.489 33.7019 43.4738 33.6117 43.4585 33.5215"
            fill="#6B33EC"
          />
          <path
            d="M14.6801 37.4899C14.6801 37.4899 15.4276 40.7593 11.7588 39.7672C11.7588 39.7672 13.2004 42.7811 19.6608 42.6082C24.6263 42.4729 31.5749 42.5406 35.7853 42.6082C40.583 42.6759 44.2975 38.4519 43.5043 33.7845C43.489 33.7019 43.4738 33.6117 43.4585 33.5215"
            stroke="#1D1D20"
            strokeMiterlimit={10}
          />
        </g>
        <path
          d="M23.5278 10.6973H36.6699"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M24.2676 13.5234H38.1343"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M24.3135 16.3477H37.631"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M15.8164 21.2559H38.6073"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M16.4346 23.8945H39.2254"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M16.686 26.5098H39.4769"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M17.4414 29.4863H40.2323"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M18.0591 32.1172H40.8423"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M18.3105 34.7402H41.1014"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
        <path
          d="M19.6041 18.2991C21.6169 17.8485 22.8144 15.5997 22.2787 13.2763C21.743 10.9528 19.677 9.43464 17.6641 9.88526C15.6513 10.3359 14.4538 12.5847 14.9895 14.9081C15.5252 17.2315 17.5912 18.7497 19.6041 18.2991Z"
          fill="#DA3A9A"
          stroke="#1D1D20"
          strokeMiterlimit={10}
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={38} height={39} fill="white" transform="translate(6 4)" />
      </clipPath>
    </defs>
  </svg>
);

export const VSCar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 304 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_733_5447)">
      <path
        d="M76.2478 46.9238C89.0095 46.9238 99.355 36.5725 99.355 23.8036C99.355 11.0347 89.0095 0.683472 76.2478 0.683472C63.4861 0.683472 53.1406 11.0347 53.1406 23.8036C53.1406 36.5725 63.4861 46.9238 76.2478 46.9238Z"
        fill="#E0FEA1"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M187.111 90.6129C210.048 90.6129 228.643 71.8594 228.643 48.7258C228.643 25.5922 210.048 6.83875 187.111 6.83875C164.173 6.83875 145.578 25.5922 145.578 48.7258C145.578 71.8594 164.173 90.6129 187.111 90.6129Z"
        fill="#C84897"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M262.756 90.0838H282.042V85.4543L284.601 84.2969C290.663 81.5584 295.522 76.7147 298.286 70.6681L300.172 66.5444L275.912 58.5841C260.757 53.6128 245.1 50.3275 229.224 48.7828L213.376 47.2427L157.084 25.403C156.938 25.3483 156.802 25.4987 156.874 25.6353L169.02 49.5575L116.945 46.0853L114.527 31.2764C114.126 28.8113 112.222 26.8656 109.772 26.4054L96.2694 23.8719C95.6592 23.758 95.0307 23.7854 94.4341 23.9585L18.9601 45.698H9.19171C6.9147 45.698 5.07031 47.5434 5.07031 49.8217V65.4007C5.07031 71.5476 7.60691 77.421 12.079 81.6313C18.3453 85.395 25.5179 87.3817 32.8272 87.3817H34.0021L91.8656 94.3305H205.662L262.751 90.0838H262.756Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.1259 105.321C73.4752 105.321 83.4862 95.3044 83.4862 82.9482C83.4862 70.592 73.4752 60.5753 61.1259 60.5753C48.7767 60.5753 38.7656 70.592 38.7656 82.9482C38.7656 95.3044 48.7767 105.321 61.1259 105.321Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.1239 98.0807C69.4767 98.0807 76.2479 91.3057 76.2479 82.9482C76.2479 74.5908 69.4767 67.8158 61.1239 67.8158C52.7712 67.8158 46 74.5908 46 82.9482C46 91.3057 52.7712 98.0807 61.1239 98.0807Z"
        fill="#75FBDE"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.25 79.4396C51.291 79.4396 51.332 79.4441 51.373 79.4441C53.9323 79.4441 56.009 77.3663 56.009 74.8055C56.009 74.4774 55.9725 74.1585 55.9088 73.8486C53.5088 74.7463 51.71 76.8605 51.25 79.4396Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.0246 79.4396C70.9836 79.4396 70.9426 79.4441 70.9016 79.4441C68.3423 79.4441 66.2656 77.3663 66.2656 74.8055C66.2656 74.4774 66.3021 74.1585 66.3658 73.8486C68.7658 74.7463 70.5646 76.8605 71.0246 79.4396Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.0246 87.0354C70.9836 87.0354 70.9426 87.0309 70.9016 87.0309C68.3423 87.0309 66.2656 89.1087 66.2656 91.6695C66.2656 91.9976 66.3021 92.3165 66.3658 92.6264C68.7658 91.7287 70.5646 89.6145 71.0246 87.0354Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.25 87.0354C51.291 87.0354 51.332 87.0309 51.373 87.0309C53.9323 87.0309 56.009 89.1087 56.009 91.6695C56.009 91.9976 55.9725 92.3165 55.9088 92.6264C53.5088 91.7287 51.71 89.6145 51.25 87.0354Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M275.912 58.5841C260.757 53.6128 245.1 50.3275 229.224 48.7828L213.376 47.2427L157.084 25.403C156.938 25.3483 156.802 25.4987 156.874 25.6353L169.02 49.5575L116.945 46.0853L114.527 31.2764C114.126 28.8113 112.222 26.8656 109.772 26.4054L96.2694 23.8719C95.6592 23.758 95.0307 23.7854 94.4341 23.9585L18.9601 45.698H9.09152C6.87371 45.698 5.07031 47.4979 5.07031 49.7215V65.4007C5.07031 71.5476 7.60691 77.421 12.079 81.6313C18.3453 85.3951 25.5179 87.3817 32.8271 87.3817H33.064C32.8363 85.9373 32.7179 84.4564 32.7179 82.9436C32.7179 66.8178 46.1386 53.8315 62.4147 54.5515C76.8874 55.1894 88.6778 66.8497 89.4884 81.3215C89.7344 85.755 88.9602 89.979 87.389 93.7883L91.8702 94.326H205.666L207.201 94.2121C205.543 90.3754 204.701 86.1059 204.906 81.6176C205.589 66.6857 217.958 54.7201 232.9 54.5241C248.766 54.3145 261.69 67.1186 261.69 82.9436C261.69 85.4634 261.358 87.9057 260.743 90.2296L262.76 90.0792H282.047V85.4497L284.606 84.2924C290.667 81.5539 295.527 76.7102 298.291 70.6636L300.176 66.5399L275.917 58.5795L275.912 58.5841Z"
        fill="#0000D6"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M233.274 105.321C245.624 105.321 255.635 95.3044 255.635 82.9482C255.635 70.592 245.624 60.5753 233.274 60.5753C220.925 60.5753 210.914 70.592 210.914 82.9482C210.914 95.3044 220.925 105.321 233.274 105.321Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M233.276 98.0807C241.629 98.0807 248.4 91.3057 248.4 82.9482C248.4 74.5908 241.629 67.8158 233.276 67.8158C224.924 67.8158 218.152 74.5908 218.152 82.9482C218.152 91.3057 224.924 98.0807 233.276 98.0807Z"
        fill="#75FBDE"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.067 46.6686C127.067 46.6686 125.364 37.961 121.133 37.7195C116.902 37.478 118.305 46.0808 118.305 46.0808L127.072 46.6641L127.067 46.6686Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.012 49.5575L189.997 50.8652C195.262 51.1933 200.544 50.6647 205.64 49.3069L213.378 47.2427L157.085 25.403C156.939 25.3483 156.803 25.4987 156.876 25.6354L169.021 49.5575H169.012Z"
        fill="#6B33EC"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.984 42.1986L169.014 49.5575H173.135C174.624 49.5575 175.831 48.35 175.831 46.86V45.7436C175.831 43.2785 173.832 41.2827 171.373 41.2827H168.79C168.299 41.2827 167.925 41.7156 167.993 42.2031L167.984 42.1986Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.766 26.4054L79.8053 38.5168C77.5282 39.4372 76.0391 41.6472 76.0391 44.0986C76.0391 45.7709 77.4144 47.1106 79.0857 47.0695L116.939 46.0853L114.521 31.2764C114.12 28.8113 112.216 26.8656 109.766 26.4054Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.0221 24.3915L61.7109 46.6687"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.141 46.2357L117.072 65.6878C117.541 70.2079 119.112 74.5458 121.648 78.3187L128.256 88.1564"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.9688 88.1564H205.347"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.6016 72.9739H206.667"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.39844 72.9739H34.515"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3125 69.1736H16.2574"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M285.855 69.1736H292.8"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.016 49.5575L190.001 50.8653C195.265 51.1934 200.548 50.6648 205.644 49.3069L213.381 47.2428"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M196.148 50.9609C200.452 55.5221 202.51 61.7555 201.768 67.9844L199.364 88.1564"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.957 45.698L79.0839 47.0695"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M259.887 72.9739H297.098"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M262.16 85.6685H274.907"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M298.284 70.6636H302.027C302.893 70.6636 303.512 71.502 303.257 72.3313C302.387 75.1746 300.001 77.2934 297.077 77.822L292.801 78.5966C292.801 78.5966 296.398 74.7918 298.284 70.6681V70.6636Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.71451 72.9739H1.97109C1.10582 72.9739 0.486475 73.8123 0.741501 74.6416C1.61132 77.4849 3.99764 79.6037 6.92133 80.1323L11.1976 80.9069C11.1976 80.9069 7.59988 77.1022 5.71451 72.9784V72.9739Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.867 25.1797L196.146 50.9609"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.887 36.7762L200.687 49.6258"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.988 30.3333L107.247 44.4951"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.477 32.3016L103.426 44.4951"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.9609 34.1562L99.6095 44.4952"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.4961 36.6715L95.7439 44.4952"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.9609 38.3528L91.9401 44.4951"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.4688 40.5264L88.1018 44.4952"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.9336 42.1531L84.307 44.4952"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.3516 43.2239L80.5559 44.4952"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.171 9.86958C118.712 9.86958 119.962 8.61903 119.962 7.07639C119.962 5.53376 118.712 4.2832 117.171 4.2832C115.629 4.2832 114.379 5.53376 114.379 7.07639C114.379 8.61903 115.629 9.86958 117.171 9.86958Z"
        fill="#6436E3"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M233.275 105.317H98.7852"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.9411 105.317H46"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.9307 105.317H38.7656"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M223.539 79.4396C223.58 79.4396 223.621 79.4441 223.662 79.4441C226.221 79.4441 228.298 77.3663 228.298 74.8055C228.298 74.4774 228.262 74.1585 228.198 73.8486C225.798 74.7463 223.999 76.8605 223.539 79.4396Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M243.314 79.4396C243.273 79.4396 243.232 79.4441 243.191 79.4441C240.631 79.4441 238.555 77.3663 238.555 74.8055C238.555 74.4774 238.591 74.1585 238.655 73.8486C241.055 74.7463 242.854 76.8605 243.314 79.4396Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M243.314 87.0354C243.273 87.0354 243.232 87.0309 243.191 87.0309C240.631 87.0309 238.555 89.1087 238.555 91.6695C238.555 91.9976 238.591 92.3165 238.655 92.6264C241.055 91.7287 242.854 89.6145 243.314 87.0354Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M223.539 87.0354C223.58 87.0354 223.621 87.0309 223.662 87.0309C226.221 87.0309 228.298 89.1087 228.298 91.6695C228.298 91.9976 228.262 92.3165 228.198 92.6264C225.798 91.7287 223.999 89.6145 223.539 87.0354Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.1094 20.9832L47.901 27.1938"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M38.3711 28.9435L44.3505 32.1923"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M35.957 21.1562L46.0624 29.513"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M147.684 62.4526L143.18 64.4758"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M149.57 55.226L145.07 57.2491"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M150.735 58.0374L140.43 62.3433"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M88.1029 25.0521L86.0991 24.0087C85.0152 23.4436 83.7583 23.3161 82.5834 23.6487L75.2286 25.7356C73.3751 26.2596 72.223 28.1141 72.5691 30.0097L88.0983 25.0567L88.1029 25.0521Z"
        fill="#1D1D20"
        stroke="#1D1E20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={304} height={106} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const VSHorse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 181 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_740_5527)">
      <path
        d="M118.155 112.31C148.989 112.31 173.985 87.3618 173.985 56.5862C173.985 25.8106 148.989 0.862061 118.155 0.862061C87.3204 0.862061 62.3242 25.8106 62.3242 56.5862C62.3242 87.3618 87.3204 112.31 118.155 112.31Z"
        fill="#E0FEA1"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M180.135 143.043H56.1836"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.8792 143.043H7.53125"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.774 143.043H0.863281"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.0799 105.431L66.1519 132.56L60.9609 141.914H73.243L71.4637 133.103L74.1153 131.31L73.5884 102.672L69.0799 105.431Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g>
        <path
          d="M4.52734 86.4224L7.77491 71.181C7.77491 71.181 11.7307 76.8879 11.1952 80.2069C10.6597 83.5259 8.37951 83.2414 8.8891 86.5776"
          fill="#1D1D20"
        />
        <path
          d="M4.52734 86.4224L7.77491 71.181C7.77491 71.181 11.7307 76.8879 11.1952 80.2069C10.6597 83.5259 8.37951 83.2414 8.8891 86.5776"
          stroke="#1D1D20"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M154.106 109.715V131.672C154.106 132.362 153.976 133.052 153.726 133.69L150.012 143.172H162.294L160.739 135.465C160.601 134.793 160.886 134.103 161.456 133.724C162.501 133.017 163.028 131.767 162.812 130.526L158.614 106.948L154.106 109.715Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.118 56.112C165.807 43.1551 155.192 32.5776 142.21 32.2845C138.6 32.2069 135.162 32.9052 132.053 34.2327C130.386 34.9396 128.59 35.2672 126.776 35.2672H84.8684C83.7542 35.2672 82.6573 35.0172 81.6553 34.5517C78.5114 33.0948 75.2984 32.0086 71.334 31.4396C70.358 31.3017 69.382 31.5 68.4319 31.6207C31.819 31.6207 7.59184 81.1293 7.59184 81.1293L7.6523 81.181C4.89705 83.5172 3.15234 87 3.15234 90.8879C3.15234 92.7069 3.53238 94.431 4.22335 95.9914L8.81831 117.957C9.4661 121.052 12.1695 123.388 15.3394 123.379C18.8115 123.379 21.6013 120.776 21.964 117.457L22.5341 108.56C22.7673 104.957 23.942 101.431 26.1185 98.5431C27.7337 96.4051 28.6924 93.75 28.6924 90.8707C28.6924 89.5689 28.4937 88.3103 28.131 87.1207C38.5992 80.3189 51.1403 78.4052 59.4147 77.9655L59.7429 78.1983L68.3023 107.853C68.1123 108.319 68.0087 108.828 68.0087 109.362C68.0087 110.397 68.3973 111.345 69.0365 112.069C69.7361 112.853 70.1248 113.862 70.1248 114.914V132.129L66.2467 141.81H77.8809L76.2658 133.965C76.2226 133.776 76.309 133.586 76.4644 133.474L77.1122 133.043C78.0796 132.405 78.5805 131.25 78.3732 130.103L75.3243 113.379C75.2034 112.707 75.2898 112.009 75.6353 111.422C75.9807 110.836 76.1967 110.112 76.1967 109.362C76.1967 108.474 75.9117 107.647 75.4193 106.974C74.9011 106.259 74.6074 105.397 74.6679 104.509L76.4558 80.5603C78.7965 80.0603 81.0162 79.2327 83.0546 78.112H125.1C127.873 78.112 130.507 79.3448 132.286 81.4655L150.891 103.69C151.893 104.888 151.953 106.621 151.02 107.888L138.272 125.25C137.633 126.129 137.235 127.155 137.132 128.241L136.475 135.095C136.372 136.19 136.562 137.293 137.019 138.284L140.198 145.112L145.743 137.534C146.278 136.802 146.253 135.81 145.683 135.103L142.366 131.009L144.879 129.353L160.461 105.241C161.247 104.103 161.696 102.767 161.748 101.379L163.138 68.3103C165.107 64.6896 166.187 60.5172 166.083 56.0776L166.118 56.112Z"
        fill="#DA3A9A"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46484 83L24.839 85.6983C24.839 85.6983 19.268 89.8448 15.9254 89.431C12.5829 89.0172 12.7901 86.7328 9.46484 87.3535"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.648 46.2069L165.349 47.5344C167.621 49.3103 168.985 52 169.08 54.8793L170.419 95.3276V109.328C170.419 109.328 158.992 88.7844 159.079 69.8879C159.165 50.9913 163.648 46.1982 163.648 46.1982V46.2069Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4883 98.5603C11.4883 98.5603 12.8702 100.526 15.5218 98.5603"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5264 118.836C14.5264 118.836 12.6263 117.578 13.4986 116.707C14.371 115.836 17.1521 118.836 17.1521 118.836"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.4337 31.6121L68.8655 64.1897L64.1842 43.8793L63.4328 56.7414L60.9625 46.2069C60.9625 46.2069 60.0815 62.5 58.8378 67.0086C58.8378 67.0086 54.7352 38.2759 50.2266 35.7155C50.2266 35.7155 60.2802 30.6207 68.4423 31.6121H68.4337Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6179 134.586C21.6179 134.586 17.7743 137.181 17.1525 143.043C17.1525 143.043 16.6688 138.991 17.1525 135.69"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.2443 70.6724L38.0898 72.5344"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M43.9818 64.0344L39.8359 65.8965"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M45.0508 66.6121L35.5586 70.5776"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M147.089 72L139.16 75.3103"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M148.664 75.3103L140.727 78.612"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M140.25 72.3103L143.981 80.0431"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M143.473 70.5776L147.204 78.3018"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={181} height={146} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IllustrationsCubeCyllinder = (props: any) => (
  <svg
    width={190}
    height={175}
    viewBox="0 0 190 175"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_25_2519)">
      <path
        d="M59.54 118.08C91.8708 118.08 118.08 91.8708 118.08 59.54C118.08 27.2093 91.8708 1 59.54 1C27.2093 1 1 27.2093 1 59.54C1 91.8708 27.2093 118.08 59.54 118.08Z"
        fill="#E0FEA1"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <g>
        <path
          d="M184.99 91.83C183.17 93.71 176.47 90.19 170.04 83.96C163.6 77.73 159.86 71.16 161.69 69.27L164.47 66.4L187.78 88.95L185 91.82L184.99 91.83Z"
          fill="#E0FEA1"
          stroke="#1D1D20"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M187.764 88.9713C189.588 87.0862 185.848 80.5079 179.411 74.2784C172.974 68.0488 166.276 64.527 164.452 66.4122C162.628 68.2974 166.367 74.8756 172.805 81.1052C179.242 87.3347 185.939 90.8565 187.764 88.9713Z"
          fill="#E0FEA1"
          stroke="#1D1D20"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M165.97 73.95L163.61 76.38"
          stroke="#1D1D20"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M167.34 75.96L164.99 78.39"
          stroke="#1D1D20"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M169.16 77.72L166.81 80.15"
          stroke="#1D1D20"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M181.6 88.15L179.24 90.58"
          stroke="#1D1D20"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
      </g>
      <path
        d="M32.69 112.15C32.69 113.4 30.8 114.42 28.47 114.42C26.14 114.42 24.25 113.41 24.25 112.15V52.4H32.68V112.16L32.69 112.15Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M48.44 91.66C48.44 92.91 46.55 93.93 44.22 93.93C41.89 93.93 40 92.92 40 91.66V38.9H48.43V91.66H48.44Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M65.5699 75.98C65.5699 77.23 63.6799 78.25 61.3499 78.25C59.0199 78.25 57.1299 77.24 57.1299 75.98V33.22H65.5599V75.98H65.5699Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M163.38 140.41L90.3501 173.26L17.3301 140.41V132.95L90.3501 100.11L163.38 132.95V140.41Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M163.38 132.95L90.3501 165.8L17.3301 132.95L90.3501 100.11L163.38 132.95Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M89.5501 112.06C91.8807 112.06 93.7701 111.044 93.7701 109.79C93.7701 108.536 91.8807 107.52 89.5501 107.52C87.2194 107.52 85.3301 108.536 85.3301 109.79C85.3301 111.044 87.2194 112.06 89.5501 112.06Z"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M82.5201 131.15C82.5201 132.4 80.6301 133.42 78.3001 133.42C75.9701 133.42 74.0801 132.41 74.0801 131.15V78.39H82.5101V131.15H82.5201Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M68.8299 107.16C68.8299 108.41 66.9399 109.43 64.6099 109.43C62.2799 109.43 60.3899 108.42 60.3899 107.16V54.4H68.8199V107.16H68.8299Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M54.8899 87.92C54.8899 89.17 52.9999 90.19 50.6699 90.19C48.3399 90.19 46.45 89.18 46.45 87.92V35.16H54.88V87.92H54.8899Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M40.0101 72.75C40.0101 74 38.1201 75.02 35.7901 75.02C33.4601 75.02 31.5701 74.01 31.5701 72.75V45.39H40.0001V72.75H40.0101Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M163.38 52.86L90.3501 85.7L17.3301 52.86V45.4L90.3501 12.55L163.38 45.4V52.86Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M163.38 45.4L90.3501 78.24L17.3301 45.4L90.3501 12.55L163.38 45.4Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M114.59 147.65C114.59 148.9 112.7 149.92 110.37 149.92C108.04 149.92 106.15 148.91 106.15 147.65V118.96H114.58V147.65H114.59Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M110.37 120.9C112.701 120.9 114.59 119.884 114.59 118.63C114.59 117.376 112.701 116.36 110.37 116.36C108.039 116.36 106.15 117.376 106.15 118.63C106.15 119.884 108.039 120.9 110.37 120.9Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M99.24 155.71C99.24 156.96 97.35 157.98 95.02 157.98C92.69 157.98 90.8 156.97 90.8 155.71V136.02H99.23V155.71H99.24Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M95.02 137.96C97.3507 137.96 99.24 136.944 99.24 135.69C99.24 134.436 97.3507 133.42 95.02 133.42C92.6894 133.42 90.8 134.436 90.8 135.69C90.8 136.944 92.6894 137.96 95.02 137.96Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M78.3001 154.35C80.6307 154.35 82.5201 153.334 82.5201 152.08C82.5201 150.826 80.6307 149.81 78.3001 149.81C75.9694 149.81 74.0801 150.826 74.0801 152.08C74.0801 153.334 75.9694 154.35 78.3001 154.35Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M63.76 148.01C66.0907 148.01 67.98 146.994 67.98 145.74C67.98 144.486 66.0907 143.47 63.76 143.47C61.4294 143.47 59.54 144.486 59.54 145.74C59.54 146.994 61.4294 148.01 63.76 148.01Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M50.6399 141.22C52.9706 141.22 54.8599 140.204 54.8599 138.95C54.8599 137.696 52.9706 136.68 50.6399 136.68C48.3093 136.68 46.4199 137.696 46.4199 138.95C46.4199 140.204 48.3093 141.22 50.6399 141.22Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M36.7901 135.22C39.1207 135.22 41.0101 134.204 41.0101 132.95C41.0101 131.696 39.1207 130.68 36.7901 130.68C34.4594 130.68 32.5701 131.696 32.5701 132.95C32.5701 134.204 34.4594 135.22 36.7901 135.22Z"
        fill="#1D1D20"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M53.48 127.87C55.8107 127.87 57.7 126.854 57.7 125.6C57.7 124.346 55.8107 123.33 53.48 123.33C51.1494 123.33 49.26 124.346 49.26 125.6C49.26 126.854 51.1494 127.87 53.48 127.87Z"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M68.8301 121.23C71.1608 121.23 73.0501 120.214 73.0501 118.96C73.0501 117.706 71.1608 116.69 68.8301 116.69C66.4995 116.69 64.6101 117.706 64.6101 118.96C64.6101 120.214 66.4995 121.23 68.8301 121.23Z"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M130.42 141.25C130.42 142.5 128.53 143.52 126.2 143.52C123.87 143.52 121.98 142.51 121.98 141.25V100.43H130.41V141.25H130.42Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M126.2 102.37C128.531 102.37 130.42 101.354 130.42 100.1C130.42 98.8463 128.531 97.83 126.2 97.83C123.869 97.83 121.98 98.8463 121.98 100.1C121.98 101.354 123.869 102.37 126.2 102.37Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M144.88 135.01C144.88 136.26 142.99 137.28 140.66 137.28C138.33 137.28 136.44 136.27 136.44 135.01V82.25H144.87V135.01H144.88Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M140.66 84.18C142.991 84.18 144.88 83.1637 144.88 81.91C144.88 80.6563 142.991 79.64 140.66 79.64C138.329 79.64 136.44 80.6563 136.44 81.91C136.44 83.1637 138.329 84.18 140.66 84.18Z"
        fill="#75FBDE"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M71.3299 44.47L55.8899 47.51"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M77.8001 33.65L62.3601 36.68"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M81.8 37.86L46.46 44.31"
        stroke="#1D1D20"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={189.23} height={174.26} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
