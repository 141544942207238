import React, { useEffect } from "react";

import classNames from "classnames";
import { useState } from "react";
import {
  Bars3Icon,
  ChatBubbleLeftEllipsisIcon,
  RocketLaunchIcon,
  StarIcon,
} from "@heroicons/react/24/solid";
import { useAuthContext } from "../auth";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDataContext } from "../context/DataContext";
import { DASHBOARD_MODULES } from "../module-data";
import { Accordion } from "./Accordion";
import { TopiaAccordion } from "./TopiaAccordion";

export function ApplicationShell(props: { children: any }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [step, setStep] = useState(0);
  const authCtx = useAuthContext();
  const dataCtx = useDataContext();

  const location = useLocation();
  const navigate = useNavigate();

  const innerCircleTabs: any = dataCtx.user.isInTheInnerCircle
    ? [
        {
          type: "header",
          name: "Inner Circle",
        },
        {
          name: "Ask Grant",
          icon: ChatBubbleLeftEllipsisIcon,
          href: "/ask-grant-a-question",
          current: location?.pathname === "/ask-grant-a-question",
        },
        {
          name: "Grant's Inner Circle",
          icon: RocketLaunchIcon,
          href: "/__inner-circle",
          current: location?.pathname === "/__inner-circle",
        },
      ]
    : [
        {
          name: "Grant's Inner Circle",
          icon: RocketLaunchIcon,
          href: "/join-the-inner-circle",
          current: location?.pathname === "/join-the-inner-circle",
        },
      ];

  const blueprintTabs: any[] = [
    {
      type: "header",
      name: "Your Side Business",
    },
    {
      name: "Ecommerce: Start Selling Online",
      href: "/playbook/fba",
      current: location?.pathname === "/playbook/fba",
      value: "fba",
    },
    {
      name: "Influencer: Connect & Inspire",
      href: "/playbook/influencer",
      current: location?.pathname === "/playbook/influencer",
      value: "influencer",
    },
    {
      name: "Start a Blog: Share Your Passion",
      href: "/playbook/content",
      current: location?.pathname === "/playbook/content",
      value: "content",
    },
    {
      name: "DIY: A tailor made business",
      href: "/playbook/diy",
      current: location?.pathname === "/playbook/diy",
      value: "diy",
    },
  ];

  const usersSelectedPlaybook =
    DASHBOARD_MODULES[dataCtx.user.currentModuleId]?.playbook;

  const activeBluePrintTabs = blueprintTabs.filter(
    (blueprint) =>
      blueprint.type === "header" || blueprint.value === usersSelectedPlaybook
  );

  const inActiveBluePrintTabs = blueprintTabs.filter(
    (blueprint) =>
      blueprint.value !== usersSelectedPlaybook && blueprint.type !== "header"
  );

  const tabs: (
    | {
        type?: undefined;
        name: string;
        href: string;
        icon?: any;
        current: boolean;
      }
    | {
        type: "header";
        name: string;
      }
  )[] = [
    {
      name: "Home",
      href: "/",
      current: location?.pathname === "/",
    },
    {
      name: "Community Info",
      href: "/community-info",
      current: location?.pathname === "/community-info",
    },
    {
      name: "Grant's Video Vault",
      href: "/video-vault",
      current: location?.pathname === "/video-vault",
    },
    {
      name: "Weekly Calls",
      href: "/weekly-calls",
      current: location?.pathname === "/weekly-calls",
    },
    {
      name: "Grant's Financial Freedom Course",
      href: "/grants-financial-freedom-course",
      current: location?.pathname === "/grants-financial-freedom-course",
    },
    {
      name: "Your Coaches",
      href: "/your-coaches",
      current: location?.pathname === "/your-coaches",
    },
    ...innerCircleTabs,
    ...activeBluePrintTabs,
    // ...blueprintTabs,
  ];

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  useEffect(() => {
    // Close the mobile nav when the page changes
    setMobileNavOpen(false);
  }, [props.children]);

  return (
    <div className="relative">
      <div className="bg-gray-700 sticky z-10 top-0 xl:hidden">
        <div className="flex flex-row p-4 justify-between">
          <img
            src="/logo.svg"
            className="w-[100px] h-auto"
            style={{
              filter: "invert(1)",
            }}
          />
          <a
            onClick={() => {
              setMobileNavOpen(!mobileNavOpen);
            }}
          >
            <Bars3Icon className="w-8 h-8 text-white" />
          </a>
        </div>
        {mobileNavOpen && (
          <div className=" bg-slate-600 rounded-b-lg w-full z-10 ">
            {[...tabs, ...inActiveBluePrintTabs].map((tab, ix) => (
              <React.Fragment key={(tab as any).name}>
                {(tab as any).type === "header" ? (
                  <h5 className="text-xl text-white  px-3 py-2 mt-2" key={ix}>
                    {(tab as any).name}
                  </h5>
                ) : (
                  <Link
                    key={ix}
                    to={(tab as any).href}
                    onClick={() => {
                      setMobileNavOpen(false);
                    }}
                    className={classNames(
                      (tab as any).current
                        ? "text-pale-yellow font-black bg-white/10"
                        : "text-white hover:text-white hover:bg-white/20",
                      "px-3 py-2 font-bold text-xs lg:text-sm transition-all rounded-lg min-w-[240px] block"
                    )}
                    aria-current={(tab as any).current ? "page" : undefined}
                  >
                    {(tab as any).name}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>

      <div className="flex  h-full">
        {/* Static sidebar for desktop */}
        <div
          className="hidden xl:sticky z-40 xl:top-0 xl:flex xl:flex-shrink-0 h-full "
          id="sidebar"
        >
          <div className="flex w-full flex-1 flex-col">
            <div className="topia-bounce-in h-full   min-h-screen flex flex-col p-2 bg-neutral-800">
              <div className="flex flex-row justify-between mt-2 p-2 mb-4">
                {/* <TopiaLogo color="white" className="w-[120px] h-auto" /> */}
                <img
                  src="/logo.svg"
                  className="w-[200px] h-auto"
                  style={{
                    filter: "invert(1)",
                  }}
                />
              </div>
              <nav className="flex flex-col flex-1" aria-label="Tabs">
                {tabs.map((tab, ix) => {
                  return (
                    <React.Fragment key={tab.name}>
                      {tab.type === "header" ? (
                        <h5
                          className="text-xl text-white  px-3 py-2 mt-2"
                          key={ix}
                        >
                          {tab.name}
                        </h5>
                      ) : (
                        <>
                          <Link
                            key={ix}
                            to={(tab as any).href}
                            className={classNames(
                              (tab as any).current
                                ? "text-pale-yellow font-black bg-white/10"
                                : "text-white hover:text-white hover:bg-white/20",
                              "px-3 py-2 font-bold text-xs lg:text-sm transition-all rounded-lg min-w-[240px]  relative"
                            )}
                            aria-current={
                              (tab as any).current ? "page" : undefined
                            }
                          >
                            {(tab as any).name}
                            {(tab as any).icon && (
                              // @ts-ignore
                              <tab.icon
                                className="absolute top-2 right-2 w-6 h-6"
                                color="#e0fea1"
                              />
                            )}
                          </Link>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
                <TopiaAccordion
                  titleStyle={`px-3 py-2  font-bold text-xs lg:text-sm transition-all rounded-lg  relative`}
                  title="Other Blueprints"
                >
                  {inActiveBluePrintTabs.map((blueprint, ix) => {
                    return (
                      <React.Fragment key={blueprint.name}>
                        <Link
                          key={ix}
                          to={(blueprint as any).href}
                          className={classNames(
                            (blueprint as any).current
                              ? "text-pale-yellow font-black bg-white/10"
                              : "text-slate-300/80 hover:text-white hover:bg-white/20",
                            "px-3 py-2 font-bold text-xs lg:text-sm transition-all rounded-lg min-w-[240px]  relative"
                          )}
                          aria-current={
                            (blueprint as any).current ? "page" : undefined
                          }
                        >
                          {(blueprint as any).name}
                          {(blueprint as any).icon && (
                            // @ts-ignore
                            <tab.icon
                              className="absolute top-2 right-2 w-6 h-6"
                              color="#e0fea1"
                            />
                          )}
                        </Link>
                      </React.Fragment>
                    );
                  })}
                </TopiaAccordion>
              </nav>
              <div className="flex-1"></div>
              {dataCtx.privateUser.role === "admin" && (
                <button
                  onClick={() => {
                    navigate("/admin");
                  }}
                  className="w-full text-left block p-4 border-b text-white text-md"
                >
                  Admin
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="flex w-full flex-1 flex-col">
          <div className="relative z-0 flex flex-1 ">
            <main className="relative z-0 flex-1 focus:outline-none xl:order-last w-full">
              {/* Start main area*/}
              <div className="">{props.children}</div>
              {/* End main area */}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
