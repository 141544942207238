import { Card } from "@topia-app/topia-react-web";
import { useForm } from "react-hook-form";
import { TopiaEmailInput } from "@topia-app/topia-react-web";
import { TopiaButton } from "@topia-app/topia-react-web";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useEffect, useState } from "react";
import { mixpanelTrack } from "../helpers";

export default function ForgotPassWordPage() {
  const form = useForm();
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const auth = getAuth();
  useEffect(() => {
    mixpanelTrack(`page_view_forgot_password`);
  }, []);
  const successMessage = (
    <>
      <p className="text-white py-4 text-center">
        Please check your email address. We have sent a secure verification link
        where you can reset your password.
      </p>
      <a
        href="/login"
        className="block text-future-blue  w-full underline mt-4 text-center"
      >
        Back to Login
      </a>
    </>
  );

  const passwordResetHandler = (vals) => {
    setLoading(true);

    sendPasswordResetEmail(auth, vals.email)
      .then(() => {
        setSendSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        setSendSuccess(true);
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="p-4 w-full">
      <Card className="my-4 mx-auto w-full lg:w-3/6">
        <div className="flex flex-col gap-2">
          {!sendSuccess && (
            <>
              <h2 className="text-white text-2xl text-center">
                Forgot your password?
              </h2>
              <p className="text-white">
                Please enter your email address. You will receive an email
                message with instructions on how to reset your password.
              </p>
              <form onSubmit={form.handleSubmit(passwordResetHandler)}>
                <TopiaEmailInput
                  label=""
                  inputProps={form.register("email", {
                    required: true,
                  })}
                  id="login-email"
                  className=""
                />

                <TopiaButton
                  type="submit"
                  className="w-full"
                  btnStyle="primary"
                  loading={loading}
                >
                  Reset Password
                </TopiaButton>
              </form>
            </>
          )}
        </div>
        {sendSuccess && successMessage}
      </Card>
    </div>
  );
}
