import { PortalModule } from "../module-types";

export function MultiText(props: {
  inputTitle: string;
  inputIx: number;
  multiTextValue: string;
  onChange: (val) => void;
}) {
  return (
    <div key={props.inputTitle} className="">
      <p className="text-topia-black text-lg mb-2">{props.inputTitle}</p>
      <input
        type="text"
        className="p-2 bg-white border border-gray-300 rounded-md w-full mb-2"
        key={props.inputIx + "_"}
        value={props.multiTextValue}
        onChange={(ev) => {
          props.onChange(ev.target.value);
        }}
      />
    </div>
  );
}
