import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

export const chadTest = (data) =>
  httpsCallable(functions, "gpt-chadTest")(data);

export const chatGptRequest = (data) =>
  httpsCallable(functions, "gpt-chatGptRequest")(data);

// export const getAllUsers = (data) =>
//   httpsCallable(functions, "admin-getAllUsers")(data);

export const getAllUsers = httpsCallable<undefined, { users: any[] }>(
  functions,
  "admin-getAllUsers"
);
export const adminGetUser = httpsCallable<{ id: string }, any>(
  functions,
  "admin-adminGetUser"
);
export const adminSaveUser = httpsCallable<
  { privateUser: any; adminInfo: any; id: any },
  any
>(functions, "admin-adminSaveUser");
