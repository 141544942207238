import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

export function Accordion(props: { header: string; detail: any }) {
  const [accordionOpen, setAccordionOpen] = useState(false);
  return (
    <motion.div
      animate={accordionOpen ? "open" : "closed"}
      className="  border-b-[2px] border-b-slate-300 p-1"
    >
      <button
        className="flex w-full cursor-pointer flex-row items-center justify-between py-6"
        onClick={() => {
          setAccordionOpen((prev) => !prev);
        }}
      >
        <motion.span
          variants={{
            open: {
              color: "rgba(3, 6, 23, 0)",
            },
            closed: {
              color: "rgb(255,255,255)",
            },
          }}
          className="bg-future-blue bg-clip-text text-left text-md font-medium "
        >
          {props.header}
        </motion.span>

        <motion.span
          variants={{
            open: {
              rotate: "180deg",
              color: "#75FBDE",
            },
            closed: {
              rotate: "0deg",
              color: "#FFFFFF",
            },
          }}
        >
          <FiChevronDown className="text-2xl" />
        </motion.span>
      </button>
      {accordionOpen && (
        <motion.div
          className="text-white overflow-hidden"
          initial={false}
          animate={{
            height: open ? "fit-content" : "0px",
            marginBottom: open ? "24px" : "0px",
          }}
        >
          {props.detail}
        </motion.div>
      )}
    </motion.div>
  );
}
