import { TopiaButton, TopiaTextInput } from "@topia-app/topia-react-web";
import { useDataContext } from "../context/DataContext";
import { useState } from "react";
import classNames from "classnames";
import { useModalContext } from "../context/ModalContext";
import { AddToCalendar } from "./AddToCalendar";

export type JoinTheWeeklyCallsModalProps = {};

export function JoinTheWeeklyCallsModal(props: JoinTheWeeklyCallsModalProps) {
  const dataCtx = useDataContext();
  const modalCtx = useModalContext();

  const [loading, setLoading] = useState(false);

  async function onSubmit() {
    setLoading(true);

    if (loading) {
      return;
    }

    await dataCtx.updateUser({
      needleMovers: (dataCtx.user.needleMovers || []).filter(
        (x) => x.type !== "join-the-calls"
      ),
    });

    modalCtx.closeModal();
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl text-center text-topia-black">
        Unlocking More Freedom: Weekly Group Coaching with Grant
      </h2>
      <div className="flex flex-row justify-center mb-6">
        <div className="max-w-5xl flex flex-col gap-3">
          <p className="text-topia-black">
            <strong>Grant as Your Coach:</strong> You're not just getting
            guidance; you're accessing a wealth of knowledge, experience, and
            inspiration tailored to help you achieve your goals.
          </p>
          <p className="text-topia-black">
            <strong>Stay on Track to a Life You Love:</strong> Grant will ensure
            every part of your journey is aligned with your ultimate goal:
            crafting a life filled with joy and purpose. Together, you'll
            overcome challenges, refine your strategies, and grow your
            business—all while staying true to your vision.
          </p>
          <p className="text-topia-black">
            <strong>Your Key to Success:</strong> Treat these calls as
            essential. Schedule them, set reminders, and carve out this crucial
            time with Grant. It's an investment in your future, ensuring you're
            consistently advancing toward your dreams.
          </p>
        </div>
      </div>
      <p className="text-lg my-4 text-center text-topia-black">
        Use the tools below to add the calls to your calendar, then click Done
        when you're finished.
      </p>

      <TopiaButton className="w-full my-4" onClick={onSubmit} loading={loading}>
        Done! I've added the calls to my calendar
      </TopiaButton>

      <div className="flex flex-row bg-future-blue p-4 rounded-md justify-center">
        <AddToCalendar style=" " />
      </div>

      <section className="flex mt-4 flex-col relative gap-4 ">
        <p className="text-white absolute top-[20%] right-[50%]">Loading...</p>
        {/* <p className="text-white  text-lg mt-6">Weekly Meetings</p> */}
        <iframe
          src="https://www.addevent.com/calendar/XK624050/embed/?id=XK624050&calendars=XK624050&calendars_sel=XK624050&config=true&title=&titleshow=true&today=true&datenav=true&date=true&monthweektoggle=true&subscribebtn=true&swimonth=true&swiweek=true&swischedule=true&print=true&timezone=true&defaultview=month&firstday=1&datetimeformat=1#XK624050"
          className="h-screen"
        ></iframe>
      </section>
    </div>
  );
}
