import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";
import { Style } from "util";

export function TopiaAccordion(props: {
  title: string;
  children: ReactNode;
  titleStyle?: any;
  chevronFill?: string;
  chevronWidth?: number;
  containerStyle?: any;
  chevronHeight?: number;
}) {
  const [isAccordionShown, setIsAccordionShown] = useState(false);
  return (
    <div className={`${props.containerStyle} flex flex-col`}>
      <button
        onClick={() => {
          setIsAccordionShown((prev) => !prev);
        }}
        className="flex flex-1 flex-row justify-between items-center"
      >
        <span className={`text-white text-sm font-bold ${props.titleStyle}`}>
          {props.title}
        </span>
        {isAccordionShown ? (
          <ChevronUpIcon fill="white" width={25} height={25} />
        ) : (
          <ChevronDownIcon fill="white" width={25} height={25} />
        )}
      </button>
      {isAccordionShown && props.children}
    </div>
  );
}
