import { useEffect, useState } from "react";
import { TopiaTextInput, formatCurrency } from "@topia-app/topia-react-web";

export function SubscriptionRevCalculator(props: {
  onProfitChange?: (v: number) => void;
  inputs: { id: string; label: string; defaultValue: string }[];
  formula?: string;
}) {
  // console.log("inputs", props.inputs);
  const stateValue = {};
  props.inputs.forEach((input) => {
    stateValue[input.id] = input.defaultValue;
  });
  const keys = Object.keys(stateValue);
  const [state, setState] = useState(props.inputs);

  let completedFormula = "";
  for (let input of keys) {
    completedFormula += `let ${input} = ${stateValue[input] || `0`};\n`;
  }
  completedFormula += props.formula;

  let formulaResult = 0;
  try {
    formulaResult = eval(completedFormula);
  } catch (ex) {
    console.log("error", ex);
  }

  useEffect(() => {
    props.onProfitChange?.(formulaResult);
  }, [formulaResult]);

  useEffect(() => {
    setState(props.inputs);
  }, [props.inputs]);

  return (
    <>
      <div className="flex flex-col justify-center ">
        <p className="text-sm text-topia-gray">Profit</p>
        <p className="text-2xl font-bold text-future-blue">
          {formatCurrency(formulaResult)}/mo
        </p>
      </div>
      <div className="my-4 grid grid-cols-2 gap-4 md:flex md:flex-row  md:items-end">
        {state.map((inputs, ix) => {
          if (inputs.defaultValue === "0") {
            setState((s) => {
              s[ix].defaultValue = "";
              return [...s];
            });
          }
          return (
            <div
              key={inputs.id}
              className="rounded-md  md:flex md:flex-col md:items-center"
            >
              <TopiaTextInput
                label={inputs.label}
                key={inputs.id}
                inputProps={{
                  type: "number",
                  value: inputs.defaultValue,
                  onChange: (ev) => {
                    setState((s) => {
                      s[ix].defaultValue = ev.target.value;

                      return [...s];
                    });
                    // console.log(state)
                  },
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}
