import { CheckIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

export function AskGrantPage() {
  return (
    <>
      <div className="p-8 pb-20 m-4 mt-8">
        <div className="mt-4">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <h2 className="text-base font-semibold leading-7 text-pale-yellow">
                Ask Grant
              </h2>
              <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                Ask Me Anything
              </p>
            </div>
            <p className="mx-auto mt-6 max-w-4xl text-lg leading-8 text-gray-300">
              I'm here to guide you every step. Whether you're seeking advice on
              your side business, looking for insights on your journey to FI, or
              need a fresh perspective on your mindset, I'm ready to help.
            </p>
            <p className="mx-auto mt-6 max-w-4xl text-lg leading-8 text-gray-300">
              Let's tackle your challenges, refine your strategies, and grow
              your business in a way that fully aligns with the life you want to
              live. Don't hesitate—ask me anything.
            </p>

            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "bg-white/5 ring-2 ring-pale-yellow"
                      : "ring-1 ring-white/10",
                    "rounded-3xl p-8 xl:p-10"
                  )}
                >
                  <div className="flex items-center justify-between gap-x-4">
                    <p
                      id={tier.id}
                      className="text-2xl text-center mb-4 font-semibold leading-8 text-white"
                    >
                      {tier.name}
                    </p>
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-300">
                    {tier.description}
                  </p>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    {/* <span className="text-4xl font-bold tracking-tight text-white">{tier.price[frequency.value]}</span>
                <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span> */}
                  </p>
                  <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "bg-pale-yellow hover:opacity-50 text-topia-black shadow-sm hover:bg-pale-yellow focus-visible:outline-pale-yellow"
                        : "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white",
                      "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    )}
                  >
                    {tier.cta}
                  </a>
                  <ul
                    role="list"
                    className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10"
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-white"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const tiers = [
  {
    name: "Send a Video Message to Grant",
    id: "q-video",
    href: "https://senja.io/p/fipreneurs/r/nY2O7c ",
    description: "Receive a detailed video reply from Grant",
    features: ["Video reply from Grant", "7-Day Response time"],
    cta: "Record your video question",
    mostPopular: false,
  },
  {
    name: "Jump on a call with Grant",
    id: "q-call",
    href: "https://calendly.com/logan-156/inner-circle-ask-grant-your-question-live?month=2024-04&date=2024-03-25",
    description:
      "Schedule a 15 minute discussion with Grant and explore every aspect of your question in detail",
    features: [
      "15 minute call with Grant",
      "Detailed Discussion",
      "Flexible Scheduling",
    ],
    cta: "Schedule your call",
    mostPopular: true,
  },
  {
    name: "Send a Written Message",
    id: "q-written",
    href: "/ask-grant-a-question/written",
    description: "Receive a brief written response from Grant",
    features: ["Brief written response from Grant", "10-Day Response"],
    mostPopular: false,
    cta: "Submit written message",
  },
];
