import { FirebaseOptions, initializeApp } from "firebase/app";
import * as fbFunctions from "firebase/functions";
import * as fbFirestore from "firebase/firestore";
import * as fbAuth from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectAuthEmulator } from "firebase/auth";
import { connectFunctionsEmulator } from "firebase/functions";

let firebaseConfig;
if (
  process.env["REACT_APP_ENV"] === "staging" ||
  process.env.NODE_ENV === "development"
) {
  firebaseConfig = {
    apiKey: "AIzaSyC2b8oO0aSDMI-ZBFnRMMsDwTdwpKL4xSw",
    authDomain: "fipreneurs-staging.firebaseapp.com",
    projectId: "fipreneurs-staging",
    storageBucket: "fipreneurs-staging.appspot.com",
    messagingSenderId: "635942113226",
    appId: "1:635942113226:web:ea52a3445867e40ad54933",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCWarly1g1QPKaSYrs6S37zGocOCNgRxnc",
    authDomain: "fipreneurs-85cbe.firebaseapp.com",
    projectId: "fipreneurs-85cbe",
    storageBucket: "fipreneurs-85cbe.appspot.com",
    messagingSenderId: "600889626194",
    appId: "1:600889626194:web:c6e4842c56c05d25e4598b",
  };
}

if (process.env.NODE_ENV === "development") {
}

const app = initializeApp(firebaseConfig);

export const auth = fbAuth.getAuth(app);
export const db = fbFirestore.getFirestore(app);
export const functions = fbFunctions.getFunctions(app, "europe-west2");

const USE_EMULATORS = process.env["REACT_APP_USE_EMULATORS"];
if (USE_EMULATORS) {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099", {
    disableWarnings: true,
  });
  connectFunctionsEmulator(functions, "localhost", 5001);
}
