import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "../hooks/useQuery";
import { useModalContext } from "../context/ModalContext";
import {
  PLAYBOOK_TITLES,
  getCurrentPositionIDInPlaybook,
  mixpanelTrack,
  upperFirstLetter,
} from "../helpers";
import { DASHBOARD_MODULES } from "../module-data";
import { useDataContext } from "../context/DataContext";
import { DashboardModule } from "../dashboard-modules";
import { DummyLoader } from "../components/DummyLoader";
import classNames from "classnames";
import Select from "react-select";
import { formatCurrency } from "@topia-app/topia-react-web";
import { Whistle } from "../svg";
import { PlaybookType } from "../module-types";
import OutsourcePanel from "../components/OutSourcePanel";
export function PlaybookEmbed() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const { playbook } = useParams();
  const modalCtx = useModalContext();
  const dataCtx = useDataContext();
  const title = PLAYBOOK_TITLES[playbook];

  const modules = Object.entries(DASHBOARD_MODULES)
    .filter(([id, x]) => x.playbook === playbook)
    .map(([id, x]) => ({ id, ...x }));

  const stages = [...new Set(modules.map((x) => x.playbookStage))];
  const query = useQuery();

  // if (playbook && dataCtx.user) {
  const startingModuleID = getCurrentPositionIDInPlaybook(
    playbook as PlaybookType,
    dataCtx.user
  );

  const [currentModuleId, setCurrentModuleId] = useState(startingModuleID);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);

  useEffect(() => {
    const currModule = DASHBOARD_MODULES[startingModuleID];
    if (currModule.isOnboardingOnly) {
      setCurrentModuleId(currModule.nextId);
    } else {
      setCurrentModuleId(startingModuleID);
    }
  }, [playbook]);

  if (dataCtx.doingInitialLoad) {
    return <></>;
  }

  // console.log(
  //   DASHBOARD_MODULES[dataCtx.user.currentModuleId]?.playbook,
  //   "CHAD"
  // );
  const currentModule = DASHBOARD_MODULES[currentModuleId as any];

  const modulesByStage = stages.map((stage) =>
    modules.filter((x) => x.playbookStage === stage && !x.isOnboardingOnly)
  );

  const selectorValues = stages.map((stage, stageIx) => {
    return {
      label: `${stageIx + 1}. ${upperFirstLetter(stage)}`,
      options: modulesByStage[stageIx].map((module) => ({
        label: module.moduleLabel || module.title,
        value: module.id,
        isCompleted: !!dataCtx.user.moduleResults[module.id],
      })),
    };
  });

  const currentSelectorValue =
    selectorValues.find((sv) => {
      return sv.options.find(
        (svoptions) => svoptions.value === currentModuleId
      );
    }) || selectorValues[0];

  // console.log(currentModuleId);
  return (
    <div className="w-full  bg-gradient-to-bl min-h-screen from-neutral-900 to-neutral-700">
      <DummyLoader key={playbook} rippleClassNames={"white"}>
        <div className="px-4 pt-4">
          {currentModule && currentModule.playbook && (
            <div className="p-4 bg-neutral-900 border-b border-neutral-600 rounded-tl-lg rounded-tr-lg shadow-xl">
              <div className="flex flex-row justify-between gap-4 mb-2">
                <h2 className="text-2xl text-white">
                  Side Biz:{" "}
                  <strong>{PLAYBOOK_TITLES[currentModule.playbook]}</strong>
                </h2>
                <h2 className="text-2xl text-white">
                  Stage:{" "}
                  <strong>
                    {upperFirstLetter(currentModule?.playbookStage)}
                  </strong>
                </h2>
              </div>

              <div className="h-10 bg-gradient-to-r shadow-md from-white to-gray-100 border border-gray-500 rounded-full w-full relative overflow-hidden">
                <div
                  className="h-10 relative bg-gradient-to-tr from-future-blue to-blue-500 rounded-full shadow-sm"
                  style={{
                    width: `${currentModule.playbookPct}%`,
                  }}
                >
                  <p
                    className={classNames(
                      "absolute top-1 text-lg font-medium",
                      currentModule.playbookPct > 80
                        ? "right-4 text-white"
                        : "-right-10 text-topia-black"
                    )}
                  >
                    {currentModule.playbookPct}%
                  </p>
                </div>
              </div>

              <div className="flex flex-row justify-end">
                {/* <p className="text-lg text-gray-800">$0/month</p> */}
                {/* <p className="text-lg text-gray-300">$2k/month</p> */}
              </div>
            </div>
          )}
        </div>

        {windowWidth > 900 ? (
          <div className="flex flex-row  w-full">
            <div className="w-[20vw] flex-0 p-4">
              {stages.map((stage, stageIx) => (
                <div key={stage} className="mb-2">
                  <div className="p-4 bg-neutral-700 shadow-md rounded-lg">
                    <p className="text-2xl text-white">
                      {stageIx + 1}. {upperFirstLetter(stage)}
                    </p>
                    {modulesByStage[stageIx].map((module) => (
                      <div
                        key={module.id}
                        className={`flex ${
                          module.id === currentModuleId
                            ? "bg-slate-600 p-2 rounded-md"
                            : ""
                        } flex-row items-start gap-4 my-2`}
                      >
                        <p className="text-lg">
                          {dataCtx.user.moduleResults[module.id] ? "✅" : "□"}
                        </p>
                        <a
                          className="text-white cursor-pointer text-xs hover:underline"
                          onClick={() => setCurrentModuleId(module.id)}
                        >
                          {module.moduleLabel || module.title}
                          {dataCtx.user.moduleResults[module.id]
                            ?.finishedAt && (
                            <p className="text-[10px] font-black text-neutral-400">
                              {new Date(
                                dataCtx.user.moduleResults[module.id].finishedAt
                              ).toLocaleString()}
                            </p>
                          )}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex-1 py-4 pr-4 h-full">
              <div className="w-full">
                <div className="max-w-6xl mx-auto w-full">
                  <div className=" rounded-lg shadow-lg border-2 border-neutral-900">
                    {currentModuleId && (
                      <DashboardModule
                        configId={currentModuleId as any}
                        noBack={true}
                        onNext={(nextId) => {
                          setCurrentModuleId(nextId);
                        }}
                        onQuestionOpen={(questionOpen) => {
                          setQuestionModalOpen(questionOpen);
                        }}
                        noLockOut
                      />
                    )}
                  </div>
                  {DASHBOARD_MODULES[currentModuleId]?.outsourceCTALabel && (
                    <OutsourcePanel
                      label={
                        DASHBOARD_MODULES[currentModuleId]?.outsourceCTALabel
                      }
                      pitchCopy={
                        DASHBOARD_MODULES[currentModuleId].outsourcePitchCopy
                      }
                      price={
                        DASHBOARD_MODULES[currentModuleId]
                          .outsourcePrice as string
                      }
                      stripeButton={
                        <div
                          className="topia-stripe-buy-button-card"
                          dangerouslySetInnerHTML={{
                            __html: DASHBOARD_MODULES[currentModuleId].stripe,
                          }}
                        ></div>
                      }
                    />
                    // <a
                    //   className="block p-4 rounded-md shadow-md my-4 bg-future-blue border border-black/20"
                    //   href="/join-the-inner-circle"
                    //   target="_blank"
                    // >
                    //   <div className="flex flex-col items-center md:items-center md:flex-row gap-8">
                    //     <div className="">
                    //       {DASHBOARD_MODULES[currentModuleId].stripe && (
                    //         <div
                    //           className="topia-stripe-buy-button-card"
                    //           dangerouslySetInnerHTML={{
                    //             __html:
                    //               DASHBOARD_MODULES[currentModuleId].stripe,
                    //           }}
                    //         ></div>
                    //       )}
                    //     </div>
                    //     <div>
                    //       <div className="flex flex-row gap-4 items-center mb-2">
                    //         <Whistle width={100} height={100} />
                    //         <h3 className="text-3xl text-topia-black">
                    //           {
                    //             DASHBOARD_MODULES[currentModuleId]
                    //               .outsourceCTALabel
                    //           }
                    //         </h3>
                    //       </div>
                    //       <p className="text-lg text-topia-black">
                    //         {
                    //           DASHBOARD_MODULES[currentModuleId]
                    //             .outsourcePitchCopy
                    //         }
                    //       </p>
                    //       {/* <div className="p-4 md:w-1/3 border border-topia-black mt-1 rounded-full bg-future-blue flex items-center justify-center">
                    //         <p className="text-center  text-topia-black text-lg font-bold">
                    //           {
                    //             DASHBOARD_MODULES[currentModuleId]
                    //               .outsourcePrice
                    //           }
                    //         </p>
                    //       </div> */}
                    //     </div>
                    //   </div>
                    // </a>
                  )}
                  {!dataCtx.user?.isInTheInnerCircle && !questionModalOpen && (
                    <JoinInnerCircleCTA />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex my-4 flex-row justify-center">
            <div className="flex w-[95%] flex-col gap-10">
              <div>
                <h2 className="text-white text-lg text-center mb-3">
                  Blueprint Steps
                </h2>
                <Select
                  isSearchable={false}
                  options={selectorValues}
                  value={currentSelectorValue.options[0].label}
                  onChange={(val) => {
                    //@ts-ignore
                    setCurrentModuleId(val.value);
                  }}
                  placeholder={
                    DASHBOARD_MODULES[currentModuleId]?.moduleLabel ||
                    DASHBOARD_MODULES[currentModuleId]?.title
                  }
                  styles={{
                    groupHeading: (base, { data }) => {
                      return {
                        ...base,
                        color: "black",
                        fontSize: 30,
                      };
                    },

                    option: (base, { data, label, type }) => ({
                      ...base,
                      fontStyle:
                        data.value === currentModuleId ? "italic" : "normal",
                      backgroundColor:
                        data.value === currentModuleId ? "#d4cecd" : "",
                      //@ts-ignore
                      color: data.isCompleted ? "green" : "black",
                    }),
                    menu: (base, props) => ({
                      backgroundColor: "white",
                      zIndex: 60,
                    }),
                  }}
                />
              </div>
              <div className="mb-4 rounded-lg shadow-lg border-2 border-neutral-900">
                {currentModuleId && (
                  <DashboardModule
                    configId={currentModuleId as any}
                    noBack={true}
                    onNext={(nextId) => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setCurrentModuleId(nextId);
                    }}
                    onQuestionOpen={(questionOpen) => {
                      setQuestionModalOpen(questionOpen);
                    }}
                    noLockOut
                  />
                )}
              </div>
              {DASHBOARD_MODULES[currentModuleId]?.outsourceCTALabel && (
                <OutsourcePanel
                  label={DASHBOARD_MODULES[currentModuleId]?.outsourceCTALabel}
                  pitchCopy={
                    DASHBOARD_MODULES[currentModuleId].outsourcePitchCopy
                  }
                  price={
                    DASHBOARD_MODULES[currentModuleId].outsourcePrice as string
                  }
                  stripeButton={
                    <div
                      className="topia-stripe-buy-button-card"
                      dangerouslySetInnerHTML={{
                        __html: DASHBOARD_MODULES[currentModuleId].stripe,
                      }}
                    ></div>
                  }
                />
              )}
              {!dataCtx.user?.isInTheInnerCircle && !questionModalOpen && (
                <JoinInnerCircleCTA />
              )}
            </div>
          </div>
        )}
      </DummyLoader>
    </div>
  );
}

function JoinInnerCircleCTA() {
  return (
    <div className="block p-4 my-4 rounded-xl shadow-md bg-gradient-to-tr from-pale-yellow to-future-blue border-2 border-black">
      <div className="flex flex-col items-center md:items-start md:flex-row gap-8">
        <div>
          <div className="flex flex-row justify-between items-center gap-4 mb-4">
            <img
              src="/grant.jpg"
              className="w-[150px] h-[150px] rounded-full border  border-topia-black"
            />
            <div className="flex flex-col flex-1">
              <p className="text-md text-center text-neutral-700">
                Are you ready to start
              </p>
              <p className="text-3xl text-center leading-[54px] text-left text-topia-black font-black">
                Fast-Tracking Your Progress?
              </p>
            </div>
          </div>
          <ol className="text-md flex flex-col md:grid md:grid-cols-2 gap-10 text-topia-black list-decimal px-4">
            <li className="">
              <strong>Personalized Feedback:</strong>&nbsp; Work directly with
              Grant to build and grow your business, he'll be there to help you
              at every step from idea all the way through scaling to a six
              figure+ business.
            </li>
            <li className="">
              <strong>Regular Check-ins with Grant</strong>&nbsp; Utilize this
              opportunity to interact directly with Grant in our weekly calls.
              Discuss your progress, get your questions answered, and stay
              aligned with your goals.
            </li>
            <li className="">
              <strong>Access to Expert Resources:</strong>&nbsp; As part of
              Grant's circle, tap into an exclusive network of tools and
              contacts, from cutting edge SEO solutions to top-tier consultants,
              all to enhance your personal growth.
            </li>
            <li className="">
              <strong>In-person event in NYC:</strong> A truly transformative
              experience with Grant, hear from keynote speakers, connect with
              high-achieving FIpreneurs, and accelerate your journey.{" "}
            </li>
          </ol>
          <a
            className="p-4 mt-8 rounded-full bg-topia-black flex flex-col items-center justify-center transition-all hover:scale-[103%]"
            target="_blank"
            href="/join-the-inner-circle"
          >
            <p className="text-center text-white text-lg font-bold">
              Join Grant's Inner Circle
            </p>
            <p className="text-xs text-neutral-300">$149/month</p>
          </a>
        </div>
      </div>
    </div>
  );
}
