import classNames from "classnames";

export function RippleLoader(props: {
  loaderClassNames?: any;
  rippleClassNames?: any;
}) {
  return (
    <div
      className={classNames(
        "flex items-center justify-center py-32 w-full",
        props.loaderClassNames
      )}
    >
      <div className={classNames("lds-ripple", props.rippleClassNames)}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
