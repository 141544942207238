import classNames from "classnames";
import {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import {
  TopiaButton,
  TopiaSelect,
  TopiaTextInput,
} from "@topia-app/topia-react-web";
import { chatGptRequest } from "../api";
import { IllustrationsCubeCyllinder, VSCar } from "../svg";
import { BarLoader } from "./BarLoader";
import { mixpanelTrack } from "../helpers";

const TOPI_CHAT_BUBBLE_PROPS = {
  isUser: false,
  name: "Sarah",
  photo: <IllustrationsCubeCyllinder width={96} height={96} />,
  isLoading: false,
};

const USER_CHAT_BUBBLE_PROPS = {
  isUser: true,
  name: "You",
  photo: <VSCar width={96} height={96} />,
  isLoading: false,
};

export type TopiaChatBotConfig = {
  welcomeMessage: string;
  emailMessage: string;
  thankyouMessage: string;
  prompts: {
    systemPrompt: string;
    questions: {
      key: string;
      message: string;
      inputType: "string" | "prompt-followup";
    }[];
  }[];
};

type TopiaChatBotState = {
  chatLog: ChatBubbleProps[];
  //   email: string;
  inputs: Record<string, string>;
  promptResults: Record<number, string>;
  promptIx: number;
  inputOptions?: string[];
  chatState:
    | ({
        type: "question";
        questionIx: number;
      } & (
        | { inputType: "string" }
        | { inputType: "select"; options: string[] }
      ))
    | {
        type: "loading";
      }
    | {
        type: "done";
      };
};

type TopiaChatBotActions =
  | {
      type: "submit-response";
      response: string;
    }
  | {
      type: "submit-gpt-response";
      response: string;
    }
  | {
      type: "force-set-state";
      state: TopiaChatBotState;
    }
  | {
      type: "restart";
    };

function makeChatBotReducer(config: TopiaChatBotConfig) {
  function makeChatBubble(body: string, isUser: boolean): ChatBubbleProps {
    return {
      ...(isUser ? USER_CHAT_BUBBLE_PROPS : TOPI_CHAT_BUBBLE_PROPS),
      body: <div style={{ whiteSpace: "pre-wrap" }}>{body}</div>,
    };
  }

  function getPromptQuestion(
    promptIx: number,
    numberIx: number
  ): ChatBubbleProps {
    return makeChatBubble(
      config.prompts[promptIx].questions[numberIx].message,
      false
    );
  }

  function getNextQuestionState(
    state: TopiaChatBotState,
    questionIx: number
  ): TopiaChatBotState["chatState"] {
    let nextQuestionIx = questionIx + 1;

    if (nextQuestionIx >= config.prompts[state.promptIx].questions.length) {
      return {
        type: "loading",
      };
    }

    let q = config.prompts[state.promptIx].questions[nextQuestionIx];

    return {
      type: "question",
      questionIx: nextQuestionIx,
      inputType: "string",
    };
  }

  return function topiaChatBotReducer(
    state: TopiaChatBotState,
    action: TopiaChatBotActions
  ): TopiaChatBotState {
    switch (action?.type) {
      case "submit-gpt-response": {
        mixpanelTrack("chatbot_see_gpt_result");
        const newChatLog = [...state.chatLog];

        const newPromptIx = state.promptIx + 1;
        let newChatState: TopiaChatBotState["chatState"];

        if (newPromptIx < config.prompts.length) {
          // TODO: Make more flexible, in the future not all followup questions may need a select

          const content = action.response
            .trim()
            .split("\n")
            .map((x) => {
              const parts = x.split(" - ");
              return {
                headline: parts[0],
                body: parts[1],
              };
            });

          newChatState = {
            type: "question",
            questionIx: 0,
            inputType: "select",
            options: content.map((x) =>
              x.headline.split(".").slice(1, Infinity).join(".")
            ),
          };

          newChatLog.push({
            ...TOPI_CHAT_BUBBLE_PROPS,
            body: (
              <div>
                {content.map((x) => (
                  <div key={x.headline} className="mb-2">
                    <p className="text-lg font-black text-white">
                      {x.headline}
                    </p>
                    <p className="text-md text-white">{x.body}</p>
                  </div>
                ))}
              </div>
            ),
          });
          newChatLog.push(getPromptQuestion(newPromptIx, 0));
        } else {
          newChatLog.push(makeChatBubble(action.response, false));
          mixpanelTrack("chatbot_complete");
          newChatState = {
            type: "done",
          };
          // if (config.thankyouMessage) {
          //   newChatLog.push({
          //     ...TOPI_CHAT_BUBBLE_PROPS,
          //     body: (
          //       <>
          //         <div style={{ whiteSpace: "pre-wrap" }}>
          //           {config.thankyouMessage}
          //         </div>
          //         <div className="mt-4">
          //           <a
          //             href="https://course.grantsabatier.com/enroll/2510572?price_id=3405286"
          //             target="_blank"
          //             className="mt-4 rounded-full border border-topia-black bg-pale-yellow p-4 px-7 text-center text-lg font-black text-topia-black"
          //           >
          //             Join the FIpreneurs
          //           </a>
          //         </div>
          //       </>
          //     ),
          //   });
          // }
        }

        return {
          ...state,
          promptResults: {
            ...state.promptResults,
            [state.promptIx]: action.response,
          },
          chatLog: newChatLog,
          promptIx: newPromptIx,
          chatState: newChatState,
        };
      }
      case "submit-response": {
        if (state.chatState.type !== "question") {
          return state;
        }
        mixpanelTrack("chatbot_submit_response", {
          promptIndex: state.promptIx,
          questionIndex: state.chatState.questionIx,
        });

        const newState = getNextQuestionState(
          state,
          state.chatState.questionIx
        );

        const newChatLog = [
          ...state.chatLog,
          makeChatBubble(action.response, true),
        ];

        if (newState.type === "question") {
          newChatLog.push(
            getPromptQuestion(state.promptIx, newState.questionIx)
          );
        }

        return {
          ...state,
          inputs: {
            ...state.inputs,
            [config.prompts[state.promptIx].questions[
              state.chatState.questionIx
            ].key]: action.response,
          },
          chatLog: newChatLog,
          chatState: newState,
        };
      }
      case "restart":
        mixpanelTrack("chatbot_restart");
        return {
          ...state,
          promptIx: 0,
          chatLog: [
            makeChatBubble(config.welcomeMessage, false),
            getPromptQuestion(0, 0),
          ],
          chatState: {
            type: "question",
            inputType: "string",
            questionIx: 0,
          },
        };
      case "force-set-state":
        return action.state;
    }
  };
}

async function makeChatGptRequest(input: {
  systemPrompt: string;
  inputs: Record<string, string>;
  //   email: string;
}) {
  try {
    const response = await chatGptRequest(input);
    return (response.data as any).gptResult;
  } catch (ex) {
    console.error(ex);
    return "Sorry, something went wrong. Please try again.";
  }
}

export function TopiaChatBot(props: {
  config: TopiaChatBotConfig;
  children?: any;
}) {
  //   const userCtx = useUserCtx();

  useLayoutEffect(() => {
    mixpanelTrack("chatbot_open");
  }, []);

  const scrollRef = useRef<HTMLDivElement>();

  const [input, setInput] = useState("");
  const [fullscreen, setFullscreen] = useState(false);
  const [chatLog, setChatLog] = useState<ChatBubbleProps[]>([]);

  const initialState: TopiaChatBotState = {
    chatLog: [
      {
        ...TOPI_CHAT_BUBBLE_PROPS,
        body: props.config.welcomeMessage,
      },
      {
        ...TOPI_CHAT_BUBBLE_PROPS,
        body: props.config.prompts[0].questions[0].message,
      },
    ],
    promptIx: 0,
    inputs: {},
    promptResults: {},
    chatState: {
      type: "question",
      inputType: "string",
      questionIx: 0,
    },
  };

  const [state, dispatch] = useReducer(
    makeChatBotReducer(props.config),
    initialState
  );

  useEffect(() => {
    setChatLog([]);
    dispatch({
      type: "force-set-state",
      state: initialState,
    });
  }, [props.config.welcomeMessage]);

  // Sync chatLog with state.chatLog
  // This is so multiple messages sent at once show up with a proper animation and delay
  useEffect(() => {
    let _cancelled = false;

    (async () => {
      if (chatLog.length > state.chatLog.length) {
        setChatLog(state.chatLog);
      } else if (chatLog.length < state.chatLog.length) {
        setChatLog(state.chatLog.slice(0, chatLog.length));
        for (let i = chatLog.length; i < state.chatLog.length; i++) {
          if (_cancelled) return;
          setChatLog(state.chatLog.slice(0, i + 1));
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      }
    })();

    return () => {
      _cancelled = true;
    };
  }, [state.chatLog]);

  // Watch for 'loading' state and make chatgpt request
  useEffect(() => {
    let _cancelled = false;

    if (state.chatState.type === "loading") {
      (async () => {
        try {
          const currentPrompt = props.config.prompts[state.promptIx];
          const inputs = {};
          for (let q of currentPrompt.questions) {
            inputs[q.key] = state.inputs[q.key];
          }
          const result = await makeChatGptRequest({
            // email: state.email,
            systemPrompt: currentPrompt.systemPrompt,
            inputs,
          });
          if (_cancelled) {
            return;
          }
          dispatch({
            type: "submit-gpt-response",
            response: result,
          });
        } catch (ex) {
          // TODO: Error handling
          console.error(ex);
        }
      })();
    }

    if (
      state.chatState.type === "question" &&
      state.chatState.inputType === "select"
    ) {
      setInput(state.chatState.options[0]);
    }

    return () => {
      _cancelled = true;
    };
  }, [state.chatState]);

  useEffect(() => {
    scrollRef?.current?.scrollTo({
      left: 0,
      top: 999999,
    });
  }, [chatLog.length]);

  return (
    <div
      className={`flex flex-col overflow-hidden rounded-xl border-b-4 border-topia-black bg-future-blue`}
    >
      {props.children && <div className="mb-4 px-4">{props.children}</div>}
      <div className={`relative px-4`}>
        <div
          className={
            "mx-auto flex h-[70vh] max-w-5xl flex-col overflow-y-scroll rounded-xl py-8"
          }
          ref={scrollRef}
        >
          {chatLog.map((item, ix) => (
            <ChatBubble
              key={item.body + ix}
              {...item}
              hideName={
                chatLog[ix + 1] && chatLog[ix + 1].isUser === item.isUser
              }
            />
          ))}
          {state.chatState.type === "loading" && (
            <ChatBubble
              {...TOPI_CHAT_BUBBLE_PROPS}
              isLoading
              body={"lorum ipsum dolor set amut\nlorum ipsum"}
            />
          )}
        </div>
        <div className="rounded-tr-2xl rounded-tl-2xl bg-royal-purple p-4 px-8">
          <form
            onSubmit={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();

              //   if (state.chatState.type === "email") {
              //     // mixpanelTrack('chatbot_capture_email')
              //     dispatch({
              //       type: "set-email",
              //       email: input,
              //     });
              //     userCtx.setEmail(input);
              //   } else {
              dispatch({
                type: "submit-response",
                response: input,
              });

              //   }
              setInput("");
            }}
          >
            {state.chatState.type === "question" && (
              <div className="mb-4">
                {state.chatState.inputType === "string" && (
                  <TopiaTextInput
                    className="w-full"
                    label="Response"
                    inputProps={{
                      value: input,
                      onChange: (e) => setInput(e.target.value),
                    }}
                  />
                )}
                {state.chatState.inputType === "select" && (
                  <TopiaSelect
                    className="w-full"
                    label="Response"
                    options={state.chatState.options.map((o) => ({
                      label: o,
                      value: o,
                    }))}
                    id="response"
                    inputProps={{
                      value: input,
                      onChange: (e) => setInput(e.target.value),
                    }}
                  />
                )}
              </div>
            )}
            <div className="flex flex-row gap-4">
              {state.chatState.type === "question" && (
                <>
                  {state.chatState.inputType === "string" && (
                    <TopiaButton
                      btnStyle="future"
                      className="flex-1"
                      onClick={() => {
                        dispatch({
                          type: "submit-response",
                          response: "I'm not sure",
                        });
                        setInput("");
                      }}
                    >
                      I'm not sure
                    </TopiaButton>
                  )}
                  <TopiaButton
                    btnStyle="primary"
                    type="submit"
                    className="flex-1"
                  >
                    Submit
                  </TopiaButton>
                </>
              )}
              {state.chatState.type === "done" && (
                <TopiaButton
                  btnStyle="future"
                  className="flex-1"
                  onClick={() => dispatch({ type: "restart" })}
                >
                  Start Over
                </TopiaButton>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

type ChatBubbleProps = {
  body: any;
  photo: any;
  name: any;
  isUser: boolean;
  isLoading: boolean;
  hideName?: boolean;
};

function ChatBubble(props: ChatBubbleProps) {
  return (
    <div className="topia-chat-bubble mb-2 flex flex-col">
      <div
        className={classNames(
          "relative w-full rounded-xl p-6",
          props.isUser
            ? "rounded-bl-sm bg-white"
            : "rounded-br-sm bg-topia-black"
        )}
      >
        <p
          className={classNames(
            props.isUser ? "text-rgb-blue" : "text-white",
            "text-sm",
            props.isLoading && "blur-sm"
          )}
        >
          {props.isLoading
            ? "Lorum ipsum dolor set amut lorum ipsum dolor set amut lorim lorum ipsum dolor set amut"
            : props.body}
        </p>
        {props.isLoading && (
          <div className="insets-0 absolute flex w-full justify-center">
            <div className="-mt-4">
              <BarLoader color="white" />
            </div>
          </div>
        )}
      </div>
      {!props.hideName && (
        <div
          className={classNames(
            "relative z-10 -mt-6 flex flex-row items-center  gap-2",
            !props.isUser && "justify-end"
          )}
        >
          <p className="text-lg font-black text-topia-black">{props.name}</p>
          <div className="h-[96px] w-[96px] rounded-full">{props.photo}</div>
        </div>
      )}
    </div>
  );
}
