import { TopiaButton, TopiaEmailInput } from "@topia-app/topia-react-web";
import { collection, doc, setDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { db } from "../firebase";
import { useAuthContext } from "../auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "../hooks/useQuery";
const beachImage = require("../Images/beach.png");
export default function FreeAccountSignUpPage() {
  const authCtx = useAuthContext();
  const navigate = useNavigate();
  const query = useQuery();
  const flow = query.get("flow");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [err, setErr] = useState<any>();
  const onSubmit = async (vals: any) => {
    try {
      const user = await authCtx.signUpAnonymously();

      if (!flow) {
        await setDoc(doc(collection(db, "users"), user.user.uid), {
          email: vals.email.trim(),
          created_free_account: true,
        });
      } else {
        await setDoc(doc(collection(db, "users"), user.user.uid), {
          email: "",
          created_free_account: true,
          flow: flow,
        });
      }
      navigate("/");
    } catch (ex) {
      console.log("ERROR", ex);
      if (ex.code === "auth/email-already-in-use") {
        setErr(
          <div className="p-4">
            <Link to="/login">
              <p className="text-red-500">Email already in use</p>
              <p className="text-red-500">Try logging in</p>
            </Link>
          </div>
        );
      }
      console.error(ex);
    }
  };
  return (
    <div className="flex items-center justify-center p-8">
      {flow === "UK_canopy_rent" ? (
        <div className={`max-w-[1000px] flex flex-col items-center gap-4`}>
          <h1 className="my-4 text-xl text-center md:text-3xl -mb-2 md:mb-0 tracking-tight text-white font-serif font-light">
            Unlock more freedom in your life
          </h1>
          <h2
            className={`text-md text-white text-center md:text-xl font-sans `}
          >
            How would an additional £500/£1k/£2k each month change your world?
          </h2>
          <img src={beachImage} className={`my-5`} />
          <p className={`text-white text-sm md:text-lg text-center`}>
            Try our custom-built AI idea generation tool. Crafted to explore
            your passions and interests, it creates customized side business
            ideas just for you—so you can be your own boss and live a life you
            love.
          </p>
          <TopiaButton
            className={`w-full`}
            onClick={() => {
              onSubmit({});
            }}
          >
            Get started
          </TopiaButton>
        </div>
      ) : (
        <div className="w-full max-w-[500px]">
          <div className="w-full">
            {/* <div className="flex items-center justify-center">
            <img
              src="/logo.svg"
              className="max-w-[300px] h-auto"
              style={{
                filter: "invert(1)",
              }}
            />
          </div> */}
            <h2 className="my-8 text-2xl text-center md:text-5xl  tracking-tight text-white font-serif font-light">
              Enter your email to get started
            </h2>
            {/* <p className="text-white text-md mb-3 md:mb-6">
            Now, let's create your member's portal account where you'll access
            all of your new side business playbooks, resources, community, and
            tools.
          </p> */}
          </div>

          <div className="w-full mt-2">
            <div className="">
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <TopiaEmailInput
                    label="Email address"
                    inputProps={register("email", {
                      required: true,
                      onChange: (ev) => {
                        // console.log(ev);
                      },
                    })}
                    id="email"
                    className=""
                  />
                </div>
                {/* <a
                href="/"
                target="_blank"
                className="underline text-slate-300 my-4 hover:text-future-blue hover:cursor-pointer"
              >
                Already have an account?
              </a> */}

                <div>
                  <TopiaButton
                    analyticsEvent="sign_up_page_submit_clicked"
                    type="submit"
                    className="w-full"
                    btnStyle="primary"
                    //   loading={loading}
                  >
                    Create Account
                  </TopiaButton>
                </div>
              </form>

              <p className="text-xs text-white mt-2">
                By creating your account you agree to the FIPreneur Community{" "}
                <a
                  href="/fipreneurs_terms_and_conditions.pdf"
                  className="text-future-blue underline"
                  target="_blank"
                >
                  Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  href="/fipreneurs_community_privacy_policy.pdf"
                  className="text-future-blue underline"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </p>
              {err}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
