import { TopiaChatBot, TopiaChatBotConfig } from "./TopiaChatBot";
import stories from "../stories.json";

export const TBChatBot = ({ blok }) => {
  const prompt = stories.find((x) => x.uuid === blok.prompt_type);

  const config: TopiaChatBotConfig = {
    emailMessage: prompt.content.email_question_prompt,
    thankyouMessage: prompt.content.thankyou_message,
    welcomeMessage: prompt.content.welcome_message,
    prompts: [
      {
        systemPrompt: prompt.content.system_prompt,
        questions: prompt.content.questions.map((q) => ({
          key: q.prompt_field,
          message: q.chatbot_message,
          inputType: "string",
        })),
      },
    ],
  };

  if (prompt.content.follow_up_question_enabled) {
    config.prompts.push({
      systemPrompt: prompt.content.follow_up_system_prompt,
      questions: [
        {
          key: "product idea",
          inputType: "prompt-followup",
          message: prompt.content.follow_up_question_message,
        },
      ],
    });
  }

  return (
    <TopiaChatBot config={config}>
      {/* <h2 className="text-center text-4xl text-topia-black">Sarah</h2>
      <p className="text-center text-lg font-bold">
        Your AI Powered Side Hustle Copilot
      </p> */}
    </TopiaChatBot>
  );
};
