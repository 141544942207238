import { CheckIcon } from "@heroicons/react/24/outline";
import { useDataContext } from "../context/DataContext";
import { useModalContext } from "../context/ModalContext";
import { Link, useNavigate } from "react-router-dom";

export function InnerCirclePitch() {
  const dataCtx = useDataContext();
  const modalCtx = useModalContext();

  const tiers = [
    {
      name: "Standard FIPreneur",
      id: "tier-standard",
      className: "md:col-span-2",
      current: dataCtx.user.isInTheInnerCircle !== true,
      priceMonthly: "$79",
      // description: "Your current membership:",
      features: [
        "Personalized Idea Discovery with Grant",
        "AI powered side business tools ",
        "Bi-Weekly Coaching with Grant",
        "Accountability Power Hours",
        "8 Module 4k Financial Freedom Course",
      ],
    },
    {
      name: "Grant's Inner Circle",
      id: "tier-inner-circle",
      className: "md:col-span-3",
      href: "__inner-circle",
      current: dataCtx.user.isInTheInnerCircle === true,
      priceMonthly: "$149",
      priceDiff: "$70",
      postCTA:
        "Our Committment to You: We're so confident in the value Grant brings, we guarantee it. If you do not see tangible progress in 30 days, you'll receive a full refund no questions asked.",
      features: [
        "Customized Business Roadmap",
        "Unlimited Direct Access to Grant for Personalized Advice & Support ",
        "Access to Exclusive Inner Circle Weekly Calls",
        "Access to Grant’s invite only in-Person event in NYC",
        "All of the features included in the Standard FIPreneur membership",
      ],
    },
  ];

  return (
    <div className="w-full">
      {/* <p className="text-2xl font-bold mb-8 px-8 text-topia-black text-center">
        Accelerate Your Success in Grant's Inner Circle
      </p> */}
      <div className="relative z-10 flex flex-col items-center md:flex-1 md:flex-row gap-4 md:justify-center">
        <div className="md:mr-8 flex flex-col items-center">
          <div className="w-[200px] h-[200px]">
            <img
              src="/grant.jpg"
              className="w-full h-auto rounded-full shadow-md"
            />
          </div>
          <p className="text-lg mt-2 mb-2 font-black text-center text-topia-black">
            Grant Sabatier
          </p>
          <ul className="list-disc flex-col gap-2 text-xs">
            <li>International best selling author</li>
            <li>Generates $1M+ in Annual Revenue from 8x businesses</li>
            <li>Sold The 'Millennial Money' Blog for an 8-Figure Sum</li>
          </ul>
        </div>
        <div>
          <ol className="text-lg leading-6 md:m-0 mt-4 text-topia-black list-decimal">
            <li className="mb-1">
              <strong>Personalized Feedback:</strong>&nbsp; Work directly with
              Grant to build and grow your business, he'll be there to help you
              at every step from idea all the way through scaling to a six
              figure+ business.
            </li>
            <li className="mb-1">
              <strong>Regular Check-ins with Grant</strong>&nbsp; Utilize this
              opportunity to interact directly with Grant in our weekly calls.
              Discuss your progress, get your questions answered, and stay
              aligned with your goals.
            </li>
            <li className="mb-1">
              <strong>Access to Expert Resources:</strong>&nbsp; As part of
              Grant's circle, tap into an exclusive network of tools and
              contacts, from cutting edge SEO solutions to top-tier consultants,
              all to enhance your personal growth.
            </li>
            <li className="mb-1">
              <strong>In-person event in NYC:</strong> A truly transformative
              experience with Grant, hear from keynote speakers, connect with
              high-achieving FIpreneurs, and accelerate your journey.{" "}
            </li>
          </ol>

          <a
            href={"/__inner-circle"}
            className="mt-4 block rounded-full bg-topia-black p-2 text-center leading-6 text-white hover:bg-neutral-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rgb-blue border border-topia-black shadow-md"
          >
            <span className="text-md font-semibold block">Join Now</span>
            <span className="text-xs block">
              Only <strong>$149</strong>/month
            </span>
          </a>
          <p className="text-center text-sm mb-6 mt-1 md:my-1">
            30-day, 100% money-back guarantee
          </p>
        </div>
      </div>

      <div className="relative mt-20">
        <div className="absolute z-0 -top-[12%] left-[-50%] w-[200%] h-[140%] rotate-[-1deg] bg-future-blue"></div>
        <div className="absolute z-0 -top-[14%] left-[-50%] w-[200%] h-[1%] rotate-[-1deg] bg-future-blue"></div>
        <div className="absolute z-0 -bottom-[30%] left-[-50%] w-[200%] h-[1%] rotate-[-1deg] bg-future-blue"></div>
        <h1 className="text-2xl z-1 relative mt-12 mb-8 mx-auto px-8 text-topia-black text-center">
          Hear how Grant's advice has impacted other FIpreneurs
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 place-items-end mt-4 gap-4 z-1 relative">
          <div className="topia-fade-in">
            <p className="text-center mb-1">
              Grant helped boost Brady's sales from $20 to $750 a month
            </p>
            <iframe
              src={`https://share.descript.com/embed/bk10PGnCDDR`}
              width="100%"
              height="280"
              frameBorder="0"
              className="rounded-lg"
              allowFullScreen
            ></iframe>
          </div>
          <div className="topia-fade-in">
            <p className="text-center mb-1">
              Grant's Gives Claire the Framework to Start Monetizing Her Blog
            </p>
            <iframe
              src={`https://share.descript.com/embed/YF0UMbJ7wcV`}
              width="100%"
              height="280"
              frameBorder="0"
              className="rounded-lg"
              allowFullScreen
            ></iframe>
          </div>
          <div className="topia-fade-in">
            <p className="text-center mb-1">
              Grant Guided Jess to a $1M/Year Blogging Niche
            </p>
            <iframe
              src={`https://share.descript.com/embed/0Axb3QG8A9W`}
              width="100%"
              height="280"
              frameBorder="0"
              className="rounded-lg"
              allowFullScreen
            ></iframe>
            {/* <video controls={true} className="aspect-video">
            <source src={"/grant-welcome.mp4"} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          </div>
        </div>
      </div>

      <div className="relative z-10 mt-8 grid w-full p-4 grid-cols-1 gap-8 lg:grid-cols-5 flex-1">
        {tiers.map((tier) => (
          <div
            key={tier.id}
            className={`flex flex-col justify-between rounded-3xl bg-${
              tier.id === "tier-inner-circle" ? "pale-yellow" : "white"
            } p-4 shadow-xl ring-1 ring-gray-900/10 sm:p-6 ${tier.className}`}
          >
            <div>
              <h3
                id={tier.id}
                className="text-base font-semibold leading-7 text-rgb-blue"
              >
                {tier.name}
              </h3>
              <div className="mt-4 flex items-baseline gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">
                  {tier.priceMonthly}
                </span>
                <span className="text-base font-semibold leading-7 text-gray-600">
                  /month
                </span>
              </div>
              {tier.priceDiff && (
                <div className="mt-0 flex items-baseline">
                  <span className="text-base font-semibold leading-7 text-gray-600 mr-2">
                    Only an extra
                  </span>
                  <span className="text-lg font-bold tracking-tight text-gray-900">
                    {tier.priceDiff}
                  </span>
                  <span className="text-base font-semibold leading-7 text-gray-600">
                    /month
                  </span>
                </div>
              )}
              {/* <p className="mt-6 text-base leading-7 text-gray-600">
                      {tier.description}
                    </p> */}
              <ul
                role="list"
                className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-rgb-blue"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            {tier.href && (
              <a
                aria-describedby={tier.id}
                href={tier.href}
                className="mt-8 block rounded-full bg-rgb-blue p-4 text-center leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rgb-blue border border-topia-black shadow-md"
              >
                <span className="text-xl font-semibold block">Join Now</span>
                <span className="text-sm block">
                  Only <strong>$4.80</strong>/day
                </span>
              </a>
            )}

            {tier.postCTA && (
              <p className="text-xs mt-4 px-4 text-topia-black text-center">
                {tier.postCTA}
              </p>
            )}
          </div>
        ))}
      </div>
      {/* <a
        className="text-topia-black font-black text-2xl bg-future-blue mt-4 mx-auto max-w-[450px] rounded-full border-2 shadow-md border-topia-black flex items-center justify-center p-4 px-8"
        href="https://course.grantsabatier.com/enroll/2510572?price_id=3668982"
      >
        Let's get started
      </a>

      <p className="text-center text-md text-topia-black mb-4 mt-1">
        $149/month. Cancel anytime.
      </p>

      <p className="text-center text-md text-topia-black mb-4 mt-1">
        Limited spots available.
      </p> */}
    </div>
  );
}
