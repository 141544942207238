import { TbBrandIntercom } from "react-icons/tb";
import { HiOutlineMail } from "react-icons/hi";
import { BsChatText } from "react-icons/bs";
import { Accordion } from "../components/Accordion";
import { mixpanelTrack } from "../helpers";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useModalContext } from "../context/ModalContext";
import { TopiaButton } from "@topia-app/topia-react-web";
import { CancelSubscriptionModal } from "../components/CancelSubscriptionModal";
import { YoutubeEmbed } from "../components/YoutubeEmbed";

export function CommunityInfoPage() {
  const modalCtx = useModalContext();

  useEffect(() => {
    mixpanelTrack(`page_view_community_info`);
  }, []);
  const faqs: { header: string; detail: any }[] = [
    {
      header: "I've just joined, what should I do now?",
      detail: `Welcome to the FIpreneur community! Grant is currently reviewing your side business idea. You can expect feedback within the next couple of days. In the meantime, head over to the 'Home' tab to discover your "needle movers" — these are your key actions for getting set up and integrated into our community. While waiting, explore Grant's free Financial Freedom resources and the FIpreneurs Video Vault to kickstart your journey. Once Grant has reviewed your idea, you'll receive your blueprint to begin working through it systematically.`,
    },
    {
      header: "I'm feeling stuck, what should I do?",
      detail: (
        <p>
          Starting a side business can feel overwhelming, but there's no need to
          worry. Your blueprint offers clear, step-by-step guidance. Look at
          your "needle movers" on the 'Home' tab for the next steps tailored to
          your progress. If challenges persist, please contact Logan Leckie at
          <a
            className="underline text-future-blue"
            href="mailto:logan@fipreneurs.com"
          >
            {" "}
            logan@fipreneurs.com
          </a>
          . As your dedicated community manager, he's here to assist you through
          any obstacles.
        </p>
      ),
    },
    {
      header:
        "I'm worried I don't have enough time to commit. What should I do?",
      detail: `Committing to your side business is about prioritizing your long-term freedom. While it does require some sacrifices, the potential to achieve financial independence is significant. We recommend dedicating a few hours each week to this endeavor if it's a priority for you. If you're unable to commit this time, it may be worth reconsidering your membership to ensure you're making the best choice for your current situation.`,
    },
    {
      header: "I feel like I'm falling behind. What can I do?",
      detail: `Your FIpreneur journey is personal and self-paced. There's no requirement to match the progress of others, as there's no set schedule or timeline. Concentrate on the "needle movers" identified for you, and progress at a pace that suits your lifestyle and goals. Remember, every step forward is a step towards financial independence.`,
    },
    {
      header: "How can I ask Grant a question?",
      detail: `For direct access to Grant and personalized advice, FIpreneur Plus is your gateway. This premium membership allows you to ask Grant unlimited questions, ensuring you receive expert guidance tailored to your needs and challenges. Subscribe to FIpreneur Plus to enhance your journey with us.`,
    },
    {
      header: "What is Slack?",
      detail: `Slack is our chosen platform for community interaction. It allows FIpreneurs like you to connect, exchange ideas, ask questions, and share your journey with peers. This collaborative environment is designed to offer support, foster relationships, and facilitate shared learning among members.`,
    },
    {
      header: "I've missed a group call. Where can I find the recording?",
      detail: (
        <p>
          Missing a live session isn't an issue. All group calls are recorded
          and made available on the{" "}
          <Link className="text-future-blue underline" to={"/weekly-calls"}>
            Weekly Group Calls
          </Link>{" "}
          page for you to view at your convenience. This ensures you're always
          in the loop and benefiting from the collective wisdom of our
          community.
        </p>
      ),
    },
    {
      header: "If I leave the community, can I rejoin later?",
      detail:
        "Membership in the FIpreneur community is exclusive and entry is currently paused. Although we can't specify when we'll reopen for new members, we encourage you to keep in touch and look out for updates regarding future openings. Your continued interest and engagement with our community are valued.",
    },
  ];
  return (
    <div className="p-8 flex flex-col gap-12">
      <section className="xl:grid xl:grid-cols-2 flex flex-col gap-4">
        <div className="">
          <p className="text-white text-lg">Contact Logan with any questions</p>
          <div className="flex flex-col items-start mb-4 md:flex-row md:gap-8">
            <a
              href="mailto:logan@topia-app.com"
              className="flex flex-row   hover:underline text-white cursor-pointer justify-center items-center gap-4"
            >
              <HiOutlineMail size={20} color="#FFFFFF" />
              <p className="text-white">support@fipreneurs.com</p>
            </a>
            {/* <div className=".intercom-launcher flex border rounded-lg p-2 flex-row gap-4  justify-center items-center cursor-pointer">
            <TbBrandIntercom size={30} color="#FFFFFF" />
            <p className="text-white">Intercom</p>
          </div> */}
            <a
              href="tel:13158190582"
              className="flex flex-row items-center hover:underline text-white rounded-lg justify-center gap-4"
            >
              <BsChatText size={20} color="#FFFFFF" />
              <p className="text-white">315-819-0582</p>
            </a>
          </div>
          <p className="text-white text-lg">Legal Disclosures</p>
          <div className="flex flex-col items-start mb-4 md:flex-row md:gap-8">
            <a
              href="/fipreneurs_community_privacy_policy.pdf"
              target="_blank"
              className="flex flex-row   hover:underline text-white cursor-pointer justify-center items-center gap-4"
            >
              <p className="text-white">Privacy Policy</p>
            </a>
            <a
              href="/fipreneurs_terms_and_conditions.pdf"
              target="_blank"
              className="flex flex-row   hover:underline text-white cursor-pointer justify-center items-center gap-4"
            >
              <p className="text-white">Terms &amp; Conditions</p>
            </a>
          </div>
          <div className="w-[90%]">
            <YoutubeEmbed youtubeId="CPNwJXoUdjo" className={"rounded-lg"} />
          </div>
        </div>
        <section className="flex flex-col gap-1 md:gap-4">
          <p className="text-lg text-white">Cancellation Policy</p>
          <p className="text-white">
            Whats the FIpreneurs cancellation policy? If it's not the right fit
            or timing for you, that's okay. We'd love to hear your thoughts and
            feedback, you can let us know here. And remember, we stand by our
            30-day money-back guarantee. Want to cancel? It's hassle-free.
            Simply click the link below and then click on your name or picture
            in the top right of the site header and select{" "}
            <strong>My Account</strong>. Hit cancel on your FIpreneur
            subscription.
          </p>
          <div className=" w-full ">
            <button
              onClick={() => {
                modalCtx.openModal(
                  <CancelSubscriptionModal
                    onDismiss={() => {
                      modalCtx.closeModal();
                    }}
                  />
                );
              }}
              className="p-2 border hover:bg-slate-300/20 text-white"
            >
              Cancel Subscription
            </button>
          </div>
        </section>
      </section>
      <section className="pb-20">
        <p className="text-lg text-white">FAQ's</p>
        {faqs.map((faq, ix) => (
          <Accordion key={ix} detail={faq.detail} header={faq.header} />
        ))}
      </section>
    </div>
  );
}
