import { useState } from "react";
import { useDataContext } from "../context/DataContext";
import { useQuery } from "../hooks/useQuery";
import {
  CheckCircleIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useAuthContext } from "../auth";
import { format } from "date-fns";

export function AskGrantWrittenPage() {
  const dataCtx = useDataContext();
  const authCtx = useAuthContext();
  const [message, setMessage] = useState("");
  const [wantsToDoLiveQA, setWantsToDoLiveQA] = useState(false);

  const messageHistory = [...(dataCtx.user.grantQuestions || [])];

  if (messageHistory[messageHistory.length - 1]?.sentBy === "user") {
    messageHistory.push({
      message: "We have received your question and will get back to you soon",
      sentBy: "fipreneur-team",
      sentAt: messageHistory[messageHistory.length - 1].sentAt,
    });
  }

  const queryParams = useQuery();
  // const [selected, setSelected] = useState();

  function submitMessage() {
    if (message.length < 1) return;
    dataCtx.updateUser({
      grantQuestions: [
        ...messageHistory,
        {
          message,
          sentAt: new Date().toISOString(),
          wantsToDoLiveQA,
          moduleId: queryParams.get("moduleId") || "",
          sentBy: "user",
        },
      ],
    });
    setMessage("");
  }

  return (
    <>
      <div className="p-8 pb-20 m-4 mt-8">
        <h1 className="text-3xl text-center text-white my-4">
          Ask Grant a Question
        </h1>

        <ul role="list" className="space-y-6">
          {messageHistory.map((questionDetail, questionIx) => (
            <li key={questionIx} className="relative flex gap-x-4">
              <div
                className={classNames(
                  // questionIx === activity.length - 1 ? "h-6" : "-bottom-6",
                  "absolute left-0 top-0 flex w-6 justify-center"
                )}
              >
                <div className="w-px bg-neutral-200" />
              </div>
              {/* If it is a question asked by the user it goes here */}
              {questionDetail.sentBy === "user" ? (
                <>
                  <div className="relative flex w-6 h-6 rounded-full flex-none items-center justify-center bg-white">
                    <EllipsisHorizontalIcon
                      className="h-6 w-6 text-royal-purple"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-neutral-500">
                    <div className="flex justify-between gap-x-4">
                      <div className="py-0.5 text-xs leading-5 text-neutral-500">
                        <span className="font-medium text-white">
                          {/* {authCtx.user.displayName} */}You
                        </span>{" "}
                        asked
                      </div>

                      <p className="text-white">
                        {format(new Date(questionDetail.sentAt), "MMM-dd-yyyy")}
                      </p>
                    </div>
                    <p className="text-sm leading-6 text-white">
                      {questionDetail.message}
                    </p>
                  </div>
                </>
              ) : (
                /* Any updates from our end can go here */
                <>
                  <div className="relative flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white">
                    <CheckCircleIcon
                      className="h-6 w-6 text-royal-purple"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="flex-auto py-0.5 text-xs leading-5 text-neutral-500">
                    <span className="font-medium text-white">
                      Thank you! We have received your question and Grant will
                      be in touch within 10 days.
                    </span>{" "}
                  </p>
                  <p className="text-white">
                    {format(new Date(), "MMM-dd-yyyy")}
                  </p>
                  {/* <time
                dateTime={activityItem.dateTime}
                className="flex-none py-0.5 text-xs leading-5 text-neutral-500"
              >
                {activityItem.date}
              </time> */}
                </>
              )}
            </li>
          ))}
        </ul>

        {/* New comment form */}
        <div className="mt-4 flex gap-x-3">
          <form
            className="relative flex-auto"
            onSubmit={(ev) => {
              ev.preventDefault();
              submitMessage();
            }}
          >
            <>
              <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-neutral-500 bg-topia-black focus-within:ring-2 focus-within:ring-pale-yellow">
                <label htmlFor="comment" className="sr-only">
                  Add your comment
                </label>
                <textarea
                  value={message}
                  onChange={(ev) => {
                    setMessage(ev.target.value);
                  }}
                  rows={2}
                  name="comment"
                  id="comment"
                  className="block w-full resize-none border-0 bg-transparent py-1.5 text-white placeholder:text-neutral-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Ask your question..."
                  defaultValue={""}
                />
              </div>

              <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                <div className="flex items-center space-x-5"></div>
                <button className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-500 hover:bg-neutral-50">
                  Ask question
                </button>
              </div>
            </>
          </form>
        </div>

        <div className="mt-12 pt-8 border-t border-neutral-500">
          {/* <h3 className="text-xl text-center text-white">Previous Questions</h3> */}

          {/* {askedQuestions.map((q) => (
            <div className="my-4 flex flex-row even:bg-white/5">
              <p className="flex-none p-4 text-white">
                {new Date(q.questionAskedAt).toLocaleDateString()}
              </p>
              <div className="flex-grow p-4 text-white">
                {q.question.slice(0, 80) +
                  (q.question.length > 80 ? "..." : "")}
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </>
  );
}
