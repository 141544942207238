import { renderRichText, storyblokEditable } from "@storyblok/react";
import { topiaBlockClassnames } from "@topia-app/topia-react-web";
import { motion } from "framer-motion";
import { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import useMeasure from "react-use-measure";
const CARD_WIDTH = 300;
const CARD_HEIGHT = 300;
const MARGIN = 20;
const CARD_SIZE = CARD_WIDTH + MARGIN;

const BREAKPOINTS = {
  sm: 640,
  lg: 1024,
};
type List = {
  side_hustle: string;
  name: string;
  stat_1: string;
  case_summary: { content: any };
  _uid: string;
  headshot: { filename: string; alt: string };
  background_image?: { filename: string; alt: string };
};

export function TBCarousel({ blok }) {
  const [ref, { width }] = useMeasure();

  const [offset, setOffset] = useState(0);
  const CARD_BUFFER =
    width > BREAKPOINTS.lg ? 3 : width > BREAKPOINTS.sm ? 2 : 1;

  const CAN_SHIFT_LEFT = offset < 0;

  const CAN_SHIFT_RIGHT =
    Math.abs(offset) <
    CARD_SIZE * ((blok.carousel_block?.length || 0) - CARD_BUFFER);

  const shiftLeft = () => {
    if (!CAN_SHIFT_LEFT) {
      return;
    }
    setOffset((pv) => (pv += CARD_SIZE));
  };

  const shiftRight = () => {
    if (!CAN_SHIFT_RIGHT) {
      return;
    }
    setOffset((pv) => (pv -= CARD_SIZE));
  };

  return (
    <div {...storyblokEditable(blok)} className="pb-12   " ref={ref}>
      {/* <pre>{JSON.stringify(blok, null, 2)}</pre> */}
      <div className={`py-4 overflow-hidden ${topiaBlockClassnames(blok)}`}>
        <h3 className="py-4 text-center text-3xl">{blok.title}</h3>
        <div className="relative ">
          {/* CARDS */}

          {/* <div className="absolute top-0 bottom-0 left-0 z-30 w-[10%]  bg-black/80" /> */}
          {/* <div className="absolute top-0 bottom-0 right-0 z-30  w-[10%]  bg-black/80" /> */}

          <div className={`mx-auto  md:max-w-3xl max-w-4xl`}>
            <motion.div
              animate={{
                x: offset,
              }}
              className="flex"
            >
              {blok.carousel_block?.map((item) => {
                return <Card key={item._uid} {...item} />;
              })}
            </motion.div>
          </div>

          {/* BUTTONS */}
          <>
            <motion.button
              initial={false}
              animate={{
                x: CAN_SHIFT_LEFT ? "0%" : "-100%",
              }}
              className="absolute left-0 top-[80%] z-10 rounded-r-xl bg-slate-500/30 p-2 pl-2 text-3xl text-white backdrop-blur-sm transition-[padding] hover:pl-3"
              onClick={shiftLeft}
            >
              <FiChevronLeft />
            </motion.button>
            <motion.button
              initial={false}
              animate={{
                x: CAN_SHIFT_RIGHT ? "0%" : "100%",
              }}
              className="absolute right-0 top-[80%] z-10 rounded-l-xl bg-slate-500/30 p-2 pr-2 text-3xl text-white backdrop-blur-sm transition-[padding] hover:pr-3"
              onClick={shiftRight}
            >
              <FiChevronRight />
            </motion.button>
          </>
        </div>
      </div>
    </div>
  );
}

const Card = (props: List) => {
  //   console.log(props.background_image?.filename);
  return (
    <div
      title={props.background_image ? props.background_image.alt : ""}
      className="relative  shrink-0 cursor-pointer rounded-2xl bg-topia-gray bg-contain bg-top bg-no-repeat shadow-md transition-all hover:scale-[1.015] hover:shadow-xl"
      style={{
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        marginRight: MARGIN,
        backgroundImage: `url(${
          props.background_image ? props.background_image.filename : ""
        })`,
      }}
    >
      <div className="absolute inset-0 rounded-2xl bg-topia-black p-6 text-white transition-[backdrop-filter] hover:backdrop-blur-sm">
        <div className={`flex flex-row items-center gap-4`}>
          <img
            width={50}
            className={`rounded-full`}
            src={`${props.headshot.filename}`}
            alt={props.headshot.alt}
          />
          <span className="text-md font-bold uppercase text-future-blue">
            {props.name} - {props.side_hustle}
          </span>
        </div>
        <p className="my-2 text-md font-bold">{props.stat_1}</p>
        <div
          // className={`${topiaBlockClassnames(blok)}`}
          className="text-sm text-white"
          dangerouslySetInnerHTML={{
            __html: renderRichText(props.case_summary as any),
          }}
        />
        {/* <p className="text-lg text-slate-300">{props.case_summary}</p> */}
      </div>
    </div>
  );
};
